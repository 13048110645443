import React, { Ref, FC } from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import clsx from 'clsx';
import { object, string } from 'yup';
import { TextField } from 'formik-material-ui';
import ProfilePreview from './ProfilePreview';

interface AddEditProfileFormProps {
  className?: string;
  values?: any;
  formRef?: Ref<any>;
  onSubmit?: (values: any, action: FormikHelpers<any>) => void;
  onDelete?: () => void;
  onClose?: () => void;
  isStyleDirty?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  listName: {
    fontWeight: theme.typography.fontWeightMedium
  },
  checklist: {
    '& + &': {
      marginTop: theme.spacing(3)
    }
  },
  cancelButton: {
    marginRight: 16,
    borderRadius: 8,
    border: '1px solid rgba(145, 158, 171, 0.32)',
    fontSize: 15,
    fontWeight: 700,
    color: '#637381'
  },
  saveButton: {
    background: '#37879A',
    borderRadius: 8,
    color: '#fff',
    fontSize: 15,
    fontWeight: 700,
    '&:hover': {
      background: '#3e98ae'
    },
    '&:disabled': {
      background: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)'
    }
  }
}));

const validationSchema = object().shape({
  name: string()
    .max(255)
    .required('Name is required')
});

const AddEditProfileForm: FC<AddEditProfileFormProps> = ({
  className,
  onSubmit,
  values: initialValues,
  formRef,
  onClose,
  isStyleDirty
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
      >
        {({ handleSubmit, dirty, isValid, isSubmitting }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Box display="flex">
                  <Field name="imageUrl" component={ProfilePreview} />
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Box display="flex">
                  <Field
                    fullWidth
                    type="text"
                    component={TextField}
                    label="Name"
                    name="name"
                    required
                  />
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Box display="flex">
                  <Field
                    fullWidth
                    type="text"
                    component={TextField}
                    label="Role"
                    name="role"
                  />
                </Box>
              </Grid>
              <Grid item sm={12}>
                <Box display="flex">
                  <Field
                    fullWidth
                    type="text"
                    component={TextField}
                    label="Center Name"
                    name="centerName"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              my={2}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Button
                onClick={onClose}
                disabled={isSubmitting}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!(isValid && (dirty || isStyleDirty)) || isSubmitting}
                className={classes.saveButton}
              >
                Preview & Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddEditProfileForm;
