import clsx from 'clsx';
import React, { FC, memo, useEffect, useRef } from 'react';
import {
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction
} from '@material-ui/core';
import moment from 'moment';
import { groupBy, map, size } from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { INotificationByProvider } from 'shared';
import { Theme } from 'src/theme';

const ShadowDiv = ({ htmlContent }) => {
  const shadowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const shadowRoot =
      shadowRef.current?.attachShadow({ mode: 'open' }) || undefined;
    const contentContainer = document.createElement('div');

    if (shadowRoot) {
      contentContainer.innerHTML = htmlContent;
      shadowRoot.appendChild(contentContainer);
    }
  }, [htmlContent]);

  return <div ref={shadowRef}></div>;
};

interface NotificationProps {
  loading?: boolean;
  notifications: INotificationByProvider;
}

interface LoaderProps {
  number?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    height: 315,
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  primaryText: {
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    '&:hover': {
      // textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  secondaryText: {
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '22px',
    color: '#687F92'
  },
  secondaryAction: {
    paddingRight: 75,
    paddingTop: 12,
    paddingBottom: 12
  },
  icon: {
    fontSize: 16
  },
  unreadNotification: {
    borderLeft: '2px solid #9089FF'
  },
  containerList: {
    padding: 0
  },
  notificationIcon: {
    height: 35,
    width: 35,
    alignItems: 'center',
    alignSelf: 'center',
    background: 'rgb(53 76 95 / 40%) !important',
    '& .MuiChip-icon': {
      marginLeft: 18
    }
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    background: '#F4F4F4',
    height: 32,
    padding: '0 10px'
  },
  labelText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontFamily: '"Roboto", sans-serif',
    fontSize: theme.spacing(2),
    lineHeight: '19px',
    letterSpacing: 0.75
  },
  notificationImage: {
    width: '50%'
  }
}));

const Loader: Function = ({ number }: LoaderProps): JSX.Element[] => {
  const classes = useStyles();

  return Array.from({ length: number }).map((v, key) => {
    return (
      <ListItem
        divider
        key={key}
        classes={{
          secondaryAction: classes.secondaryAction
        }}
      >
        <ListItemText
          primary={<Skeleton animation="wave" height={20} width="100%" />}
        />
        <ListItemSecondaryAction>
          <Skeleton variant="circle" width={24} height={24} />
        </ListItemSecondaryAction>
      </ListItem>
    );
  });
};

const Notifications: FC<NotificationProps> = ({ loading, notifications }) => {
  const classes = useStyles();

  function sortByDate(date) {
    const REFERENCE = moment();
    const TODAY = REFERENCE.clone().startOf('day');
    const YESTERDAY = REFERENCE.clone()
      .subtract(1, 'days')
      .startOf('day');
    const A_WEEK_OLD = REFERENCE.clone()
      .subtract(7, 'days')
      .startOf('day');
    const DAY_DIFF = REFERENCE.diff(date, 'days');
    if (moment(date).isSame(TODAY, 'day')) {
      return 'Today';
    } else if (moment(date).isSame(YESTERDAY, 'd')) {
      return 'Yesterday';
    } else if (moment(date).isAfter(A_WEEK_OLD)) {
      return TODAY.subtract(2, 'days')
        .startOf('day')
        .format('dddd');
    } else if (DAY_DIFF >= 7 && DAY_DIFF <= 13) {
      return 'Last week';
    } else {
      return moment(date).format('dddd, MMMM D, YYYY');
    }
  }

  const orderbyNotification = notifications?.items?.map(item => {
    return {
      ...item,
      orderBy: sortByDate(item?.creationTime)
    };
  });
  const groupByNotification = groupBy(orderbyNotification, 'orderBy');

  return (
    <Box className={classes.container}>
      <PerfectScrollbar
        className={classes.containerList}
        options={{ suppressScrollX: true }}
      >
        {loading ? (
          <List disablePadding>
            <Loader number={5} />
          </List>
        ) : (
          map(groupByNotification, (items, itemIndex) => {
            const sizeOfGroup = size(groupByNotification);
            return (
              <>
                {sizeOfGroup > 1 && (
                  <Box className={classes.labelContainer}>
                    <Typography className={classes.labelText}>
                      {itemIndex}
                    </Typography>
                  </Box>
                )}
                <List disablePadding>
                  {map(items, item => (
                    <ListItem
                      divider
                      key={item.id}
                      classes={{
                        root: clsx({
                          [classes.unreadNotification]: item.status === 0
                        }),
                        secondaryAction: classes.secondaryAction
                      }}
                    >
                      <ListItemText
                        classes={{
                          primary: classes.primaryText
                        }}
                        primary={<ShadowDiv htmlContent={item.name} />}
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            );
          })
        )}
        {!loading && notifications?.totalCount < 1 && (
          <Box p={5} display="flex" flexDirection="column" alignItems="center">
            <img
              className={classes.notificationImage}
              src="https://cdn.onevillage.co/global/images/static/home/notification.svg"
              alt="notification"
            />
            <Typography variant="h5" className={classes.secondaryText}>
              You’re all caught up!
            </Typography>
          </Box>
        )}
      </PerfectScrollbar>
    </Box>
  );
};

export default memo(Notifications);
