import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  LinearProgress,
  Popover,
  ButtonBase
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PersonIcon from '@material-ui/icons/Person';
import ThumbnailImg from 'src/components/ThumbnailImg';
import { Theme } from 'src/theme';
import ImageResizeDialog from './ImageResizeDialog';

interface ProfilePreviewProps {
  title?: string;
  field?: any;
  form?: any;
  handleOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  avatarInner: {
    position: 'relative',
    margin: theme.spacing(1, 0, 3)
  },
  avatar: {
    width: 80,
    height: 80,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '50%',
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  },
  avatarIcon: {
    width: '75%',
    height: '75%'
  },
  input: {
    display: 'none'
  },
  editContainer: {
    position: 'absolute',
    bottom: -12,
    right: -16
  },
  editButton: {
    width: 40,
    height: 40,
    padding: 0,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.5)',
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    '&:hover,&:focus,&:active': {
      background: theme.palette.common.white
    }
  },
  primaryText: {
    fontSize: 24,
    lineHeight: '40px',
    color: theme.palette.text.primary
  },
  secondaryText: {
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: theme.palette.text.primary
  },
  linkText: {
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    textDecoration: 'underline',
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.secondary.main
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    objectFit: 'cover'
  },
  popoverPaper: {
    width: 220,
    borderRadius: 12,
    boxShadow: '-20px 20px 40px -4px rgb(145 158 171 / 24%)',
    filter: 'drop-shadow(0px 0px 2px rgba(145, 158, 171, 0.24))'
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    background: 'rgba(255,255,255,0.8)',
    position: 'absolute'
  },
  profilePhotoMenu: { padding: 8 }
}));

const ProfilePreview: FC<ProfilePreviewProps> = ({ title, field, form }) => {
  const classes = useStyles();
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;

  useEffect(() => {
    if (!image) {
      setImage(field?.value);
    }
  }, [image, field, setImage]);

  const handleRemovePhoto = useCallback(async () => {
    setImage(null);
    const { setFieldValue } = form;
    const { name } = field;
    await setFieldValue(name, null);
    handleClose();
  }, [form, field]);

  return (
    <Box className={classes.avatarContainer}>
      <Typography variant="h5" className={classes.primaryText}>
        {title}
      </Typography>
      <Box className={classes.avatarInner}>
        <Box className={classes.avatar}>
          {isLoading && (
            <Box className={classes.loading}>
              <Box width="100%">
                <LinearProgress />
              </Box>
            </Box>
          )}
          {!image && (
            <>
              <PersonIcon className={classes.avatarIcon} />
            </>
          )}
          {image && (
            <ThumbnailImg className={classes.image} url={image} alt="logo" />
          )}
        </Box>
        {!isLoading && (
          <Box className={classes.editContainer}>
            <IconButton
              onClick={handleProfileClick}
              className={classes.editButton}
            >
              <EditOutlinedIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              classes={{ paper: classes.popoverPaper }}
            >
              <Box width="220px">
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap="4px"
                  py="8px"
                >
                  <ButtonBase
                    onClick={() => {
                      setIsDialogOpen(true);
                      handleClose();
                    }}
                    className={classes.profilePhotoMenu}
                  >
                    {image ? 'Change Profile Photo' : 'Add Profile Photo'}
                  </ButtonBase>
                  {image && (
                    <ButtonBase
                      onClick={handleRemovePhoto}
                      className={classes.profilePhotoMenu}
                    >
                      Remove Profile Photo
                    </ButtonBase>
                  )}
                </Box>
              </Box>
            </Popover>
            {/* </label> */}
          </Box>
        )}
      </Box>
      {isDialogOpen && (
        <ImageResizeDialog
          field={field}
          form={form}
          setImage={setImage}
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          setLoading={setIsLoading}
        />
      )}
    </Box>
  );
};

export default ProfilePreview;
