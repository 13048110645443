import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { first } from 'lodash';
import React, { FC, RefObject, useCallback, useRef, useState } from 'react';
import { Theme } from 'src/theme';

export interface FileUploadEvent {
  value: File[];
}

export interface FileUploadDropZoneProps {
  className?: string;
  contentClassName?: string;
  multiple?: boolean;
  setImage?: any;
  id?: string;
  fileInputRef?: RefObject<any>;
  setFile?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: 36,
    borderRadius: theme.spacing(1)
  },
  dragging: {
    borderColor: theme.palette.background.dark
  },
  fileInput: {
    display: 'none'
  },
  contentArea: {
    margin: 'auto',
    cursor: 'pointer'
  }
}));

const FileUploadDropZone: FC<FileUploadDropZoneProps> = ({
  multiple,
  className,
  contentClassName,
  children,
  fileInputRef,
  setImage,
  setFile,
  id
}) => {
  const classes = useStyles();
  const [dragging, setDragging] = useState(false);
  const defaultFileInputRef = useRef<any>();
  const fileControl = fileInputRef ? fileInputRef : defaultFileInputRef;

  const dragenterListener = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.dataTransfer.items && event.dataTransfer.items[0]) {
        setDragging(true);
      } else if (
        event.dataTransfer.types &&
        event.dataTransfer.types[0] === 'Files'
      ) {
        setDragging(true);
      }
    },
    [setDragging]
  );

  const dragleaveListener = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(false);
    },
    [setDragging]
  );

  const dropListener = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setDragging(false);
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        let files = Array.from(event.dataTransfer.files);
        console.log(files);
        if (!multiple) {
          files = [first(files)];
        }
        setFile(files);
        setImage(files);
      }
    },
    [setDragging, multiple, setImage, setFile]
  );

  const onSelectFileClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      fileControl?.current?.click();
    },
    [fileControl]
  );

  const onFileChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () => setImage(reader.result));
        reader.readAsDataURL(event.target.files[0]);
        setFile(event.target.files[0]);
        event.target.value = null;
      }
    },
    [setImage, setFile]
  );

  return (
    <Box
      onDragOver={dragenterListener}
      onDragEnter={dragenterListener}
      onDragLeave={dragleaveListener}
      onDrop={dropListener}
      className={clsx(
        classes.root,
        className,
        dragging ? classes.dragging : null
      )}
    >
      <Box
        className={clsx(classes.contentArea, contentClassName)}
        display="flex"
        alignItems="center"
        alignContent="center"
        onClick={onSelectFileClick}
      >
        {children}
      </Box>
      <input
        ref={fileControl}
        type="file"
        multiple={multiple}
        className={classes.fileInput}
        onChange={onFileChanged}
      />
    </Box>
  );
};

export default FileUploadDropZone;
