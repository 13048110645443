import { createSlice } from '@reduxjs/toolkit';
import {
  AutomationService,
  ProviderBrandingProfileService,
  ProviderService,
  ProviderTemplateService,
  UserProviderService
} from 'shared';
import { AppThunk } from 'src/store';
import { setProviderEmailSettings } from './current-user';

const variablesToRemoveFromPostmarkLayout = [
  '{{#provider_social_facebook}}',
  '{{/provider_social_facebook}}',
  '{{#provider_social_instagram}}',
  '{{/provider_social_instagram}}'
];

export interface SettingsState {
  userSettings: any;
  layouts: any;
  selectedLayout: any;
  isLayoutsLoading: boolean;
  isLayoutLoading: boolean;
  isUpdatingLayoutLoading: boolean;
  brandingProfileInfo: any;
  automations: any;
}

const initialState: SettingsState = {
  userSettings: null,
  layouts: null,
  selectedLayout: null,
  isLayoutsLoading: false,
  isLayoutLoading: false,
  isUpdatingLayoutLoading: false,
  brandingProfileInfo: null,
  automations: null
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setUserSettings(state: SettingsState, action: any) {
      state.userSettings = action.payload;
    },
    setLayoutsLoading(state: SettingsState, action: any) {
      state.isLayoutsLoading = action.payload;
    },
    setLayoutLoading(state: SettingsState, action: any) {
      state.isLayoutLoading = action.payload;
    },
    updatingLayoutSettingsLoading(state: SettingsState, action: any) {
      state.isUpdatingLayoutLoading = action.payload;
    },
    getProviderTemplateLayouts(state: SettingsState, action: any) {
      state.layouts = action.payload;
    },
    getProviderTemplateLayout(state: SettingsState, action: any) {
      state.selectedLayout = action.payload;
    },
    setProviderBrandInfo(state: SettingsState, action: any) {
      state.brandingProfileInfo = action.payload;
    },
    getAutomation(state: SettingsState, action: any) {
      state.automations = action.payload;
    }
  }
});

export const reducer = slice.reducer;

const userProviderService = UserProviderService.getInstance<
  UserProviderService
>();

const automationService = AutomationService.getInstance<AutomationService>();

export const getAutomation = (): AppThunk => dispatch => {
  automationService.getAll().then(res => {
    dispatch(slice.actions.getAutomation(res.data.items));
  });
};

export const getUserSettings = (providerId: string): AppThunk => dispatch => {
  userProviderService.getUserSettings().then(({ data }) => {
    dispatch(slice.actions.setUserSettings(data));
  });
  //   return calendarEventService.getEventByType('Tour').then(({ data }) => {
  //     dispatch(
  //       slice.actions.setTourMeetingDetails({
  //         ...TourInfoMapper({
  //           ...data,
  //           bufferAfterMeeting: String(data.bufferAfterMeeting)
  //         })
  //       })
  //     );
  //     return {
  //       ...TourInfoMapper({
  //         ...data,
  //         bufferAfterMeeting: String(data.bufferAfterMeeting)
  //       })
  //     };
  //   });
};

const providerTemplateService = ProviderTemplateService.getInstance<
  ProviderTemplateService
>();

export const getProviderTemplateLayouts = (): AppThunk => dispatch => {
  dispatch(slice.actions.setLayoutsLoading(true));

  providerTemplateService
    .getProviderTemplateLayouts()
    .then(({ data }) => {
      dispatch(slice.actions.getProviderTemplateLayouts(data));
    })
    .finally(() => dispatch(slice.actions.setLayoutsLoading(false)));
};

export const getProviderTemplateLayout = (
  layoutAlias: string
): AppThunk => dispatch => {
  dispatch(slice.actions.setLayoutLoading(true));

  providerTemplateService
    .getProviderTemplateLayout(layoutAlias)
    .then(({ data }) => {
      let htmlBody = data.htmlBody;
      variablesToRemoveFromPostmarkLayout.forEach(variable => {
        const removeVariable = htmlBody.replaceAll(variable, '');
        htmlBody = removeVariable;
      });
      dispatch(slice.actions.getProviderTemplateLayout({ ...data, htmlBody }));
    })
    .finally(() => dispatch(slice.actions.setLayoutLoading(false)));
};

export const setProviderTemplateLayout = (
  layoutAlias: string
): AppThunk => dispatch => {
  dispatch(slice.actions.updatingLayoutSettingsLoading(true));

  providerTemplateService
    .updateProviderTemplateLayout(layoutAlias)
    .finally(() =>
      dispatch(slice.actions.updatingLayoutSettingsLoading(false))
    );
};

const providerBrandingProfileService = ProviderBrandingProfileService.getInstance<
  ProviderBrandingProfileService
>();

export const getProviderBrandingProfileInfo = (
  type: string | number
): AppThunk => dispatch => {
  providerBrandingProfileService
    .getInfoByType(type)
    .then(res => dispatch(slice.actions.setProviderBrandInfo(res.data)));
};

export const updateProviderBrandingProfileInfo = (
  providerBrandingProfileId: string | number,
  request: any
): AppThunk => dispatch => {
  dispatch(slice.actions.updatingLayoutSettingsLoading(true));

  providerBrandingProfileService
    .updateInfo(providerBrandingProfileId, request)
    .then(res => {
      dispatch(slice.actions.setProviderBrandInfo(res.data));
    })
    .finally(() =>
      dispatch(slice.actions.updatingLayoutSettingsLoading(false))
    );
};

const providerService = ProviderService.getInstance<ProviderService>();

export const updateProviderEmailSettings = (request: {
  emailSync: boolean;
}): AppThunk => dispatch => {
  dispatch(slice.actions.updatingLayoutSettingsLoading(true));

  providerService
    .updateProviderEmailSettings(request)
    .then(res => dispatch(setProviderEmailSettings(res.data)))
    .finally(() =>
      dispatch(slice.actions.updatingLayoutSettingsLoading(false))
    );
};

export default slice;
