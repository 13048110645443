import { createSlice } from '@reduxjs/toolkit';
import {
  SubscriptionService,
  SubscriptionTransactionService,
  ISubscriptionTransaction
} from 'shared';
import { AppThunk } from 'src/store';

export interface SubscriptionState {
  paymentMethod: any;
  paymentHistory: ISubscriptionTransaction[];
  paymentMethods: any[];
}

const initialState: SubscriptionState = {
  paymentMethod: null,
  paymentHistory: [],
  paymentMethods: []
};

const slice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setPaymentMethod(state: SubscriptionState, action: any) {
      state.paymentMethod = action.payload;
    },
    setPaymentHistory(state: SubscriptionState, action: any) {
      state.paymentHistory = action.payload;
    },
    setPaymentMethods(state: SubscriptionState, action: any) {
      state.paymentMethods = action.payload;
    }
  }
});

export const reducer = slice.reducer;

const subscriptionTransactionService = SubscriptionTransactionService.getInstance<
  any
>();

const subscriptionService = SubscriptionService.getInstance<any>();

export const getPaymentMethod = (): AppThunk => dispatch => {
  subscriptionService.getPaymentMethod().then(res => {
    if (res.data !== '') {
      dispatch(slice.actions.setPaymentMethod(res.data));
    }
  });
};

export const getAllPaymentMethods = (): AppThunk => dispatch => {
  subscriptionService
    .getAllPaymentMethods()
    .then(res => dispatch(slice.actions.setPaymentMethods(res.data)));
};

export const getPaymentHistory = (): AppThunk => dispatch => {
  subscriptionTransactionService
    .getPaymentHistory()
    .then(res => dispatch(slice.actions.setPaymentHistory(res.data)));
};

export const createNewPaymentMethod = (
  paymentmethodId: string
): AppThunk => dispatch => {
  subscriptionService.createNewPaymentMethod(paymentmethodId).then(res => {
    if (res.data !== '') {
      dispatch(slice.actions.setPaymentMethod(res.data));
    }
  });
};

export default slice;
