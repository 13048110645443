import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import {
    TaskService,
    NotificationService,
    SearchService,
    ProviderSummaryService,
    IProviderSummary,
    ITask,
    INotificationByProvider,
    MicrositeActivityService,
    IMicrositeActivity,
    INotification,
    TaskStatusEnum,
    TaskAssociationEnum,
    ProviderUserNoteService, ProviderGettingStartedService,
    IProviderGettingStarted
} from 'shared';
import { sortBy, chain, findIndex, cloneDeep } from "lodash";

interface DashboardState {
    isLoaded: boolean;
    isProfileDialog: boolean;
    isLogoCollectionDialog: boolean;
    microsite: IMicrositeActivity;
    providerSummary: IProviderSummary;
    isProviderSummaryLoading: boolean;
    setupTasks: {
        items: ITask[],
        totalCount: number;
    };
    currentTask: ITask;
    notifications: INotificationByProvider;
    unreadNotification: INotificationByProvider;
    notes: {
        isLoading: boolean;
        items: any[];
        totalCount: number;
    };
    globalSearch: any;
    sessionTimeoutDetails: any;
    gettingStarted: IProviderGettingStarted;
};

const initialState: DashboardState = {
    isLoaded: false,
    isProfileDialog: false,
    isLogoCollectionDialog: false,
    microsite: {
        items: [],
        totalCount: 0,
    },
    providerSummary: {      
        todayCount: 0,
        nextDaysCount: 0,
        overdueToursCount: 0,
        todayToursCount: 0,
        nextDaysToursCount: 0,
        providerId: null,
        thisWeekInfo: null,
        familiesSummary: [],
        overdueTours: [],
        todayTours: [],
        nextDaysTours: [],
    },
    isProviderSummaryLoading: false,
    setupTasks: {
        items: [],
        totalCount: 0
    },
    currentTask: null,
    notifications: {
        items: null,
        totalCount: 0
    },
    unreadNotification: {
        items: null,
        totalCount: 0
    },
    notes: {
        isLoading: false,
        items: [],
        totalCount: 0,
    },
    globalSearch: null,
    sessionTimeoutDetails: null,
    gettingStarted: {
        providerId: null,
        tenantId: null,
        completedGettingStartedGuide: null,
        providerGettingStartedGuides: []
    }
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setSetupTasks(state: DashboardState, action: PayloadAction<{ setupTasks: { items: ITask[], totalCount: number } }>) {
            state.setupTasks = action.payload.setupTasks;
        },
        setCurrentTask(state: DashboardState, action: PayloadAction<{ task: ITask }>) {
            state.currentTask = action.payload.task;
        },
        getGlobalSearch(state: DashboardState, action: PayloadAction<any>) {
            state.globalSearch = action.payload;
        },
        setSessionTimeoutDetails(state: DashboardState, action: PayloadAction<{ sessionTimeoutDetails: any }>) {
            state.sessionTimeoutDetails = action.payload.sessionTimeoutDetails;
        },
        resetGlobalSearch(state: DashboardState, action: PayloadAction) {
            state.globalSearch = null;
        },
        setProfileDialog(state: DashboardState, action: PayloadAction<boolean>) {
            state.isProfileDialog = action.payload;
        },
        setLogoCollectionDialog(state: DashboardState, action: PayloadAction<boolean>) {
            state.isLogoCollectionDialog = action.payload;
        },
        getMicrositeActivity(state: DashboardState, action: PayloadAction<{ microsite: IMicrositeActivity }>) {
            state.microsite = action.payload.microsite;
        },
        getProviderSummary(state: DashboardState, action: PayloadAction<{ providerSummary: IProviderSummary }>) {
            state.providerSummary = action.payload.providerSummary;
        },
        updateProviderSummary(state: DashboardState, action: PayloadAction<{ providerSummary: IProviderSummary }>) {
            state.providerSummary = action.payload.providerSummary;
        },
        setProviderSummaryLoading(state: DashboardState,action: PayloadAction<boolean>) {
            state.isProviderSummaryLoading = action.payload;
        },
        getNotifications(state: DashboardState, action: PayloadAction<{ notifications: INotificationByProvider }>) {
            state.notifications = action.payload.notifications;
        },
        getUnReadNotifications(state: DashboardState, action: PayloadAction<{ unreadNotification: INotificationByProvider }>) {
            state.unreadNotification = action.payload.unreadNotification
        },
        setNoteList(state: DashboardState, action: PayloadAction<{ items: any[], totalCount: number }>) {
            state.notes.items = action.payload.items.reverse();
            state.notes.totalCount = action.payload.totalCount;
        },
        setNewNote(state: DashboardState, action: PayloadAction<any>) {
            state.notes.items = [action.payload, ...state.notes.items];
            state.notes.totalCount = state.notes.totalCount + 1;
        },
        setNoteLoading(state: DashboardState, action: PayloadAction<{ isLoading: boolean }>) {
            state.notes.isLoading = action.payload.isLoading
        },
        removeNote(state: DashboardState, action: PayloadAction<{ id: string }>) {
            state.notes.items = state.notes.items.filter((note) => note.id !== action.payload.id);
            state.notes.totalCount = state.notes.totalCount - 1;
        },
        updateNote(state: DashboardState, action: PayloadAction<any>) {
            const items = state.notes.items.filter((note) => note.id !== action.payload.id);
            state.notes.items = [action.payload, ...items]
        },
        setGettingStarted(state: DashboardState, action: PayloadAction<IProviderGettingStarted>) {
            state.gettingStarted = action.payload;
        }
    }
});

export const reducer = slice.reducer;

const micrositeActivityService = MicrositeActivityService.getInstance<MicrositeActivityService>();
const providerSummaryService = ProviderSummaryService.getInstance<ProviderSummaryService>();
const taskService = TaskService.getInstance<TaskService>();
const notificationService = NotificationService.getInstance<NotificationService>();
const searchService = SearchService.getInstance<SearchService>();
const providerGettingStartedService = ProviderGettingStartedService.getInstance<ProviderGettingStartedService>();

export const getMicrositeActivity = (): AppThunk => (dispatch) => {
    return micrositeActivityService.get(1000).then((response) => {
        const microsite = response.data;
        dispatch(slice.actions.getMicrositeActivity({ microsite }));
    })
};

export const getGlobalSearch = (searchValue: string): AppThunk => (dispatch) => {
    return searchService.globalSearch(searchValue).then((response) => {
        const data = response.data;
        dispatch(slice.actions.getGlobalSearch(data));
    })
};

export const resetGlobalSearch = (): AppThunk => dispatch => {
    dispatch(slice.actions.resetGlobalSearch());
  };

export const getProviderSummary = (): AppThunk => (dispatch) => {
    dispatch(slice.actions.setProviderSummaryLoading(true));
    return providerSummaryService.get().then((response) => {
        const providerSummary = response.data;
        dispatch(slice.actions.getProviderSummary({ providerSummary }));
        dispatch(slice.actions.setProviderSummaryLoading(false));
    })
}

export const updateSessionTimeout = (sessionTimeoutDetails: any): AppThunk => (dispatch) => {
    dispatch(slice.actions.setSessionTimeoutDetails({ sessionTimeoutDetails }));
}

export const updateProviderSummary = (summary): AppThunk => async (dispatch, getState) => {
    const { providerSummary } = getState().dashboard;
    let nextDaysTours = [...providerSummary.nextDaysTours];
    let overdueTours = [...providerSummary.overdueTours];
    let todayTours = [...providerSummary.todayTours];

    const fourWeekToursIndex = nextDaysTours.findIndex(item => item.date === summary.date && item.startTime === summary.startTime && item.endTime === summary.endTime);
    if (fourWeekToursIndex !== -1) {
        nextDaysTours.splice(fourWeekToursIndex, 1, summary);
    }

    const thisWeekToursIndex = overdueTours.findIndex(item => item.date === summary.date && item.startTime === summary.startTime && item.endTime === summary.endTime);
    if (thisWeekToursIndex !== -1) {
        overdueTours.splice(thisWeekToursIndex, 1, summary);
    }

    const nextWeekToursIndex = todayTours.findIndex(item => item.date === summary.date && item.startTime === summary.startTime && item.endTime === summary.endTime);
    if (nextWeekToursIndex !== -1) {
        todayTours.splice(nextWeekToursIndex, 1, summary);
    }

    dispatch(slice.actions.updateProviderSummary({ providerSummary: { ...providerSummary, nextDaysTours, overdueTours, todayTours } }));
};

export const getSetupTasks = (forceFetch = false): AppThunk => async (dispatch, getState) => {
    const { setupTasks } = getState().dashboard;
    if (forceFetch || !(setupTasks?.items?.length > 0)) {
        const data = await taskService.getTasksByProvider().then(({ data }) => {
            data.items = updateTaskOrder(data.items)
            const task = getCurrentTask(data.items);
            dispatch(slice.actions.setCurrentTask({ task }));
            dispatch(slice.actions.setSetupTasks({ setupTasks: data }));
            return data
        });
        return data;
    } else {
        return setupTasks;
    }
}

export const makeTaskDone = (item: ITask, status = TaskStatusEnum.Done): AppThunk => (dispatch, getState) => {
    const { setupTasks } = getState().dashboard;
    return taskService.update(item.id, {
        ...item,
        status,
    })
        .then(({ data }) => {
            const items = cloneDeep(setupTasks.items);
            const index = findIndex(setupTasks.items, { id: data.id });
            items.splice(index, 1, data);
            const task = getCurrentTask(items);
            dispatch(slice.actions.setCurrentTask({ task }));
            dispatch(slice.actions.setSetupTasks({ setupTasks: { ...setupTasks, items } }));
            return setupTasks
        });
}

export const getNotifications = (): AppThunk => (dispatch) => {
    return notificationService.getNotificationsOfLastMonthByProvider().then((response) => {
        const notifications = response.data;
        dispatch(slice.actions.getNotifications({ notifications }));
    });
}

export const getUnReadNotifications = (): AppThunk => (dispatch) => {
    return notificationService.getUnreadNotificationsByProvider().then((response) => {
        const unreadNotification = response.data;
        dispatch(slice.actions.getUnReadNotifications({ unreadNotification }));
    });
}

export const setNotifications = (notifications: INotificationByProvider): AppThunk => (dispatch) => {
    dispatch(slice.actions.getNotifications({ notifications }));
}

export const pushNotification = (notification: INotification): AppThunk => (dispatch, getState) => {
    const { unreadNotification } = getState().dashboard;
    const items: INotification[] = [notification, ...(unreadNotification?.items || [])];
    dispatch(slice.actions.getUnReadNotifications({
        unreadNotification: {
            items,
            totalCount: (unreadNotification.totalCount || 0) + 1,
        }
    }));
}

export const markAsReadNotification = (notificationsId: string[]): AppThunk => (dispatch, getState) => {
    const { notifications } = getState().dashboard;
    const items: INotification[] = notifications?.items?.map((item) => {
        if (notificationsId.includes(item.id)) {
            return {
                ...item,
                status: 1
            }
        }
        return item;
    });
    dispatch(slice.actions.getNotifications({
        notifications: {
            items,
            totalCount: notifications.totalCount,
        }
    }))
    return notificationService.markAsRead({ notificationsId });
};

export const setProfileDialog = (value: boolean): AppThunk => {
    return async (dispatch) => {
        dispatch(slice.actions.setProfileDialog(value));
    }
}

export const setLogoCollectionDialog = (value: boolean): AppThunk => {
    return async (dispatch) => {
        dispatch(slice.actions.setLogoCollectionDialog(value));
    }
}

export default slice;


function updateTaskOrder(items: ITask[]) {
    const order = [
        TaskAssociationEnum.PROFILE,
        TaskAssociationEnum.MICROSITE,
    ];
    return sortBy(items, (task) => order.indexOf(task.association));
}

function getCurrentTask(item: ITask[]) {
    return chain(item).filter({ status: TaskStatusEnum.NotDone }).first().value();
}

// Note APIs

const providerUserNoteService = ProviderUserNoteService.getInstance<ProviderUserNoteService>();

export const getNotes = (): AppThunk => (dispatch) => {
    dispatch(slice.actions.setNoteLoading({ isLoading: true }));
    return providerUserNoteService.get().then((response) => {
        dispatch(slice.actions.setNoteList(response.data));
    }).finally(() => {
        dispatch(slice.actions.setNoteLoading({ isLoading: false }));
    });
};

export const createNote = (request: any): AppThunk => (dispatch) => {
    dispatch(slice.actions.setNoteLoading({ isLoading: true }));
    return providerUserNoteService.post(request).then((response) => {
        dispatch(slice.actions.setNewNote(response.data));
    }).finally(() => {
        dispatch(slice.actions.setNoteLoading({ isLoading: false }));
    });
};

export const updateNote = (id: string, request: any): AppThunk => (dispatch) => {
    dispatch(slice.actions.setNoteLoading({ isLoading: true }));
    return providerUserNoteService.putById(id, request).then((response) => {
        dispatch(slice.actions.updateNote(response.data));
    }).finally(() => {
        dispatch(slice.actions.setNoteLoading({ isLoading: false }));
    });
};

export const removeNote = (id: string): AppThunk => (dispatch) => {
    dispatch(slice.actions.setNoteLoading({ isLoading: true }));
    return providerUserNoteService.delete(id).then(() => {
        dispatch(slice.actions.removeNote({ id }));
    }).finally(() => {
        dispatch(slice.actions.setNoteLoading({ isLoading: false }));
    });
};

export const getGettingStartedInfo = (): AppThunk => (dispatch) => {
    return providerGettingStartedService.get().then((response) => {
        dispatch(slice.actions.setGettingStarted(response.data));
    });
}

export const updateGettingStartedStatus = (id: string): AppThunk => (dispatch) => {
    return providerGettingStartedService.updateGettingStartedStatus(id).then(response => {
        dispatch(slice.actions.setGettingStarted(response.data));
    });
}

export const createGettingStartedAction = (request: any): AppThunk => (dispatch) => {
    return providerGettingStartedService.createGettingStartedAction(request).then(response => {
        dispatch(slice.actions.setGettingStarted(response.data));
    });
};