import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { FeatureFlagService, IProfile, IProvider, IProviderSubscription, ProfileService, SubscriptionService, IFeatureFlag, IUpdateProviderEmailSettings } from 'shared';
import { ProviderService } from 'shared';

export interface CurrentUserState {
  provider: IProvider;
  subscription: IProviderSubscription;
  user: IProfile;
  featureFlags: IFeatureFlag;
};

const initialState: CurrentUserState = {
  provider: null,
  subscription: null,
  user: null,
  featureFlags: null,
};

const slice = createSlice({
  name: 'current-user',
  initialState,
  reducers: {
    setCurrentUser(state: CurrentUserState, action: PayloadAction<{ provider: IProvider; }>) {
      const { provider } = action.payload;
      state.provider = provider;
    },
    setSubscription(state: CurrentUserState, action: PayloadAction<{ subscription: IProviderSubscription; }>) {
      const { subscription } = action.payload;
      state.subscription = subscription;
    },
    setFeatureFlags(state: CurrentUserState, action: PayloadAction<{ featureFlags: IFeatureFlag; }>) {
      const { featureFlags } = action.payload;
      state.featureFlags = featureFlags;
    },
    setUser(state: CurrentUserState, action: PayloadAction<{ user: IProfile; }>) {
      const { user } = action.payload;
      state.user = user;
    },
    setProviderEmailSettings(state: CurrentUserState, action: PayloadAction<{ emailSettings: IUpdateProviderEmailSettings; }>) {
      const { emailSettings } = action.payload;
      state.provider = { ...state.provider, ...emailSettings  };
    }
  }
});

export const reducer = slice.reducer;

const providerService = ProviderService.getInstance<ProviderService>();
const subscriptionService = SubscriptionService.getInstance<SubscriptionService>();
const profileService = ProfileService.getInstance<ProfileService>();
const featureFlagsService = FeatureFlagService.getInstance<FeatureFlagService>();

export const getUser = (): AppThunk => {
  return async dispatch => {
    profileService.getOVProfile().then((response) => {
      dispatch(slice.actions.setUser({ user: response.data }));
    });
  }
}

export const setProviderEmailSettings = (layoutSettings): AppThunk => {
  return dispatch => {
    dispatch(slice.actions.setProviderEmailSettings({ emailSettings: layoutSettings }));
  }
}

export const getUserById = (id): AppThunk => 
   (dispatch) => {
   return providerService.get(id).then((response) => {
      dispatch(slice.actions.setCurrentUser({ provider: response.data }));
      return response.data;
    });
  }


export const setCurrentProviderData = (data): AppThunk => 
(dispatch) => {
  dispatch(slice.actions.setCurrentUser({ provider: data }));
  return data;
}

export const setCurrentUserSubscription = (data): AppThunk => 
(dispatch) => {
  dispatch(slice.actions.setSubscription({ subscription: data }));
  return data;
}


export const updateCurrentUser = (data): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setCurrentUser({ provider: data }));
  }
}

export const updateUser = (data): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setUser({ user: data }));
  }
}

export const getCurrentUserSubscription = (force = false): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    if (!state?.currentUser?.subscription || force) {
      const newSubscription = subscriptionService.getProviderSubscription().then((response) => {
        dispatch(slice.actions.setSubscription({ subscription: response.data }));
        return response.data;
      });
      return newSubscription;
    } else {
      return state?.currentUser?.subscription;
    }
  }
}

export const getFeatureFlagsByProvider = (providerId: string): AppThunk => {
  return (dispatch) => {
    return featureFlagsService.getFeatureFlagsByProvider(providerId).then(({ data }) => {
      dispatch(slice.actions.setFeatureFlags({ featureFlags: data }));
      return data;
    });
  }
}

export const setProviderFeatureFlags = (data: any): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setFeatureFlags({featureFlags: data}))
  }
}

export const updateUserSubscription = (data): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setSubscription({ subscription: data }));
  }
}

export default slice;
