import React, { useCallback } from 'react';
import {
  makeStyles,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme } from 'src/theme';
import SettingInfoCard from '../SettingInfoCard';

const useStyles = makeStyles((theme: Theme) => ({
  accordionContainer: {
    '& .MuiAccordionSummary-root': {
      backgroundColor: '#DFE3E8'
    },
    '& .MuiCollapse-container': {
      backgroundColor: '#F4F6F8'
    }
  }
}));

const SignatureTab = ({
  closingSignature,
  setClosingSignature,
  contactProfile,
  setContactProfile,
  setIsDirty
}) => {
  const classes = useStyles();

  const handleProfileChange = useCallback(
    (event, name) => {
      event.persist();
      setContactProfile(prevState => ({
        ...prevState,
        [name]: event.target.value
      }));
      setIsDirty(true);
    },
    [setContactProfile, setIsDirty]
  );

  const handleSignatureChange = useCallback(
    (event, name) => {
      event.persist();
      setClosingSignature(prevState => ({
        ...prevState,
        [name]: event.target.value
      }));
      setIsDirty(true);
    },
    [setClosingSignature, setIsDirty]
  );

  const handleSignatureSwitch = useCallback(
    (e, valueName) => {
      e.persist();
      setClosingSignature(prevState => ({
        ...prevState,
        [valueName]: e.target.checked
      }));
      setIsDirty(true);
    },
    [setClosingSignature, setIsDirty]
  );

  const handleProfileSwitch = useCallback(
    (e, valueName) => {
      e.persist();
      setContactProfile(prevState => ({
        ...prevState,
        [valueName]: e.target.checked
      }));
      setIsDirty(true);
    },
    [setContactProfile, setIsDirty]
  );

  return (
    <Box display="flex" flexDirection="column" gridGap="10px">
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="email-closing"
          id="email-closing"
        >
          <Typography>Closing Signature</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {closingSignature && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gridGap={10}
            >
              <SettingInfoCard
                title="Closing"
                value={closingSignature?.closing}
                isChecked={closingSignature?.closingActive}
                handleSwitchChange={e =>
                  handleSignatureSwitch(e, 'closingActive')
                }
                handleInputChange={e => handleSignatureChange(e, 'closing')}
                disabled={closingSignature?.closingDisabled}
              />
              <SettingInfoCard
                title="Name"
                value={closingSignature?.name}
                isChecked={closingSignature?.nameActive}
                handleSwitchChange={e => handleSignatureSwitch(e, 'nameActive')}
                handleInputChange={e => handleSignatureChange(e, 'name')}
                disabled={closingSignature?.nameDisabled}
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="email"
          id="email"
        >
          <Typography>Contact Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {contactProfile && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gridGap={10}
            >
              <SettingInfoCard
                title="Full Name"
                value={contactProfile?.full_name}
                isChecked={contactProfile?.fullNameActive}
                handleSwitchChange={e =>
                  handleProfileSwitch(e, 'fullNameActive')
                }
                handleInputChange={e => handleProfileChange(e, 'full_name')}
                disabled={contactProfile?.fullNameDisabled}
              />
              <SettingInfoCard
                title="Role"
                value={contactProfile?.role}
                isChecked={contactProfile?.roleActive}
                handleSwitchChange={e => handleProfileSwitch(e, 'roleActive')}
                handleInputChange={e => handleProfileChange(e, 'role')}
                disabled={contactProfile?.roleDisabled}
              />
              <SettingInfoCard
                title="Phone"
                value={contactProfile?.phone}
                isChecked={contactProfile?.phoneActive}
                handleSwitchChange={e => handleProfileSwitch(e, 'phoneActive')}
                handleInputChange={e => handleProfileChange(e, 'phone')}
                disabled={contactProfile?.phoneDisabled}
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SignatureTab;
