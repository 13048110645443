import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

const LayoutTabSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" gridGap={10}>
      <Box>
        <Skeleton />
        <Skeleton variant="rect" width={150} height={150} />
      </Box>
      <Box>
        <Skeleton />
        <Skeleton variant="rect" width={150} height={150} />
      </Box>
    </Box>
  );
};

export default LayoutTabSkeleton;
