import { createSlice } from '@reduxjs/toolkit';
import {
  IProviderTemplateContent,
  ITemplateContentInput,
  ProviderSectionTemplateService,
  ProviderTemplateContentService,
  ProviderTemplateService
} from 'shared';
import { AppThunk } from 'src/store';

export interface EmailTemplateState {
  templateSections: any[];
  templateSectionsData: any[];
  selectedTemplateSection: any;
  providerTemplateContent: IProviderTemplateContent | null;
}

const initialState: EmailTemplateState = {
  templateSections: [],
  templateSectionsData: [],
  selectedTemplateSection: '',
  providerTemplateContent: null
};

const slice = createSlice({
  name: 'emailTemplate',
  initialState,
  reducers: {
    setSectionTemplates(state: EmailTemplateState, action: any) {
      state = { ...state, templateSections: action.payload };
    },
    setSelectedTemplateSection(state: EmailTemplateState, action: any) {
      state = { ...state, selectedTemplateSection: action.payload };
    },
    setProviderTemplateContent(state: EmailTemplateState, action: any) {
      state = { ...state, providerTemplateContent: action.payload };
    },
    setTemplateSectionsData(state: EmailTemplateState, action: any) {
      state = { ...state, templateSectionsData: action.payload };
    }
  }
});

export const reducer = slice.reducer;

const providerSectionTemplateService = ProviderSectionTemplateService.getInstance<
  ProviderSectionTemplateService
>();

const providerTemplateService = ProviderTemplateService.getInstance<
  ProviderTemplateService
>();

const providerTemplateContentService = ProviderTemplateContentService.getInstance<
  ProviderTemplateContentService
>();

export const getSectionTemplates = (): AppThunk => dispatch => {
  return providerSectionTemplateService
    .getSectionTemplates()
    .then(({ data }) => {
      dispatch(slice.actions.setSectionTemplates(data));
      return data;
    });
};

export const getTemplateSectionByAlias = (request: {
  postmarkAlias: string;
  settingsJson?: string;
  htmlBody?: string;
}): AppThunk => dispatch => {
  return providerTemplateService
    .getTemplateSectionByAlias(request)
    .then(({ data }) => {
      dispatch(slice.actions.setSelectedTemplateSection(data));
      return data;
    });
};

export const updateProviderTemplateContent = (
  request: ITemplateContentInput
): AppThunk => dispatch => {
  return providerTemplateContentService
    .getTemplateContentByVariable(request)
    .then(({ data }) => {
      dispatch(slice.actions.setProviderTemplateContent(data));
      return data;
    });
};

export const getTemplateContentByType = (): AppThunk => dispatch => {
  return providerTemplateContentService
    .getTemplateContentByType()
    .then(({ data }) => {
      dispatch(slice.actions.setTemplateSectionsData(data));
      return data;
    });
};

export default slice;
