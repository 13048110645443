import React, { FC } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
// import Hero from './Hero';
// import Features from './Features';
// import Testimonials from './Testimonials';
// import CTA from './CTA';
// import FAQS from './FAQS';

const useStyles = makeStyles(() => ({
  root: {},
  mainContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const HomeView: FC = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      {/* <Hero />
      <Features />
      <Testimonials />
      <CTA />
      <FAQS /> */}
      <Box className={classes.mainContainer}>
        <Typography variant="h1">Provider Portal</Typography>
      </Box>
    </Page>
  );
};

export default HomeView;
