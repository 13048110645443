import React, { FC, useCallback, useEffect, useState } from 'react';
import { EditorContent, useEditor, BubbleMenu } from '@tiptap/react';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import StarterKit from '@tiptap/starter-kit';
import TiptapTypography from '@tiptap/extension-typography';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  makeStyles,
  Typography,
  // Select,
  // MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  Box
  // Checkbox
} from '@material-ui/core';
import { useInView } from 'react-cool-inview';
import classNames from 'classnames';
import {
  RiBold,
  RiItalic,
  RiLinkM,
  RiListUnordered,
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6
} from 'react-icons/ri';

export interface TiptapProps {
  isPrimary?: boolean;
  tiptap_content?: any;
  onUpdateContent?: (content: any) => void;
  getNewLink?: (link: string) => void;
  getNewButtonLabel?: (label: string) => void;
  favouriteLinks?: string[];
  hasInitialValue?: boolean;
  editable?: boolean;
  boldItemShow?: boolean;
  italicItemShow?: boolean;
  linkItemShow?: boolean;
  buttonItemShow?: boolean;
  listItemShow?: boolean;
  h1ItemShow?: boolean;
  h2ItemShow?: boolean;
  h3ItemShow?: boolean;
  h4ItemShow?: boolean;
  h5ItemShow?: boolean;
  h6ItemShow?: boolean;
  buttonExistingLabel?: string;
  buttonExistingLink?: string;
  setTiptapEditor?: any;
}

const Tiptap: FC<TiptapProps> = props => {
  const {
    tiptap_content,
    onUpdateContent,
    getNewLink,
    getNewButtonLabel,
    // favouriteLinks,
    editable,
    hasInitialValue = true,
    boldItemShow = true,
    italicItemShow = true,
    linkItemShow = true,
    listItemShow = true,
    buttonItemShow = false,
    h1ItemShow = true,
    h2ItemShow = true,
    h3ItemShow = true,
    h4ItemShow = true,
    h5ItemShow = true,
    h6ItemShow = true,
    isPrimary = false,
    buttonExistingLabel = null,
    buttonExistingLink = null,
    setTiptapEditor
  } = props;
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [toggleButtonModal, setToggleButtonModal] = useState<boolean>(false);
  const [newLink, setNewLink] = useState<any>(null);
  const [buttonLink, setButtonLink] = useState<any>(null);
  const [buttonLabel, setButtonLabel] = useState<any>(null);
  // const [saveAsFavourite, setSaveAsFavourite] = useState<any>(false);
  const useStyles = makeStyles((theme: Theme) => ({
    tiptap: {
      '& ul': {
        padding: '0 1rem'
      }
    },
    toolbarContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      position: 'sticky',
      top: 0,
      borderRadius: '0.5rem',
      border: '1px solid #37879A',
      boxShadow: '',
      backgroundColor: '#fff',
      padding: '0 0.5rem',
      zIndex: 1,
      '& .stick': {
        borderTop: '2px solid transparent',
        boxShadow: '0px 3px 5px -3px rgba(51, 51, 51, 0.5)'
      }
    },
    toolbar: {
      display: 'flex',
      '& .icon': {
        borderRadius: '5px',
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#1e1e1e'
      },
      '& .icon.disabled': {
        color: 'rgba(51, 51, 51, 0.5)',
        pointervents: 'none'
      },
      '& .icon:hover': {
        background: '#333333',
        color: '#ffffff',
        cursor: 'pointer'
      },
      '& .divider': {
        border: 'none',
        borderLeft: '2px solid rgba(51, 51, 51, 0.2)',
        margin: '2px 10px'
      },
      '& .icon+.icon': {
        marginLeft: '4px'
      }
    },
    content: {
      '& > div': {
        padding: '5px 5px',
        borderRadius: '5px',
        fontSize: 16,
        color: isPrimary ? '#ffffff' : '#666666'
      },
      '& > div:focus': {
        outline: 'none',
        outlineStyle: editable && 'solid',
        outlineColor: editable && '#37879A',
        outlineWidth: editable && 'thin'
      },
      '& a': {
        cursor: 'pointer'
      }
    },
    messageContent: {
      '& > div': {
        padding: '24px 16px',
        borderRadius: '5px',
        fontSize: 16,
        color: isPrimary ? '#ffffff' : '#666666'
      },
      '& > div:focus': {
        outline: 'none',
        outlineStyle: editable && 'solid',
        outlineColor: editable && '#37879A',
        outlineWidth: editable && 'thin'
      },
      '& a': {
        cursor: 'pointer'
      }
    },
    selectedItem: {
      backgroundColor: '#333333',
      color: '#ffffff !important'
    },
    buttonContainer: {
      color: '#37879A',
      minWidth: 80
    },
    marginLeft: {
      marginLeft: 4
    }
  }));
  const classes = useStyles();
  const { observe, inView } = useInView({
    rootMargin: '-1px 0px 0px 0px',
    threshold: [1]
  });
  const editor = useEditor({
    extensions: [
      StarterKit,
      TiptapTypography,
      ListItem,
      Paragraph,
      Link.configure({
        openOnClick: false
      }),
      BulletList.configure({
        keepMarks: true
      }),
      Placeholder.configure({
        // Use a placeholder:
        placeholder: 'Type a message'
        // Use different placeholders depending on the node type:
        // placeholder: ({ node }) => {
        //   if (node.type.name === 'heading') {
        //     return 'What’s the title?'
        //   }

        //   return 'Can you add some further context?'
        // },
      })
    ],
    content: tiptap_content,
    onUpdate: () => onUpdateContent(editor.getHTML())
  });

  useEffect(() => {
    if (editor) {
      if (setTiptapEditor) {
        setTiptapEditor(editor);
      }
      editor.options.editable = editable;
      if (hasInitialValue === false) {
        editor.commands.setContent(tiptap_content);
      }
    }
  }, [editor, editable, tiptap_content, hasInitialValue, setTiptapEditor]);

  useEffect(() => {
    if (buttonItemShow) {
      if (buttonExistingLabel !== null) {
        setButtonLabel(buttonExistingLabel);
      }
      if (buttonExistingLink !== null) {
        setButtonLink(buttonExistingLink);
      }
    }
  }, [buttonExistingLabel, buttonExistingLink, buttonItemShow]);

  const openLinkModal = useCallback(() => {
    if (!editor.isActive('link')) {
      setNewLink(null);
      setToggleModal(true);
    } else {
      setNewLink(editor.getAttributes('link').href);
      setToggleModal(true);
      // editor
      //   .chain()
      //   .focus()
      //   .unsetLink()
      //   .run();
    }
  }, [editor]);

  const openButtonLinkModal = useCallback(() => {
    setToggleButtonModal(true);
  }, []);

  const setButtonLinkToggle = () => {
    // if (isButton) {
    getNewLink(buttonLink);
    getNewButtonLabel(buttonLabel);
    // }
    setToggleButtonModal(false);
  };

  const setLinkToggle = useCallback(() => {
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: `https://${newLink}` })
      .run();
    setToggleModal(false);
  }, [newLink, editor]);

  return (
    <div className={classes.tiptap}>
      <BubbleMenu editor={editor}>
        <div
          className={classNames(classes.toolbarContainer, { sticky: !inView })}
          ref={observe}
        >
          <div className={classes.toolbar}>
            {boldItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor && editor.isActive('bold') && classes.selectedItem
                )}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleBold()
                    .run()
                }
              >
                <RiBold />
              </div>
            )}
            {italicItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor && editor.isActive('italic') && classes.selectedItem
                )}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleItalic()
                    .run()
                }
              >
                <RiItalic />
              </div>
            )}
            {buttonItemShow && (
              <Box
                className={classes.buttonContainer}
                display="flex"
                onClick={openButtonLinkModal}
              >
                <RiLinkM />
                <span className={classes.marginLeft}>Edit Link</span>
              </Box>
            )}
            {linkItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor && editor.isActive('link') && classes.selectedItem
                )}
                onClick={openLinkModal}
              >
                <RiLinkM />
              </div>
            )}
            {listItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor &&
                    editor.isActive('bulletList') &&
                    classes.selectedItem
                )}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleBulletList()
                    .run()
                }
              >
                <RiListUnordered />
              </div>
            )}
            {h1ItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor &&
                    editor.isActive('heading', { level: 1 }) &&
                    classes.selectedItem
                )}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: 1 })
                    .run()
                }
              >
                <RiH1 />
              </div>
            )}
            {h2ItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor &&
                    editor.isActive('heading', { level: 2 }) &&
                    classes.selectedItem
                )}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: 2 })
                    .run()
                }
              >
                <RiH2 />
              </div>
            )}
            {h3ItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor &&
                    editor.isActive('heading', { level: 3 }) &&
                    classes.selectedItem
                )}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: 3 })
                    .run()
                }
              >
                <RiH3 />
              </div>
            )}
            {h4ItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor &&
                    editor.isActive('heading', { level: 4 }) &&
                    classes.selectedItem
                )}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: 4 })
                    .run()
                }
              >
                <RiH4 />
              </div>
            )}
            {h5ItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor &&
                    editor.isActive('heading', { level: 5 }) &&
                    classes.selectedItem
                )}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: 5 })
                    .run()
                }
              >
                <RiH5 />
              </div>
            )}
            {h6ItemShow && (
              <div
                className={classNames(
                  'icon',
                  editor &&
                    editor.isActive('heading', { level: 6 }) &&
                    classes.selectedItem
                )}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: 6 })
                    .run()
                }
              >
                <RiH6 />
              </div>
            )}
          </div>
        </div>
      </BubbleMenu>
      <EditorContent
        editor={editor}
        className={setTiptapEditor ? classes.messageContent : classes.content}
      />
      <Dialog open={toggleModal}>
        <DialogTitle>
          <Typography variant="h4">Add Link</Typography>
        </DialogTitle>
        <DialogContent style={{ minWidth: '37rem' }}>
          {/* {!isButton && (
            <FormControl fullWidth>
              <Typography variant="caption">Link Text</Typography>
              <Select
                variant="filled"
                value={newLink}
                onChange={e => setNewLink(e.target.value)}
              >
                {favouriteLinks &&
                  favouriteLinks.length > 0 &&
                  favouriteLinks.map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )} */}

          <FormControl fullWidth style={{ marginTop: '1rem' }}>
            <Typography variant="caption">Link</Typography>
            <TextField
              variant="filled"
              onChange={e => setNewLink(e.target.value)}
              value={newLink}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">https://</InputAdornment>
                )
              }}
            />
          </FormControl>

          {/* <FormControl fullWidth style={{ marginTop: '1rem' }}>
            <Typography variant="caption">
              <Checkbox
                style={{ padding: 0 }}
                onChange={e => setSaveAsFavourite(e.target.checked)}
              />
              &nbsp; Save as favourite
            </Typography>
          </FormControl> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNewLink(null);
              setToggleModal(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={setLinkToggle} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={toggleButtonModal}>
        <DialogTitle>
          <Typography variant="h4">Edit Button</Typography>
        </DialogTitle>
        <DialogContent style={{ minWidth: '37rem' }}>
          <FormControl fullWidth>
            <Typography variant="caption">Button Label</Typography>
            <TextField
              variant="filled"
              onChange={e => setButtonLabel(e.target.value)}
              value={buttonLabel}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">https://</InputAdornment>
              //   )
              // }}
            />
          </FormControl>

          <FormControl fullWidth style={{ marginTop: '1rem' }}>
            <Typography variant="caption">Button Link</Typography>
            <TextField
              variant="filled"
              onChange={e => setButtonLink(e.target.value)}
              value={buttonLink}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">https://</InputAdornment>
                )
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setButtonLabel('');
              setButtonLink('');
              setToggleButtonModal(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={setButtonLinkToggle} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Tiptap;
