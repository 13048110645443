import clsx from 'clsx';
import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  makeStyles,
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
  ButtonBase,
  Card,
  Button,
  DialogActions,
  MobileStepper
} from '@material-ui/core';
import { useDispatch, useSelector } from 'src/store';
import Skeleton from '@material-ui/lab/Skeleton';
import SwipeableViews from 'react-swipeable-views';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { LogoCollectionService } from 'shared';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { setLogoCollectionDialog } from 'src/slices/dashboard';
import { Theme } from 'src/theme';

interface LogoCollectionDialogProps {
  fieldName?: string;
  setFieldValue?: (fieldname: string, value: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 16,
    boxShadow: '0px 4px 15px rgba(196, 196, 196, 0.25)',
    border: '1px solid rgba(104, 127, 146, 0.2)'
  },
  paperWidthLg: {
    maxWidth: 1234
  },
  headingWrapper: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 55,
    borderBottom: '1px solid rgba(104, 127, 146, 0.4)'
  },
  dialogHeading: {
    flexGrow: 1,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    color: theme.palette.text.primary
  },
  closeButton: {
    width: 48,
    height: 48,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.blue.main,
    color: theme.palette.blue.main,
    padding: 0
  },
  dialogActions: {
    justifyContent: 'center',
    height: 141,
    boxShadow: '0px -4px 15px rgba(219, 219, 219, 0.25)',
    borderRadius: '0px 0px 4px 4px',
    flexDirection: 'column'
  },
  imageWrapper: {
    borderRadius: 0,
    height: 250,
    width: 250,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2)
  },
  selected: {
    border: `2px solid ${theme.palette.secondary.main}`
  },
  imageInnerWrapper: {
    width: '100%',
    height: '100%'
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 4
  },
  button: {
    width: 148,
    height: 48,
    '&$disabled': {
      background: '#949494',
      borderRadius: 32,
      color: theme.palette.common.white
    }
  },
  stepperRoot: {
    background: 'transparent',
    marginTop: theme.spacing(2)
  },
  dot: {
    width: 20,
    height: 20,
    margin: '0 6px',
    background: '#C4C4C4'
  },
  dotActive: {
    border: '2px solid #C4C4C4',
    background: theme.palette.common.white
  },
  actionButton: {
    width: 65,
    height: 65,
    padding: 0,
    background: '#494949',
    position: 'absolute',
    color: theme.palette.common.white,
    '&:hover,&:active,&:focus': {
      background: '#494949',
      color: theme.palette.common.white
    },
    '&$disabled': {
      background: '#949494',
      color: theme.palette.common.white
    }
  },
  nextButton: {
    right: 40
  },
  prevButton: {
    left: 16
  },
  disabled: {}
}));

const logoCollectionService = LogoCollectionService.getInstance<
  LogoCollectionService
>();

const Loader: Function = () => {
  return (
    <Box display="flex" alignItems="center" marginY={3} mx={5}>
      <Skeleton
        style={{ marginLeft: 16 }}
        variant="rect"
        width={250}
        height={250}
      />
      <Skeleton
        style={{ marginLeft: 16 }}
        variant="rect"
        width={250}
        height={250}
      />
      <Skeleton
        style={{ marginLeft: 16 }}
        variant="rect"
        width={250}
        height={250}
      />
      <Skeleton
        style={{ marginLeft: 16 }}
        variant="rect"
        width={250}
        height={250}
      />
    </Box>
  );
};

const LogoCollectionDialog: FC<LogoCollectionDialogProps> = ({
  fieldName,
  setFieldValue
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.dashboard.isLogoCollectionDialog);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedLogo, setSelectedLogo] = useState<string>(null);
  const [logoCollectionList, setLogoCollectionList] = useState<any[]>([]);
  const isMountedRef = useIsMountedRef();

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleClose = useCallback(() => {
    dispatch(setLogoCollectionDialog(false));
  }, [dispatch]);

  const handleClick = () => {
    setFieldValue(fieldName, selectedLogo);
    setSelectedLogo(null);
    handleClose();
  };

  const getImageCollection = useCallback(() => {
    logoCollectionService
      .getLogoCollection()
      .then(response => {
        if (response.data) {
          setLogoCollectionList([...response.data]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, setLogoCollectionList]);

  useEffect(() => {
    if (isMountedRef.current && isOpen) {
      getImageCollection();
    }
  }, [isOpen, getImageCollection, isMountedRef]);

  const maxSteps = logoCollectionList.length;

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      fullWidth
      classes={{
        paper: classes.paper,
        paperWidthLg: classes.paperWidthLg
      }}
    >
      <Box className={classes.headingWrapper}>
        <Typography className={classes.dialogHeading}>Choose a Logo</Typography>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          {isLoading && <Loader />}
          {!isLoading && (
            <SwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
              {logoCollectionList.map((item, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  marginY={3}
                  mx={5}
                >
                  <Card
                    elevation={0}
                    className={clsx(classes.imageWrapper, {
                      [classes.selected]:
                        selectedLogo && selectedLogo === item.logoUrl1
                    })}
                  >
                    <ButtonBase
                      className={classes.imageInnerWrapper}
                      onClick={() => setSelectedLogo(item.logoUrl1)}
                    >
                      <img
                        className={classes.image}
                        src={item.logoUrl1}
                        alt="logo"
                      />
                    </ButtonBase>
                  </Card>
                  <Card
                    elevation={0}
                    className={clsx(classes.imageWrapper, {
                      [classes.selected]:
                        selectedLogo && selectedLogo === item.logoUrl2
                    })}
                  >
                    <ButtonBase
                      className={classes.imageInnerWrapper}
                      onClick={() => setSelectedLogo(item.logoUrl2)}
                    >
                      <img
                        className={classes.image}
                        src={item.logoUrl2}
                        alt="logo"
                      />
                    </ButtonBase>
                  </Card>
                  <Card
                    elevation={0}
                    className={clsx(classes.imageWrapper, {
                      [classes.selected]:
                        selectedLogo && selectedLogo === item.logoUrl3
                    })}
                  >
                    <ButtonBase
                      className={classes.imageInnerWrapper}
                      onClick={() => setSelectedLogo(item.logoUrl3)}
                    >
                      <img
                        className={classes.image}
                        src={item.logoUrl3}
                        alt="logo"
                      />
                    </ButtonBase>
                  </Card>
                  <Card
                    elevation={0}
                    className={clsx(classes.imageWrapper, {
                      [classes.selected]:
                        selectedLogo && selectedLogo === item.logoUrl4
                    })}
                  >
                    <ButtonBase
                      className={classes.imageInnerWrapper}
                      onClick={() => setSelectedLogo(item.logoUrl4)}
                    >
                      <img
                        className={classes.image}
                        src={item.logoUrl4}
                        alt="logo"
                      />
                    </ButtonBase>
                  </Card>
                </Box>
              ))}
            </SwipeableViews>
          )}
          <IconButton
            classes={{
              root: clsx(classes.actionButton, classes.prevButton),
              disabled: classes.disabled
            }}
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
          <IconButton
            classes={{
              root: clsx(classes.actionButton, classes.nextButton),
              disabled: classes.disabled
            }}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight fontSize="large" />
          </IconButton>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button
          variant="contained"
          color="secondary"
          disabled={selectedLogo === null}
          classes={{
            root: classes.button,
            disabled: classes.disabled
          }}
          onClick={handleClick}
        >
          Add Logo
        </Button>
        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          backButton={<div />}
          nextButton={<div />}
          classes={{
            root: classes.stepperRoot,
            dot: classes.dot,
            dotActive: classes.dotActive
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default LogoCollectionDialog;
