import React, { createContext, FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Close as CloseIcon } from '@material-ui/icons';
import { Box, Link, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BlockIcon from '@material-ui/icons/Block';

export const SubscriptionLimitsExceededContext = createContext(null);

export interface SubscriptionLimitsExceededDialogProps {
  open: boolean;
  subTitle?: string;
  canUpgrade?: boolean;
  resolveReject?: any[],
  onClose: () => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiDialog-paper': {
      overflow: 'visible',
      maxHeight: 'calc(100% - 124px)'
    }
  },
  redirectButton: {
    color: '#df8658',
    cursor: 'pointer'
  },
  upgradePlanBtn: {
    cursor: 'pointer'
  },
  blockIcon: {
    fontSize: '5rem'
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(-6),
    right: '0',
    backgroundColor: '#e1e1e1',
    color: '#000',
    '&:hover': {
      backgroundColor: '#e1e1e1',
    }
  }
}));


const SubscriptionLimitsExceededDialog: FC<SubscriptionLimitsExceededDialogProps> = ({ onClose, open, subTitle, canUpgrade, resolveReject }) => {
  const classes = useStyles();
  const history = useHistory();
  const [resolve] = resolveReject;

  const handleClose = () => {
    resolve();
    onClose();
  };

  const handleUpgradePlan = () => {
    resolve();
    history.push('/app/pricing')
    onClose();
  };

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth='sm'
      onClose={handleClose}
      open={open}
    >
      <IconButton
        size="small"
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>

      <Box m={3}>
        <Typography
          align="center"
          variant="h4"
        >
          Your subscription limits are exceeded.
          </Typography>
        <Box
          textAlign='center'
          m={2}>
          <BlockIcon
            className={classes.blockIcon}
          />
        </Box>
        {!!subTitle && <Typography
          align="center"
          variant="subtitle1"
          color="textSecondary"
        >
          {subTitle}
        </Typography>}
        {!!canUpgrade && <Typography
          align="center"
          variant="subtitle1"
          color="textSecondary"
        >
          <Link
            className={classes.upgradePlanBtn}
            color="secondary"
            onClick={handleUpgradePlan}>
            {'Upgrade your plan '}
          </Link>
           to get more.
          </Typography>}
      </Box>
    </Dialog>
  );
}

export default SubscriptionLimitsExceededDialog;
