import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Switch,
  Typography,
  makeStyles,
  Collapse,
  Link,
  Box,
  Breadcrumbs
} from '@material-ui/core';
import {
  IContentType,
  IProviderMasterTemplateContent,
  ProviderTemplateService,
  ProviderTemplateContentService,
  ProviderSectionTemplateService
} from 'shared';
import { useToasts } from 'src/components/core/ToastManager';
import Tiptap from './Tiptap';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'src/store';
import {
  getTrimmedContent,
  getTrimmedLabel,
  getTrimmedText
} from './EmailPreview';
import useAuth from 'src/hooks/useAuth';
import { getUser, getUserById } from 'src/slices/current-user';
import { Skeleton } from '@material-ui/lab';
import SectionSelectionDialog from './SectionSelectionDialog';
import { Theme } from 'src/theme';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloseIcon from '@material-ui/icons/Close';

export interface EmailNotificationsDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  templates?: any;
  onSettingClick?: () => void;
  onCloseSettingModal?: () => void;
}

interface CTADetail {
  canDisable: string;
  isCTADirty: boolean;
  welcomeMsg1: string;
  buttonLabel1: string;
  linkUrl1: string;
  welcomeMsg2: string;
  buttonLabel2: string;
  linkUrl2: string;
  welcomeMsg3: string;
  buttonLabel3: string;
  linkUrl3: string;
  active: boolean;
  disabled: boolean;
  content?: any | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: '16px 80px !important',
    overflow: 'hidden',
    background: '#F4F6F8',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '16px'
  },
  gridRoot: {
    height: '95%',
    background: '#fff',
    width: '100%',
    padding: '16px',
    borderRadius: '1rem',
    overflow: 'hidden'
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F4F6F8',
    display: 'flex'
  },
  leftContainer: {
    borderRight: '1px solid #EAECF0',
    backgroundColor: '#F9FAFB',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  name: {
    color: '#212B36',
    padding: '8px 16px',
    borderBottom: '1px solid #EAECF0',
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 20
  },
  leftContainerCard: {
    marginBottom: '1rem',
    border: '1px solid #fff',
    '&:hover': {
      border: '1px solid #37879A'
    }
  },
  leftContainerCardError: {
    marginBottom: '1rem',
    border: '1px solid #B72136',
    background: 'rgba(255, 231, 217, 0.30)'
  },
  leftContainerCardText: {
    color: '#454F5B',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px'
  },
  leftContainerCardContent: {
    paddingBottom: '16px !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  leftContainerLink: {
    color: '#EE824D',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '30px',
    textDecoration: 'none'
  },
  saveActionButton: {
    display: 'flex',
    alignItems: 'center',
    background: '#37879A',
    color: '#fff',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#52a3b7'
    },
    '&:disabled': {
      background: 'rgba(145, 158, 171, 0.32)',
      color: '#454F5B'
    }
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#e1e1e1'
    },
    '&:disabled': {
      background: 'rgba(145, 158, 171, 0.08)'
    },
    margin: '0 0.5rem'
  },
  rightContainer: {
    background: '#F0F0F0',
    padding: '1rem',
    textAlign: 'center',
    overflow: 'auto'
  },
  leftToggleSelected: {
    color: '#ECF5F8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 700,
    border: 'none',
    background: theme.palette.primary.main,
    '&:hover': {
      background: '#4e9bad',
      border: 'none'
    }
  },
  leftToggle: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    fontSize: 14,
    fontWeight: 700,
    border: 'none',
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    '&:hover': {
      background: '#ECF5F8',
      border: 'none',
      color: theme.palette.primary.main
    }
  },
  rightToggleSelected: {
    color: '#ECF5F8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 700,
    border: 'none',
    background: theme.palette.primary.main,
    '&:hover': {
      background: '#4e9bad',
      border: 'none'
    }
  },
  rightToggle: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    fontSize: 14,
    fontWeight: 700,
    border: 'none',
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    '&:hover': {
      background: '#ECF5F8',
      border: 'none',
      color: theme.palette.primary.main
    }
  },
  previewContentDesktop: {
    transition: 'width 1s',
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    width: '100%',
    marginTop: '1rem',
    height: 'fit-content'
  },
  previewContentMobile: {
    transition: 'width 1s',
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    marginTop: '1rem',
    width: 360,
    height: 'fit-content'
  },
  previewContentMainMarketing: {
    padding: '1rem',
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '1px solid #fff',
    margin: '1rem 0rem'
  },
  marketingTemplate: {
    width: '100%',
    backgroundColor: '#F9FAFB',
    borderRadius: '6px',
    padding: '1rem'
  },
  marketingTemplateTitle: {
    justifyContent: 'center',
    color: '#454F5B',
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '24px'
  },
  marketingTemplateBlog: {
    display: 'block',
    textAlign: 'center',
    marginTop: '0.5rem',
    color: '#454F5B',
    fontWeight: 400,
    lineHeight: '22px'
  },
  marketingTemplateLink: {
    display: 'block',
    color: '#EE824D',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    textDecoration: 'none',
    textAlign: 'center'
  },
  previewContentPrimaryAction: {
    padding: '0.5rem 0rem'
  },
  desktopSectionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f2f4f6'
  },
  mobileSectionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f2f4f6',
    paddingLeft: 10,
    paddingRight: 10
  },
  notificationContainer: {
    background: '#FFE7D9',
    padding: '12px',
    borderRadius: 8,
    border: '1px solid #FFA48D',
    marginBottom: 8
  },
  notificationTitleContainer: { display: 'flex', alignItems: 'center' },
  notificationTitle: {
    color: '#7A0C2E',
    fontWeight: 600,
    fontSize: 16,
    paddingLeft: 12
  },
  notificationDescription: {
    color: '#7A0C2E',
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 36
  },
  closeModal: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gridGap: 8
  },
  ovSection: {
    paddingLeft: 4,
    '& table': {
      paddingLeft: 28
    }
  },
  skeleton: {
    marginTop: 16
  }
}));

const providerSectionTemplateService = ProviderSectionTemplateService.getInstance<
  ProviderSectionTemplateService
>();

const EmailNotificationsDialog: FC<EmailNotificationsDialogProps> = ({
  isOpen,
  onClose,
  onSettingClick,
  templates,
  onCloseSettingModal
}) => {
  const [toggleWelcomeMessage, setToggleWelcomeMessage] = useState<boolean>(
    false
  );
  const [togglePrimaryAction, setTogglePrimaryAction] = useState<boolean>(
    false
  );
  const [toggleClosingMessage, setToggleClosingMessage] = useState<boolean>(
    false
  );
  const [toggleMarketing, setToggleMarketing] = useState<boolean>(false);
  const [templateList, setTemplateList] = useState<string>('defaultList');
  const [primaryActionTemplate, setPrimaryActionTemplate] = useState<string>(
    'primary1'
  );
  const [marketingTemplate, setMarketingTemplate] = useState<string>(
    'marketing1'
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [providerTemplateId, setProviderTemplateId] = useState(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [providerGreeting, setProviderGreeting] = useState<
    IProviderMasterTemplateContent
  >(null);
  const [greetingContent, setGreetingContent] = useState('');
  const [providerAdditionalInfo, setProviderAdditionalInfo] = useState<
    IProviderMasterTemplateContent
  >(null);
  const [additionalContent, setAdditionalContent] = useState('');
  const [primaryCtaDetails, setPrimaryCtaDetails] = useState<CTADetail | null>(
    null
  );
  const [secondaryCtaDetails, setSecondaryCtaDetails] = useState<any>(null);
  // const [fetchTemplate, setFetchTemplate] = useState<boolean>(false);
  const [statusDetails, setStatusDetails] = useState({
    disabled: true,
    canDisable: 'No',
    isDirty: false
  });
  const [templateView, setTemplateView] = useState<string>('desktop');
  const [greetingContentInitValue, setGreetingContentInitValue] = useState(
    '[Provider Greeting]'
  );
  const { addToast } = useToasts();

  const classes = useStyles();
  const providerTemplateContentService = ProviderTemplateContentService.getInstance<
    ProviderTemplateContentService
  >();
  const providerTemplateService = ProviderTemplateService.getInstance<
    ProviderTemplateService
  >();
  const { user, provider } = useSelector(state => state.currentUser);
  // const { primaryTemplates, marketingTemplates } = useSelector(
  //   state => state.emailTemplate
  // );
  const [primaryTemplates, setPrimaryTemplates] = useState([]);
  const [marketingTemplates, setMarketingTemplates] = useState([]);
  const { providerId } = useAuth();
  const [additionalContentInitValue, setAdditionalContentInitValue] = useState(
    '[Provider Closing]'
  );
  const dispatch = useDispatch();

  // newly added states
  const [topSection, setTopSection] = useState(null);
  const [footerSection, setFooterSection] = useState(null);
  const [profileSection, setProfileSection] = useState(null);
  const [marketingBorderSection, setMarketingBorderSection] = useState(null);
  const [ovContentSection, setOvContentSection] = useState(null);
  const [selectedEmailSection, setSelectedEmailSection] = useState('');
  const [selectedSection, setSelectedSection] = useState('primary');

  const handleHoverSelection = useCallback((selection: string) => {
    setSelectedEmailSection(selection);
  }, []);

  const [open, setOpen] = useState(false);

  const [linkValidation, setLinkValidation] = useState({
    primaryCTA: null,
    marketing: null
  });

  const [hasMissingLinks, setHasMissingLinks] = useState(false);
  const [switchOriginalState, setSwitchOriginalState] = useState({
    welcome: false,
    closing: false,
    primary: false,
    marketing: false
  });

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    if (
      toggleWelcomeMessage !== switchOriginalState?.welcome ||
      toggleClosingMessage !== switchOriginalState?.closing ||
      togglePrimaryAction !== switchOriginalState?.primary ||
      toggleMarketing !== switchOriginalState?.marketing ||
      isDirty
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [
    toggleClosingMessage,
    toggleWelcomeMessage,
    togglePrimaryAction,
    toggleMarketing,
    switchOriginalState,
    isDirty
  ]);

  useEffect(() => {
    if (toggleMarketing) {
      if (marketingTemplate === 'marketing1') {
        if (
          secondaryCtaDetails?.btnUrl1 !== '' &&
          secondaryCtaDetails?.btnUrl2 !== ''
        ) {
          setHasMissingLinks(false);
          setLinkValidation(prevState => ({ ...prevState, marketing: true }));
        } else {
          setLinkValidation(prevState => ({ ...prevState, marketing: false }));
          setHasMissingLinks(true);
        }
      } else {
        if (secondaryCtaDetails?.btnUrl3 !== '') {
          setHasMissingLinks(false);
          setLinkValidation(prevState => ({ ...prevState, marketing: true }));
        } else {
          setLinkValidation(prevState => ({ ...prevState, marketing: false }));
          setHasMissingLinks(true);
        }
      }
    } else {
      setLinkValidation(prevState => {
        if (prevState.primaryCTA === false) {
          setHasMissingLinks(true);
        } else {
          setHasMissingLinks(false);
        }
        return { ...prevState, marketing: null };
      });
    }
  }, [marketingTemplate, secondaryCtaDetails, toggleMarketing]);

  useEffect(() => {
    if (togglePrimaryAction) {
      if (primaryActionTemplate === 'primary1') {
        if (primaryCtaDetails?.linkUrl1 !== '') {
          setLinkValidation(prevState => ({ ...prevState, primaryCTA: true }));
          setHasMissingLinks(false);
        } else {
          setLinkValidation(prevState => ({ ...prevState, primaryCTA: false }));
          setHasMissingLinks(true);
        }
      } else if (primaryActionTemplate === 'primary2') {
        if (primaryCtaDetails?.linkUrl2 !== '') {
          setLinkValidation(prevState => ({ ...prevState, primaryCTA: true }));
          setHasMissingLinks(false);
        } else {
          setLinkValidation(prevState => ({ ...prevState, primaryCTA: false }));
          setHasMissingLinks(true);
        }
      } else {
        if (primaryCtaDetails?.linkUrl3 !== '') {
          setLinkValidation(prevState => ({ ...prevState, primaryCTA: true }));
          setHasMissingLinks(false);
        } else {
          setLinkValidation(prevState => ({ ...prevState, primaryCTA: false }));
          setHasMissingLinks(true);
        }
      }
    } else {
      setLinkValidation(prevState => {
        if (prevState.marketing === false) {
          setHasMissingLinks(true);
        } else {
          setHasMissingLinks(false);
        }
        return { ...prevState, primaryCTA: null };
      });
    }
  }, [togglePrimaryAction, primaryActionTemplate, primaryCtaDetails]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const fetchTemplateData = useCallback(() => {
    setIsLoading(true);
    providerTemplateService
      .getTemplateByAlias(templates.postmarkAlias)
      .then(({ data }) => {
        // setting newly added states
        let sections = data?.renderedContent?.split('<!-- CONTENT -->');
        sections = [sections[0], ...sections[1]?.split('<!-- SIGNATURE -->')];
        sections = [
          sections[0],
          sections[1],
          ...sections[2]?.split('<!-- MARKETING BORDER -->')
        ];
        sections = [
          sections[0],
          sections[1],
          sections[2],
          ...sections[3]?.split('<!-- MARKETING -->')
        ];
        sections = [
          sections[0],
          sections[1],
          sections[2],
          sections[3],
          ...sections[4]?.split('<!-- FOOTER -->')
        ];
        setTopSection(
          sections[0].replace('parent_name_Value', '<span>[First Name]</span>')
        );
        // setContentSection(sections[1]);
        setProfileSection(sections[2]);
        setMarketingBorderSection(sections[3]);
        // setMarketingSection(sections[3]);
        setFooterSection(sections[5]);

        if (
          templates.postmarkAlias === 'tour-scheduled' ||
          templates.postmarkAlias === 'tour-reminder'
        ) {
          setOvContentSection(
            sections[1]
              ?.split('<!-- OV Content Start -->')[1]
              ?.split('<!-- OV Content End -->')[0]
              ?.replace('date_short_Value', '[Tour Date]')
              .replace('start_time_Value', '[Tour Start]')
              .replaceAll('<ul style="', '<ul style="padding-left: 16px;')
          );
        }

        // if (sections[1]?.includes('<!-- OV CONTENT -->')) {
        //   setOvContentSection(
        //     sections[1]
        //       .split('<!-- OV CONTENT -->')[1]
        //       .split('<!-- PRIMARY CTA -->')[0]
        //   );
        // }
        setProviderTemplateId(data?.id);
        setStatusDetails({
          disabled: data?.disabled,
          canDisable: data?.canDisable,
          isDirty: false
        });
        // setRenderedContent(data?.renderedContent);
        if (
          data?.providerTemplatesContent.some(
            item => item.templateVariable === 'primary_cta'
          ) &&
          data?.providerTemplatesContent.find(
            item => item.templateVariable === 'primary_cta'
          )?.content !== null
        ) {
          setTogglePrimaryAction(true);
          setSwitchOriginalState(prevState => ({
            ...prevState,
            primary: true
          }));
        } else {
          setTogglePrimaryAction(false);
          setSwitchOriginalState(prevState => ({
            ...prevState,
            primary: false
          }));
        }

        if (
          data?.providerTemplatesContent.some(
            item => item.templateVariable === 'secondary_cta'
          ) &&
          data?.providerTemplatesContent.find(
            item => item.templateVariable === 'secondary_cta'
          )?.content !== null
        ) {
          setToggleMarketing(true);
          setSwitchOriginalState(prevState => ({
            ...prevState,
            marketing: true
          }));
        } else {
          setToggleMarketing(false);
          setSwitchOriginalState(prevState => ({
            ...prevState,
            marketing: false
          }));
        }

        const primaryCtaSettings = data?.providerTemplatesContent.find(
          item => item.templateVariable === 'primary_cta'
        )?.settingsJson;

        const secondaryCtaSettings = data?.providerTemplatesContent.find(
          item => item.templateVariable === 'secondary_cta'
        )?.settingsJson;

        let primarySettingsJson = {
          active: false,
          canDisable: 'No',
          isCTADirty: false,
          welcomeMsg1: '',
          buttonLabel1: '',
          linkUrl1: '',
          welcomeMsg2: '',
          buttonLabel2: '',
          linkUrl2: '',
          welcomeMsg3: '',
          buttonLabel3: '',
          linkUrl3: '',
          disabled: true
        };

        if (secondaryCtaSettings) {
          const convertedSecondaryCtaSettings = JSON.parse(
            secondaryCtaSettings
          );
          setMarketingTemplate(
            convertedSecondaryCtaSettings?.secondary_cta?.template_id
          );
          setSecondaryCtaDetails({
            ...convertedSecondaryCtaSettings?.secondary_cta
          });
        }

        if (primaryCtaSettings) {
          const convertedPrimaryCtaSettings = JSON.parse(primaryCtaSettings);
          primarySettingsJson = {
            ...primarySettingsJson,
            buttonLabel1: convertedPrimaryCtaSettings?.primary_cta?.btn_label1
              ? convertedPrimaryCtaSettings?.primary_cta?.btn_label1
              : '',
            active:
              convertedPrimaryCtaSettings?.primary_cta?.display === 'block',
            welcomeMsg1: convertedPrimaryCtaSettings?.primary_cta?.welcome1
              ? convertedPrimaryCtaSettings?.primary_cta?.welcome1
              : '',
            linkUrl1: convertedPrimaryCtaSettings?.primary_cta?.btn_url1
              ? convertedPrimaryCtaSettings?.primary_cta?.btn_url1.replace(
                  /\/\/|.+\/\//,
                  ''
                )
              : '',
            buttonLabel2: convertedPrimaryCtaSettings?.primary_cta?.btn_label2
              ? convertedPrimaryCtaSettings?.primary_cta?.btn_label2
              : '',
            welcomeMsg2: convertedPrimaryCtaSettings?.primary_cta?.welcome2
              ? convertedPrimaryCtaSettings?.primary_cta?.welcome2
              : '',
            linkUrl2: convertedPrimaryCtaSettings?.primary_cta?.btn_url2
              ? convertedPrimaryCtaSettings?.primary_cta?.btn_url2.replace(
                  /\/\/|.+\/\//,
                  ''
                )
              : '',
            buttonLabel3: convertedPrimaryCtaSettings?.primary_cta?.btn_label3
              ? convertedPrimaryCtaSettings?.primary_cta?.btn_label3
              : '',
            welcomeMsg3: convertedPrimaryCtaSettings?.primary_cta?.welcome3
              ? convertedPrimaryCtaSettings?.primary_cta?.welcome3
              : '',
            linkUrl3: convertedPrimaryCtaSettings?.primary_cta?.btn_url3
              ? convertedPrimaryCtaSettings?.primary_cta?.btn_url3.replace(
                  /\/\/|.+\/\//,
                  ''
                )
              : '',
            disabled: false
          };
          setPrimaryActionTemplate(
            convertedPrimaryCtaSettings?.primary_cta?.template_id
          );
        }
        setPrimaryCtaDetails(primarySettingsJson);

        const template = data.providerTemplatesContentTemplate?.filter(
          dataItem =>
            dataItem.contentType === IContentType.PROVIDER_INDIRECT_CONTENT
        );
        const providerGreet = template.find(
          templateItem =>
            templateItem.providerTemplateContent.name ===
            'Provider Greeting Text'
        );
        const providerAdditional = template.find(
          templateItem =>
            templateItem.providerTemplateContent.name ===
            'Provider Closing Text'
        );
        if (providerGreet) {
          setProviderGreeting(providerGreet);
        }

        if (providerAdditional) {
          setProviderAdditionalInfo(providerAdditional);
        }

        if (
          providerGreet &&
          providerGreet?.providerTemplateContent?.content !== null
        ) {
          if (
            JSON.parse(providerGreet?.providerTemplateContent?.settingsJson)
              ?.disabled
          ) {
            setSwitchOriginalState(prevState => ({
              ...prevState,
              welcome: false
            }));
            setToggleWelcomeMessage(false);
          } else {
            setToggleWelcomeMessage(true);
            setSwitchOriginalState(prevState => ({
              ...prevState,
              welcome: true
            }));
          }
          let greetingText = getTrimmedContent(
            providerGreet?.providerTemplateContent?.content,
            'provider-greeting'
          );
          setGreetingContent(
            greetingText !== '' ? greetingText : '[Provider Greeting]'
          );
          setGreetingContentInitValue(greetingText);
        }

        if (
          providerAdditional &&
          providerAdditional?.providerTemplateContent?.content !== null
        ) {
          if (
            JSON.parse(
              providerAdditional?.providerTemplateContent?.settingsJson
            )?.disabled
          ) {
            setSwitchOriginalState(prevState => ({
              ...prevState,
              closing: false
            }));
            setToggleClosingMessage(false);
          } else {
            setToggleClosingMessage(true);
            setSwitchOriginalState(prevState => ({
              ...prevState,
              closing: true
            }));
          }

          let additionalInfoText = getTrimmedContent(
            providerAdditional?.providerTemplateContent?.content,
            'provider-closing'
          );
          setAdditionalContent(
            additionalInfoText !== ''
              ? additionalInfoText
              : '[Provider Closing]'
          );
          setAdditionalContentInitValue(
            additionalInfoText !== ''
              ? additionalInfoText
              : '[Provider Closing]'
          );
        }

        setIsDirty(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, [templates.postmarkAlias]);

  useEffect(() => {
    if (providerId && !provider) {
      dispatch(getUserById(providerId));
    }
    if (!user) {
      dispatch(getUser());
    }
  }, [provider, providerId, dispatch, user]);

  const fetchPrimaryMarketingTemplates = useCallback(() => {
    providerSectionTemplateService
      .getPrimaryMarketingTemplates()
      .then(({ data }) => {
        setPrimaryTemplates(data.primary);
        setMarketingTemplates(data.marketing);
      });
  }, []);

  useEffect(() => {
    if (templates.postmarkAlias) {
      fetchTemplateData();
      fetchPrimaryMarketingTemplates();
      setIsDirty(false);
      setPrimaryCtaDetails(prevState => ({ ...prevState, isCTADirty: false }));

      setSecondaryCtaDetails(prevState => {
        return prevState
          ? {
              ...prevState,
              isCTADirty: false
            }
          : {
              title1: '24 - 25 School Year',
              btnUrl1: '',
              description1:
                'View our programs and pricing for the upcoming school year.',
              btnLabel1: 'View programs',
              description2:
                'Check out our programs and schedule for the upcoming summer!',
              title2: 'Summer Camp',
              btnUrl2: '',
              btnLabel2: 'View summer',
              title3: '24 - 25 School Year',
              btnLabel3: 'View programs',
              btnUrl3: '',
              description3:
                'We have limited availability for the upcoming school year. Don’t wait to sign up!',
              isCTADirty: false
            };
      });
    }
    // eslint-disable-next-line
  }, [templates.postmarkAlias, dispatch]);

  const handleCancel = () => {
    setIsDirty(false);
    onClose();
  };

  const handleSettingsSave = useCallback(async () => {
    if (isDirty) {
      if (
        providerGreeting &&
        providerGreeting.providerTemplateId &&
        providerGreeting.providerTemplateContentId
      )
        await providerTemplateService.updateTemplate({
          providerTemplateId: providerGreeting?.providerTemplateId,
          providerTemplateContent: [
            {
              id: providerGreeting?.providerTemplateContentId,
              settingsJson: JSON.stringify({
                disabled: !toggleWelcomeMessage
              }),
              content: `<div class="raw-html-embed"><div id="provider-greeting">${
                greetingContent === '[Provider Greeting]' ||
                !toggleWelcomeMessage
                  ? ''
                  : greetingContent
              }</div></div>`
            }
          ]
        });

      if (
        providerAdditionalInfo &&
        providerAdditionalInfo.providerTemplateId &&
        providerAdditionalInfo.providerTemplateContentId
      )
        await providerTemplateService.updateTemplate({
          providerTemplateId: providerAdditionalInfo?.providerTemplateId,
          providerTemplateContent: [
            {
              id: providerAdditionalInfo?.providerTemplateContentId,
              content: `<div class="raw-html-embed"><div id="provider-closing">${
                additionalContent === '[Provider Closing]' ||
                !toggleClosingMessage
                  ? ''
                  : additionalContent
              }</div></div>`,
              settingsJson: JSON.stringify({
                disabled: !toggleClosingMessage
              })
            }
          ]
        });
    }
    if (statusDetails.isDirty) {
      await providerTemplateService.updateTemplateStatus({
        id: templates?.id,
        disabled: statusDetails?.disabled
      });
    }
    if (primaryCtaDetails?.isCTADirty) {
      let primaryContent, primary_cta;
      if (primaryCtaDetails?.content !== null) {
        if (primaryActionTemplate === 'primary1') {
          let primaryContentHTML = primaryTemplates
            .filter(template => template.name.toLowerCase() === 'primary1')[0]
            ?.contentHTML?.replace(
              '{{welcomeMsg1}}',
              primaryCtaDetails?.welcomeMsg1
            )
            .replace('{{linkUrl1}}', `https://${primaryCtaDetails?.linkUrl1}`)
            .replace('{{buttonLabel1}}', primaryCtaDetails?.buttonLabel1);

          primaryContent = primaryContentHTML;

          //   primaryContent = `<table id="primary-cta" cellpadding="0" cellspacing="0">
          //   <tr style="display: block">
          //     <td>
          //   <p class="body-primary-cta-title">${
          //     primaryCtaDetails?.welcomeMsg1
          //   }</p>
          //   <table border="0" cellspacing="0" cellpadding="0" class="body-primary-cta-button-container" role="presentation">
          //       <tr>
          //         <td class="body-primary-cta-button-wrapper" bgcolor=${'#37879A'}>
          //           <a href=https://${
          //             primaryCtaDetails?.linkUrl1
          //           } target="_blank" class="${
          //     primaryActionTemplate === 'primary1'
          //       ? 'body-primary-cta-button'
          //       : primaryActionTemplate === 'primary2'
          //       ? 'body-primary-cta-button-style2'
          //       : 'body-primary-cta-button-style3'
          //   }">${primaryCtaDetails?.buttonLabel1}</a>
          //         </td>
          //       </tr>
          //       </table>
          //       </td>
          //       </tr>
          // </table>`;
          primary_cta = {
            welcome1: primaryCtaDetails.welcomeMsg1,
            template_id: primaryActionTemplate,
            btn_url1: `https://${primaryCtaDetails?.linkUrl1?.replace(
              /\/\/|.+\/\//,
              ''
            )}`,
            btn_label1: primaryCtaDetails.buttonLabel1,
            display: primaryCtaDetails.active ? 'block' : 'none'
          };
        } else if (primaryActionTemplate === 'primary2') {
          let primaryContentHTML = primaryTemplates
            .filter(template => template.name.toLowerCase() === 'primary2')[0]
            ?.contentHTML?.replace(
              '{{welcomeMsg2}}',
              primaryCtaDetails?.welcomeMsg2
            )
            .replace('{{linkUrl2}}', `https://${primaryCtaDetails?.linkUrl2}`)
            .replace('{{buttonLabel2}}', primaryCtaDetails?.buttonLabel2);
          primaryContent = primaryContentHTML;
          //   primaryContent = `<table id="primary-cta" cellpadding="0" cellspacing="0">
          //   <tr style="display: block">
          //     <td>
          //   <p class="body-primary-cta-title">${
          //     primaryCtaDetails?.welcomeMsg2
          //   }</p>
          //   <table border="0" cellspacing="0" cellpadding="0" class="body-primary-cta-button-container" role="presentation">
          //       <tr>
          //         <td class="body-primary-cta-button-wrapper" bgcolor=${'#e0e0e0'}>
          //           <a href=https://${
          //             primaryCtaDetails?.linkUrl2
          //           } target="_blank" class="body-primary-cta-button-style2">${
          //     primaryCtaDetails?.buttonLabel2
          //   }</a>
          //         </td>
          //       </tr>
          //       </table>
          //       </td>
          //       </tr>
          // </table>`;
          primary_cta = {
            welcome2: primaryCtaDetails.welcomeMsg2,
            template_id: primaryActionTemplate,
            btn_url2: `https://${primaryCtaDetails?.linkUrl2?.replace(
              /\/\/|.+\/\//,
              ''
            )}`,
            btn_label2: primaryCtaDetails.buttonLabel2,
            display: primaryCtaDetails.active ? 'block' : 'none'
          };
        } else {
          let primaryContentHTML = primaryTemplates
            .filter(template => template.name.toLowerCase() === 'primary3')[0]
            .contentHTML?.replace(
              '{{welcomeMsg3}}',
              primaryCtaDetails?.welcomeMsg3
            )
            .replace('{{linkUrl3}}', `https://${primaryCtaDetails?.linkUrl3}`)
            .replace('{{buttonLabel3}}', primaryCtaDetails?.buttonLabel3);
          primaryContent = primaryContentHTML;
          //   primaryContent = `<table id="primary-cta" cellpadding="0" cellspacing="0">
          //   <tr style="display: block">
          //     <td>
          //   <p class="body-primary-cta-title">${
          //     primaryCtaDetails?.welcomeMsg3
          //   }</p>
          //   <table border="0" cellspacing="0" cellpadding="0" class="body-primary-cta-button-container" role="presentation">
          //       <tr>
          //         <td class="body-primary-cta-button-wrapper" bgcolor=${
          //           primaryActionTemplate === 'primary1' ? '#37879A' : '#e0e0e0'
          //         }>
          //           <a href=https://${
          //             primaryCtaDetails?.linkUrl3
          //           } target="_blank" class="body-primary-cta-button-style3">${
          //     primaryCtaDetails?.buttonLabel3
          //   }</a>
          //         </td>
          //       </tr>
          //       </table>
          //       </td>
          //       </tr>
          // </table>`;
          primary_cta = {
            welcome3: primaryCtaDetails.welcomeMsg3,
            template_id: primaryActionTemplate,
            btn_url3: `https://${primaryCtaDetails?.linkUrl3?.replace(
              /\/\/|.+\/\//,
              ''
            )}`,
            btn_label3: primaryCtaDetails.buttonLabel3,
            display: primaryCtaDetails.active ? 'block' : 'none'
          };
        }
      } else {
        primaryContent = null;
      }
      await providerTemplateContentService.getTemplateContentByVariable({
        content: primaryCtaDetails?.content !== null ? primaryContent : null,
        templateVariable: 'primary_cta',
        providerTemplateId,
        settingsJson: JSON.stringify({
          primary_cta: primary_cta
        })
      });
    }
    if (secondaryCtaDetails?.isCTADirty) {
      let secondaryContent;
      if (marketingTemplate === 'marketing2') {
        let marketingContentHTML = marketingTemplates
          .filter(template => template.name.toLowerCase() === 'marketing2')[0]
          ?.contentHTML?.replace('{{title3}}', secondaryCtaDetails?.title3)
          .replace('{{description3}}', secondaryCtaDetails?.description3)
          .replace('{{btnUrl3}}', `https://${secondaryCtaDetails?.btnUrl3}`)
          .replace('{{btnLabel3}}', secondaryCtaDetails?.btnLabel3);
        secondaryContent = marketingContentHTML;
        //     secondaryContent = `<div id="secondary-cta">
        //   <div style="display: flex; flex-direction: row; justify-content:space-around; width: 100%">
        //   <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 16px; margin-bottom: 16px; background: #f9fafb">
        //     <p style="font-weight: 600;
        // font-size: 20px;">${secondaryCtaDetails?.title3}</p>
        //     <p>${secondaryCtaDetails?.description3}</p>
        //     <a href=https://${secondaryCtaDetails?.btnUrl3} target="_blank" class="marketing-section-link">${secondaryCtaDetails?.btnLabel3}</a>
        //   </div>
        //   </div>
        // </div>`;
      } else if (marketingTemplate === 'marketing1') {
        let marketingContentHTML = marketingTemplates
          .filter(template => template.name.toLowerCase() === 'marketing1')[0]
          ?.contentHTML?.replace('{{title1}}', secondaryCtaDetails?.title1)
          .replace('{{description1}}', secondaryCtaDetails?.description1)
          .replace('{{btnUrl1}}', `https://${secondaryCtaDetails?.btnUrl1}`)
          .replace('{{btnLabel1}}', secondaryCtaDetails?.btnLabel1)
          .replace('{{title2}}', secondaryCtaDetails?.title2)
          .replace('{{description2}}', secondaryCtaDetails?.description2)
          .replace('{{btnUrl2}}', `https://${secondaryCtaDetails?.btnUrl2}`)
          .replace('{{btnLabel2}}', secondaryCtaDetails?.btnLabel2);
        secondaryContent = marketingContentHTML;
        //   secondaryContent = `<table cellpadding="0" cellspacing="0" width="100%" style="border-spacing: 0;" role="presentation" id="secondary-cta" class="secondary-cta">
        //   <tr style="width: 100%">
        //     <td class="marketing-section">
        //   <span style="padding: 8px; display: block; background: #f9fafb">
        //     <p class="marketing-section-title">${secondaryCtaDetails?.title1}</p>
        //     <p class="marketing-section-description">${secondaryCtaDetails?.description1}</p>
        //     <a href=https://${secondaryCtaDetails?.btnUrl1} target="_blank" class="marketing-section-link">${secondaryCtaDetails?.btnLabel1}</a>
        //   </span>
        //   </td>
        //   <td class="marketing-section">
        //   <span style="padding: 8px; display: block; background: #f9fafb">
        //     <p class="marketing-section-title">${secondaryCtaDetails?.title2}</p>
        //     <p class="marketing-section-description">${secondaryCtaDetails?.description2}</p>
        //     <a href=https://${secondaryCtaDetails?.btnUrl2} target="_blank" class="marketing-section-link">${secondaryCtaDetails?.btnLabel2}</a>
        //   </span>
        //   </td>
        //   </tr>
        // </table>`;
      }

      await providerTemplateContentService.getTemplateContentByVariable({
        content:
          secondaryCtaDetails?.content !== null ? secondaryContent : null,
        templateVariable: 'secondary_cta',
        providerTemplateId,
        settingsJson: JSON.stringify({
          secondary_cta: {
            ...secondaryCtaDetails,
            template_id: marketingTemplate
          }
        })
      });
    }
    if (templates?.postmarkAlias) {
      await fetchTemplateData();
      setIsDirty(false);
      setPrimaryCtaDetails(prevState => ({ ...prevState, isCTADirty: false }));
      setSecondaryCtaDetails(prevState => {
        return {
          ...prevState,
          isCTADirty: false
        };
      });
    }
    setGreetingContentInitValue(greetingContent);
    setAdditionalContentInitValue(additionalContent);
    setTemplateList('defaultList');

    await providerTemplateService.updateTemplateCompleteStatus({
      postmarkAlias: templates?.postmarkAlias,
      layoutUpdate: false
    });
    // eslint-disable-next-line
  }, [
    isDirty,
    templates,
    greetingContent,
    additionalContent,
    statusDetails,
    primaryCtaDetails,
    secondaryCtaDetails,
    providerTemplateId,
    primaryActionTemplate,
    marketingTemplate,
    toggleWelcomeMessage,
    toggleClosingMessage,
    marketingTemplates,
    primaryTemplates
  ]);

  const onPrimaryCtaInputChange = useCallback(async (content, fieldName) => {
    await setIsDirty(true);
    await setPrimaryCtaDetails(prevState => ({
      ...prevState,
      isCTADirty: true,
      [fieldName]: content
    }));
  }, []);

  const onMarketingInputChange = useCallback(async (content, fieldName) => {
    await setIsDirty(true);
    await setSecondaryCtaDetails(prevState => {
      return {
        ...prevState,
        isCTADirty: true,
        [fieldName]: content
      };
    });
  }, []);

  const handleTogglePrimaryAction = useCallback(toggleValue => {
    if (toggleValue) {
      setPrimaryCtaDetails(prevState => ({
        ...prevState,
        isCTADirty: true,
        active: true,
        buttonLabel1: 'View programs',
        welcomeMsg1:
          'Ready to see more? Check out our programs by selecting the button below.',
        buttonLabel2: '',
        buttonLabel3: '',
        welcomeMsg2: '',
        welcomeMsg3: '',
        content: ''
      }));
      setPrimaryActionTemplate('primary1');
    } else {
      setPrimaryCtaDetails(prevState => ({
        ...prevState,
        isCTADirty: true,
        active: false,
        content: null
      }));
    }
    setTogglePrimaryAction(toggleValue);
  }, []);

  const sendTestEmail = useCallback(() => {
    providerTemplateService
      .sendTestByProvider({
        postmarkAlias: templates.postmarkAlias
      })
      .then(resp => {
        if (resp.status === 204) {
          addToast('Test email sent successfully');
        }
      });
  }, [templates, addToast, providerTemplateService]);

  const handlePrimaryActionSectionSelection = useCallback(
    async templateName => {
      await setPrimaryActionTemplate(templateName);
      let buttonLabel1,
        welcomeMsg1,
        buttonLabel2,
        welcomeMsg2,
        buttonLabel3,
        welcomeMsg3;
      if (templateName === 'primary2') {
        buttonLabel2 = 'Schedule tour';
        welcomeMsg2 = 'Want to schedule a tour? Check out our tour calendar.';
        buttonLabel1 = '';
        buttonLabel3 = '';
        welcomeMsg1 = '';
        welcomeMsg3 = '';
      } else if (templateName === 'primary1') {
        buttonLabel1 = 'View programs';
        welcomeMsg1 =
          'Ready to see more? Check out our programs by selecting the button below.';
        buttonLabel2 = '';
        buttonLabel3 = '';
        welcomeMsg2 = '';
        welcomeMsg3 = '';
      } else {
        buttonLabel3 = 'Register now';
        welcomeMsg3 = 'Ready to register? Select the registration link below.';
        buttonLabel1 = '';
        buttonLabel2 = '';
        welcomeMsg1 = '';
        welcomeMsg2 = '';
      }
      await setPrimaryCtaDetails(prevState => ({
        ...prevState,
        isCTADirty: true,
        welcomeMsg1: '',
        buttonLabel1: '',
        welcomeMsg2: '',
        buttonLabel2: '',
        welcomeMsg3: '',
        buttonLabel3: '',
        active: true
      }));
      await setPrimaryCtaDetails(prevState => ({
        ...prevState,
        isCTADirty: true,
        welcomeMsg1,
        buttonLabel1,
        welcomeMsg2,
        buttonLabel2,
        welcomeMsg3,
        buttonLabel3,
        active: true
      }));
      await setIsDirty(true);
    },
    []
  );

  const handleMarketingSectionSelection = async templateName => {
    await setMarketingTemplate(templateName);

    let title1,
      title2,
      title3,
      btnUrl1,
      description1,
      btnLabel1,
      btnUrl2,
      description2,
      btnLabel2,
      btnUrl3,
      description3,
      btnLabel3;

    if (templateName === 'marketing1') {
      title1 = '24 - 25 School Year';
      btnUrl1 = '';
      description1 =
        'View our programs and pricing for the upcoming school year.';
      btnLabel1 = 'View programs';
      description2 =
        'Check out our programs and schedule for the upcoming summer!';
      title2 = 'Summer Camp';
      btnUrl2 = '';
      btnLabel2 = 'View summer';
      await setSecondaryCtaDetails(prevState => {
        return {
          ...prevState,
          isCTADirty: true,
          title1,
          btnUrl1,
          description1,
          btnLabel1,
          title2,
          btnUrl2,
          description2,
          btnLabel2,
          title3: '',
          btnUrl3: '',
          description3: '',
          btnLabel3: ''
        };
      });
    } else {
      title3 = '24 - 25 School Year';
      btnLabel3 = 'View programs';
      btnUrl3 = '';
      description3 =
        'We have limited availability for the upcoming school year. Don’t wait to sign up!';

      await setSecondaryCtaDetails(prevState => {
        return {
          ...prevState,
          isCTADirty: true,
          title3,
          btnUrl3,
          description3,
          btnLabel3,
          title1: '',
          btnUrl1: '',
          description1: '',
          btnLabel1: '',
          title2: '',
          btnUrl2: '',
          description2: '',
          btnLabel2: ''
        };
      });
    }

    await setIsDirty(true);
  };

  return (
    <>
      <Dialog disableEnforceFocus fullScreen onClose={onClose} open={isOpen}>
        <DialogContent className={classes.dialogContent}>
          <Box display="flex" gridGap={4} flexDirection="column">
            <Box display="flex" justifyContent="space-between">
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <Link
                  component="button"
                  underline="always"
                  color="primary"
                  onClick={() => {
                    onClose();
                    onSettingClick();
                  }}
                >
                  Settings
                </Link>
                <Link
                  component="button"
                  underline="always"
                  color="primary"
                  onClick={() => {
                    onClose();
                    onSettingClick();
                  }}
                >
                  Emails
                </Link>
                <Link
                  component="button"
                  underline="always"
                  color="primary"
                  onClick={onClose}
                >
                  Lead Email Template
                </Link>
              </Breadcrumbs>
              <Typography
                className={classes.closeModal}
                onClick={onCloseSettingModal}
              >
                <CloseIcon />
                Close
              </Typography>
            </Box>
          </Box>

          <Grid classes={{ root: classes.gridRoot }} container>
            <Grid className={classes.contentContainer}>
              <Grid className={classes.leftContainer} item xs={4}>
                {isLoading ? (
                  <Skeleton variant="rect" width="100%" height="100%" />
                ) : (
                  <>
                    <Typography className={classes.name}>
                      {templates?.name}
                    </Typography>

                    <Collapse
                      in={templateList === 'defaultList' ? true : false}
                    >
                      <Box height="calc(100vh - 225px)" overflow="auto" p={2}>
                        {hasMissingLinks && (
                          <Box className={classes.notificationContainer}>
                            <Box className={classes.notificationTitleContainer}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17ZM12 14C12.5523 14 13 13.5523 13 13V8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V13C11 13.5523 11.4477 14 12 14Z"
                                  fill="#FF4842"
                                />
                              </svg>
                              <Typography className={classes.notificationTitle}>
                                Missing button links
                              </Typography>
                            </Box>
                            <Typography
                              className={classes.notificationDescription}
                            >
                              For your email to work properly, you need to add a
                              link to each button{' '}
                            </Typography>
                          </Box>
                        )}

                        <Card
                          onMouseEnter={() => handleHoverSelection('greeting')}
                          onMouseLeave={() => handleHoverSelection('')}
                          className={classes.leftContainerCard}
                        >
                          <CardContent
                            className={classes.leftContainerCardContent}
                          >
                            <Typography
                              className={classes.leftContainerCardText}
                            >
                              Welcome Message
                            </Typography>
                            <Switch
                              color="primary"
                              onChange={() => {
                                setToggleWelcomeMessage(!toggleWelcomeMessage);
                                setIsDirty(true);
                              }}
                              checked={toggleWelcomeMessage}
                            />
                          </CardContent>
                        </Card>

                        <Card
                          onMouseEnter={() =>
                            handleHoverSelection('primaryCta')
                          }
                          onMouseLeave={() => handleHoverSelection('')}
                          className={
                            linkValidation?.primaryCTA !== null &&
                            !linkValidation?.primaryCTA
                              ? classes.leftContainerCardError
                              : classes.leftContainerCard
                          }
                        >
                          <CardContent
                            className={classes.leftContainerCardContent}
                          >
                            <Box>
                              <Typography
                                className={classes.leftContainerCardText}
                              >
                                Primary Action
                              </Typography>
                              <Typography variant="caption" color="textPrimary">
                                <Link
                                  component={'button'}
                                  onClick={() => {
                                    setSelectedSection('primary');
                                    handleClickOpen();
                                  }}
                                  className={classes.leftContainerLink}
                                >
                                  Change Section Template &gt;
                                </Link>
                              </Typography>
                            </Box>
                            <Switch
                              color="primary"
                              onChange={() =>
                                handleTogglePrimaryAction(!togglePrimaryAction)
                              }
                              checked={togglePrimaryAction}
                            />
                          </CardContent>
                        </Card>

                        <Card
                          onMouseEnter={() => handleHoverSelection('closing')}
                          onMouseLeave={() => handleHoverSelection('')}
                          className={classes.leftContainerCard}
                        >
                          <CardContent
                            className={classes.leftContainerCardContent}
                          >
                            <Typography
                              className={classes.leftContainerCardText}
                            >
                              Closing Message
                            </Typography>
                            <Switch
                              color="primary"
                              onChange={() => {
                                setToggleClosingMessage(!toggleClosingMessage);
                                setIsDirty(true);
                              }}
                              checked={toggleClosingMessage}
                            />
                          </CardContent>
                        </Card>

                        <Card
                          onMouseEnter={() => handleHoverSelection('marketing')}
                          onMouseLeave={() => handleHoverSelection('')}
                          className={
                            linkValidation?.marketing !== null &&
                            !linkValidation?.marketing
                              ? classes.leftContainerCardError
                              : classes.leftContainerCard
                          }
                        >
                          <CardContent
                            className={classes.leftContainerCardContent}
                          >
                            <Box>
                              <Typography
                                className={classes.leftContainerCardText}
                              >
                                Marketing
                              </Typography>
                              <Typography variant="caption" color="textPrimary">
                                <Link
                                  component={'button'}
                                  onClick={() => {
                                    setSelectedSection('marketing');
                                    handleClickOpen();
                                  }}
                                  className={classes.leftContainerLink}
                                >
                                  Change Section Template &gt;
                                </Link>
                              </Typography>
                            </Box>
                            <Switch
                              color="primary"
                              onChange={() => {
                                setToggleMarketing(!toggleMarketing);
                                if (!toggleMarketing) {
                                  setSecondaryCtaDetails(prevState => {
                                    return {
                                      ...prevState,
                                      isCTADirty: true,
                                      title1:
                                        prevState?.title1 &&
                                        prevState?.title1 !== ''
                                          ? prevState?.title1
                                          : '24 - 25 School Year',
                                      btnUrl1:
                                        prevState?.btnUrl1 &&
                                        prevState?.btnUrl1 !== ''
                                          ? prevState?.btnUrl1
                                          : '',
                                      description1:
                                        prevState?.description1 &&
                                        prevState?.description1 !== ''
                                          ? prevState?.description1
                                          : 'View our programs and pricing for the upcoming school year.',
                                      btnLabel1:
                                        prevState?.btnLabel1 &&
                                        prevState?.btnLabel1 !== ''
                                          ? prevState?.btnLabel1
                                          : 'View programs',
                                      title2:
                                        prevState?.title2 &&
                                        prevState?.title2 !== ''
                                          ? prevState?.title2
                                          : 'Summer Camp',
                                      btnUrl2:
                                        prevState?.btnUrl2 &&
                                        prevState?.btnUrl2 !== ''
                                          ? prevState?.btnUrl2
                                          : '',
                                      description2:
                                        prevState?.description2 &&
                                        prevState?.description2 !== ''
                                          ? prevState?.description2
                                          : 'Check out our programs and schedule for the upcoming summer!',
                                      btnLabel2:
                                        prevState?.btnLabel2 &&
                                        prevState?.btnLabel2 !== ''
                                          ? prevState?.btnLabel2
                                          : 'View summer',
                                      title3: '',
                                      btnUrl3:
                                        prevState?.btnUrl3 &&
                                        prevState?.btnUrl3 !== ''
                                          ? prevState?.btnUrl3
                                          : '',
                                      description3:
                                        prevState?.description3 &&
                                        prevState?.description3 !== ''
                                          ? prevState?.description3
                                          : '',
                                      btnLabel3:
                                        prevState?.btnLabel3 &&
                                        prevState?.btnLabel3 !== ''
                                          ? prevState?.btnLabel3
                                          : '',
                                      content: ''
                                    };
                                  });
                                } else {
                                  setSecondaryCtaDetails(prevState => ({
                                    ...prevState,
                                    content: null,
                                    isCTADirty: true
                                  }));
                                }
                              }}
                              checked={toggleMarketing}
                            />
                          </CardContent>
                        </Card>

                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={sendTestEmail}
                          fullWidth
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M17.5587 2.44132L8.67204 11.328M2.72591 6.86272L16.5642 2.0617C17.4164 1.76604 18.234 2.58364 17.9383 3.43584L13.1373 17.2741C12.8084 18.2221 11.4771 18.2481 11.1115 17.3136L8.91407 11.698C8.80432 11.4176 8.58243 11.1957 8.30196 11.0859L2.68636 8.88852C1.75191 8.52286 1.7779 7.19162 2.72591 6.86272Z"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                          </svg>
                          &nbsp;Send Test Email
                        </Button>
                      </Box>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '1rem',
                          borderTop: '1px solid #EAECF0'
                        }}
                      >
                        <Button
                          onClick={handleCancel}
                          className={classes.actionButton}
                        >
                          Cancel
                        </Button>

                        <Button
                          className={classes.saveActionButton}
                          onClick={handleSettingsSave}
                          disabled={isSaveDisabled || hasMissingLinks}
                          style={{ marginRight: '0rem !important' }}
                        >
                          Save
                        </Button>
                      </div>
                    </Collapse>
                  </>
                )}
              </Grid>

              <Grid className={classes.rightContainer} item xs={8}>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Button
                    onClick={() => setTemplateView('desktop')}
                    className={
                      templateView === 'desktop'
                        ? classes.leftToggleSelected
                        : classes.leftToggle
                    }
                  >
                    Desktop
                  </Button>
                  <Button
                    onClick={() => setTemplateView('mobile')}
                    className={
                      templateView === 'mobile'
                        ? classes.rightToggleSelected
                        : classes.rightToggle
                    }
                  >
                    Mobile
                  </Button>
                </ButtonGroup>
                {isLoading ? (
                  <Skeleton
                    className={classes.skeleton}
                    variant="rect"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <div
                    style={{
                      overflowY: 'auto',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      className={
                        templateView === 'desktop'
                          ? classes.previewContentDesktop
                          : classes.previewContentMobile
                      }
                    >
                      <div>
                        <div className="content">
                          <Box>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  topSection && topSection ? topSection : ''
                              }}
                            ></div>
                            <div
                              className={
                                templateView === 'desktop'
                                  ? classes.desktopSectionContainer
                                  : classes.mobileSectionContainer
                              }
                            >
                              <Box
                                maxWidth="600px"
                                width="600px"
                                style={{
                                  background: '#ffffff',
                                  textAlign: 'left',
                                  marginLeft: 10,
                                  paddingLeft: 24,
                                  marginRight: 10
                                }}
                              >
                                <div
                                  style={
                                    selectedEmailSection === 'greeting' &&
                                    toggleWelcomeMessage
                                      ? {
                                          border: '1px solid #37879A',
                                          width: '100%'
                                        }
                                      : { width: '100%' }
                                  }
                                >
                                  <Collapse in={toggleWelcomeMessage}>
                                    <Tiptap
                                      tiptap_content={greetingContentInitValue}
                                      onUpdateContent={editorInst => {
                                        setIsDirty(true);
                                        setGreetingContent(editorInst);
                                      }}
                                      getNewLink={link => console.log(link)}
                                      favouriteLinks={['https://example.com']}
                                      editable={true}
                                    />
                                  </Collapse>
                                </div>
                                {ovContentSection && (
                                  <div
                                    className={classes.ovSection}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        ovContentSection && ovContentSection
                                          ? ovContentSection
                                          : ''
                                    }}
                                  ></div>
                                )}
                                {primaryCtaDetails && (
                                  <div
                                    className={
                                      classes.previewContentPrimaryAction
                                    }
                                    style={
                                      selectedEmailSection === 'primaryCta' &&
                                      togglePrimaryAction
                                        ? {
                                            border: '1px solid #37879A',
                                            padding: '0.5rem 0rem'
                                          }
                                        : { padding: '0.5rem 0rem' }
                                    }
                                  >
                                    <Collapse in={togglePrimaryAction}>
                                      {
                                        <>
                                          {primaryActionTemplate ===
                                            'primary1' && (
                                            <Grid container>
                                              <Grid item xs={12}>
                                                <div
                                                  className={classNames(
                                                    'primaryTemplateItem',
                                                    classes.marketingTemplate
                                                  )}
                                                  style={{
                                                    backgroundColor: '#fff',
                                                    padding: '0rem'
                                                  }}
                                                >
                                                  <Tiptap
                                                    tiptap_content={
                                                      primaryCtaDetails?.welcomeMsg1 &&
                                                      primaryCtaDetails?.welcomeMsg1 !==
                                                        ''
                                                        ? primaryCtaDetails.welcomeMsg1
                                                        : 'Ready to see more? Check out our programs by selecting the button below.'
                                                    }
                                                    onUpdateContent={content =>
                                                      onPrimaryCtaInputChange(
                                                        getTrimmedText(content),
                                                        'welcomeMsg1'
                                                      )
                                                    }
                                                    getNewLink={link =>
                                                      console.log(link)
                                                    }
                                                    favouriteLinks={[
                                                      'https://example.com'
                                                    ]}
                                                    editable={true}
                                                  />
                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{
                                                      borderRadius: '8px',
                                                      marginTop: '0.5rem'
                                                    }}
                                                    href={
                                                      // primaryCtaDetails
                                                      //   ? primaryCtaDetails.linkUrl1
                                                      ''
                                                    }
                                                  >
                                                    <Tiptap
                                                      tiptap_content={
                                                        primaryCtaDetails &&
                                                        primaryCtaDetails?.buttonLabel1 !==
                                                          ''
                                                          ? primaryCtaDetails?.buttonLabel1
                                                          : 'View programs'
                                                      }
                                                      onUpdateContent={content =>
                                                        onPrimaryCtaInputChange(
                                                          getTrimmedLabel(
                                                            content
                                                          ),
                                                          'buttonLabel1'
                                                        )
                                                      }
                                                      buttonExistingLabel={
                                                        primaryCtaDetails?.buttonLabel1
                                                      }
                                                      buttonExistingLink={
                                                        primaryCtaDetails?.linkUrl1
                                                      }
                                                      getNewButtonLabel={label =>
                                                        onPrimaryCtaInputChange(
                                                          label,
                                                          'buttonLabel1'
                                                        )
                                                      }
                                                      getNewLink={link =>
                                                        onPrimaryCtaInputChange(
                                                          link,
                                                          'linkUrl1'
                                                        )
                                                      }
                                                      favouriteLinks={[
                                                        'https://example.com'
                                                      ]}
                                                      editable={true}
                                                      hasInitialValue={false}
                                                      boldItemShow={false}
                                                      buttonItemShow={true}
                                                      italicItemShow={false}
                                                      listItemShow={false}
                                                      linkItemShow={false}
                                                      h1ItemShow={false}
                                                      h2ItemShow={false}
                                                      h3ItemShow={false}
                                                      h4ItemShow={false}
                                                      h5ItemShow={false}
                                                      h6ItemShow={false}
                                                      isPrimary={true}
                                                    />
                                                  </Button>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          )}
                                          {primaryActionTemplate ===
                                            'primary2' && (
                                            <Grid container>
                                              <Grid item xs={12}>
                                                <div
                                                  className={classNames(
                                                    'primaryTemplateItem',
                                                    classes.marketingTemplate
                                                  )}
                                                  style={{
                                                    backgroundColor: '#fff',
                                                    padding: '0rem'
                                                  }}
                                                >
                                                  <Tiptap
                                                    tiptap_content={
                                                      primaryCtaDetails?.welcomeMsg2 &&
                                                      primaryCtaDetails?.welcomeMsg2 !==
                                                        ''
                                                        ? primaryCtaDetails.welcomeMsg2
                                                        : 'Want to schedule a tour? Check out our tour calendar.'
                                                    }
                                                    onUpdateContent={content =>
                                                      onPrimaryCtaInputChange(
                                                        getTrimmedText(content),
                                                        'welcomeMsg2'
                                                      )
                                                    }
                                                    getNewLink={link =>
                                                      console.log(link)
                                                    }
                                                    favouriteLinks={[
                                                      'https://example.com'
                                                    ]}
                                                    editable={true}
                                                  />
                                                  <Button
                                                    variant="contained"
                                                    color="default"
                                                    style={{
                                                      borderRadius: '8px',
                                                      marginTop: '0.5rem'
                                                    }}
                                                    href={
                                                      // primaryCtaDetails
                                                      //   ? primaryCtaDetails.linkUrl2
                                                      ''
                                                    }
                                                  >
                                                    <Tiptap
                                                      tiptap_content={
                                                        primaryCtaDetails &&
                                                        primaryCtaDetails?.buttonLabel2 !==
                                                          ''
                                                          ? primaryCtaDetails?.buttonLabel2
                                                          : 'Schedule tour'
                                                      }
                                                      onUpdateContent={content =>
                                                        onPrimaryCtaInputChange(
                                                          getTrimmedLabel(
                                                            content
                                                          ),
                                                          'buttonLabel2'
                                                        )
                                                      }
                                                      getNewLink={link =>
                                                        onPrimaryCtaInputChange(
                                                          link,
                                                          'linkUrl2'
                                                        )
                                                      }
                                                      getNewButtonLabel={label =>
                                                        onPrimaryCtaInputChange(
                                                          label,
                                                          'buttonLabel2'
                                                        )
                                                      }
                                                      buttonExistingLabel={
                                                        primaryCtaDetails?.buttonLabel2
                                                      }
                                                      buttonExistingLink={
                                                        primaryCtaDetails?.linkUrl2
                                                      }
                                                      favouriteLinks={[
                                                        'https://example.com'
                                                      ]}
                                                      editable={true}
                                                      boldItemShow={false}
                                                      italicItemShow={false}
                                                      listItemShow={false}
                                                      linkItemShow={false}
                                                      buttonItemShow={true}
                                                      h1ItemShow={false}
                                                      h2ItemShow={false}
                                                      h3ItemShow={false}
                                                      h4ItemShow={false}
                                                      h5ItemShow={false}
                                                      h6ItemShow={false}
                                                      hasInitialValue={false}
                                                    />
                                                  </Button>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          )}
                                          {primaryActionTemplate ===
                                            'primary3' && (
                                            <Grid container>
                                              <Grid item xs={12}>
                                                <div
                                                  className={classNames(
                                                    'primaryTemplateItem',
                                                    classes.marketingTemplate
                                                  )}
                                                  style={{
                                                    backgroundColor: '#fff',
                                                    padding: '0rem'
                                                  }}
                                                >
                                                  <Tiptap
                                                    tiptap_content={
                                                      primaryCtaDetails?.welcomeMsg3 &&
                                                      primaryCtaDetails?.welcomeMsg3 !==
                                                        ''
                                                        ? primaryCtaDetails.welcomeMsg3
                                                        : 'Ready to register? Select the registration link below.'
                                                    }
                                                    onUpdateContent={content =>
                                                      onPrimaryCtaInputChange(
                                                        getTrimmedText(content),
                                                        'welcomeMsg3'
                                                      )
                                                    }
                                                    getNewLink={link =>
                                                      console.log(link)
                                                    }
                                                    favouriteLinks={[
                                                      'https://example.com'
                                                    ]}
                                                    editable={true}
                                                  />
                                                  <Link
                                                    component={'button'}
                                                    className={
                                                      classes.marketingTemplateLink
                                                    }
                                                    style={{
                                                      color: '#37879A',
                                                      textAlign: 'justify',
                                                      marginTop: '0.5rem'
                                                    }}
                                                    href={
                                                      // primaryCtaDetails
                                                      //   ? primaryCtaDetails.linkUrl3
                                                      ''
                                                    }
                                                  >
                                                    <Tiptap
                                                      tiptap_content={
                                                        primaryCtaDetails &&
                                                        primaryCtaDetails?.buttonLabel3 !==
                                                          ''
                                                          ? primaryCtaDetails?.buttonLabel3
                                                          : 'Register now'
                                                      }
                                                      onUpdateContent={content =>
                                                        onPrimaryCtaInputChange(
                                                          getTrimmedLabel(
                                                            content
                                                          ),
                                                          'buttonLabel3'
                                                        )
                                                      }
                                                      getNewLink={link =>
                                                        onPrimaryCtaInputChange(
                                                          link,
                                                          'linkUrl3'
                                                        )
                                                      }
                                                      getNewButtonLabel={label =>
                                                        onPrimaryCtaInputChange(
                                                          label,
                                                          'buttonLabel3'
                                                        )
                                                      }
                                                      buttonExistingLabel={
                                                        primaryCtaDetails?.buttonLabel3
                                                      }
                                                      buttonExistingLink={
                                                        primaryCtaDetails?.linkUrl3
                                                      }
                                                      favouriteLinks={[
                                                        'https://example.com'
                                                      ]}
                                                      editable={true}
                                                      boldItemShow={false}
                                                      italicItemShow={false}
                                                      listItemShow={false}
                                                      linkItemShow={false}
                                                      buttonItemShow={true}
                                                      h1ItemShow={false}
                                                      h2ItemShow={false}
                                                      h3ItemShow={false}
                                                      h4ItemShow={false}
                                                      h5ItemShow={false}
                                                      h6ItemShow={false}
                                                      hasInitialValue={false}
                                                    />
                                                  </Link>
                                                </div>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </>
                                      }
                                    </Collapse>
                                  </div>
                                )}

                                <div
                                  style={
                                    selectedEmailSection === 'closing' &&
                                    toggleClosingMessage
                                      ? {
                                          display: 'flex',
                                          justifyContent: 'left',
                                          alignItems: 'center',
                                          width: '100%',
                                          paddingBottom: 16,
                                          border: '1px solid #37879A'
                                        }
                                      : {
                                          display: 'flex',
                                          justifyContent: 'left',
                                          alignItems: 'center',
                                          width: '100%',
                                          paddingBottom: 16
                                        }
                                  }
                                >
                                  <Collapse in={toggleClosingMessage}>
                                    <Tiptap
                                      tiptap_content={
                                        additionalContentInitValue
                                      }
                                      onUpdateContent={editorInst => {
                                        setIsDirty(true);
                                        setAdditionalContent(editorInst);
                                      }}
                                      getNewLink={link => console.log(link)}
                                      favouriteLinks={['https://example.com']}
                                      editable={true}
                                    />
                                  </Collapse>
                                </div>
                              </Box>
                            </div>
                            <div
                              className={
                                templateView === 'desktop'
                                  ? classes.desktopSectionContainer
                                  : classes.mobileSectionContainer
                              }
                            >
                              <Box
                                maxWidth="600px"
                                width="600px"
                                style={{
                                  background: '#ffffff',
                                  marginLeft: 10,
                                  paddingLeft: 28,
                                  marginRight: 10,
                                  textAlign: 'left'
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      profileSection && profileSection
                                        ? profileSection
                                        : ''
                                  }}
                                ></div>
                              </Box>
                            </div>
                            <div
                              className={
                                templateView === 'desktop'
                                  ? classes.desktopSectionContainer
                                  : classes.mobileSectionContainer
                              }
                            >
                              <Box
                                maxWidth="600px"
                                width="600px"
                                style={{
                                  background: '#ffffff',
                                  marginLeft: 10,
                                  // paddingLeft: 28,
                                  marginRight: 10
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      marketingBorderSection &&
                                      marketingBorderSection
                                        ? marketingBorderSection
                                        : ''
                                  }}
                                ></div>
                              </Box>
                            </div>
                            <div
                              className={
                                templateView === 'desktop'
                                  ? classes.desktopSectionContainer
                                  : classes.mobileSectionContainer
                              }
                            >
                              <Box
                                maxWidth="600px"
                                width="600px"
                                style={
                                  selectedEmailSection === 'marketing' &&
                                  toggleMarketing
                                    ? {
                                        background: '#ffffff',
                                        border: '1px solid #37879A',
                                        marginLeft: 10,

                                        marginRight: 10
                                      }
                                    : {
                                        background: '#ffffff',
                                        marginLeft: 10,

                                        marginRight: 10
                                      }
                                }
                              >
                                <div
                                  className={
                                    classes.previewContentMainMarketing
                                  }
                                >
                                  <Collapse in={toggleMarketing === true}>
                                    <>
                                      {marketingTemplate === 'marketing1' && (
                                        <Grid container spacing={1}>
                                          <Grid item xs={6}>
                                            <div
                                              className={
                                                classes.marketingTemplate
                                              }
                                            >
                                              <Typography
                                                className={
                                                  classes.marketingTemplateTitle
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M12 16C13.6569 16 15 14.6568 15 13C15 11.3431 13.6569 9.99998 12 9.99998C10.3431 9.99998 9 11.3431 9 13C9 14.6568 10.3431 16 12 16Z"
                                                    stroke="black"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M3 10.5651C3 9.9907 3 9.70352 3.07403 9.43905C3.1396 9.20478 3.24737 8.98444 3.39203 8.78886C3.55534 8.56806 3.78202 8.39175 4.23539 8.03912L11.0177 2.764C11.369 2.49075 11.5447 2.35412 11.7387 2.3016C11.9098 2.25526 12.0902 2.25526 12.2613 2.3016C12.4553 2.35412 12.631 2.49075 12.9823 2.764L19.7646 8.03913C20.218 8.39175 20.4447 8.56806 20.608 8.78886C20.7526 8.98444 20.8604 9.20478 20.926 9.43905C21 9.70352 21 9.9907 21 10.5651V17.8C21 18.9201 21 19.4801 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4801 3 18.9201 3 17.8V10.5651Z"
                                                    stroke="black"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                                &nbsp;
                                                <Tiptap
                                                  tiptap_content={
                                                    secondaryCtaDetails &&
                                                    secondaryCtaDetails?.title1 !==
                                                      ''
                                                      ? secondaryCtaDetails?.title1
                                                      : '24 - 25 School Year'
                                                  }
                                                  onUpdateContent={content =>
                                                    onMarketingInputChange(
                                                      getTrimmedText(content),
                                                      'title1'
                                                    )
                                                  }
                                                  getNewLink={link =>
                                                    console.log(link)
                                                  }
                                                  favouriteLinks={[
                                                    'https://example.com'
                                                  ]}
                                                  editable={true}
                                                />
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                className={
                                                  classes.marketingTemplateBlog
                                                }
                                              >
                                                <Tiptap
                                                  tiptap_content={
                                                    secondaryCtaDetails &&
                                                    secondaryCtaDetails?.description1 !==
                                                      ''
                                                      ? secondaryCtaDetails?.description1
                                                      : 'View our programs and pricing for the upcoming school year.'
                                                  }
                                                  onUpdateContent={content =>
                                                    onMarketingInputChange(
                                                      getTrimmedText(content),
                                                      'description1'
                                                    )
                                                  }
                                                  getNewLink={link =>
                                                    console.log(link)
                                                  }
                                                  favouriteLinks={[
                                                    'https://example.com'
                                                  ]}
                                                  editable={true}
                                                />
                                              </Typography>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'center'
                                                }}
                                              >
                                                <Link
                                                  component={'button'}
                                                  className={
                                                    classes.marketingTemplateLink
                                                  }
                                                  // href={
                                                  //   secondaryCtaDetails?.btnUrl1
                                                  // }
                                                >
                                                  <Tiptap
                                                    tiptap_content={
                                                      secondaryCtaDetails?.btnLabel1 &&
                                                      secondaryCtaDetails?.btnLabel1 !==
                                                        ''
                                                        ? secondaryCtaDetails?.btnLabel1
                                                        : 'View programs'
                                                    }
                                                    onUpdateContent={content =>
                                                      onMarketingInputChange(
                                                        getTrimmedText(content),
                                                        'btnLabel1'
                                                      )
                                                    }
                                                    getNewButtonLabel={label =>
                                                      onMarketingInputChange(
                                                        label,
                                                        'btnLabel1'
                                                      )
                                                    }
                                                    getNewLink={link =>
                                                      onMarketingInputChange(
                                                        link,
                                                        'btnUrl1'
                                                      )
                                                    }
                                                    buttonExistingLabel={
                                                      secondaryCtaDetails?.btnLabel1
                                                    }
                                                    buttonExistingLink={
                                                      secondaryCtaDetails?.btnUrl1
                                                    }
                                                    favouriteLinks={[
                                                      'https://example.com'
                                                    ]}
                                                    editable={true}
                                                    hasInitialValue={false}
                                                    buttonItemShow={true}
                                                    linkItemShow={false}
                                                    boldItemShow={false}
                                                    italicItemShow={false}
                                                    listItemShow={false}
                                                    h1ItemShow={false}
                                                    h2ItemShow={false}
                                                    h3ItemShow={false}
                                                    h4ItemShow={false}
                                                    h5ItemShow={false}
                                                    h6ItemShow={false}
                                                  />
                                                </Link>
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item xs={6}>
                                            <div
                                              className={
                                                classes.marketingTemplate
                                              }
                                            >
                                              <Typography
                                                className={
                                                  classes.marketingTemplateTitle
                                                }
                                              >
                                                <svg
                                                  width="16"
                                                  height="16"
                                                  viewBox="0 0 16 16"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 10C12.42 10 16 11.79 16 14V16H0V14C0 11.79 3.58 10 8 10Z"
                                                    fill="#585858"
                                                  />
                                                </svg>
                                                &nbsp;
                                                <Tiptap
                                                  tiptap_content={
                                                    secondaryCtaDetails?.title2 &&
                                                    secondaryCtaDetails?.title2 !==
                                                      ''
                                                      ? secondaryCtaDetails?.title2
                                                      : 'Summer Camp'
                                                  }
                                                  onUpdateContent={content =>
                                                    onMarketingInputChange(
                                                      getTrimmedText(content),
                                                      'title2'
                                                    )
                                                  }
                                                  getNewLink={link =>
                                                    console.log(link)
                                                  }
                                                  favouriteLinks={[
                                                    'https://example.com'
                                                  ]}
                                                  editable={true}
                                                />
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                className={
                                                  classes.marketingTemplateBlog
                                                }
                                              >
                                                <Tiptap
                                                  tiptap_content={
                                                    secondaryCtaDetails?.description2 &&
                                                    secondaryCtaDetails?.description2 !==
                                                      ''
                                                      ? secondaryCtaDetails?.description2
                                                      : 'Check out our programs and schedule for the upcoming summer!'
                                                  }
                                                  onUpdateContent={content =>
                                                    onMarketingInputChange(
                                                      getTrimmedText(content),
                                                      'description2'
                                                    )
                                                  }
                                                  getNewLink={link =>
                                                    console.log(link)
                                                  }
                                                  favouriteLinks={[
                                                    'https://example.com'
                                                  ]}
                                                  editable={true}
                                                />
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                  }}
                                                >
                                                  <Link
                                                    component={'button'}
                                                    className={
                                                      classes.marketingTemplateLink
                                                    }
                                                    // href={
                                                    //   secondaryCtaDetails?.btnUrl2
                                                    // }
                                                  >
                                                    <Tiptap
                                                      tiptap_content={
                                                        secondaryCtaDetails?.btnLabel2 &&
                                                        secondaryCtaDetails?.btnLabel2 !==
                                                          ''
                                                          ? secondaryCtaDetails?.btnLabel2
                                                          : 'View summer'
                                                      }
                                                      onUpdateContent={content =>
                                                        onMarketingInputChange(
                                                          getTrimmedText(
                                                            content
                                                          ),
                                                          'btnLabel2'
                                                        )
                                                      }
                                                      getNewButtonLabel={label =>
                                                        onMarketingInputChange(
                                                          label,
                                                          'btnLabel2'
                                                        )
                                                      }
                                                      getNewLink={link =>
                                                        onMarketingInputChange(
                                                          link,
                                                          'btnUrl2'
                                                        )
                                                      }
                                                      buttonExistingLabel={
                                                        secondaryCtaDetails?.btnLabel2
                                                      }
                                                      buttonExistingLink={
                                                        secondaryCtaDetails?.btnUrl2
                                                      }
                                                      favouriteLinks={[
                                                        'https://example.com'
                                                      ]}
                                                      editable={true}
                                                      hasInitialValue={false}
                                                      boldItemShow={false}
                                                      linkItemShow={false}
                                                      buttonItemShow={true}
                                                      italicItemShow={false}
                                                      listItemShow={false}
                                                      h1ItemShow={false}
                                                      h2ItemShow={false}
                                                      h3ItemShow={false}
                                                      h4ItemShow={false}
                                                      h5ItemShow={false}
                                                      h6ItemShow={false}
                                                    />
                                                  </Link>
                                                </div>
                                              </Typography>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      )}
                                      {marketingTemplate === 'marketing2' && (
                                        <Grid container spacing={1}>
                                          <Grid item xs={12}>
                                            <div
                                              className={
                                                classes.marketingTemplate
                                              }
                                            >
                                              <Typography
                                                className={
                                                  classes.marketingTemplateTitle
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M12 16C13.6569 16 15 14.6568 15 13C15 11.3431 13.6569 9.99998 12 9.99998C10.3431 9.99998 9 11.3431 9 13C9 14.6568 10.3431 16 12 16Z"
                                                    stroke="black"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M3 10.5651C3 9.9907 3 9.70352 3.07403 9.43905C3.1396 9.20478 3.24737 8.98444 3.39203 8.78886C3.55534 8.56806 3.78202 8.39175 4.23539 8.03912L11.0177 2.764C11.369 2.49075 11.5447 2.35412 11.7387 2.3016C11.9098 2.25526 12.0902 2.25526 12.2613 2.3016C12.4553 2.35412 12.631 2.49075 12.9823 2.764L19.7646 8.03913C20.218 8.39175 20.4447 8.56806 20.608 8.78886C20.7526 8.98444 20.8604 9.20478 20.926 9.43905C21 9.70352 21 9.9907 21 10.5651V17.8C21 18.9201 21 19.4801 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4801 3 18.9201 3 17.8V10.5651Z"
                                                    stroke="black"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                                &nbsp;
                                                <Tiptap
                                                  tiptap_content={
                                                    secondaryCtaDetails?.title3
                                                      ? secondaryCtaDetails?.title3
                                                      : '24 - 25 School Year'
                                                  }
                                                  onUpdateContent={content =>
                                                    onMarketingInputChange(
                                                      getTrimmedText(content),
                                                      'title3'
                                                    )
                                                  }
                                                  getNewLink={link =>
                                                    console.log(link)
                                                  }
                                                  favouriteLinks={[
                                                    'https://example.com'
                                                  ]}
                                                  editable={true}
                                                />
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                className={
                                                  classes.marketingTemplateBlog
                                                }
                                              >
                                                <Tiptap
                                                  tiptap_content={
                                                    secondaryCtaDetails?.description3
                                                      ? secondaryCtaDetails?.description3
                                                      : 'We have limited availability for the upcoming school year. Don’t wait to sign up!'
                                                  }
                                                  onUpdateContent={content =>
                                                    onMarketingInputChange(
                                                      getTrimmedText(content),
                                                      'description3'
                                                    )
                                                  }
                                                  getNewLink={link =>
                                                    console.log(link)
                                                  }
                                                  favouriteLinks={[
                                                    'https://example.com'
                                                  ]}
                                                  editable={true}
                                                />
                                              </Typography>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'center'
                                                }}
                                              >
                                                <Link
                                                  component={'button'}
                                                  className={
                                                    classes.marketingTemplateLink
                                                  }
                                                  // href={
                                                  //   secondaryCtaDetails?.btnUrl3
                                                  // }
                                                >
                                                  <Tiptap
                                                    tiptap_content={
                                                      secondaryCtaDetails &&
                                                      secondaryCtaDetails?.btnLabel3
                                                        ? secondaryCtaDetails?.btnLabel3
                                                        : 'View programs'
                                                    }
                                                    onUpdateContent={content =>
                                                      onMarketingInputChange(
                                                        getTrimmedText(content),
                                                        'btnLabel3'
                                                      )
                                                    }
                                                    getNewButtonLabel={label =>
                                                      onMarketingInputChange(
                                                        label,
                                                        'btnLabel3'
                                                      )
                                                    }
                                                    getNewLink={link =>
                                                      onMarketingInputChange(
                                                        link,
                                                        'btnUrl3'
                                                      )
                                                    }
                                                    buttonExistingLabel={
                                                      secondaryCtaDetails?.btnLabel3
                                                    }
                                                    buttonExistingLink={
                                                      secondaryCtaDetails?.btnUrl3
                                                    }
                                                    favouriteLinks={[
                                                      'https://example.com'
                                                    ]}
                                                    editable={true}
                                                    hasInitialValue={false}
                                                    boldItemShow={false}
                                                    linkItemShow={false}
                                                    buttonItemShow={true}
                                                    italicItemShow={false}
                                                    listItemShow={false}
                                                    h1ItemShow={false}
                                                    h2ItemShow={false}
                                                    h3ItemShow={false}
                                                    h4ItemShow={false}
                                                    h5ItemShow={false}
                                                    h6ItemShow={false}
                                                  />
                                                </Link>
                                              </div>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </>
                                  </Collapse>
                                </div>
                              </Box>
                            </div>
                            <div
                              className={
                                templateView === 'desktop'
                                  ? classes.desktopSectionContainer
                                  : classes.mobileSectionContainer
                              }
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    footerSection && footerSection
                                      ? footerSection
                                      : ''
                                }}
                              ></div>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <SectionSelectionDialog
        selectedSection={selectedSection}
        open={open}
        onClose={handleClose}
        marketingTemplate={marketingTemplate}
        primaryActionTemplate={primaryActionTemplate}
        handleMarketingSectionSelection={handleMarketingSectionSelection}
        handlePrimaryActionSectionSelection={
          handlePrimaryActionSectionSelection
        }
      />
    </>
  );
};

export default EmailNotificationsDialog;
