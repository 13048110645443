import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  leftContainerCard: {
    marginBottom: '1rem',
    border: '1px solid #fff',
    '&:hover': {
      border: '1px solid #37879A'
    }
  },
  leftContainerCardContent: {
    paddingBottom: '16px !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  marketingTemplate: {
    width: '100%',
    backgroundColor: '#F9FAFB',
    borderRadius: '6px',
    padding: '1rem'
  },
  marketingTemplateTitle: {
    justifyContent: 'center',
    color: '#454F5B',
    fontSize: '16px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '24px'
  },
  marketingTemplateBlog: {
    display: 'block',
    textAlign: 'center',
    marginTop: '0.5rem',
    color: '#454F5B',
    fontWeight: 400,
    lineHeight: '22px'
  },
  marketingTemplateLink: {
    display: 'block',
    color: '#EE824D',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    textDecoration: 'none',
    textAlign: 'center'
  }
}));

const SectionSelectionDialog = ({
  open,
  onClose,
  selectedSection,
  marketingTemplate,
  primaryActionTemplate,
  handleMarketingSectionSelection,
  handlePrimaryActionSectionSelection
}) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {selectedSection === 'primary' ? (
        <>
          <DialogTitle>Select Primary Action section template</DialogTitle>
          <DialogContent>
            <Card
              className={classes.leftContainerCard}
              style={{
                cursor: 'pointer',
                marginBottom: 8,
                border: `1px solid ${
                  primaryActionTemplate === 'primary1' ? '#37879A' : '#fff'
                }`
              }}
              onClick={() => {
                handlePrimaryActionSectionSelection('primary1');
                handleClose();
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ backgroundColor: '#fff', padding: '0rem' }}>
                      <div>
                        Ready to see more? Check out our programs by selecting
                        the button below.
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: '8px', marginTop: '0.5rem' }}
                        href={''}
                      >
                        View programs
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              className={classes.leftContainerCard}
              style={{
                cursor: 'pointer',
                marginBottom: 8,
                border: `1px solid ${
                  primaryActionTemplate === 'primary2' ? '#37879A' : '#fff'
                }`
              }}
              onClick={() => {
                handlePrimaryActionSectionSelection('primary2');
                handleClose();
              }}
            >
              <CardContent className={classes.leftContainerCardContent}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ backgroundColor: '#fff', padding: '0rem' }}>
                      <div>
                        Want to schedule a tour? Check out our tour calendar.
                      </div>
                      <Button
                        variant="contained"
                        color="default"
                        style={{ borderRadius: '8px', marginTop: '0.5rem' }}
                        href={''}
                      >
                        Schedule tour
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card
              className={classes.leftContainerCard}
              style={{
                cursor: 'pointer',
                marginBottom: 8,
                border: `1px solid ${
                  primaryActionTemplate === 'primary3' ? '#37879A' : '#fff'
                }`
              }}
              onClick={() => {
                handlePrimaryActionSectionSelection('primary3');
                handleClose();
              }}
            >
              <CardContent className={classes.leftContainerCardContent}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ backgroundColor: '#fff', padding: '0rem' }}>
                      <div>
                        Ready to register? Select the registration link below.
                      </div>
                      <Button
                        style={{ borderRadius: '8px', marginTop: '0.5rem' }}
                        href={''}
                      >
                        Register now
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>Select Marketing section template</DialogTitle>
          <DialogContent>
            <Card
              className={classes.leftContainerCard}
              style={{
                cursor: 'pointer',
                border: `1px solid ${
                  marketingTemplate === 'marketing1' ? '#37879A' : '#fff'
                }`
              }}
              onClick={() => {
                handleMarketingSectionSelection('marketing1');
                handleClose();
              }}
            >
              <CardContent className={classes.leftContainerCardContent}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <div className={classes.marketingTemplate}>
                      <Typography className={classes.marketingTemplateTitle}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 16C13.6569 16 15 14.6568 15 13C15 11.3431 13.6569 9.99998 12 9.99998C10.3431 9.99998 9 11.3431 9 13C9 14.6568 10.3431 16 12 16Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3 10.5651C3 9.9907 3 9.70352 3.07403 9.43905C3.1396 9.20478 3.24737 8.98444 3.39203 8.78886C3.55534 8.56806 3.78202 8.39175 4.23539 8.03912L11.0177 2.764C11.369 2.49075 11.5447 2.35412 11.7387 2.3016C11.9098 2.25526 12.0902 2.25526 12.2613 2.3016C12.4553 2.35412 12.631 2.49075 12.9823 2.764L19.7646 8.03913C20.218 8.39175 20.4447 8.56806 20.608 8.78886C20.7526 8.98444 20.8604 9.20478 20.926 9.43905C21 9.70352 21 9.9907 21 10.5651V17.8C21 18.9201 21 19.4801 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4801 3 18.9201 3 17.8V10.5651Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        &nbsp;
                        <div>24 - 25 School Year</div>
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.marketingTemplateBlog}
                      >
                        <div>
                          View our programs and pricing for the upcoming school
                          year.
                        </div>
                      </Typography>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Link
                          component={'button'}
                          className={classes.marketingTemplateLink}
                        >
                          View programs
                        </Link>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.marketingTemplate}>
                      <Typography className={classes.marketingTemplateTitle}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 10C12.42 10 16 11.79 16 14V16H0V14C0 11.79 3.58 10 8 10Z"
                            fill="#585858"
                          />
                        </svg>
                        &nbsp; Summer Camp
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.marketingTemplateBlog}
                      >
                        Check out our programs and schedule for the upcoming
                        summer!
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Link
                            component={'button'}
                            className={classes.marketingTemplateLink}
                          >
                            View summer
                          </Link>
                        </div>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card
              className={classes.leftContainerCard}
              style={{
                cursor: 'pointer',
                border: `1px solid ${
                  marketingTemplate === 'marketing2' ? '#37879A' : '#fff'
                }`
              }}
              onClick={() => {
                handleMarketingSectionSelection('marketing2');
                handleClose();
              }}
            >
              <CardContent className={classes.leftContainerCardContent}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div className={classes.marketingTemplate}>
                      <Typography className={classes.marketingTemplateTitle}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 16C13.6569 16 15 14.6568 15 13C15 11.3431 13.6569 9.99998 12 9.99998C10.3431 9.99998 9 11.3431 9 13C9 14.6568 10.3431 16 12 16Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3 10.5651C3 9.9907 3 9.70352 3.07403 9.43905C3.1396 9.20478 3.24737 8.98444 3.39203 8.78886C3.55534 8.56806 3.78202 8.39175 4.23539 8.03912L11.0177 2.764C11.369 2.49075 11.5447 2.35412 11.7387 2.3016C11.9098 2.25526 12.0902 2.25526 12.2613 2.3016C12.4553 2.35412 12.631 2.49075 12.9823 2.764L19.7646 8.03913C20.218 8.39175 20.4447 8.56806 20.608 8.78886C20.7526 8.98444 20.8604 9.20478 20.926 9.43905C21 9.70352 21 9.9907 21 10.5651V17.8C21 18.9201 21 19.4801 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4801 3 18.9201 3 17.8V10.5651Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        &nbsp; 24 - 25 School Year
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.marketingTemplateBlog}
                      >
                        We have limited availability for the upcoming school
                        year. Don’t wait to sign up!
                      </Typography>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Link
                          component={'button'}
                          className={classes.marketingTemplateLink}
                        >
                          View programs
                        </Link>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default SectionSelectionDialog;
