import _ from 'lodash';
import {
  colors,
  createMuiTheme,
  fade,
  responsiveFontSizes
} from '@material-ui/core';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import type {
  Palette as MuiPalette,
  PaletteColor,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette';
import { THEMES } from 'src/constants';
import { softShadows } from './shadows';
import typography from './typography';
import { OvBlue, OvThird, OvPrimary, OvSecondary, OvSuccess, OvError, OvWarning, OvInfo, OvText } from './colors';

interface TypeBackground extends MuiTypeBackground {
  [x: string]: any;
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
  third?: PaletteColor,
  blue?: PaletteColor,
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
  zIndex?: any;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'body': {
          color: colors.blueGrey[100],
        },
        '.custom-scroll::-webkit-scrollbar, .custom-scroll ::-webkit-scrollbar': {
          width: 7,
          backgroundColor: '#F4F4F4',
        },
        '.custom-scroll::-webkit-scrollbar-track, .custom-scroll ::-webkit-scrollbar-track': {
          backgroundColor: '#F4F4F4',
        },
        '.custom-scroll::-webkit-scrollbar-thumb, .custom-scroll ::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: '#F4F4F4',
        }
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      },
      icon: {
        color: 'inherit',
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255,255,255,0.9)',
      }
    },
    MuiButton: {
      root: {
        paddingLeft: 24,
        paddingRight: 24,
        // borderRadius: 100,
        textTransform: 'none',
      }
    },
    MuiLink: {
      root: {
        '&:hover': {
          textDecoction: 'none !important'
        }
      }
    },
    MuiCard: {
      root: {
        borderRadius: 8,
        '&.scrollCard': {
          display: 'flex',
          flexDirection: 'column',
          "& .MuiCardHeader-root": {
            flexGrow: 0,
          },
          "& .MuiCardContent-root": {
            flexGrow: 1,
            height: "100%",
            overflow: 'auto',
          }
        }
      },

    },
    MuiCardContent: {
      root: {
        paddingBottom: 16,
      },
    },
    MuiTabs: {
      root: {
        minHeight: 40,
        backgroundColor: colors.common.white,
        borderBottom: `none`,
        color: OvText.main
      },
      indicator: {
        background: OvBlue.main
      },
      flexContainer: {
        '&&&': {
          borderBottom: 'none'
        }
      }
    },
    MuiTab: {
      root: {
        fontFamily: 'Roboto',
        minWidth: 0,
        minHeight: 40,
        fontWeight: 600,
        padding: '0px 16px',
        textTransform: 'none',
        '&.Mui-selected': {
          color: OvText.main,
        }
      },
    },
    MuiPopover: {
      root: {
        zIndex: '9999999 !important',
      }
    },
    MuiStepper: {
      root: {
        '&.colorThird': {
          '& .MuiStepConnector-line': {
            borderColor: OvThird.main,
          },
          '& .MuiStepLabel-label': {
            color: colors.grey[800],
          },
          '& .MuiStepIcon-root': {
            color: OvThird.main,
          },
          '& .Mui-disabled': {
            '& .MuiStepConnector-line': {
              borderColor: colors.grey[500],
            },
            '& .MuiStepLabel-label': {
              color: fade(colors.grey[800], 0.5),
            },
            '& .MuiStepIcon-root': {
              color: fade(OvThird.light, 0.5),
            },
          }
        }
      }
    }
  }
};

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.OV,
    overrides: {
      MuiInput: {
        underline: {
          '&:hover': {
            '&:before': {
              borderColor: `${OvBlue.light} !important`
            }
          }
        },
      },
      MuiInputBase: {
        root: {
          color: OvText.main,
          fontWeight: 500
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          '& fieldset': {
            borderColor: '#eaeaea',
          }
        }
      },
      MuiInputLabel: {
        root: {
          fontWeight: 500,
          '&$focused': {
            color: OvBlue.light
          }
        },
        focused: {}
      },
      MuiToolbar: {
        root: {
          backgroundColor: '#F3FAFA',
          color: '#4E9AAC',
        }
      },
      MuiListSubheader: {
        root: {
          color: '#4E9AAC',
        }
      },
      MuiLinearProgress: {
        barColorPrimary: {
          backgroundColor: '#698198',
        }
      },
      MuiButton: {
        textThird: {
          color: OvText.main,
        },
        root: {
          '&.textThird': {
            color: OvText.main,
          }
        }
      },
      MuiTypography: {
        root: {
          '&.textThird': {
            color: OvThird.main,
          },
          '&.textSecondaryLight': {
            color: OvText.main
          },
          '&.textSecondaryHint': {
            color: OvBlue[100],
          }
        },
        'h1': {
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontSize: 30
        },
        'h2': {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: 28
        },
        'h3': {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: 24
        },
        'h4': {
          fontFamily: 'Roboto',
          fontWeight: 700,
          fontSize: 16
        },
        'body1': {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: 16
        },
        'body2': {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: 14
        },
        'caption': {
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: 14
        },
        'h5': {
          fontFamily: 'Roboto',
        },
        'h6': {
          fontFamily: 'Roboto',
        }
      },
      MuiDataGrid: {
        root: {
          borderColor: '#DCDCDC',
          background: '#FBFBFB',
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#F0F0F0',
            borderBottomColor: '#DCDCDC',
          },
          '& .MuiDataGrid-cell': {
            borderBottomColor: '#DCDCDC',
          }
        }
      },
    },
    palette: {
      type: 'light',
      primary: OvPrimary,
      secondary: OvSecondary,
      third: OvThird,
      success: OvSuccess,
      error: OvError,
      warning: OvWarning,
      info: OvInfo,
      blue: OvBlue,
      text: {
        primary: OvText.main,
        secondary: OvText.light,
        hint: OvText.main
      },
      grey: {
        ...colors.grey,
        500: '#EBEBEB',
      },
      background: {
        default: '#F4F4F4',
        dark: '#BFD4DB',
        paper: colors.common.white,
        grey: {
          main: '#FCFCFC',
          contrastText: '#2A4C6B',
        },
      },
      divider: fade('#4E9AAC', 0.2),
    },

    shadows: softShadows
  }
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
}
