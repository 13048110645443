import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import _, {
  chain, //, uniqueId
  groupBy
} from 'lodash';
import { AppThunk } from 'src/store';
import {
  ICreateDealWithDetailsInput,
  IProviderDealFilter,
  Member,
  ProviderDealFilterService,
  IDeal,
  IPipelineStage,
  PipelineStageService,
  DealService,
  EnrollmentProgramScheduleService,
  PipelineService
} from 'shared';
import moment from 'moment';
// import { PipelineService } from 'shared';
// import { IDeal } from 'shared';
// import { IPipelineStage } from 'shared';
// import { PipelineStageService } from 'shared';
// import { DealService } from 'shared';

interface LeadsState {
  isLoaded: boolean;
  pipelineId: string;
  providerDealFilterId: string;
  lists: IPipelineStage[];
  filter: IProviderDealFilter;
  selectedCard?: IDeal;
  cardListMap: { [x: string]: string };
  members: Member[];
  selectedStatus?: number;
  activeCount?: number;
  archiveCount?: number;
  futureCount?: number;
  filteredEvents?: any[];
  terms?: any[];
  termList?: any[];
  registrationClasses?: any;
  isShowChat?: boolean;
  isShowChild?: boolean;
  wizardStepsInfo?: any;
  registrationTerms?: any[];
  registrationTermClasses?: any[];
  registrationTermInfo?: any;
}

const initialState: LeadsState = {
  isLoaded: false,
  pipelineId: null,
  providerDealFilterId: null,
  selectedCard: null,
  cardListMap: {},
  filter: null,
  lists: [],
  members: [],
  selectedStatus: null,
  activeCount: 0,
  archiveCount: 0,
  futureCount: 0,
  filteredEvents: [],
  terms: [],
  termList: [],
  registrationClasses: null,
  isShowChat: false,
  isShowChild: false,
  wizardStepsInfo: {
    isLoading: false,
    termClasses: null,
    selectedTerm: null,
    apiPayload: {}
  },
  registrationTerms: [],
  registrationTermClasses: [],
  registrationTermInfo: {
    childId: [],
    selectedTerm: null,
    selectedClasses: []
  }
};

const slice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    getBoard(
      state: LeadsState,
      action: PayloadAction<{
        lists: IPipelineStage[];
        pipelineId: string;
        cardListMap: any;
        filter?: IProviderDealFilter;
        providerDealFilterId?: string;
        activeCount?: number;
        archiveCount?: number;
        futureCount?: number;
      }>
    ) {
      const {
        lists,
        pipelineId,
        cardListMap,
        filter,
        providerDealFilterId,
        activeCount,
        archiveCount,
        futureCount
      } = action.payload;

      state.pipelineId = pipelineId;
      state.lists = lists;
      state.cardListMap = cardListMap;
      state.isLoaded = true;
      state.filter = filter;
      state.providerDealFilterId = providerDealFilterId;
      state.activeCount = activeCount;
      state.archiveCount = archiveCount;
      state.futureCount = futureCount;
    },

    setSelectedCard(state: LeadsState, action: PayloadAction<{ card: IDeal }>) {
      const { card } = action.payload;
      state.selectedCard = card;
    },

    setIsShowChat(
      state: LeadsState,
      action: PayloadAction<{ isShow: boolean }>
    ) {
      const { isShow } = action.payload;
      state.isShowChat = isShow;
    },

    setIsShowChild(
      state: LeadsState,
      action: PayloadAction<{ isShow: boolean }>
    ) {
      const { isShow } = action.payload;
      state.isShowChild = isShow;
    },

    setTerms(state: LeadsState, action: PayloadAction<{ terms: any[] }>) {
      const { terms } = action.payload;
      state.terms = terms;
    },

    getTermList(state: LeadsState, action: PayloadAction<any>) {
      state.termList = action.payload;
    },

    setRegistrationTerms(
      state: LeadsState,
      action: PayloadAction<{ terms: any[] }>
    ) {
      const { terms } = action.payload;
      state.registrationTerms = terms;
    },

    setRegistrationClasses(
      state: LeadsState,
      action: PayloadAction<{ classes: any[] }>
    ) {
      const { classes } = action.payload;
      state.registrationClasses = classes;
    },

    setRegistrationTermClasses(
      state: LeadsState,
      action: PayloadAction<{ classes: any[] }>
    ) {
      const { classes } = action.payload;
      state.registrationTermClasses = classes;
    },

    setFilteredEvents(
      state: LeadsState,
      action: PayloadAction<{ events: any[] }>
    ) {
      const { events } = action.payload;
      state.filteredEvents = events;
    },

    setSelectedStatus(
      state: LeadsState,
      action: PayloadAction<{ status: number }>
    ) {
      const { status } = action.payload;
      state.selectedStatus = status;
    },

    createList(
      state: LeadsState,
      action: PayloadAction<{ list: IPipelineStage }>
    ) {
      const { list } = action.payload;
      state.lists.push(list);
    },

    updateList(
      state: LeadsState,
      action: PayloadAction<{ list: IPipelineStage }>
    ) {
      const { list } = action.payload;
      state.lists = state.lists.map(oldList => {
        return list.id === oldList.id ? list : oldList;
      });
    },

    deleteList(state: LeadsState, action: PayloadAction<{ listId: string }>) {
      const { listId } = action.payload;
      state.lists = _.filter(state.lists, list => list.id !== listId);
    },

    createCard(state: LeadsState, action: PayloadAction<{ card: IDeal }>) {
      const { card } = action.payload;
      const list = _.find(
        state.lists,
        list => card.pipelineStageId === list.id
      );
      list?.deals.push(card);

      state.cardListMap[card.id] = card.pipelineStageId;

      state.lists = state.lists.map(oldList => {
        return list.id === oldList.id ? list : oldList;
      });
    },

    updateCard(
      state: LeadsState,
      action: PayloadAction<{ cardId: string; card: IDeal }>
    ) {
      const { card, cardId } = action.payload;
      const list = _.find(
        state.lists,
        list => card.pipelineStageId === list.id
      );

      if (list?.deals) {
        list.deals = list.deals.map(oldCard => {
          return cardId === oldCard.id ? card : oldCard;
        });
      }

      state.cardListMap[card.id] = card.pipelineStageId;

      state.lists = state.lists.map(oldList => {
        return list.id === oldList.id ? list : oldList;
      });
    },

    moveCard(
      state: LeadsState,
      action: PayloadAction<{
        cardId: string;
        position: number;
        listId?: string;
      }>
    ) {
      const { cardId, position, listId } = action.payload;

      const oldListId = state.cardListMap[cardId];

      const oldList = _.find(state.lists, list => oldListId === list.id);
      const newList = _.find(state.lists, list => list.id === listId);

      if (oldList?.deals) {
        const card = _.find(oldList.deals, deal => cardId === deal.id);
        oldList.deals = oldList.deals.filter(card => card.id !== cardId);

        if (listId) {
          card.pipelineStageId = listId;
          state.cardListMap[cardId] = listId;
          if (position >= 0) {
            newList.deals.splice(position, 0, card);
          } else {
            newList.deals.push(card);
          }
        } else {
          if (position) {
            oldList.deals.splice(position, 0, card);
          } else {
            oldList.deals.push(card);
          }
        }
      }

      if (oldList?.id) {
        state.lists = state.lists.map(item => {
          if (newList && item.id === newList.id) return newList;
          else if (item.id === oldList.id) {
            return oldList;
          } else {
            return item;
          }
        });
      }
      // state.lists = _.map(state.lists, (list) => list.id != newList.id ? list : newList);
    },

    deleteCard(state: LeadsState, action: PayloadAction<{ cardId: string }>) {
      const { cardId } = action.payload;

      const listId = state.cardListMap[cardId];
      const list = _.find(state.lists, list => listId === list.id);
      list.deals = list.deals.filter(item => item.id !== cardId);
      state.lists = state.lists.map(item => {
        return list.id === item.id ? list : item;
      });
    },

    // Wizard actions

    setLoadingForTermWizard(state: LeadsState, action: PayloadAction<any>) {
      state.wizardStepsInfo.isLoading = action.payload;
    },

    setSelectedTermForWizard(
      state: LeadsState,
      action: PayloadAction<{ term: any }>
    ) {
      const { term } = action.payload;
      state.wizardStepsInfo.selectedTerm = term;
    },

    addCustomTermName(state: LeadsState, action: PayloadAction<any>) {
      state.wizardStepsInfo.apiPayload.name = action.payload;
    },

    setUpdateSessions(
      state: LeadsState,
      action: PayloadAction<{ sessions: any }>
    ) {
      const { sessions } = action.payload;
      state.wizardStepsInfo.apiPayload.sessions = sessions;
    },

    resetWizardStepsInfo(state: LeadsState, action: PayloadAction) {
      state.wizardStepsInfo = {
        termClasses: null,
        selectedTerm: null,
        apiPayload: {}
      };
    },

    resetRegistrationTermInfo(state: LeadsState, action: PayloadAction) {
      state.registrationTermInfo = {
        childId: [],
        selectedTerm: null,
        selectedClasses: []
      };
    },

    resetRegistrationTermClassInfo(state: LeadsState, action: PayloadAction) {
      state.registrationTermInfo.selectedClasses = [];
    },

    setTermRegistrationTermInfo(
      state: LeadsState,
      action: PayloadAction<{ selectedTerm: any }>
    ) {
      const selectedTerm = action.payload;
      state.registrationTermInfo.selectedTerm = selectedTerm;
    },

    setChildRegistrationTermInfo(
      state: LeadsState,
      action: PayloadAction<{ selectedTerm: any }>
    ) {
      const childId = action.payload;
      state.registrationTermInfo.childId = childId;
    },

    setClassesRegistrationTermInfo(
      state: LeadsState,
      action: PayloadAction<{ selectedClasses: any }>
    ) {
      const selectedClasses = action.payload;
      state.registrationTermInfo.selectedClasses = selectedClasses;
    },

    changeTermDatesForWizard(
      state: LeadsState,
      action: PayloadAction<{ startDate: any; endDate: any }>
    ) {
      const { startDate, endDate } = action.payload;
      state.wizardStepsInfo.apiPayload.programStartDate = startDate;
      state.wizardStepsInfo.apiPayload.programEndDate = endDate;
    },

    getTermWizardSetupInfo(
      state: LeadsState,
      action: PayloadAction<{ info: any }>
    ) {
      const { info } = action.payload;
      state.wizardStepsInfo.apiPayload = info;
    },

    getTermClasses(state: LeadsState, action: PayloadAction<{ classes: any }>) {
      const { classes } = action.payload;
      state.wizardStepsInfo.termClasses = classes;
    },

    changeClassSelection(state: LeadsState, action: PayloadAction<any>) {
      state.wizardStepsInfo.apiPayload.classes = action.payload;
    }
  }
});

export const reducer = slice.reducer;

const pipelineService = PipelineService.getInstance<PipelineService>();
const providerDealFilterService = ProviderDealFilterService.getInstance<
  ProviderDealFilterService
>();
const pipelineStageService = PipelineStageService.getInstance<
  PipelineStageService
>();
const dealService = DealService.getInstance<DealService>();
const enrollmentProgramScheduleService = EnrollmentProgramScheduleService.getInstance<
  EnrollmentProgramScheduleService
>();

export const getBoard = (status: number = null): AppThunk => dispatch => {
  return pipelineService
    .getWithDetails({
      PipelineType: 'Lead',
      ProviderDealFilterId: null,
      Status: status
    })
    .then(resp => {
      const lists: IPipelineStage[] = resp.data.pipelineStages;
      const cardListMap = chain(lists)
        .map(list => list.deals)
        .flatten()
        .keyBy('id')
        .mapValues('pipelineStageId')
        .value();
      dispatch(
        slice.actions.getBoard({
          pipelineId: resp.data.id,
          cardListMap,
          lists,
          activeCount: resp.data.activeDealsCount,
          futureCount: resp.data.futureDealsCount,
          archiveCount: resp.data.archivedDealsCount
        })
      );
    });
  // return providerDealFilterService
  //   .getByProvider({
  //     PipelineType: 'Lead',
  //     PipelineRelationType: 7
  //   })
  //   .then(resp => {
  //     const filter: IProviderDealFilter = {
  //       baseStatus: resp.data.baseStatus,
  //       providerId: resp.data.providerId,
  //       leadDates: resp.data.leadDates,
  //       startImmediate: resp.data.startImmediate,
  //       startUnsure: resp.data.startUnsure,
  //       selectedAges: resp.data.selectedAges,
  //       selectedTiming: resp.data.selectedTiming,
  //       startTimeFrame: resp.data.startTimeFrame,
  //       childrenAges: resp.data.childrenAges,
  //       startNotDefined: resp.data.startNotDefined,
  //       noChildren: resp.data.noChildren,
  //       providerDealFiltersEnrollmentSchedules:
  //         resp.data.providerDealFiltersEnrollmentSchedules,
  //       pipelineType: resp.data.pipelineType ? resp.data.pipelineType : 'Lead',
  //       pipelineRelationType: resp.data.pipelineRelationType
  //         ? resp.data.pipelineRelationType
  //         : 7
  //     };
  //     const lists: IPipelineStage[] = resp.data.pipeline.pipelineStages;
  //     const cardListMap = chain(lists)
  //       .map(list => list.deals)
  //       .flatten()
  //       .keyBy('id')
  //       .mapValues('pipelineStageId')
  //       .value();
  //     dispatch(
  //       slice.actions.getBoard({
  //         pipelineId: resp.data.id,
  //         cardListMap,
  //         lists,
  //         filter,
  //         providerDealFilterId: resp.data.id
  //       })
  //     );
  //   });
};

export const updateBoard = (
  id: string,
  request: IProviderDealFilter
): AppThunk => dispatch => {
  return providerDealFilterService
    .updateFilterByProvider(id, request)
    .then(resp => {
      const filter: IProviderDealFilter = {
        baseStatus: resp.data.baseStatus,
        providerId: resp.data.providerId,
        leadDates: resp.data.leadDates,
        startImmediate: resp.data.startImmediate,
        startUnsure: resp.data.startUnsure,
        startNotDefined: resp.data.startNotDefined,
        selectedAges: resp.data.selectedAges,
        selectedTiming: resp.data.selectedTiming,
        startTimeFrame: resp.data.startTimeFrame,
        childrenAges: resp.data.childrenAges,
        noChildren: resp.data.noChildren,
        providerDealFiltersEnrollmentSchedules:
          resp.data.providerDealFiltersEnrollmentSchedules,
        pipelineType: resp.data.pipelineType ? resp.data.pipelineType : 'Lead',
        pipelineRelationType: resp.data.pipelineRelationType
          ? resp.data.pipelineRelationType
          : 7
      };
      const lists: IPipelineStage[] = resp.data.pipeline.pipelineStages;
      const cardListMap = chain(lists)
        .map(list => list.deals)
        .flatten()
        .keyBy('id')
        .mapValues('pipelineStageId')
        .value();
      dispatch(
        slice.actions.getBoard({
          pipelineId: resp.data.id,
          cardListMap,
          lists,
          filter,
          providerDealFilterId: resp.data.id
        })
      );
    });
};

export const setDealsInList = (deals: IDeal[]): AppThunk => (
  dispatch,
  getState
) => {
  const { leads } = getState();

  const dealsByPipelineStageId = groupBy(deals, 'pipelineStageId');

  for (const list of leads.lists) {
    const deals = dealsByPipelineStageId[list.id] || [];
    dispatch(
      slice.actions.updateList({
        list: {
          ...list,
          deals: deals
        }
      })
    );
  }
};

export const createList = (name: string): AppThunk => (dispatch, getState) => {
  return pipelineStageService
    .create({
      name,
      pipelineId: getState().leads.pipelineId
    })
    .then(resp => {
      const list = resp.data;
      dispatch(slice.actions.createList({ list }));
      return resp.data;
    });
};

export const updateList = (listId: string, update: any): AppThunk => (
  dispatch,
  getState
) => {
  const state = getState().leads;

  const list = _.find(state.lists, { id: listId });

  const request = {
    ...list,
    ...update
  };

  return pipelineStageService.update(listId, request).then(resp => {
    dispatch(slice.actions.updateList({ list: request }));
    return resp.data;
  });
};

export const deleteList = (listId: string): AppThunk => dispatch => {
  return pipelineStageService.delete(listId).then(() => {
    dispatch(slice.actions.deleteList({ listId }));
  });
};

export const createCard = (listId: string, card: any): AppThunk => dispatch => {
  const request = {
    ...card,
    pipelineStageId: listId
  };
  // const tempId = uniqueId();

  return dealService.create(request).then(resp => {
    dispatch(
      slice.actions.createCard({
        card: resp.data
      })
    );
    return resp.data;
    //  dispatch(slice.actions.updateCard({ cardId: tempId, card: resp.data }));
  });
};

export const createWithDetails = (
  request: Partial<ICreateDealWithDetailsInput>
): AppThunk => dispatch => {
  return dealService.createWithDetails(request).then(resp => {
    const card = resp.data;
    if (request?.deal?.dealId) {
      const cardId = request?.deal?.dealId;

      dispatch(
        slice.actions.moveCard({
          cardId,
          position: null,
          listId: request.deal?.pipelineStageId
        })
      );
      dispatch(slice.actions.updateCard({ cardId, card }));
    } else {
      dispatch(slice.actions.createCard({ card }));
    }

    return card;
  });
};

export const updateCard = (
  cardId: string,
  update: Partial<IDeal>
): AppThunk => (dispatch, getState) => {
  const state = getState().leads;
  const listId = state.cardListMap[cardId];
  const list = _.find(state.lists, { id: listId });
  const card = _.find(list.deals, deal => cardId === deal.id);
  const request = {
    ...card,
    ...update
  };
  return dealService.update(cardId, request).then(resp => {
    if (card.pipelineStageId !== request.pipelineStageId) {
      dispatch(
        slice.actions.moveCard({
          cardId: cardId,
          position: null,
          listId: request.pipelineStageId
        })
      );
    }
    dispatch(slice.actions.updateCard({ cardId, card: resp.data }));
    return resp.data;
  });
};

export const moveCard = (
  cardId: string,
  position: number,
  listId?: string,
  registerLead?: boolean
): AppThunk => (dispatch, getState) => {
  const state = getState().leads;
  const oldListId = state.cardListMap[cardId];
  const oldList = _.find(state.lists, list => oldListId === list.id);
  const card = _.find(oldList.deals, deal => cardId === deal.id);
  const oldPosition = oldList.deals.indexOf(card);

  dispatch(
    slice.actions.moveCard({
      cardId: cardId,
      position,
      listId
    })
  );

  let request = null;

  if (typeof registerLead !== 'undefined') {
    request = {
      ...card,
      dealOrder: position,
      ...(listId ? { pipelineStageId: listId } : {}),
      registerLead
    };
  } else {
    request = {
      ...card,
      dealOrder: position,
      ...(listId ? { pipelineStageId: listId } : {})
    };
  }

  return dealService.update(card.id, request).catch(error => {
    dispatch(
      slice.actions.moveCard({
        cardId: cardId,
        position: oldPosition,
        listId: oldListId
      })
    );
    throw error.error;
  });
};

export const deleteCard = (cardId: string): AppThunk => dispatch => {
  return dealService.delete(cardId).then(() => {
    dispatch(slice.actions.deleteCard({ cardId }));
  });
};

export const setSelectedCard = (card: IDeal): AppThunk => dispatch => {
  dispatch(slice.actions.setSelectedCard({ card }));
};

export const setIsShowChat = (isShow: boolean): AppThunk => dispatch => {
  dispatch(slice.actions.setIsShowChat({ isShow }));
};

export const setIsShowChild = (isShow: boolean): AppThunk => dispatch => {
  dispatch(slice.actions.setIsShowChild({ isShow }));
};

export const getTermsByProvider = (): AppThunk => dispatch => {
  return enrollmentProgramScheduleService.getTermsByProvider().then(res => {
    if (res.status === 200) {
      dispatch(slice.actions.setTerms({ terms: res.data }));
      return res.data;
    }
  });
};

export const getTermList = (): AppThunk => dispatch => {
  return enrollmentProgramScheduleService.getTermList().then(res => {
    if (res.status === 200) {
      const terms = res.data.filter(
        data => data.status === 'Active' || data.status === 'Future'
      );
      dispatch(slice.actions.getTermList(terms));
    }
  });
};

export const getTerms = (): AppThunk => dispatch => {
  return enrollmentProgramScheduleService.getTermBaseData().then(res => {
    if (res.status === 200) {
      dispatch(slice.actions.setRegistrationTerms({ terms: res.data }));
      return res.data;
    }
  });
};

export const getClasses = (): AppThunk => dispatch => {
  return enrollmentProgramScheduleService.getTermClasses().then(res => {
    if (res.status === 200) {
      dispatch(slice.actions.setRegistrationTermClasses({ classes: res.data }));
      return res.data;
    }
  });
};

export const getRegistrationDataByTerm = (id: string): AppThunk => dispatch => {
  return enrollmentProgramScheduleService
    .getRegistrationDataByTerm(id)
    .then(res => {
      if (res.status === 200) {
        return res.data;
      }
      return [];
    });
};

export const getTermClasses = (id: string): AppThunk => dispatch => {
  return enrollmentProgramScheduleService
    .getRegistrationDataByTerm(id)
    .then(res => {
      if (res.status === 200) {
        dispatch(
          slice.actions.getTermClasses({
            classes: res.data.enrollmentTermsClasses
          })
        );
      } else {
        dispatch(slice.actions.getTermClasses({ classes: [] }));
      }
      return [];
    })
    .catch(() => dispatch(slice.actions.getTermClasses({ classes: [] })));
};

export const setRegistrationClasses = (classes: any): AppThunk => dispatch => {
  dispatch(slice.actions.setRegistrationClasses({ classes }));
};

export const setFilteredEvents = (events: any[]): AppThunk => dispatch => {
  dispatch(slice.actions.setFilteredEvents({ events }));
};

export const setSelectedStatus = (status: number): AppThunk => dispatch => {
  dispatch(slice.actions.setSelectedStatus({ status }));
};

// Wizard actions

export const changeClassSelection = ({ id, isSelected }: any): AppThunk => (
  dispatch,
  getState
) => {
  const classes = getState().leads.wizardStepsInfo.apiPayload.classes;

  const classList = classes.map(classInfo => {
    if (classInfo.id === id) {
      return { ...classInfo, isSelected };
    }
    return classInfo;
  });

  dispatch(slice.actions.changeClassSelection(classList));
};

export const setSelectedTermForWizard = (term: any): AppThunk => dispatch => {
  dispatch(slice.actions.setSelectedTermForWizard({ term }));
};

export const setUpdateSessions = (sessions: any): AppThunk => dispatch => {
  dispatch(slice.actions.setUpdateSessions({ sessions }));
};

export const resetWizardStepsInfo = (): AppThunk => dispatch => {
  dispatch(slice.actions.resetWizardStepsInfo());
};

export const resetRegistrationTermInfo = (): AppThunk => dispatch => {
  dispatch(slice.actions.resetRegistrationTermInfo());
};

export const resetRegistrationTermClassInfo = (): AppThunk => dispatch => {
  dispatch(slice.actions.resetRegistrationTermClassInfo());
};

export const setTermRegistrationTermInfo = (
  selectedTerm: any
): AppThunk => dispatch => {
  dispatch(slice.actions.setTermRegistrationTermInfo(selectedTerm));
};

export const setChildRegistrationTermInfo = (
  childId: any
): AppThunk => dispatch => {
  dispatch(slice.actions.setChildRegistrationTermInfo(childId));
};

export const setClassesRegistrationTermInfo = (
  selectedClasses: any
): AppThunk => dispatch => {
  dispatch(slice.actions.setClassesRegistrationTermInfo(selectedClasses));
};

export const addCustomTermName = (termName: string): AppThunk => dispatch => {
  dispatch(slice.actions.addCustomTermName(termName));
};

export const changeTermDatesForWizard = ({
  startDate,
  endDate
}: any): AppThunk => dispatch => {
  dispatch(slice.actions.changeTermDatesForWizard({ startDate, endDate }));
};

export const getInitTermWizardSetup = (): AppThunk => dispatch => {
  return enrollmentProgramScheduleService
    .getTermWizardSetup()
    .then(res => {
      if (res.status === 200) {
        const date = moment().format('YYYY-MM-DDTHH:mm:ss');
        dispatch(
          slice.actions.getTermWizardSetupInfo({
            info: { ...res.data, programEndDate: date, programStartDate: date }
          })
        );
      }
    })
    .catch(() => {});
};

export const getTermWizardSetupByTermId = (
  id: string
): AppThunk => dispatch => {
  dispatch(slice.actions.setLoadingForTermWizard(true));
  return enrollmentProgramScheduleService
    .getTermWizardSetupByTermId(id)
    .then(res => {
      if (res.status === 200) {
        dispatch(slice.actions.getTermWizardSetupInfo({ info: res.data }));
      }
    })
    .catch(() => {})
    .finally(() => dispatch(slice.actions.setLoadingForTermWizard(false)));
};

export const putTermWizardSetups = (request: any): AppThunk => dispatch => {
  return enrollmentProgramScheduleService.putTermWizardSetup(request);
};

export const updateClassScheduleClassOption = (
  classId: string,
  classTitle: string,
  optionTitle: string,
  isSelected: boolean
): AppThunk => (dispatch, getState) => {
  const classes = getState().leads.wizardStepsInfo.apiPayload.classes;

  const classList = classes.map(classInfo => {
    if (classInfo.id === classId) {
      const scheduleClassOptionsDetails = classInfo.scheduleClassOptions[
        classTitle
      ].map(option => {
        if (option.name === optionTitle) {
          return { ...option, isSelected };
        }
        return option;
      });

      return {
        ...classInfo,
        scheduleClassOptions: {
          ...classInfo.scheduleClassOptions,
          [classTitle]: scheduleClassOptionsDetails
        }
      };
    }
    return classInfo;
  });

  dispatch(slice.actions.changeClassSelection(classList));
};

export default slice;
