import React, { FC, useCallback } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Switch as Toggle
} from '@material-ui/core';

import { Theme } from 'src/theme';
import { FieldProps } from 'formik';

const useStyles = makeStyles((theme: Theme) => ({
  centerInfoCard: {
    width: '100%',
    padding: theme.spacing(2, 0),
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #C4CDD5'
  },
  title: {
    color: '#2A3745',
    fontWeight: 500,
    fontSize: 16
  },
  dialogHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#212B36',
    display: 'flex',
    alignItems: 'center'
  },
  dialogSubHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center'
  }
}));

export interface SwitchTextFieldProps extends FieldProps {
  title: string;
  subTitle?: string;
  disabled: boolean;
}

const SwitchTextField: FC<SwitchTextFieldProps> = ({
  form: { setFieldValue },
  field: { name, value },
  title,
  subTitle,
  disabled = false
}) => {
  const classes = useStyles();

  const handleChange = useCallback(() => {
    setFieldValue(name, !value);
  }, [name, value, setFieldValue]);

  return (
    <Box className={classes.centerInfoCard}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.dialogHeading}>{title}</Typography>
          <Toggle
            onChange={handleChange}
            checked={value}
            color="primary"
            name={title}
            disabled={disabled}
          />
        </Box>
        <Typography className={classes.dialogSubHeading}>{subTitle}</Typography>
      </Box>
    </Box>
  );
};

export default SwitchTextField;
