import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface BasicInfoState {
  hasValuesChanged: boolean,
};

const initialState: BasicInfoState = {
  hasValuesChanged: false,
};

const slice = createSlice({
    name: 'basic-info',
    initialState,
    reducers: {
      setValuesChanged(state: BasicInfoState, action: PayloadAction<boolean>) {
        state.hasValuesChanged = action.payload;
      }
    }
});

export const reducer = slice.reducer;

export const setValuesChanged = (value: boolean) => {
  return async (dispatch) => {
    dispatch(slice.actions.setValuesChanged(value));
  }
}

export default slice;