import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as leadsReducer } from 'src/slices/leads';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as currentUserReducer } from 'src/slices/current-user';
import { reducer as programsReducer } from 'src/slices/programs';
import { reducer as BasicInfoReducer } from 'src/slices/basic-info';
import { reducer as onBoardingReducer } from 'src/slices/on-boarding';
import { reducer as familiesReducer } from 'src/slices/families';
import { reducer as dashboardReducer } from 'src/slices/dashboard';
import { reducer as mediaReducer } from 'src/slices/media';
import { reducer as tourReducer } from 'src/slices/tour';
import { reducer as settingsReducer } from 'src/slices/settings';
import { reducer as enrollmentReducer } from 'src/slices/enrollment';
import { reducer as registrationReducer } from 'src/slices/registration';
import { reducer as onboardingProviderReducer } from 'src/slices/onboarding-provider';
import { reducer as enrollmentClassReducer } from 'src/slices/enrollment-class';
import { reducer as enrollmentKanbanReducer } from 'src/slices/enrollment-kanban';
import { reducer as subscriptionReducer } from 'src/slices/subscription';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  currentUser: currentUserReducer,
  onBoarding: onBoardingReducer,
  programs: programsReducer,
  kanban: kanbanReducer,
  leads: leadsReducer,
  media: mediaReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  basicInfo: BasicInfoReducer,
  families: familiesReducer,
  dashboard: dashboardReducer,
  tour: tourReducer,
  settings: settingsReducer,
  enrollment: enrollmentReducer,
  enrollmentClass: enrollmentClassReducer,
  enrollmentKanban: enrollmentKanbanReducer,
  registration: registrationReducer,
  onboardingProvider: onboardingProviderReducer,
  subscription: subscriptionReducer
});

export default rootReducer;
