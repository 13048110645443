import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, ButtonBase } from '@material-ui/core';
import { Theme } from 'src/theme';
import ClassDialog from './ClassDialog';
import { useDispatch, useSelector } from 'src/store';
import { getAgeClassList } from 'src/slices/enrollment-class';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    borderRadius: '8px',
    border: '1px solid #C4CDD5',
    boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)'
  },
  title: {
    fontSize: 14
  },
  notificationLink: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: theme.spacing(2)
  },
  cardTitle: {
    fontWeight: 700
  },
  clickableItem: {
    width: '100%',
    borderBottom: '1px solid #C4CDD5'
  },
  cardContentContainer: {
    width: '100%'
  },
  cardContentRoot: { padding: 0 },
  disabled: {
    background: '#FFF4E5',
    padding: 8,
    color: '#C67608',
    borderRadius: 33,
    width: 95,
    fontSize: 14
  },
  dialogHeading: {
    ontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#212B36',
    display: 'flex',
    alignItems: 'center'
  },
  dialogSubHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center'
  }
}));

const Registration = () => {
  const classes = useStyles();
  const [toggleClassDialog, setToggleClassDialog] = useState<boolean>(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const ageClassList = useSelector(state => state.enrollmentClass.ageClassList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAgeClassList());
  }, [dispatch]);

  const handleClassDialog = useCallback(() => {
    setToggleClassDialog(false);
  }, []);

  const handleClassSelection = useCallback(regClass => {
    setSelectedClass(regClass);
    setIsDirty(false);
    setToggleClassDialog(true);
  }, []);

  return (
    <>
      <Card className={classes.root}>
        <CardContent
          classes={{ root: classes.cardContentRoot }}
          className={classes.cardContentContainer}
        >
          <Box pl={2} py={2} borderBottom="0.5px solid rgba(0, 0, 0, 0.25)">
            <Typography className={classes.cardTitle}>Classes</Typography>
          </Box>

          {ageClassList &&
            ageClassList.map(regClass => (
              <ButtonBase
                //   disabled={template.status === 'Planned'}
                className={classes.clickableItem}
                onClick={() => handleClassSelection(regClass)}
                key={regClass.id}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  p={2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    textAlign="left"
                  >
                    <Typography className={classes.dialogHeading}>
                      {regClass.title}
                    </Typography>
                    <Typography className={classes.dialogSubHeading}>
                      Customize the UI the parent will see when they go to book
                      a tour
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <ArrowForwardIosIcon className={classes.notificationLink} />
                  </Box>
                </Box>
              </ButtonBase>
            ))}
        </CardContent>
      </Card>
      <ClassDialog
        isOpen={toggleClassDialog}
        onClose={handleClassDialog}
        selectedClass={selectedClass}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
      />
    </>
  );
};
export default Registration;
