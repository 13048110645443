export const appConfig = {
  baseUrl:
    process.env.REACT_APP_OIDC_PROVIDER_CLIENT_ROOT ||
    'http://localhost:3002/#',
  appName: process.env.REACT_SITE_NAME || 'One Village'
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const apiConfig = {
  // https://api.onevillage.co/ for production, https://api.dev.onevillage.co/ for staging, etc
  apiUrl: process.env.REACT_APP_API_URL || 'https://localhost:44363/'
};

export const oidcConfig = {
  // In production: 'https://provider-account.onevillage.co/'
  stsAuthority:
    process.env.REACT_APP_OIDC_PROVIDER_AUTHORITY || 'https://localhost:44313/',
  clientId:
    process.env.REACT_APP_OIDC_PROVIDER_CLIENT_ID || 'OneVillage_Provider',
  // In production: 'https://provider.onevillage.co/'
  clientRoot:
    process.env.REACT_APP_OIDC_PROVIDER_CLIENT_ROOT || 'http://localhost:3002/',
  clientScope: 'openid profile email OneVillage'
};

export const XGridLicenseKey =
  '4c6e7f6c456779ffd4abf14bc6c5dcebT1JERVI6MjE2MjYsRVhQSVJZPTE2NDU1NTc1MjkwMDAsS0VZVkVSU0lPTj0x';

export const SmartyStreetsKey = '79550741143835614';
export const GoogleMapKey = 'AIzaSyDd3gn5zW5ECuR5TJSl2WaomDDS9hMozKA';

export const FullCalendarSchedulerLicenseKey = '0042728714-fcs-1617383560';

export const UserbackToken = '31455|66785|yl92rUcdaUXRuDRdU0W2verPU';
