import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Breadcrumbs,
  ButtonBase,
  Container,
  Dialog,
  DialogContent,
  Link,
  Popover,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Theme } from 'src/theme';
import { useSelector } from 'src/store';
import { MicrositeAccessService } from 'shared';
import AddEditProfileForm from './AddEditProfileForm';
import { FormikHelpers } from 'formik';
import { useToasts } from 'src/components/core/ToastManager';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    marginTop: 16,
    marginBottom: 16,
    padding: '16px',
    overflow: 'hidden',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '16px',
    boxShadow: '0px 1px 2px 0px rgba(145, 158, 171, 0.16)',
    borderRadius: 16
  },
  dialogPaper: {
    background: '#F4F6F8'
  },
  closeModal: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gridGap: 8
  },
  popoverPaper: {
    width: 220,
    borderRadius: 12,
    boxShadow: '-20px 20px 40px -4px rgb(145 158 171 / 24%)',
    filter: 'drop-shadow(0px 0px 2px rgba(145, 158, 171, 0.24))'
  },
  profileContainer: {
    display: 'flex',
    padding: theme.spacing(2),
    gridGap: '16px',
    width: '100%',
    borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
  },
  breadcrumbLink: {
    color: '#454F5B',
    fontSize: 14,
    fontWeight: 600
  },
  breadcrumbActive: { fontSize: 14, fontWeight: 600, color: '#637381' },
  featuresContainer: { fontWeight: 600, color: '#637381', marginBottom: 8 },
  contentWrapper: { borderRadius: 8, background: '#F9FAFB', display: 'flex' },
  leftPanelWrapper: {
    padding: 20,
    width: 320,
    borderRight: '1px solid rgba(223, 227, 232, 0.50)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  fieldTitle: {
    color: '#454F5B',
    fontWeight: 600,
    marginBottom: 4
  },
  fieldWrapper: {
    border: '1px solid #F4F6F8',
    borderRadius: 8,
    background: '#fff',
    padding: '12px 16px'
  },
  cancelButton: {
    marginRight: 16,
    borderRadius: 8,
    border: '1px solid rgba(145, 158, 171, 0.32)',
    fontSize: 15,
    fontWeight: 700,
    color: '#637381'
  },
  saveButton: {
    background: '#37879A',
    borderRadius: 8,
    color: '#fff',
    fontSize: 15,
    fontWeight: 700,
    '&:hover': {
      background: '#3e98ae'
    }
  },
  profileImage: {
    height: 80,
    width: 80,
    marginBottom: 16,
    borderRadius: '50%',
    border: '1px solid #fff'
  },
  rightSectionWrapper: {
    width: '100%',
    background: '#F4F6F8',
    paddingLeft: 43,
    paddingRight: 43,
    paddingTop: 64,
    paddingBottom: 64
  },
  previewContainer: {
    width: 714,
    height: 400,
    boxShadow: '0px 1px 2px 0px rgba(145, 158, 171, 0.16)',
    background: '#fff',
    borderRadius: 8,
    display: 'flex'
  },
  previewProfileSection: {
    width: 200,
    background: '#37879A',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  previewProfileName: {
    fontWeight: 600,
    color: '#fff',
    marginBottom: 2
  },
  previewProfileNameDark: {
    color: '#585858',
    fontWeight: 600,
    marginBottom: 2
  },
  previewProfileRole: { fontSize: 14, color: '#fff', opacity: '0.7' },
  previewProfileRoleDark: { fontSize: 14, color: '#585858', opacity: '0.7' },
  previewCentreSection: {
    borderTop: '1px solid rgba(255, 255, 255, 0.24)',
    textAlign: 'center'
  },
  previewCentreName: {
    fontSize: 14,
    fontWeight: 600,
    color: '#fff',
    paddingTop: 16,
    paddingBottom: 16
  },
  previewCentreNameDark: {
    fontSize: 14,
    fontWeight: 600,
    color: '#585858',
    paddingTop: 16,
    paddingBottom: 16
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    objectFit: 'cover'
  },
  tealColorSelector: {
    background: '#37879A',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  blueColorSelector: {
    background: '#344AAD',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  orangeColorSelector: {
    background: '#DC652A',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  purpleColorSelector: {
    background: '#7C52B7',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  yellowColorSelector: {
    background: '#FFE16A',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  peachColorSelector: {
    background: '#FFA48D',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  redColorSelector: {
    background: '#FF4842',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  greenColorSelector: {
    background: '#4C7F39',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  lightTealColorSelector: {
    background: '#BFDDE4',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  brownColorSelector: {
    background: '#B78103',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  lightGreenColorSelector: {
    background: '#DCF4D4',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  lightBlueColorSelector: {
    background: '#A0AEE3',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  lightPurpleColorSelector: {
    background: '#E4DAF1',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  lightOrangeColorSelector: {
    background: '#FFC9AD',
    width: 32,
    height: 32,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fullWidth: { width: '100%' },
  colorSelectionText: { fontWeight: 600 },
  selectColor: {
    marginBottom: 8,
    color: '#637381',
    fontWeight: 600,
    fontSize: 14
  }
}));

const micrositeAccessService = MicrositeAccessService.getInstance<
  MicrositeAccessService
>();

const defaultFormValue = {
  name: '',
  imageUrl: null,
  role: '',
  centerName: '',
  color: 'teal',
  chat: true
};

const selectableColors: any = [
  { color: 'teal', value: 'Teal', code: '#37879A' },
  { color: 'blue', value: 'Blue', code: '#344AAD' },
  { color: 'orange', value: 'Orange', code: '#DC652A' },
  { color: 'purple', value: 'Purple', code: '#7C52B7' },
  { color: 'green', value: 'Green', code: '#4C7F39' },
  { color: 'brown', value: 'Brown', code: '#B78103' },
  { color: 'red', value: 'Red', code: '#FF4842' },
  { color: 'light-teal', value: 'Light Teal', code: '#BFDDE4' },
  { color: 'light-blue', value: 'Light Blue', code: '#A0AEE3' },
  { color: 'light-orange', value: 'Light Orange', code: '#FFC9AD' },
  { color: 'light-purple', value: 'Light Purple', code: '#E4DAF1' },
  { color: 'light-green', value: 'Light Green', code: '#DCF4D4' },
  { color: 'yellow', value: 'Yellow', code: '#FFE16A' },
  { color: 'peach', value: 'Peach', code: '#FFA48D' }
];

const MessageUs = ({ onClose, isOpen }) => {
  const classes = useStyles();
  const { provider } = useSelector(state => ({
    provider: state.currentUser.provider
  }));

  // const [tourLink, setTourLink] = useState('');
  // const [messageLink, setMessageLink] = useState('');
  const [micrositeSettings, setMicrositeSettings] = useState(null);
  const [profileSettings, setProfileSettings] = useState(defaultFormValue);
  // const formRef = useRef<any>();
  const { addToast } = useToasts();
  const [isDarkText, setIsDarkText] = useState(false);

  // const handleFormSubmitTrigger = useCallback(() => {
  //   if (formRef.current) {
  //     const obj: any = formRef.current;
  //     obj.handleSubmit();
  //   }
  // }, [formRef]);
  const [isStyleDirty, setIsStyleDirty] = useState(false);
  const [selectedColor, setSelectedColor] = useState('teal');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSubmit = useCallback(
    async (values: any, action: FormikHelpers<any>) => {
      console.log(values);
      const request = {
        ...micrositeSettings,
        userName: values?.name,
        userPhoto: values?.imageUrl,
        userRole: values?.role,
        centerName: values?.centerName,
        centerStyleColor: selectedColor,
        chat: true
      };
      if (micrositeSettings?.id) {
        await micrositeAccessService
          .update(micrositeSettings.id, request)
          .then(res => {
            if (res.status === 200) {
              const providerSetting = res.data;
              setMicrositeSettings(providerSetting);
              setProfileSettings({
                name: providerSetting?.userName,
                imageUrl: providerSetting?.userPhoto,
                role: providerSetting?.userRole,
                centerName: providerSetting?.centerName,
                color: providerSetting?.centerStyleColor,
                chat: providerSetting?.chat
              });
              setIsStyleDirty(false);
              addToast('Message Us settings updated successfully');
            } else {
              addToast(
                'Something went wrong while updating Message Us Settings. Please try again later',
                'error'
              );
            }
          });
      }
    },
    [micrositeSettings, addToast, selectedColor]
  );

  useEffect(() => {
    if (
      micrositeSettings?.centerStyleColor === 'yellow' ||
      micrositeSettings?.centerStyleColor === 'peach' ||
      micrositeSettings?.centerStyleColor === 'light-teal' ||
      micrositeSettings?.centerStyleColor === 'light-blue' ||
      micrositeSettings?.centerStyleColor === 'light-orange' ||
      micrositeSettings?.centerStyleColor === 'light-green' ||
      micrositeSettings?.centerStyleColor === 'light-purple'
    ) {
      setIsDarkText(true);
    } else {
      setIsDarkText(false);
    }
  }, [micrositeSettings]);

  // useEffect(() => {
  //   const environment = window.location.origin.includes('.dev.')
  //     ? 'dev'
  //     : window.location.origin.includes('.stage.')
  //     ? 'stage'
  //     : 'production';

  //   const link = `https://${provider?.slug}.${
  //     environment !== 'production' ? `${environment}.` : ''
  //   }onevillage.me/sites/${provider?.slug}/tour`;

  //   const msgLink = `https://${provider?.slug}.${
  //     environment !== 'production' ? `${environment}.` : ''
  //   }onevillage.me/sites/${provider?.slug}/message-us`;

  //   setTourLink(link);
  //   setMessageLink(msgLink);
  // }, [provider]);

  useEffect(() => {
    micrositeAccessService.get().then(res => {
      if (res.status === 200) {
        const providerSetting = res.data?.items.filter(
          item => item.providerId === provider?.id
        )[0];
        setMicrositeSettings(providerSetting);
        setProfileSettings({
          name: providerSetting?.userName,
          imageUrl: providerSetting?.userPhoto,
          role: providerSetting?.userRole,
          centerName: providerSetting?.centerName,
          color: providerSetting?.centerStyleColor,
          chat: providerSetting?.chat
        });
        setSelectedColor(providerSetting?.centerStyleColor);
      }
    });
  }, [provider]);

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      disableEnforceFocus
      fullScreen
      onClose={onClose}
      open={isOpen}
    >
      <Container maxWidth="lg">
        <Box display="flex" gridGap={4} flexDirection="column">
          <Box mt={2} display="flex" justifyContent="space-between">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link
                className={classes.breadcrumbLink}
                component="button"
                underline="always"
                color="primary"
                onClick={onClose}
              >
                Settings
              </Link>
              <Typography className={classes.breadcrumbActive}>
                Tours and Message Us
              </Typography>
            </Breadcrumbs>
            <Typography className={classes.closeModal} onClick={onClose}>
              <CloseIcon />
              Close
            </Typography>
          </Box>
        </Box>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.leftPanelWrapper}>
              <Box>
                {/* <Box mb={2}>
                  <Typography className={classes.featuresContainer}>
                    Features
                  </Typography>
                  <Box mb={1} className={classes.fieldWrapper}>
                    <Typography className={classes.fieldTitle}>
                      Online Tour Scheduling
                    </Typography>
                    <Button
                      color="secondary"
                      component="a"
                      size="small"
                      href={tourLink}
                      endIcon={<ChevronRightIcon />}
                      target="_blank"
                    >
                      View page
                    </Button>
                  </Box>
                  <Box className={classes.fieldWrapper}>
                    <Typography className={classes.fieldTitle}>
                      Message Us
                    </Typography>
                    <Button
                      color="secondary"
                      component="a"
                      size="small"
                      href={messageLink}
                      endIcon={<ChevronRightIcon />}
                      target="_blank"
                    >
                      View page
                    </Button>
                  </Box>
                </Box> */}
                <Box mb={2}>
                  <Typography className={classes.featuresContainer}>
                    Style
                  </Typography>
                  <Box mb={1} className={classes.fieldWrapper}>
                    <ButtonBase
                      onClick={handleClick}
                      className={classes.fullWidth}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box display="flex">
                          <div
                            style={{
                              marginRight: 12,
                              width: 20,
                              height: 20,
                              borderRadius: 40,
                              background: selectableColors.filter(
                                x => x.color === selectedColor
                              )[0].code
                            }}
                          ></div>
                          <Typography className={classes.colorSelectionText}>
                            {
                              selectableColors.filter(
                                x => x.color === selectedColor
                              )[0].value
                            }
                          </Typography>
                        </Box>
                        <KeyboardArrowDownIcon />
                      </Box>
                    </ButtonBase>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                    >
                      <Box p={2}>
                        <Typography className={classes.selectColor}>
                          Select Color
                        </Typography>
                        <Box
                          width="264px"
                          height="32px"
                          display="flex"
                          justifyContent="space-between"
                          mb={1}
                        >
                          <div
                            className={classes.lightTealColorSelector}
                            onClick={() => {
                              setSelectedColor('light-teal');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'light-teal' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.lightBlueColorSelector}
                            onClick={() => {
                              setSelectedColor('light-blue');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'light-blue' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.lightOrangeColorSelector}
                            onClick={() => {
                              setSelectedColor('light-orange');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'light-orange' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.lightPurpleColorSelector}
                            onClick={() => {
                              setSelectedColor('light-purple');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'light-purple' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.lightGreenColorSelector}
                            onClick={() => {
                              setSelectedColor('light-green');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'light-green' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.yellowColorSelector}
                            onClick={() => {
                              setSelectedColor('yellow');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'yellow' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.peachColorSelector}
                            onClick={() => {
                              setSelectedColor('peach');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'peach' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                        </Box>
                        <Box
                          width="264px"
                          height="32px"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <div
                            className={classes.tealColorSelector}
                            onClick={() => {
                              setSelectedColor('teal');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'teal' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.blueColorSelector}
                            onClick={() => {
                              setSelectedColor('blue');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'blue' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.orangeColorSelector}
                            onClick={() => {
                              setSelectedColor('orange');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'orange' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.purpleColorSelector}
                            onClick={() => {
                              setSelectedColor('purple');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'purple' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.greenColorSelector}
                            onClick={() => {
                              setSelectedColor('green');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'green' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.brownColorSelector}
                            onClick={() => {
                              setSelectedColor('brown');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'brown' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                          <div
                            className={classes.redColorSelector}
                            onClick={() => {
                              setSelectedColor('red');
                              setIsStyleDirty(true);
                              handleClose();
                            }}
                          >
                            {selectedColor === 'red' && (
                              <CheckIcon htmlColor="#fff" fontSize="small" />
                            )}
                          </div>
                        </Box>
                      </Box>
                    </Popover>
                  </Box>
                </Box>
                <Box mb={2}>
                  <Typography className={classes.featuresContainer}>
                    Settings
                  </Typography>
                  <AddEditProfileForm
                    onClose={onClose}
                    values={profileSettings}
                    onSubmit={handleSubmit}
                    isStyleDirty={isStyleDirty}
                    // formRef={formRef}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.rightSectionWrapper}>
              <Box className={classes.previewContainer}>
                <Box
                  className={classes.previewProfileSection}
                  style={{
                    background: selectableColors.filter(
                      x => x.color === profileSettings?.color
                    )[0].code
                  }}
                >
                  <Box p={3}>
                    {profileSettings?.imageUrl ? (
                      <Box className={classes.profileImage}>
                        <img
                          className={classes.image}
                          alt="profile"
                          src={profileSettings?.imageUrl}
                        />
                      </Box>
                    ) : (
                      <svg
                        width="62"
                        height="62"
                        viewBox="0 0 62 62"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.65">
                          <circle
                            cx="31"
                            cy="31"
                            r="30.5"
                            fill="#F4F6F8"
                            stroke="#637381"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.7666 42.5558C19.3837 37.7687 25.0812 34.6667 31.5 34.6667C37.9187 34.6667 43.6163 37.7687 47.2334 42.5558C47.2497 42.5774 47.2661 42.5991 47.2826 42.6209C47.5936 43.0322 47.9271 43.4732 48.1654 43.8986C48.4539 44.4136 48.6676 45.0093 48.6544 45.7338C48.6437 46.3162 48.4673 46.8613 48.253 47.2918C48.0387 47.7223 47.7102 48.1917 47.2519 48.5513C46.6408 49.0308 45.9755 49.2006 45.3845 49.2715C44.8659 49.3337 44.2514 49.3336 43.6336 49.3334C43.6057 49.3334 43.5779 49.3334 43.55 49.3334H19.4499C19.4221 49.3334 19.3942 49.3334 19.3664 49.3334C18.7485 49.3336 18.134 49.3337 17.6155 49.2715C17.0244 49.2006 16.3591 49.0308 15.748 48.5513C15.2898 48.1917 14.9612 47.7223 14.7469 47.2918C14.5327 46.8613 14.3562 46.3162 14.3456 45.7338C14.3323 45.0093 14.5461 44.4136 14.8345 43.8986C15.0728 43.4732 15.4063 43.0322 15.7173 42.6209C15.7338 42.5991 15.7502 42.5774 15.7666 42.5558Z"
                            fill="#454F5B"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.4166 22.7501C21.4166 17.1812 25.9311 12.6667 31.5 12.6667C37.0688 12.6667 41.5833 17.1812 41.5833 22.7501C41.5833 28.319 37.0688 32.8334 31.5 32.8334C25.9311 32.8334 21.4166 28.319 21.4166 22.7501Z"
                            fill="#454F5B"
                          />
                        </g>
                      </svg>
                    )}

                    <Typography
                      className={
                        isDarkText
                          ? classes.previewProfileNameDark
                          : classes.previewProfileName
                      }
                    >
                      {profileSettings?.name}
                    </Typography>
                    <Typography
                      className={
                        isDarkText
                          ? classes.previewProfileRoleDark
                          : classes.previewProfileRole
                      }
                    >
                      {profileSettings?.role}
                    </Typography>
                  </Box>
                  <Box className={classes.previewCentreSection}>
                    <Typography
                      className={
                        isDarkText
                          ? classes.previewCentreNameDark
                          : classes.previewCentreName
                      }
                    >
                      {profileSettings?.centerName}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default MessageUs;
