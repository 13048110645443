import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, Box, Avatar, Typography, IconButton } from '@material-ui/core';
import type { Theme } from 'src/theme';
import MailIcon from 'src/components/icons/MailIcon';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
interface MessageBoxProps {
    largeMessage?: boolean
};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        padding: '6px 24px 6px 24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: 78,
        maxHeight: 78,
        boxShadow: 'none',
        background: 'transparent',
        margin: theme.spacing(1, 0),
        width: 360,
        position: 'relative',
        marginRight: theme.spacing(6),
        border: '1px solid #DCDCDC'
    },
    largeItem: {
        height: 102,
        maxHeight: 102,
    },
    topContainer: {
        display: 'flex',
        width: '100%'
    },
    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 56
    },
    middleContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexGrow: 1
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexGrow: 1
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        height: 24
    },
    primary: {
        fontWeight: 500,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 20,
        textTransform: 'capitalize',
        letterSpacing: 0.15,
        lineHeight: '28px',
        color: '#333333'
    },
    secondary: {
        fontWeight: 400,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        color: '#414547'
    },
    captionText: {
        fontWeight: 500,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        letterSpacing: 0.1,
        lineHeight: '24px',
        color: theme.palette.text.primary
    },
    rightContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        minWidth: 48
    },
    statusButtonContainer: {
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center'
    },
    statusButton: {
        padding: 0
    },
    statusIcon: {
        fontSize: 14,
        color: '#838383'
    },
    avatarContainer: {
        minWidth: 56,
        flexShrink: 0
    },
    avatar: {
        width: 40,
        height: 40,
        fontWeight: 'bold',
        fontFamily: '"Roboto", sans-serif',
        fontSize: theme.spacing(3),
        lineHeight: '28px',
        color: theme.palette.common.white,
        textTransform: 'uppercase'
    },
    bottomContainer: {
        display: 'flex',
        width: '100%'
    },
    messageText: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        color: theme.palette.text.primary,
        marginTop: 5,
        overflow: 'hidden',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1
    },
    unreadBadge: {
        position: 'absolute',
        left: 6,
        top: 32,
        width: 12,
        height: 12,
        borderRadius: '100%',
        background: theme.palette.primary.main
    },
    countContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#838383',
        paddingLeft: theme.spacing(2),
        height: '100%'
    },
    countText: {
        fontWeight: 400,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        letterSpacing: 0.2,
        lineHeight: '16px',
        textTransform: 'capitalize'
    },
    countIcon: {
        fontSize: 14,
        marginRight: theme.spacing(0.5)
    },
    arrowIcon: {
        color: theme.palette.text.primary,
        marginRight: -8
    },
    selected: {
        '& $primary': {
            fontWeight: 700
        },
        '& $secondary': {
            fontWeight: 700
        }
    }
}));

const MessageBox: FC<MessageBoxProps> = ({ largeMessage }) => {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.container, { [classes.largeItem]: largeMessage })}>
            {largeMessage && <Box className={classes.unreadBadge} />}
            <Box className={classes.topContainer}>
                <Box className={classes.leftContainer}>
                    <Box className={classes.avatarContainer}>
                        <Avatar
                            className={classes.avatar}
                            src='/static/images/avatars/avatar_1.png'
                        />
                    </Box>
                </Box>
                <Box className={clsx(classes.middleContainer, { [classes.selected]: largeMessage })}>
                    <Box className={classes.nameContainer}>
                        <Typography variant='h5' className={classes.primary}>
                            Smith
                        </Typography>
                        <Typography className={classes.captionText} variant="caption">
                            2:43pm
                        </Typography>
                    </Box>
                    <Box className={classes.infoContainer}>
                        <Box className={classes.statusButtonContainer}>
                            <IconButton className={classes.statusButton}>
                                <MailIcon className={classes.statusIcon} />
                            </IconButton>
                        </Box>
                        <Typography variant='h5' noWrap className={classes.secondary}>
                            Re: Visit to your school
                        </Typography>
                        <Box className={classes.countContainer}>
                            <Box display="flex" alignItems="center">
                                <QuestionAnswerIcon className={classes.countIcon} />
                                <Typography variant='h4' className={classes.countText}>
                                    11
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {largeMessage && (
                <Box className={classes.bottomContainer}>
                    <Box className={classes.leftContainer} />
                    <Box className={classes.middleContainer}>
                        <Typography className={classes.messageText}>
                            Hi Lisa, I have registered online via...
                        </Typography>
                    </Box>
                    <Box className={classes.rightContainer}>
                        <ChevronRightIcon className={classes.arrowIcon} />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default MessageBox;