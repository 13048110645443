import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, ButtonBase } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Theme } from 'src/theme';
import { EmailService } from 'shared';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { boolean, object, string } from 'yup';
import { useToasts } from 'src/components/core/ToastManager';
import EmailLayoutDialog from '../EmailLayout';
import { useDispatch, useSelector } from 'src/store';
import SwitchTextField from 'src/components/core/form/SwitchTextField';
import { updateProviderEmailSettings } from 'src/slices/settings';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    borderRadius: '8px',
    border: '1px solid #C4CDD5',
    boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)'
  },
  notificationLink: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: theme.spacing(2)
  },
  cardTitle: {
    fontWeight: 700
  },
  notificationDescription: {
    fontSize: 14,
    color: '#687F92'
  },
  notificationTitle: {
    color: '#114D71'
  },
  clickableItem: {
    width: '100%'
  },
  disabledBtn: {
    backgroundColor: '#E0E0E0',
    '&:hover': {
      boxShadow: 'unset'
    }
  },
  cardContentContainer: {
    width: '100%'
  },
  cardContentRoot: {
    padding: '24px'
  },
  inputIconColor: {
    color: '#667085'
  },
  dialogHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#212B36',
    display: 'flex',
    alignItems: 'center'
  },
  dialogSubHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center'
  }
}));

const defaultValue = {
  signatureEmail: '',
  signatureEmailDomain: '',
  emailSync: false
};

const validationSchema = object().shape({
  signatureEmail: string().nullable(),
  emailSync: boolean().nullable()
});

const providerEmailService = EmailService.getInstance<EmailService>();

const ParentEmails = ({
  handleIsLeadEmailTemplate,
  contactIdentitiesFormRef,
  setIsDirty,
  isDirty,
  onClose
}) => {
  const classes = useStyles();
  const { addToast } = useToasts();

  const [isShowEmailLayout, setIsShowEmailLayout] = useState(false);
  const [contactIdentitiesInfo, setContactIdentitiesInfo] = useState(
    defaultValue
  );
  const dispatch = useDispatch();
  const { provider } = useSelector(state => state.currentUser);

  const handleEmailLayout = () => {
    setIsShowEmailLayout(prevState => !prevState);
  };

  const handleSubmit = useCallback(
    async (values: any, action: FormikHelpers<any>) => {
      if (values) {
        providerEmailService
          .setPostmarkEmailAddresses({
            value: values.signatureEmail
          })
          .then(() => {
            dispatch(
              updateProviderEmailSettings({
                emailSync: values.emailSync
              })
            );
            addToast('Contact Identities successfully saved');
          })
          .catch(error => {
            addToast(error?.error?.message, 'error');
          });
      }
    },
    [addToast, dispatch]
  );

  useEffect(() => {
    if (provider) {
      providerEmailService.getPostmarkEmailAddresses().then(res => {
        if (res?.data?.signatureEmail) {
          const splitEmail = res.data.signatureEmail.split('@');
          setContactIdentitiesInfo({
            signatureEmail: splitEmail[0],
            signatureEmailDomain: splitEmail[1],
            emailSync: provider.emailSync
          });
        }
      });
    }
  }, [setContactIdentitiesInfo, provider]);

  return (
    <>
      <Card className={classes.root}>
        <CardContent
          classes={{ root: classes.cardContentRoot }}
          className={classes.cardContentContainer}
        >
          <Box display="flex" gridGap={4} flexDirection="column">
            <Box display="flex" justifyContent="space-between">
              <Typography className={classes.dialogHeading}>
                Email Address
              </Typography>
            </Box>
            <Typography className={classes.dialogSubHeading}>
              Email address used when sending emails to parents
            </Typography>
          </Box>
          <Box pt={2}>
            <Formik
              enableReinitialize
              initialValues={Object.assign(
                {},
                defaultValue,
                contactIdentitiesInfo
              )}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={contactIdentitiesFormRef}
            >
              {res => {
                if (
                  res.initialValues.signatureEmail !==
                    res.values.signatureEmail ||
                  res.initialValues.emailSync !== res.values.emailSync
                ) {
                  if (!isDirty) setIsDirty(true);
                }
                return (
                  <Form>
                    <Box display="flex" flexDirection="column" gridGap={24}>
                      <Box
                        display="flex"
                        alignItems="center"
                        gridGap={10}
                        width="400px"
                      >
                        <Field
                          fullWidth
                          hiddenLabel
                          variant="outlined"
                          size="small"
                          name="signatureEmail"
                          component={TextField}
                        />
                        <Typography
                          variant="h6"
                          className={classes.dialogSubHeading}
                        >
                          {`@${provider?.slug}.${contactIdentitiesInfo.signatureEmailDomain}`}
                        </Typography>
                      </Box>

                      <Box width="100%" display="flex" flexDirection="column">
                        <Field
                          component={SwitchTextField}
                          name="emailSync"
                          title="Email Smart Sync"
                          subTitle="Enable email forwarding and replies to and from your personal email address"
                        />
                      </Box>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
            {/* {provider?.subscriptionPlan?.toLowerCase() !== 'basic' &&
                provider?.subscriptionPlan?.toLowerCase() !== 'free' && ( */}

            {/* )} */}
          </Box>
        </CardContent>
      </Card>

      <Box display="flex" gridGap={4} flexDirection="column" mt={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.dialogHeading}>
            Email Styling and Content
          </Typography>
        </Box>
        {/* <Typography className={classes.dialogSubHeading}>
          Customize the content for your email templates
        </Typography> */}
      </Box>
      <Card className={classes.root}>
        <CardContent
          classes={{ root: classes.cardContentRoot }}
          className={classes.cardContentContainer}
        >
          <Box display="flex" flexDirection="column">
            <ButtonBase
              className={classes.clickableItem}
              onClick={handleEmailLayout}
              disableRipple
              disableTouchRipple
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box display="flex" flexDirection="column" width="100%">
                  <Typography className={classes.dialogHeading}>
                    Email Layout
                  </Typography>
                  <Typography className={classes.dialogSubHeading}>
                    Customize the styling used for all emails
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <ArrowForwardIosIcon className={classes.notificationLink} />
                </Box>
              </Box>
            </ButtonBase>
          </Box>
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent
          classes={{ root: classes.cardContentRoot }}
          className={classes.cardContentContainer}
        >
          <Box display="flex" flexDirection="column">
            <ButtonBase
              className={classes.clickableItem}
              onClick={() => handleIsLeadEmailTemplate('Lead Emails')}
              disableRipple
              disableTouchRipple
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  textAlign="left"
                >
                  <Typography className={classes.dialogHeading}>
                    Lead Email Templates
                  </Typography>
                  <Typography className={classes.dialogSubHeading}>
                    Customize the content for emails
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <ArrowForwardIosIcon className={classes.notificationLink} />
                </Box>
              </Box>
            </ButtonBase>
          </Box>
        </CardContent>
      </Card>
      {isShowEmailLayout && (
        <EmailLayoutDialog
          isOpen={isShowEmailLayout}
          onClose={onClose}
          onEmail={handleEmailLayout}
        />
      )}
    </>
  );
};
export default ParentEmails;
