import React, { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Theme } from 'src/theme';
import SelectWithLabel from 'src/pages/tours/ToursView/components/SelectWithLabel';
import AgeRangeSlider from './AgeRangeSlider';
import {
  EnrollmentAgeOptionService,
  IAgeClassRequest,
  IEnrollmentAgeClass
} from 'shared';
import { useDispatch } from 'src/store';
import { updateAgeClassList } from 'src/slices/enrollment-class';
import { useToasts } from 'src/components/core/ToastManager';

export interface ClassDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  selectedClass?: IEnrollmentAgeClass;
  isDirty?: boolean;
  setIsDirty?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  paperFullScreen: {},
  paper: {
    padding: theme.spacing(4),
    height: '100%'
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    left: 20,
    top: 20,
    color: theme.palette.text.primary,
    fontSize: '2rem'
  },
  dialogSubHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 18,
    color: '#333333'
  },
  saveContainer: {
    background: theme.palette.common.white,
    height: 80,
    width: '100%',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(30)
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center'
  },
  saveButtonContainer: {
    marginRight: theme.spacing(3),
    height: theme.spacing(6),
    width: theme.spacing(13.75),
    textTransform: 'none',
    fontWeight: 'bold',
    background: theme.palette.secondary.main,
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(4),
    color: theme.palette.common.white,
    '&:hover': {
      background: '#f16924',
      color: theme.palette.common.white
    }
  },
  discardButtonContainer: {
    marginLeft: 16,
    marginRight: 16,
    height: theme.spacing(6),
    width: theme.spacing(12.75),
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    border: `1px solid ${theme.palette.secondary.main}`,
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)'
  },
  ageRangeSliderLabel: {
    width: 120,
    marginRight: 20,
    marginLeft: 20,
    fontWeight: 500
  },
  switchLabel: {
    fontWeight: 500
  }
}));

const scheduleTypeOptions = [
  { value: 0, label: 'Fixed' },
  { value: 1, label: 'Ongoing' }
];

const enrollmentAgeOptionService = EnrollmentAgeOptionService.getInstance<
  EnrollmentAgeOptionService
>();

const ClassDialog: FC<ClassDialogProps> = ({
  isOpen,
  onClose,
  selectedClass,
  isDirty,
  setIsDirty
}) => {
  const classes = useStyles();
  const [status, setStatus] = useState<boolean>(false);
  const [selectedScheduleType, setSelectedScheduleType] = useState(null);
  const [ageList, setAgeList] = useState(null);
  const [sliderProps, setSliderProps] = useState(null);
  const [sliderValues, setSliderValues] = useState(null);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (selectedClass?.enrollmentAge) {
      setSliderValues([
        selectedClass.enrollmentAge.minAgeValue,
        selectedClass.enrollmentAge.maxAgeValue
      ]);
      setSelectedScheduleType(selectedClass.termType);
      setStatus(selectedClass.status === 1);
      enrollmentAgeOptionService
        .getOptionByAgeGroup(selectedClass.enrollmentAge?.ageGroup)
        .then(response => {
          setAgeList(
            response.data.map(item => ({
              id: item.id,
              label: item.label,
              value: item.weeks,
              weeks: item.weeks
            }))
          );
        });
    }
  }, [selectedClass]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStatus(event.target.checked);
      setIsDirty(true);
    },
    [setIsDirty]
  );

  const handleTermTypeChange = useCallback(
    selectedValue => {
      setIsDirty(true);
      setSelectedScheduleType(selectedValue);
    },
    [setIsDirty]
  );

  const handleCancel = useCallback(() => {
    onClose();
    setIsDirty(false);
  }, [onClose, setIsDirty]);

  const handleSettingsSave = useCallback(async () => {
    const request: IAgeClassRequest = {
      name: selectedClass?.title,
      minAgeOptionId: ageList.find(ageItem => ageItem.value === sliderValues[0])
        .id,
      maxAgeOptionId: ageList.find(ageItem => ageItem.value === sliderValues[1])
        .id,
      status: status ? 1 : 0,
      termType: selectedScheduleType
    };
    const response = await dispatch(
      updateAgeClassList(request, selectedClass?.id)
    );
    if (response && response?.status === 200) {
      addToast(response?.message);
      onClose();
    } else {
      addToast(response?.message, 'error');
    }
  }, [
    selectedClass,
    sliderValues,
    status,
    selectedScheduleType,
    ageList,
    dispatch,
    addToast,
    onClose
  ]);

  const handleSliderChange = useCallback(
    (event: any, newValue: number | number[]) => {
      setSliderValues(newValue as number[]);
      setIsDirty(true);
    },
    [setIsDirty]
  );

  useEffect(() => {
    if (ageList) {
      setSliderProps({
        min: ageList[0].weeks,
        max: ageList[ageList.length - 1].weeks
      });
    }
  }, [ageList]);

  return (
    <Dialog
      fullScreen
      onClose={handleCancel}
      open={isOpen}
      className={classes.root}
      classes={{ paperFullScreen: classes.paperFullScreen }}
    >
      <DialogContent>
        <Box p={4} position="relative">
          <ArrowBackIcon className={classes.closeIcon} onClick={handleCancel} />
        </Box>
        <Box p={2} borderRadius="12px">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="stretch"
                >
                  <Grid item xs={12}>
                    <Box px={8} mb={6.5}>
                      <Typography className={classes.dialogSubHeading}>
                        {selectedClass?.title} class
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box px={8} ml={2} mb={6.5}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={status}
                            onChange={handleChange}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        className={classes.switchLabel}
                        label={`${status ? 'Enable' : 'Disabled'} Registration`}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box px={8}>
                      <Box mb={6.5}>
                        <SelectWithLabel
                          options={scheduleTypeOptions}
                          label="Schedule Type"
                          id="schedule-type"
                          value={selectedScheduleType}
                          setOnChange={handleTermTypeChange}
                        />
                      </Box>
                      <Box display="flex">
                        <Typography className={classes.ageRangeSliderLabel}>
                          Age Range
                        </Typography>
                        <Box width={600}>
                          <AgeRangeSlider
                            sliderProps={sliderProps}
                            sliderValues={sliderValues}
                            handleSliderChange={handleSliderChange}
                            ageList={ageList}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <DialogActions>
                      <div className={classes.saveContainer}>
                        <div className={classes.rightSection}>
                          <Button
                            className={classes.saveButtonContainer}
                            onClick={handleSettingsSave}
                            variant="contained"
                            disabled={!isDirty}
                          >
                            Save
                          </Button>
                          <Button
                            className={classes.discardButtonContainer}
                            onClick={handleCancel}
                            variant="outlined"
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ClassDialog;
