import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'

export interface OnboardingProviderState {
  currentStep: number;
  mainStep: number;
  activeStep: number;
  profileStep: number;
  tourStep: number;
  allSteps: any[];
};

const initialState: OnboardingProviderState = {
  currentStep: 0,
  mainStep: 0,
  profileStep: 0,
  tourStep: 0,
  activeStep: 0,
  allSteps: [],
};

const slice = createSlice({
  name: 'onboarding-provider',
  initialState,
  reducers: {
    setCurrentStep(state: OnboardingProviderState, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setMainStep(state: OnboardingProviderState, action: PayloadAction<number>) {
      state.mainStep = action.payload;
    },
    setActiveStep(state: OnboardingProviderState, action: PayloadAction<number>) {
      state.activeStep = action.payload;
    },
    setProfileStep(state: OnboardingProviderState, action: PayloadAction<number>) {
      state.profileStep = action.payload;
    },
    setTourStep(state: OnboardingProviderState, action: PayloadAction<number>) {
      state.tourStep = action.payload;
    },
    setAllSteps(state: OnboardingProviderState, action: PayloadAction<any[]>) {
      state.allSteps = action.payload;
    },
  }
});

export const reducer = slice.reducer;

export const setCurrentStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setCurrentStep(data));
}


export const setMainStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setMainStep(data));
}


export const setActiveStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setActiveStep(data));
}

export const setProfileStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setProfileStep(data));
}

export const setTourStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setTourStep(data));
}


export const setAllSteps = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setAllSteps(data));
}


export default slice;
