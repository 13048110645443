import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { useSelector } from 'src/store';
import { Theme } from 'src/theme';

import LayoutTabSkeleton from './Skeleton';

const colorCodes = [
  {
    primary_darkest: '#212B36',
    primary_darker: '#454F5B',
    primary_main: '#637381',
    primary_lighter: '#C4CDD5',
    primary_lightest: '#F4F6F8'
  },
  {
    primary_darkest: '#203F46',
    primary_darker: '#1A6475',
    primary_main: '#37879A',
    primary_lighter: '#BFDDE4',
    primary_lightest: '#ECF5F8'
  },
  {
    primary_darkest: '#335015',
    primary_darker: '#4F7A21',
    primary_main: '#669F2A',
    primary_lighter: '#CEEAB0',
    primary_lightest: '#FAFDF7'
  },
  {
    primary_darkest: '#7F1D1D',
    primary_darker: '#DC2626',
    primary_main: '#F87171',
    primary_lighter: '#FECACA',
    primary_lightest: '#FEF2F2'
  },
  {
    primary_darkest: '#4C1D95',
    primary_darker: '#7C3AED',
    primary_main: '#A78BFA',
    primary_lighter: '#DDD6FE',
    primary_lightest: '#F5F3FF'
  },
  {
    primary_darkest: '#382E81',
    primary_darker: '#4F46E5',
    primary_main: '#818CF8',
    primary_lighter: '#C7D2FE',
    primary_lightest: '#EEF2FF'
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  layoutCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  layoutTitle: {
    color: '#637381',
    fontWeight: 600,
    fontSize: 16
  },
  layoutCardBox: {
    width: 150,
    height: 150,
    backgroundColor: '#F9FAFB',
    border: '2px solid rgba(0, 0, 0, 0.2)',
    borderRadius: theme.spacing(1),
    position: 'relative'
  },
  selectedLayoutCardBox: {
    backgroundColor: 'rgba(55, 135, 154, 0.08)',
    border: '2px solid #1A6475'
  },
  layoutCardLabel: {
    width: 'fit-content',
    backgroundColor: '#EAECF5',
    border: '0.5px solid #6172F3',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0, 1),
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    '& .MuiTypography-root': {
      color: '#4E5BA6',
      fontWeight: 600,
      fontSize: 12
    }
  },
  title: {
    color: '#101828',
    fontWeight: 400,
    fontSize: 16
  },
  subTitle: {
    color: '#667085',
    fontWeight: 400,
    fontSize: 14
  },
  colorCard: {
    width: 34,
    height: 34,
    borderRadius: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      color: '#fff'
    }
  },
  image: {
    width: '100%;',
    height: '100%',
    objectFit: 'none'
  }
}));

const LayoutTab = ({
  layoutSetting,
  handleChangeSelectedLayoutType,
  handleChangeSelectedLayoutColor
}) => {
  const classes = useStyles();
  const { layouts, isLayoutsLoading } = useSelector(state => state.settings);

  const ColorCard = ({ onClick, isSelected, color }) => (
    <Box
      component="span"
      className={classes.colorCard}
      bgcolor={color}
      onClick={onClick}
    >
      {isSelected && <CheckIcon />}
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" gridGap="10px">
      <Box>
        <Typography className={classes.title}>
          Selection your template
        </Typography>
        <Typography className={classes.subTitle}>
          The email layout is used to style all of your emails consistently
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="24px"
        py={2}
      >
        {isLayoutsLoading || !layouts ? (
          <LayoutTabSkeleton />
        ) : (
          <>
            {layouts?.map(layout => (
              <Box
                key={layout.alias}
                className={classes.layoutCardContainer}
                onClick={() => handleChangeSelectedLayoutType(layout.alias)}
              >
                <Typography className={classes.layoutTitle}>
                  {layout.name}
                </Typography>
                <Box
                  className={clsx(classes.layoutCardBox, {
                    [classes.selectedLayoutCardBox]:
                      layoutSetting.selectedLayoutType === layout.alias
                  })}
                >
                  <Box width={145} height={145}>
                    <img
                      className={classes.image}
                      alt="email-template"
                      src="static/images/email-template.png"
                    />
                  </Box>
                  <Box className={classes.layoutCardLabel}>
                    <Typography>{layout.alias}</Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Box>
          <Typography className={classes.title}>Colors</Typography>
          <Typography className={classes.subTitle}>
            Select a layout template and colors.
          </Typography>
        </Box>
        <Box display="flex" gridGap={8} py={2} justifyContent="space-around">
          {colorCodes.map(colorCode => (
            <ColorCard
              key={colorCode.primary_main}
              onClick={() => handleChangeSelectedLayoutColor(colorCode)}
              isSelected={
                layoutSetting.selectedColor.primary_main ===
                colorCode.primary_main
              }
              color={colorCode.primary_main}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutTab;
