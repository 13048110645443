import { defaultsDeep } from 'lodash';
import React, { useState, useCallback, createContext, FC } from 'react';
import ConfirmDialog, {
  ConfirmContext,
  ConfirmDialogProps,
  defaultProps as confirmDefaultProps
} from './ConfirmDialog';

import { DefaultDialogProps } from './Dialog';


export const DialogsContext = createContext(null);

export interface DialogsProviderProps {
  dialogProps?: DefaultDialogProps,
  confirmDialogProps?: ConfirmDialogProps;
}

const defaultProps = {
  dialogProps: {},
  confirmDefaultProps,
}

const buildConfirmOptions = (defaultOptions: any = {}, options: any = {}) => {


  const dialogProps = defaultsDeep(
    options.dialogProps,
    defaultOptions.dialogProps,
    defaultProps.confirmDefaultProps.dialogProps,
    defaultProps.dialogProps,
  );

  const confirmDefaultProps = defaultsDeep(options, defaultOptions, defaultProps.confirmDefaultProps);

  return {
    ...confirmDefaultProps,
    dialogProps,
  }
};


const DialogsProvider: FC<DialogsProviderProps> = ({
  children,
  confirmDialogProps
}) => {

  const [confirmDialogOptions, setConfirmDialogOptions] = useState(defaultProps.confirmDefaultProps);
  const [resolveReject, setResolveReject] = useState([]);

  const contextValue = useCallback((options = {}) => {
    return new Promise((resolve, reject) => {
      setConfirmDialogOptions(buildConfirmOptions(confirmDialogProps, options));
      setResolveReject([resolve, reject]);
    });
  }, [setConfirmDialogOptions, confirmDialogProps, setResolveReject]);

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  return (
    <>
      <ConfirmContext.Provider value={contextValue}>
        {children}
      </ConfirmContext.Provider>

      <ConfirmDialog
        open={resolveReject.length === 2}
        onClose={handleClose}
        resolveReject={resolveReject}
        {...confirmDialogOptions}
      >
      </ConfirmDialog>
    </>
  );
};

export default DialogsProvider;