export const OvThird = {
  50: '#edebff',
  100: '#d1ceff',
  200: '#b3aeff',
  300: '#958dff',
  400: '#7e74ff',
  500: '#675cff',
  600: '#5f54ff',
  700: '#544aff',
  800: '#4a41ff',
  900: '#3930ff',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#dddbff',
  A700: '#c4c2ff',

  light: '#7e74ff',
  main: '#675cff',
  dark: '#5f54ff',
  contrastText: '#fff'
};

export const OvBlue = {
  50: '#e2eaee',
  100: '#A4B2BE',
  200: '#88a6b8',
  300: '#58829c',
  400: '#356886',
  500: '#114d71',
  600: '#0f4669',
  700: '#0c3d5e',
  800: '#0a3454',
  900: '#052542',
  A100: '#78b4ff',
  A200: '#4598ff',
  A400: '#127cff',
  A700: '#006ff7',

  light: '#687F92',
  main: '#114d71',
  dark: '#0f4669',
  contrastText: '#fff'
};

export const OvPrimary = {
  main: '#37879A',
  light: '#1C6171B2',
  contrastText: '#fff'
};

export const OvSecondary = {
  main: '#EF7E31',
  light: '#EF7E31B2',
  contrastText: '#fff',
  dark: '#BF6527'
};

export const OvInfo = { main: '#6C9BF9' };

export const OvError = { main: '#E1645B' };

export const OvSuccess = { main: '#4BA64F' };

export const OvWarning = { main: '#F4A56F' };

export const OvText = { main: '#212121', light: '#464B4B' };
