import { Log, User, UserManager, WebStorageStateStore } from 'oidc-client';

import { oidcConfig } from '../config';

export class AuthService {
  public userManager: UserManager;

  static instance: AuthService

  constructor() {
    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: oidcConfig.stsAuthority,
      client_id: oidcConfig.clientId,
      // There are 2 options here: 
      // 1. Use React route /signin-oidc (see routes.tsx)
      // 2. Use HTML page at /signin-callback.html (see folder /public)
      // We are using option 1 for now
      redirect_uri: `${oidcConfig.clientRoot}#/signin-oidc`,
      silent_redirect_uri: `${oidcConfig.clientRoot}silent-renew.html`,
      // tslint:disable-next-line:object-literal-sort-keys
      post_logout_redirect_uri: `${oidcConfig.clientRoot}`,
      response_type: 'code',
      scope: oidcConfig.clientScope,
      filterProtocolClaims: true,
      loadUserInfo: true
    };

    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public signinRedirectCallback(): Promise<User> {
    return this.userManager.signinRedirectCallback();
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}