export const TourInfoMapper = apiResponse => {
  //Weekly Schedule
  let weeklySchedule = {
    mondayHours: [],
    tuesdayHours: [],
    wednesdayHours: [],
    thursdayHours: [],
    fridayHours: [],
    saturdayHours: [],
    sundayHours: []
  };

  const weeklyScheduleResponse =
    apiResponse.schedules?.filter(item => item.dayOfWeek !== null) || [];

  //Convert the existing weekly schedule to the UI data format
  if (weeklyScheduleResponse.length > 0) {
    weeklyScheduleResponse.forEach(weeklyScheduleInfo => {
      switch (weeklyScheduleInfo.dayOfWeek) {
        case 1:
          weeklySchedule.mondayHours = DayInfoMapper(
            weeklySchedule.mondayHours,
            weeklyScheduleInfo
          );
          break;
        case 2:
          weeklySchedule.tuesdayHours = DayInfoMapper(
            weeklySchedule.tuesdayHours,
            weeklyScheduleInfo
          );
          break;
        case 3:
          weeklySchedule.wednesdayHours = DayInfoMapper(
            weeklySchedule.wednesdayHours,
            weeklyScheduleInfo
          );
          break;
        case 4:
          weeklySchedule.thursdayHours = DayInfoMapper(
            weeklySchedule.thursdayHours,
            weeklyScheduleInfo
          );
          break;
        case 5:
          weeklySchedule.fridayHours = DayInfoMapper(
            weeklySchedule.fridayHours,
            weeklyScheduleInfo
          );
          break;
        case 6:
          weeklySchedule.saturdayHours = DayInfoMapper(
            weeklySchedule.saturdayHours,
            weeklyScheduleInfo
          );
          break;
        case 7:
          weeklySchedule.sundayHours = DayInfoMapper(
            weeklySchedule.sundayHours,
            weeklyScheduleInfo
          );
          break;
      }
    });
  }

  //Events mapping
  const eventsArray = apiResponse.meetings?.map((meeting, index) => ({
    id: index + 1,
    backgroundColor: '#dfddf8',
    borderColor: '#dfddf8',
    start: new Date(`${meeting.date} ${meeting.startTime}`),
    end: new Date(`${meeting.date} ${meeting.endTime}`),
    title: `${meeting.startTime} - ${meeting.endTime}`,
    data: {
      ...meeting
    },
    startTimeField: meeting.startTime,
    endTimeField: meeting.endTime,
    meetingLocation: meeting.meetingLocation,
    date: meeting.date
  }));

  const {
    id,
    duration,
    bufferAfterMeeting,
    bufferBeforeMeeting,
    daysInTheFuture,
    schedules,
    overlapDates,
    meetings,
    parents,
    latestTimePriorBeforeBooking
  } = apiResponse;

  return {
    id,
    duration,
    latestTimePriorBeforeBooking,
    bufferAfterMeeting,
    bufferBeforeMeeting,
    daysInTheFuture,
    weeksInTheFuture: daysInTheFuture / 7,
    events: eventsArray,
    schedules,
    overlapDates,
    meetings,
    parents,
    modifiedFields: [],
    ...weeklySchedule
  };
};

const DayInfoMapper = (weeklyDayHours, weeklyScheduleResponse) => {
  return [
    ...weeklyDayHours,
    {
      id: weeklyDayHours.length + 1,
      startTime: weeklyScheduleResponse.startTime
        ?.toLowerCase()
        .replace(/\s/g, '')
        .replace(/^0+/, ''),
      endTime:
        weeklyScheduleResponse.endTime !== null
          ? weeklyScheduleResponse.endTime
              .toLowerCase()
              .replace(/\s/g, '')
              .replace(/^0+/, '')
          : '5:00pm',
      meetingLocation: weeklyScheduleResponse.meetingLocation
    }
  ];
};
