import React, { FC, useCallback, useEffect, useState } from 'react';
import { UseFileUploads } from 'src/utils/fileUploads';
import { FieldProps } from 'formik';
import {
  CircularProgress,
  makeStyles,
  TextField,
  Box,
  TextFieldProps,
  Typography,
  Button
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { SmartyStreetsKey } from 'src/config';
import { debounce, isEmpty } from 'lodash';
import { Theme } from 'src/theme';

export interface LocationInputProps extends FieldProps, UseFileUploads {
  label?: string;
  options?: any[];
  variant?: 'filled' | 'standard' | 'outlined';
  placeholder?: string;
  inputParams?: TextFieldProps;
  labelKey?: string;
  idKey?: string;
  addNewLabel?: string;
  fullWidth?: boolean;
  createItem?: (event: any) => any;
  justifyCenter?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: 4
  },
  endAdornment: {
    display: 'none'
  },
  searchIcon: {
    color: '#687F92'
  },
  inputRoot: {
    paddingRight: '1px !important',
    paddingBottom: '8px !important'
  },
  locationText: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    textAlign: 'center'
  },
  button: {
    padding: 0,
    marginLeft: theme.spacing(1)
  }
}));

const LocationInput: FC<LocationInputProps> = props => {
  const {
    form: { setFieldValue },
    field: { name, value, onBlur = () => {} },
    variant,
    fullWidth,
    label,
    placeholder,
    justifyCenter = false
  } = props;
  const classes = useStyles();
  const [isEdit, setEdit] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const getOptionLabel = useCallback(option => {
    return option.text;
  }, []);

  const handleOptionSelection = useCallback(
    async (_event: any, newValue: any) => {
      if (newValue) {
        const response = await fetch(
          `https://us-street.api.smartystreets.com/street-address?key=${SmartyStreetsKey}&street=${newValue.street_line}&city=${newValue.city}&state=${newValue.state}&zipcode=${newValue.zipcode}`,
          { headers: { 'Content-Type': 'application/json' } }
        );
        const data = await response.json();

        let lat = null;
        let long = null;
        if (data[0]?.metadata) {
          const location = data[0]?.metadata;
          lat = location.latitude;
          long = location.longitude;
        }

        setFieldValue(name, {
          ...value,
          street: newValue.street_line,
          street2: newValue.secondary,
          city: newValue.city,
          state: newValue.state,
          stateCode: newValue.state,
          zipCode: newValue.zipcode,
          long,
          lat
        });
        if (isEdit) {
          setEdit(false);
        }
      }
    },
    [name, setFieldValue, value, isEdit]
  );

  const searchDebounce = debounce(setSearchText, 500);

  const handleInputChange = useCallback(
    event => {
      searchDebounce(event.target.value);
    },
    [searchDebounce]
  );

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://us-autocomplete-pro.api.smartystreets.com/lookup?search=${searchText}&key=${SmartyStreetsKey}&license=us-autocomplete-pro-cloud&selected=`,
      { headers: { 'Content-Type': 'application/json' } }
    )
      .then(async result => {
        const data = await result.json();
        let addressSuggestions = [];
        if (data.suggestions) {
          addressSuggestions = data.suggestions.map(
            ({ street_line, secondary, city, state, zipcode }) => ({
              text: `${street_line} ${secondary} ${city} ${state} ${zipcode}`,
              street_line,
              secondary,
              city,
              state,
              zipcode
            })
          );
        }
        setOptions(addressSuggestions);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [setLoading, searchText, setOptions]);

  const handleClick = () => {
    setFieldValue(name, {});
    setEdit(true);
  };

  return (
    <Box>
      <Typography className={classes.label}>{label}</Typography>
      {(isEdit || (!isEdit && isEmpty(value))) && (
        <Autocomplete
          classes={{
            endAdornment: classes.endAdornment
          }}
          fullWidth={fullWidth}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          noOptionsText={'No results found'}
          getOptionSelected={(option, value) => option.value === value.value}
          getOptionLabel={getOptionLabel}
          options={options}
          loading={loading}
          onChange={handleOptionSelection}
          renderInput={params => (
            <TextField
              {...params}
              variant={variant}
              placeholder={placeholder}
              onChange={handleInputChange}
              name="address"
              onBlur={onBlur}
              inputProps={{
                ...params.inputProps,
                form: {
                  autocomplete: 'off'
                }
              }}
              InputProps={{
                classes: {
                  root: classes.inputRoot
                },
                ...params.InputProps,
                startAdornment: <SearchIcon className={classes.searchIcon} />,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      )}
      {!isEdit && !isEmpty(value) && justifyCenter && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography className={classes.locationText}>
            {`${value.street} ${value.street2}, ${value.city}, ${value.stateCode} ${value.zipCode}`}
          </Typography>
          <Button
            color="secondary"
            className={classes.button}
            onClick={handleClick}
          >
            edit
          </Button>
        </Box>
      )}
      {!isEdit && !isEmpty(value) && !justifyCenter && (
        <Box display="flex" alignItems="center">
          <Typography className={classes.locationText}>
            {`${value.street} ${value.street2}, ${value.city}, ${value.stateCode} ${value.zipCode}`}
          </Typography>
          <Button
            color="secondary"
            className={classes.button}
            onClick={handleClick}
          >
            edit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default LocationInput;
