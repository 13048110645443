import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import CloseIcon from 'src/components/icons/CloseIcon';
import { Theme } from 'src/theme';
import Cropper from 'react-easy-crop';
import ThumbnailImg from 'src/components/ThumbnailImg';
import LoadingScreen from 'src/components/LoadingScreen';
import { getCroppedImg } from 'src/utils/cropImage';
import { AfterFileUploadEvent, useFileUploads } from 'src/utils/fileUploads';
import FileUploadDropZone from 'src/components/core/form/FileUploadDropZoneWithCrop';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      height: 600
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    root: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    previewImageContainer: {
      width: '100%',
      height: '100%'
    },
    previewImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      borderRadius: 4
    },
    editDropzone: {
      padding: 0
    },
    iconButton: {
      borderRadius: '50%',
      backgroundColor: '#EBF6F6',
      border: `1.25px solid ${theme.palette.primary.main}`,
      margin: theme.spacing(1),
      height: theme.spacing(4.5),
      width: theme.spacing(4.5)
    },
    icon: {
      color: theme.palette.primary.main
    },
    editIcon: {
      fontSize: '1.8rem'
    },
    dropzone: {
      padding: 0,
      height: '100%'
    },
    dropzoneContentArea: {
      height: '100%'
    },

    dropzoneContent: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(3, 2),
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 4,
      background: '#f3fafa'
    },
    leftVector: {
      marginRight: theme.spacing(2)
    },
    vectorIcon: {
      height: 40,
      width: 40,
      fill: '#9089FF'
    },
    fileUploadText: {
      textAlign: 'center'
    },
    fileUploadTextItem: {
      marginBottom: theme.spacing(1)
    },
    upload: {
      display: 'flex',
      alignItems: 'center'
    },
    uploadIcon: {
      height: theme.spacing(3),
      width: theme.spacing(3),
      color: '#114D71'
    },
    uploadText: {
      marginLeft: theme.spacing(1.5),
      color: '#114D71',
      fontWeight: 'bold'
    },
    rightVector: {
      transform: 'rotateY(180deg)',
      marginLeft: theme.spacing(2)
    },
    controls: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(2)
    },
    sliderContainer: {
      display: 'flex',
      // flex: '1',
      alignItems: 'center'
    },
    sliderLabel: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 65
      },
      width: 250,
      color: theme.palette.text.secondary
    },
    slider: {
      padding: '22px 0px',
      color: '#7094AA',
      marginLeft: 32,
      width: 200,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 16px'
      }
    },
    sliderThumb: {
      height: 16,
      width: 16,
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit'
      }
    },
    track: {
      height: 3
    },
    rail: {
      height: 3
    },
    saveButtonContainer: {
      marginLeft: 10,
      marginRight: 10,
      textTransform: 'none',
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        background: '#f16924',
        color: theme.palette.common.white
      }
    },
    discardButtonContainer: {
      marginLeft: 10,
      marginRight: 10,
      textTransform: 'none',
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`
    },
    fileUploadTextItemMain: {
      marginBottom: theme.spacing(1),
      fontSize: theme.spacing(4),
      fontWeight: 600,
      color: '#C3CCD3'
    },
    uploadButton: {
      borderRadius: theme.spacing(1),
      color: theme.palette.common.white,
      background: theme.palette.secondary.main,
      '&:hover': {
        background: theme.palette.secondary.dark
      }
    }
  })
);

const ImageResizeDialog = ({
  onClose,
  open,
  setImage,
  form: { setFieldValue },
  field: { name, value },
  setLoading
}) => {
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropped, setIsCropped] = useState(false);
  const [upImg, setUpImg] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleAfterFileUpload = useCallback(
    (event: AfterFileUploadEvent) => {
      setIsLoading(false);
      setFieldValue(name, event.path && event.path[0]);
      setImage(event.path && event.path[0]);
      setLoading(false);
    },
    [setFieldValue, name, setImage, setLoading]
  );

  const uploader = useFileUploads({
    afterFileUpload: handleAfterFileUpload,
    multiple: false
  });

  const blobToFile = (theBlob: any, fileName: string): File => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type
    });
  };

  const handleCroppedFileUpload = useCallback(async () => {
    const croppedImg = await getCroppedImg(
      upImg,
      croppedAreaPixels,
      uploadedFile
    );
    setIsLoading(true);
    setLoading(true);
    uploader.onUpload([blobToFile(croppedImg, uploadedFile.name)]);
    setIsCropped(true);
    onClose();
  }, [uploadedFile, uploader, croppedAreaPixels, onClose, upImg, setLoading]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
      fullWidth
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        Select Profile Photo
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.grid} container spacing={3}>
          <Grid item sm={12}>
            <Box height={360}>
              {upImg ? (
                <Box className={classes.root}>
                  <Box className={classes.previewImageContainer}>
                    {isCropped ? (
                      <ThumbnailImg
                        className={classes.previewImage}
                        url={''}
                        alt="Preview"
                      />
                    ) : (
                      <Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                        >
                          <Box>
                            <Cropper
                              cropShape="round"
                              image={upImg}
                              crop={crop}
                              zoom={zoom}
                              aspect={1}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {!isCropped ? (
                      <>
                        <Box py={4} display="flex" justifyContent="center">
                          <Box display="flex" width="50%">
                            <Typography classes={{ root: classes.sliderLabel }}>
                              Zoom in and out and move your image to choose the
                              right frame.
                            </Typography>
                          </Box>
                          <Box className={classes.sliderContainer}>
                            <img
                              src="https://cdn.onevillage.co/global/images/static/icons/zoom-small.svg"
                              alt="zoom-small"
                            />
                            <Slider
                              value={zoom}
                              min={0.1}
                              max={2}
                              step={0.1}
                              aria-labelledby="Zoom"
                              classes={{
                                root: classes.slider,
                                thumb: classes.sliderThumb,
                                track: classes.track,
                                rail: classes.rail
                              }}
                              onChange={(e, zoom: number) => setZoom(zoom)}
                            />
                            <img
                              src="https://cdn.onevillage.co/global/images/static/icons/zoom-big.svg"
                              alt="zoom-big"
                            />
                          </Box>
                        </Box>
                        <Box
                          py={4}
                          borderTop="1px solid rgba(104, 127, 146, 0.2)"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Button
                            className={classes.discardButtonContainer}
                            variant="outlined"
                            onClick={() => onClose()}
                          >
                            Cancel
                          </Button>
                          <Button
                            className={classes.saveButtonContainer}
                            variant="contained"
                            onClick={handleCroppedFileUpload}
                            disabled={isLoading}
                          >
                            Upload
                          </Button>
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </Box>
              ) : (
                <FileUploadDropZone
                  multiple={uploader.multiple}
                  className={classes.dropzone}
                  contentClassName={classes.dropzoneContentArea}
                  setImage={setUpImg}
                  setFile={setUploadedFile}
                >
                  <div className={classes.dropzoneContent}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      className={classes.fileUploadText}
                    >
                      <Box>
                        <Typography
                          color="textSecondary"
                          className={classes.fileUploadTextItemMain}
                        >
                          {/* {label} */}
                        </Typography>

                        <Box marginTop={3} marginBottom={2}>
                          <Button className={classes.uploadButton}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              px={2}
                            >
                              <Typography>Upload photo</Typography>
                              <Box display="flex" justifyContent="center">
                                <CloudUploadOutlinedIcon />
                              </Box>
                            </Box>
                          </Button>
                        </Box>

                        <Box className={classes.upload}>
                          <Typography className={classes.uploadText}>
                            Or drag them in here
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </FileUploadDropZone>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ImageResizeDialog;
