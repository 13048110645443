import React from 'react';
import type { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';

interface SetupAccountLayoutProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
}));


const SetupAccountLayout: FC<SetupAccountLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};


export default SetupAccountLayout;
