import { createSlice } from '@reduxjs/toolkit';
import { NewCalendarEventService } from 'shared';
import { AppThunk } from 'src/store';
import { TourInfoMapper } from 'src/utils/TourMapper';

export interface TourState {
  tourMeetingDetails: any;
}

const initialState: TourState = { tourMeetingDetails: null };

const slice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setTourMeetingDetails(state: TourState, action: any) {
      state.tourMeetingDetails = action.payload;
    }
  }
});

export const reducer = slice.reducer;

const newCalendarEventService = NewCalendarEventService.getInstance<
  NewCalendarEventService
>();

export const setTourMeetingDetails = (data): AppThunk => dispatch => {
  const tourInfo = {
    ...TourInfoMapper({
      ...data,
      bufferAfterMeeting: data.bufferAfterMeeting
        ? String(data.bufferAfterMeeting)
        : null
    })
  };
  dispatch(slice.actions.setTourMeetingDetails(tourInfo));
};

export const getTourSettings = (
  startDate?: string,
  endDate?: string
): AppThunk => dispatch => {
  return newCalendarEventService
    .getEventByType('Tour', startDate, endDate)
    .then(({ data }) => {
      const tourInfo = {
        ...TourInfoMapper({
          ...data,
          bufferAfterMeeting: data.bufferAfterMeeting
            ? String(data.bufferAfterMeeting)
            : null
        })
      };
      dispatch(slice.actions.setTourMeetingDetails(tourInfo));
      return tourInfo;
    });
};

export default slice;
