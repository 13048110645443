import { SubscriptionEditionsEnum } from "shared";

export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  OV: 'OV'
};

export const SUBSCRIPTION_MATRIX = [
  {
    edition: SubscriptionEditionsEnum.STANDARD,
    virtualStory: {
      totalCount: 2,
      publishedCount: 1,
      duration: 20 //sec
    },
    slideShow: {
      totalCount: 2,
      publishedCount: 1,
      photoCount: 3
    }
  },
  {
    edition: SubscriptionEditionsEnum.ESSENTIAL,
    virtualStory: {
      totalCount: 5,
      publishedCount: 3,
      duration: 40 //sec
    },
    slideShow: {
      totalCount: 5,
      publishedCount: 3,
      photoCount: 5
    }
  },
  {
    edition: SubscriptionEditionsEnum.PREMIUM,
    virtualStory: {
      totalCount: 10,
      publishedCount: 5,
      duration: 60 //sec
    },
    slideShow: {
      totalCount: 10,
      publishedCount: 5,
      photoCount: 10
    }
  }
]
