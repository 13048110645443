import { findIndex, pick } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { MediaFileService } from 'shared';
import { User } from 'shared';
import uploadFileToBlob from 'src/uploadToBlob';
import { useSubscriptionLimitsExceeded } from 'src/components/SubscriptionLimitsExceeded';

export interface AfterFileUploadEvent {
  path: string[];
  files: FileUploadEvent[];
}

export interface BeforeFileUploadEvent {
  files: File[];
}

export interface FileUploadErrorEvent {
  file: File;
  error: any;
}

export interface FileUploadEvent {
  file: File;
  path?: string;
  thumbnail?: string;
  duration?: number;
  isVideo?: boolean;
}

export interface FileUploadProgressInfo {
  id: string;
  name: string;
  value: number;
  size: number;
  isEncoding: boolean;
  errorMessage: string;
}

export interface UseFileUploads {
  multiple?: boolean;
  isUploading?: boolean;
  progressInfo?: FileUploadProgressInfo[];
  onUpload?: (files: File[]) => any;
  getProgressValue?: (id: string) => number;
  isInProgress?: (id: string) => boolean;
  isEncoding?: (id: string) => boolean;
  getErrorMessage?: (id: string) => string;
}

export interface UseFileUploadsOptions {
  files?: File[];
  multiple?: boolean;
  onFileUpload?: (event: FileUploadEvent) => void;
  onFileUploadError?: (event: FileUploadErrorEvent) => void;
  afterFileUpload?: (event: AfterFileUploadEvent) => void;
  beforeFileUpload?: (event: BeforeFileUploadEvent) => void;
  containerName?: string;
  user?: User;
  limitDuration?: number; // in seconds
  canUpgrade?: boolean;
  maxImageSize?: number; // in bytes
}

const mediaFileService = MediaFileService.getInstance<MediaFileService>();

export function toUseFileUploadProps(props: any) {
  return pick(props, [
    'onFileUpload',
    'onFileUploadError',
    'afterFileUpload',
    'beforeFileUpload',
    'containerName',
    'user'
  ]);
}

export function useFileUploads({
  onFileUpload,
  onFileUploadError,
  afterFileUpload,
  beforeFileUpload,
  containerName,
  user,
  multiple,
  limitDuration,
  canUpgrade,
  maxImageSize = 30000 //KB
}: UseFileUploadsOptions): UseFileUploads {
  const [isUploading, setUploading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { providerId } = useAuth();
  const [progressInfo, setProgressInfo] = useState<FileUploadProgressInfo[]>(
    []
  );
  const subscriptionLimitsExceeded = useSubscriptionLimitsExceeded();

  // if (!user) {
  //   user = auth.user;
  // }

  const findProgressFile = id => {
    return progressInfo.find(i => i.id === id);
  };

  const getProgressValue = id => {
    const progress = findProgressFile(id);
    return progress ? progress.value : 0;
  };

  const isInProgress = id => {
    return !!findProgressFile(id);
  };

  const isEncoding = id => {
    const current = findProgressFile(id);
    return current && current.isEncoding;
  };

  const getErrorMessage = id => {
    const current = findProgressFile(id);
    return current && current.errorMessage;
  };

  const encodeMedia = async mediaUrl => {
    const response = await mediaFileService.encodeMedia({ mediaUrl });
    return response.data;
  };

  const onUpload = useCallback(
    async files => {
      if (files.length === 0) {
        return;
      }
      const allPaths = [];
      const allFiles = [];

      setUploading(true);
      beforeFileUpload && (await beforeFileUpload({ files }));

      for (const file of files) {
        let fileProgressInfo: FileUploadProgressInfo = {
          id: file.id,
          name: file.name,
          value: 0,
          size: file.size,
          isEncoding: false,
          errorMessage: null
        };

        const onProgress = ev => {
          fileProgressInfo.value =
            (ev.loadedBytes / fileProgressInfo.size) * 100;

          setProgressInfo(items => {
            const index = findIndex(items, { id: fileProgressInfo.id });
            if (index === -1) {
              items.push(fileProgressInfo);
            } else {
              items[index] = fileProgressInfo;
            }
            return [...items];
          });
        };

        const fileType = file.type;
        const fileName = file.name;

        if (!!maxImageSize && fileType.startsWith('image')) {
          const fileSizeKB = Math.floor(file.size / 1024);
          if (fileSizeKB > maxImageSize) {
            fileProgressInfo.errorMessage = `${file.name} is bigger than allowed ${maxImageSize}KB.`;
          }
        }

        if (
          !!limitDuration &&
          (fileType.startsWith('video') || fileType.startsWith('audio'))
        ) {
          const fileDuration: any = await new Promise((resolve, reject) => {
            var video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function() {
              window.URL.revokeObjectURL(video.src);
              resolve(video.duration);
            };
            video.onerror = reject;

            video.src = URL.createObjectURL(file);
          });

          if (fileDuration > limitDuration) {
            await subscriptionLimitsExceeded({
              subTitle: `File duration ${fileDuration} seconds is more then allowed ${limitDuration} seconds.`,
              canUpgrade
            });

            fileProgressInfo.errorMessage = `${file.name} duration is longer than allowed ${limitDuration} sec.`;
          }
        }

        if (!!fileProgressInfo.errorMessage) {
          setProgressInfo(items => {
            items.push(fileProgressInfo);
            return [...items];
          });

          enqueueSnackbar(fileProgressInfo.errorMessage, {
            variant: 'error'
          });

          continue;
        }
        try {
          const fileContainerName =
            containerName || fileType.substring(0, fileType.indexOf('/'));
          const userId = providerId;
          const sasToken = await mediaFileService.getSasToken();
          let path = await uploadFileToBlob(
            file,
            fileContainerName,
            userId,
            sasToken.data,
            onProgress
          );

          let thumbnail;
          let duration;
          let isVideo = false;

          if (fileContainerName === 'video') {
            isVideo = true;
            setProgressInfo(progressInfo => {
              const index = findIndex(progressInfo, {
                id: fileProgressInfo.id
              });
              if (progressInfo[index]) {
                progressInfo[index].isEncoding = true;
              }
              return [...progressInfo];
            });

            const res = await encodeMedia(path);

            thumbnail = res.thumbnail;
            path = res.url;
            duration = res.duration;

            setProgressInfo(progressInfo => {
              const index = findIndex(progressInfo, {
                id: fileProgressInfo.id
              });
              if (progressInfo[index]) {
                progressInfo[index].isEncoding = false;
              }
              return [...progressInfo];
            });
          }

          const fileData = {
            file,
            path,
            thumbnail,
            duration,
            isVideo
          };

          onFileUpload && (await onFileUpload(fileData));
          allPaths.push(path);
          allFiles.push(fileData);

          // enqueueSnackbar(`${fileName} was uploaded`, {
          //   variant: 'success'
          // });
        } catch (error) {
          console.log(error);
          onFileUploadError &&
            (await onFileUploadError({
              file,
              error
            }));
          enqueueSnackbar(`Something went wrong with ${fileName}`, {
            variant: 'error'
          });
        }
      }

      afterFileUpload &&
        (await afterFileUpload({
          path: allPaths,
          files: allFiles
        }));
      setUploading(false);
    },
    [
      providerId,
      afterFileUpload,
      beforeFileUpload,
      setUploading,
      containerName,
      limitDuration,
      maxImageSize,
      canUpgrade,
      onFileUpload,
      onFileUploadError,
      enqueueSnackbar,
      subscriptionLimitsExceeded
    ]
  );

  return {
    multiple,
    progressInfo,
    onUpload,
    isUploading,
    getProgressValue,
    isInProgress,
    isEncoding,
    getErrorMessage
  };
}
