import { Box, makeStyles, Snackbar } from '@material-ui/core';
import React, { createContext, useCallback, useEffect } from 'react';
import { Theme } from 'src/theme';
// import CheckIcon from '@material-ui/icons/Check';
// import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { Alert } from '@material-ui/lab';

interface IToastContext {
  addToast: (content: string, type?: string) => void;
  removeToast: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  containerRoot: {
    zIndex: 999
  }
}));

const Ctx = createContext<IToastContext | null>(null);

const ToastContainer = props => {
  const classes = useStyles();
  return <Box className={classes.containerRoot} {...props} />;
};

const Toast = ({ children, onDismiss, type }) => {
  return (
    <Snackbar
      open
      autoHideDuration={6000}
      onClose={onDismiss}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={onDismiss} severity={type}>
        {children}
      </Alert>
    </Snackbar>
  );
};

let toastCount: number = 0;

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const addToast = useCallback(
    (content: string, type: string = 'success') => {
      const id = toastCount++;
      const toast = { content, type, id };
      setToasts([...toasts, toast]);
    },
    [toasts]
  );
  const removeToast = useCallback(
    (id: number) => {
      const newToasts = toasts.filter(t => t.id !== id);
      setToasts(newToasts);
    },
    [toasts]
  );
  // avoid creating a new fn on every render
  const onDismiss = (id: number) => () => removeToast(id);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toasts.length) {
        removeToast(toasts[0].id);
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [toasts, removeToast]);

  return (
    <Ctx.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer>
        {toasts.map(({ content, id, type, ...rest }) => (
          <Toast
            key={id}
            Toast={Toast}
            type={type}
            onDismiss={onDismiss(id)}
            {...rest}
          >
            {content}
          </Toast>
        ))}
      </ToastContainer>
    </Ctx.Provider>
  );
}

export const useToasts = () => React.useContext(Ctx);
