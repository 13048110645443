import { createSlice } from '@reduxjs/toolkit';
import {
  EnrollmentClassService,
  IAgeClassRequest,
  IEnrollmentAgeClass
} from 'shared';
import { AppThunk } from 'src/store';

export interface EnrollmentClassState {
  ageClassList: IEnrollmentAgeClass[];
}

const initialState: EnrollmentClassState = { ageClassList: null };

const slice = createSlice({
  name: 'enrollment-class',
  initialState,
  reducers: {
    setAgeClassList(state: EnrollmentClassState, action: any) {
      state.ageClassList = action.payload;
    },
    updateAgeClassList(state: EnrollmentClassState, action: any) {
      state.ageClassList = state.ageClassList.map(ageClass =>
        ageClass.id === action.payload.id ? action.payload : ageClass
      );
    }
  }
});

export const reducer = slice.reducer;

const enrollmentClassService = EnrollmentClassService.getInstance<
  EnrollmentClassService
>();

export const getAgeClassList = (): AppThunk => dispatch => {
  return enrollmentClassService.getAgeClassList().then(({ data }) => {
    dispatch(slice.actions.setAgeClassList(data));
  });
};

export const updateAgeClassList = (
  request: IAgeClassRequest,
  id: string
): AppThunk => dispatch => {
  let message = '';
  return enrollmentClassService
    .updateAgeClass(request, id)
    .then(({ status, data }) => {
      dispatch(slice.actions.updateAgeClassList(data));
      if (status === 200) {
        message = 'Age Class updated successfully';
      } else {
        message =
          'An error occured while updating the Age Class. Please try again later';
      }
      return { message, status };
    })
    .catch(response => {
      message =
        'An error occured while updating the Age Class. Please try again later';
      return { message, status: 403 };
    });
};

export default slice;
