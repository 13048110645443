import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '16px',
    minHeight: 120,
    borderRadius: theme.spacing(1),
    backgroundColor: '#FFF',
    boxShadow: '0px 1px 2px 0px rgba(145, 158, 171, 0.16)',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '8px',
    alignItems: 'flex-start',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      boxShadow:
        '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)'
    }
  },
  rootGreyed: {
    padding: '16px',
    background: '#dcdcdc',
    minHeight: 120,
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gridGap: '8px',
    alignItems: 'flex-start',
    overflow: 'hidden'
  },
  cardTitle: {
    color: '#161C24',
    fontWeight: 500,
    fontSize: 16,
    width: '100%'
  },
  cardTitleGreyed: {
    color: '#8a8d8f',
    fontWeight: 500,
    fontSize: 16,
    width: '100%'
  },
  cardDescription: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#637381'
  },
  cardDescriptionGreyed: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#7b7d7e'
  }
}));

const LeadCard = ({ title, description, handleClick, automationStatus }) => {
  const classes = useStyles();

  return (
    <>
      {title === 'Early Interest Follow Up' ||
      title === 'Considering follow up' ? (
        <>
          {automationStatus ? (
            <Box className={classes.root} onClick={handleClick}>
              <Typography className={classes.cardTitle}>{title}</Typography>
              <Box height="100%">
                <Typography className={classes.cardDescription}>
                  {description}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box className={classes.rootGreyed}>
              <Typography className={classes.cardTitle}>{title}</Typography>
              <Box height="100%">
                <Typography className={classes.cardDescription}>
                  {description}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box className={classes.root} onClick={handleClick}>
          <Typography className={classes.cardTitle}>{title}</Typography>
          <Box height="100%">
            <Typography className={classes.cardDescription}>
              {description}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LeadCard;
