import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonGroup, Grid } from '@material-ui/core';
import { Theme } from 'src/theme';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

export interface EmailPreviewProps {
  emailTemplateView?: any;
  templateView?: any;
  emailTemplateFooterView?: any;
  greetingContent?: any;
  isLoading?: boolean;
  setTemplateView?: any;
  additionalContent?: any;
  renderedContent?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  emailView: {
    height: 'calc(100vh - 64px)',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  mobileGrid: {
    display: 'flex',
    justifyContent: 'center'
  },
  desktopWrapper: {
    width: '90%'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  smartphone: {
    transition: 'width 1s',
    position: 'relative',
    width: 360,
    height: 812,
    margin: 'auto',
    border: '16px #6c737a solid',
    borderTopWidth: 50,
    borderBottomWidth: 60,
    borderRadius: 36,
    '& .add-content': {
      cursor: 'pointer !important'
    },
    '& .template-value-chip': {
      maxWidth: '100%',
      fontFamily: '"Roboto", sans-serif',
      fontSize: '0.8125rem',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 32,
      color: '#9365bb',
      backgroundColor: '#e9dff1',
      borderRadius: 7,
      whiteSpace: 'nowrap',
      transition:
        'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,width 1s',
      cursor: 'default',
      outline: 0,
      textDecoration: 'none',
      border: 0,
      padding: 0,
      verticalAlign: 'middle',
      boxSizing: 'border-box',
      '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: 12,
        paddingRight: 12,
        whiteSpace: 'nowrap'
      }
    },
    '& #provider-greeting': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    '& #provider-closing': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    '&:before': {
      content: '""',
      display: 'block',
      width: 60,
      height: 5,
      position: 'absolute',
      top: -30,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#ccc',
      borderRadius: 10
    },
    '& .content': {
      // width: 328,
      height: 702,
      // background: theme.palette.common.white,
      overflowY: 'auto',
      overflowX: 'hidden',
      '& .email-wrapper .email-content': {
        padding: ' 32px 8px !important',
        '& .email-body': {
          padding: '1px !important'
        }
        // maxWidth: '328px',
        // width: '328px'
      },
      '& .text-message-container': {
        transition: 'width 1s',
        alignItems: 'center',
        height: 'inherit',
        // width: '328px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        '& .email-content>p, .mid-content': {
          padding: '0 20px',
          // borderRight: '1px solid #4c8aa7',
          // borderLeft: '1px solid #4c8aa7',
          background: theme.palette.common.white
        },
        '& .es-wrapper, .es-header table, .es-footer table, .es-content, .es-footer, .es-header, .es-header table tr td, .es-footer table tr td, *[contentEditable="true"], .email-content>table': {
          transition: 'width 1s',
          // maxWidth: '328px',
          width: '100%',
          padding: '4px !important',
          '& .email-wrapper .email-content': {
            padding: '1px !important',
            '& .email-body': {
              padding: ' 16px 4px !important'
            }
            // maxWidth: '328px',
            // width: '328px'
          }
        },
        '& .new-provider-lead-content': {
          padding: '0 20px'
        },
        '& .m-content': {
          padding: 0
        },
        '& .mid-content>p': {
          border: 'unset'
        },
        '& .editor-content': {
          transition: 'width 1s',
          padding: '5px 20px',
          // maxWidth: '328px',
          width: '100%',
          // borderLeft: '1px solid #4c8aa7',
          // borderRight: '1px solid #4c8aa7',
          color: '#666666'
        },
        '& *[contentEditable="true"]': {
          color: '#666666'
        },
        '& *[contentEditable="true"]:focus, *[contentEditable="true"]:hover': {
          outline: `1px solid ${theme.palette.primary.main}`
        },
        '& .es-content table, .es-content table tr td': {
          transition: 'width 1s',
          // maxWidth: '328px',
          paddingRight: '7px !important',
          paddingLeft: '7px !important',
          '& p': {
            display: 'contents'
          }
        },
        '& ul': {
          paddingLeft: 25
        }
      }
    }
  },
  desktopView: {
    transition: 'width 1s',
    position: 'relative',
    width: 700,
    height: 812,
    margin: 'auto',
    border: '16px #6c737a solid',
    // background: '#fff',
    borderTopWidth: 24,
    borderBottomWidth: 24,
    borderRadius: 36,
    '& .add-content': {
      cursor: 'pointer !important'
    },
    '& .template-value-chip': {
      maxWidth: '100%',
      fontFamily: '"Roboto", sans-serif',
      fontSize: '0.8125rem',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 32,
      color: '#9365bb',
      backgroundColor: '#e9dff1',
      borderRadius: 7,
      whiteSpace: 'nowrap',
      transition:
        'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      cursor: 'default',
      outline: 0,
      textDecoration: 'none',
      border: 0,
      padding: 0,
      verticalAlign: 'middle',
      boxSizing: 'border-box',
      '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: 12,
        paddingRight: 12,
        whiteSpace: 'nowrap'
      }
    },
    '& #provider-greeting': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    '& #provider-closing': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    '&:before': {
      content: '""',
      display: 'block',
      width: 60,
      height: 5,
      position: 'absolute',
      top: -30,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#ccc',
      borderRadius: 10
    },
    '& .content': {
      width: '100%',
      height: 702,
      // background: theme.palette.common.white,
      display: 'flex',
      // alignItems: 'center',
      '& .text-message-container': {
        width: 'inherit',
        height: 'inherit',
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        overflowX: 'hidden',
        overflowY: 'auto',
        '& .email-content, .es-wrapper, .es-header table, .es-footer table, .es-content, .es-footer, .es-header, .es-header table tr td, .es-footer table tr td, & *[contentEditable="true"], *[contentEditable="true"]': {
          transition: 'width 1s',
          width: '100%',
          background: '#fff',
          // maxWidth: '570px',
          '& .email-wrapper': {
            background: '#fff'
          },
          '& .email-wrapper .email-content': {
            padding: '1px !important',
            '& .email-body': {
              padding: ' 16px 4px !important'
            }
            // maxWidth: '328px',
            // width: '328px'
          },
          '& ul': {
            paddingLeft: 25
          }
        },
        '& *[contentEditable="true"]': {
          color: '#666666'
        },
        '& .email-content>p, .mid-content, .email-content>table': {
          transition: 'width 1s',
          padding: '0 20px',
          // borderRight: '1px solid #4c8aa7',
          // borderLeft: '1px solid #4c8aa7',
          background: theme.palette.common.white
        },
        '& .mid-content>p, .mid-content>table': {
          border: 'unset'
        },
        '& .editor-content': {
          padding: '15px 20px',
          width: '100%',
          // maxWidth: '570px',
          // borderLeft: '1px solid #4c8aa7',
          // borderRight: '1px solid #4c8aa7',
          background: theme.palette.common.white,
          color: '#666666'
        },
        '& .email-content table tr td:not(:first-child)': {
          width: '100%'
        },
        '& .footer> table': {
          background: 'unset',
          border: 'unset'
        },
        '& *[contentEditable="true"]:focus, *[contentEditable="true"]:hover': {
          outline: `1px solid ${theme.palette.primary.main}`
        },
        '& .es-content table, .es-content table tr td': {
          // maxWidth: '570px',
          paddingLeft: '7px !important',
          '& ul': {
            paddingLeft: 25
          },
          '& p': {
            display: 'contents'
          }
        }
      }
    }
  },
  leftToggle: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    fontSize: 14,
    fontWeight: 700
  },
  rightToggle: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    fontSize: 14,
    fontWeight: 700
  },
  leftToggleSelected: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    fontSize: 14,
    fontWeight: 700,
    color: '#fff',
    background: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  rightToggleSelected: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    fontSize: 14,
    fontWeight: 700,
    color: '#fff',
    background: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

const EmailPreview: FC<EmailPreviewProps> = ({
  emailTemplateView,
  templateView,
  emailTemplateFooterView,
  greetingContent,
  isLoading,
  setTemplateView,
  additionalContent,
  renderedContent
}) => {
  const classes = useStyles();

  const handleTemplateView = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment) {
      setTemplateView(newAlignment);
    }
  };

  return (
    <Box p={2} className={classes.emailView} borderRadius="12px">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
      >
        <Grid item xs={12} className={classes.mobileGrid}>
          <Box
            className={clsx({
              [classes.desktopWrapper]: templateView === 'desktop'
            })}
          >
            <Box display="flex" justifyContent="center" marginBottom={5}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  onClick={e => handleTemplateView(e, 'desktop')}
                  className={
                    templateView === 'desktop'
                      ? classes.leftToggleSelected
                      : classes.leftToggle
                  }
                >
                  Desktop
                </Button>
                <Button
                  onClick={e => handleTemplateView(e, 'mobile')}
                  className={
                    templateView === 'mobile'
                      ? classes.rightToggleSelected
                      : classes.rightToggle
                  }
                >
                  Mobile
                </Button>
              </ButtonGroup>
            </Box>
            {isLoading && (
              <Box className={classes.content}>
                <Skeleton variant="rect" width={600} height={600} />
              </Box>
            )}
            {!isLoading && (
              <div
                className={clsx({
                  [classes.smartphone]: templateView === 'mobile',
                  [classes.desktopView]: templateView === 'desktop'
                })}
              >
                <div className="content">
                  <Box
                    width="100%"
                    dangerouslySetInnerHTML={{ __html: renderedContent }}
                  ></Box>
                </div>
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default EmailPreview;

export const getTrimmedContent = (content, id, returnContent = false) => {
  const templateContent = content
    .replace('<p>', '')
    .replace('</p>', '')
    .replaceAll('\n', '')
    .trim();
  const trimmedContent = new DOMParser()
    .parseFromString(templateContent, 'text/html')
    ?.getElementById(id)
    ?.innerHTML.trim();

  if (returnContent && trimmedContent === undefined) {
    return templateContent;
  }
  return trimmedContent;
};

export const getTrimmedText = content => {
  const templateContent = content
    .replace('<p>', '')
    .replace('</p>', '')
    .replaceAll('\n', '')
    .trim();

  return templateContent;
};

export const getTrimmedLabel = content => {
  var snippet = document.createElement('div');
  snippet.innerHTML = content;
  var link = snippet.getElementsByTagName('a')[0];
  return link?.innerHTML
    ? link?.innerHTML
    : content
        .replace('<p>', '')
        .replace('</p>', '')
        .replaceAll('\n', '')
        .trim();
};
