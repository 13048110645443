import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import SetupAccountLayout from './layouts/SetupAccountLayout';
import PortalLayout from './layouts/PortalLayout';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    // callback for OIDC login
    exact: true,
    path: '/signin-oidc',
    component: lazy(() => import('src/views/auth/SigninOidc'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/registration',
    component: lazy(() => import('src/views/registration'))
  },
  {
    exact: true,
    path: '/portal-login',
    component: lazy(() => import('src/views/login'))
  },
  {
    exact: true,
    path: '/verification',
    component: lazy(() => import('src/views/verification'))
  },
  {
    exact: true,
    path: '/password',
    component: lazy(() => import('src/views/password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/setup-account',
    guard: AuthGuard,
    layout: SetupAccountLayout,
    routes: [
      {
        exact: true,
        path: '/setup-account',
        component: () => <Redirect to="/setup-account/welcome" />
      },
      {
        exact: true,
        path: '/setup-account/welcome',
        component: lazy(() => import('src/pages/setup-account/WelcomeView'))
      },
      {
        exact: true,
        path: '/setup-account/basic-info',
        component: lazy(() => import('src/pages/setup-account/BasicInfoView'))
      },

      {
        exact: true,
        path: '/setup-account/tours',
        component: lazy(() => import('src/pages/setup-account/ToursView'))
      },
      {
        exact: true,
        path: '/setup-account/programs',
        component: lazy(() => import('src/pages/setup-account/ProgramsView'))
      },
      {
        exact: true,
        path: '/setup-account/micro-site',
        component: lazy(() => import('src/pages/setup-account/MicroSiteView'))
      },
      {
        exact: true,
        path: '/setup-account/microsite-setup',
        component: lazy(() =>
          import('src/pages/setup-account/MicrositeSetupView')
        )
      },
      {
        exact: true,
        path: '/setup-account/success',
        component: lazy(() =>
          import('src/pages/setup-account/FinalOnboardingView')
        )
      }
    ]
  },
  {
    path: '/onboarding',
    guard: AuthGuard,
    layout: SetupAccountLayout,
    routes: [
      {
        exact: true,
        path: '/onboarding',
        component: lazy(() => import('src/pages/onboarding'))
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: PortalLayout,
    routes: [
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/pages/dashboard'))
      },

      {
        exact: true,
        path: '/app/school/virtual-tours',
        component: lazy(() => import('src/pages/block/VirtualToursView'))
      },
      {
        exact: true,
        path: '/app/school/my-center',
        component: () => <Redirect to="/school/my-center/basic" />
      },
      {
        exact: true,
        path: '/app/school/my-center/basic',
        component: lazy(() => import('src/pages/account/my-center/BasicView'))
      },
      {
        exact: true,
        path: '/app/school/my-center/contact',
        component: lazy(() => import('src/pages/account/my-center/ContactView'))
      },
      {
        exact: true,
        path: '/app/school/my-center/programs',
        component: lazy(() =>
          import('src/pages/account/my-center/ProgramsView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/schedule',
        component: lazy(() =>
          import('src/pages/account/my-center/ScheduleView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/state',
        component: lazy(() => import('src/pages/account/my-center/StateView'))
      },
      {
        exact: true,
        path: '/app/school/my-center/age-group',
        component: lazy(() =>
          import('src/pages/account/my-center/AgeGroupView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/tour-settings',
        component: lazy(() => import('src/pages/tours/TourSettingView'))
      },
      {
        exact: true,
        path: '/app/school/my-center/templates',
        component: lazy(() =>
          import('src/pages/account/my-center/TemplatesView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/event-templates',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/EventTempleteView/EmailTemplateListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/event-layout',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/EventLayoutView/EmailLayoutListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/my-templates',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/MyTemplateView/MyTemplateListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-schedule',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentScheduleView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-sessions',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentSessionView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-classes',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentClassesView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-class-options',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentClassOptionsView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-room',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentRoomView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/enrollment-price',
        component: lazy(() =>
          import('src/pages/account/my-center/EnrollmentPriceView')
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/event-templates/edit/:id',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/EventTempleteView/EditEmailTemplateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/event-layout/edit/:id',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/EventLayoutView/EditEmailLayoutView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/template/create',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/MyTemplateView/MyTemplateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/template/edit/:id',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/MyTemplateView/MyTemplateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/event-templates/view/:id',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/EventTempleteView/ViewEmailTemplateView'
          )
        )
      },
      {
        exact: true,
        path: '/app/school/my-center/event-layout/view/:id',
        component: lazy(() =>
          import(
            'src/pages/account/my-center/TemplatesView/EventLayoutView/ViewEmailLayoutView'
          )
        )
      },
      {
        exact: true,
        path: '/app/terms',
        component: lazy(() => import('src/pages/terms'))
      },
      {
        exact: true,
        path: '/app/enroll/programs',
        component: lazy(() =>
          import('src/pages/enroll/programs/ProgramsListView')
        )
      },
      {
        exact: true,
        path: '/app/enroll/programs/view/:id',
        component: lazy(() =>
          import('src/pages/enroll/programs/ProgramsDetailView')
        )
      },
      {
        exact: true,
        path: '/app/families/view/:id',
        component: lazy(() => import('src/pages/families/FamilyDetailsView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/virtual-tours',
        component: lazy(() => import('src/pages/block/VirtualToursView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site-setting',
        component: lazy(() => import('src/pages/my-site-setting'))
      },
      {
        exact: true,
        path: '/app/sites/my-site',
        component: lazy(() => import('src/pages/my-site/MySiteView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/configure',
        component: lazy(() => import('src/pages/my-site/ConfigureView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/new-block',
        component: lazy(() => import('src/pages/my-site/NewBlockView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/my-block',
        component: lazy(() => import('src/pages/my-site/BlocksView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/programs',
        component: lazy(() => import('src/pages/my-site/ProgramsView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/day-in-life',
        component: lazy(() => import('src/pages/my-site/DayInLifeView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/covid-19',
        component: lazy(() => import('src/pages/my-site/CovidView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/welcome-letter',
        component: lazy(() => import('src/pages/my-site/AboutUsView'))
      },
      {
        exact: true,
        path: '/app/sites/my-site/day-in-life/timeline',
        component: lazy(() =>
          import('src/pages/my-site/DayInLifeView/TimelineView')
        )
      },
      {
        exact: true,
        path: '/app/sites/my-site/slideshows',
        component: lazy(() =>
          import('src/pages/my-site/slideshows/SlideshowsView')
        )
      },
      {
        exact: true,
        path: '/app/inbox',
        component: lazy(() => import('src/pages/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/inbox/:threadKey',
        component: lazy(() => import('src/pages/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/inbox/chatbot/settings',
        component: lazy(() => import('src/pages/chat/ChatBotSettingView'))
      },
      {
        exact: true,
        path: '/app/inbox/chatbot/questions',
        component: lazy(() => import('src/pages/chat/ChatBotQuestionView'))
      },
      {
        exact: true,
        path: '/app/features',
        component: () => <Redirect to="/app/features/chat" />
      },
      {
        exact: true,
        path: '/app/features/chat',
        component: lazy(() => import('src/pages/features/ChatView'))
      },
      {
        exact: true,
        path: '/app/features/meetings',
        component: lazy(() => import('src/pages/features/MeetingsView'))
      },
      {
        exact: true,
        path: '/app/features/register',
        component: lazy(() => import('src/pages/features/RegisterView'))
      },
      {
        exact: true,
        path: '/app/library',
        component: lazy(() => import('src/pages/library/LibraryView'))
      },
      {
        exact: true,
        path: '/app/library/folder/:id',
        component: lazy(() => import('src/pages/library/LibraryView'))
      },
      {
        exact: true,
        path: '/app/content',
        component: () => <Redirect to="/app/stories/my-stories" />
      },
      {
        exact: true,
        path: '/app/content/slides',
        component: lazy(() => import('src/pages/content/slides/SlideView'))
      },
      {
        exact: true,
        path: '/app/content/slides/create',
        component: lazy(() => import('src/pages/content/slides/NewSlideView'))
      },
      {
        exact: true,
        path: '/app/content/slides/edit/:id',
        component: lazy(() => import('src/pages/content/slides/EditSlideView'))
      },
      {
        exact: true,
        path: '/app/content/learn',
        component: lazy(() => import('src/pages/content/LearnView'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/profile',
        component: () => <Redirect to="/app/profile/general" />
      },
      {
        exact: true,
        path: '/app/profile/general',
        component: lazy(() => import('src/pages/account/profile/GeneralView'))
      },
      {
        exact: true,
        path: '/app/profile/subscription',
        component: lazy(() =>
          import('src/pages/account/profile/SubscriptionView')
        )
      },
      {
        exact: true,
        path: '/app/profile/notification',
        component: lazy(() =>
          import('src/pages/account/profile/NotificationView')
        )
      },
      {
        exact: true,
        path: '/app/profile/security',
        component: lazy(() => import('src/pages/account/profile/SecurityView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/programs',
        component: lazy(() => import('src/pages/programs/ProgramsView'))
      },
      {
        exact: true,
        path: '/app/programs/classes',
        component: lazy(() => import('src/pages/programs/ProgramsList'))
      },
      {
        exact: true,
        path: '/app/programs/marketing',
        component: lazy(() => import('src/pages/programs/MarketingView'))
      },
      {
        exact: true,
        path: '/app/registration/registration',
        component: lazy(() => import('src/pages/registration/RegistrationView'))
      },
      {
        exact: true,
        path: '/app/registration/enrollment',
        component: lazy(() => import('src/pages/registration/EnrollmentView'))
      },
      // In use Routes
      {
        exact: true,
        path: '/app/my-profile',
        component: lazy(() => import('src/pages/user-settings/MyProfile'))
      },
      // {
      //   exact: true,
      //   path: '/app/center-profile',
      //   component: lazy(() => import('src/pages/user-settings/CenterProfile'))
      // },
      {
        exact: true,
        path: '/app/user-account',
        component: lazy(() => import('src/pages/user-settings/Account'))
      },
      {
        exact: true,
        path: '/app/user-account/subscription/payment',
        component: lazy(() =>
          import('src/pages/user-settings/Account/Subscription/PaymentView')
        )
      },
      {
        exact: true,
        path: '/app/families',
        component: lazy(() => import('src/pages/families/FamiliesView'))
      },
      {
        exact: true,
        path: '/app/enroll',
        component: () => <Redirect to="/app/enroll/leads" />
      },
      {
        exact: true,
        path: '/app/enroll/family/:id',
        component: lazy(() => import('src/pages/enroll/FamilyView'))
      },
      {
        exact: true,
        path: '/app/enroll/enrollment',
        component: lazy(() => import('src/pages/enroll/EnrollmentsView'))
      },
      {
        exact: true,
        path: '/app/enroll/leads/:id?',
        component: lazy(() => import('src/pages/enroll/LeadsView'))
      },
      {
        exact: true,
        path: '/app/enroll/tours',
        component: lazy(() => import('src/pages/tours/ToursView'))
      },
      {
        exact: true,
        path: '/app/term-classes',
        component: lazy(() => import('src/pages/term-classes'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: HomeView
      },
      // {
      //   exact: true,
      //   path: '/pricing',
      //   component: lazy(() => import('src/views/pricing/PricingView'))
      // },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
