import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme,
  Link,
  Popover
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import SwipeableViews from 'src/components/SwipeableViews';
import { Theme } from 'src/theme';
import { useDispatch, useSelector } from 'src/store';
import Notifications from 'src/layouts/PortalLayout/TopBarNew/Notifications';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
  getUnReadNotifications,
  markAsReadNotification,
  getNotifications as getLastMonthNotifications
} from 'src/slices/dashboard';
import useAuth from 'src/hooks/useAuth';
import { useLocation, useRouteMatch } from 'react-router';
// import { ampli } from 'src/ampli';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  notificationHeader: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    fontSize: 18,
    fontWeight: 600
  },
  title: {
    flexGrow: 1,
    color: `${theme.palette.common.white} !important`
  },
  markAllRead: {
    display: 'flex',
    justifyContent: 'center',
    height: 50,
    alignItems: 'center',
    color: theme.palette.primary.main
  },
  notificationDrawer: {
    marginTop: '20px'
  }
}));

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const NotificationDrawer = ({ open, onClose, anchorEl }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const leadsMatch = useRouteMatch('/app/enroll/leads/*');
  const inboxMatch = useRouteMatch('/app/inbox/*');
  const isMountedRef = useIsMountedRef();
  const { providerId } = useAuth();

  const { notifications, unreadNotification } = useSelector(
    state => state.dashboard
  );

  const [value, setValue] = useState(0);
  const [isNotificationLoading, setNotificationLoading] = useState<boolean>(
    true
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const markAllRead = () => {
    if (unreadNotification?.items) {
      const notificationID = unreadNotification?.items.filter(
        item => item.status === 0
      );
      if (notificationID.length > 0) {
        dispatch(markAsReadNotification(notificationID.map(item => item.id)));
      }
    }
  };

  const getNotifications = () => {
    dispatch(getUnReadNotifications()).finally(() => {
      setNotificationLoading(false);
    });
    dispatch(getLastMonthNotifications());
  };

  useEffect(() => {
    if (isMountedRef.current) {
      getNotifications();
    }
    // eslint-disable-next-line
  }, [isMountedRef, providerId, dispatch, location, open]);

  // useEffect(() => {
  //   ampli.viewNotifications();
  // }, []);

  useEffect(() => {
    if (open && (leadsMatch || inboxMatch)) {
      onClose();
    }
  }, [open, leadsMatch, inboxMatch, onClose]);

  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      id={id}
      open={open}
      className={classes.notificationDrawer}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Box minWidth={500}>
        <AppBar position="static">
          <Toolbar className={classes.notificationHeader}>
            <Typography variant="h4" className={classes.title}>
              Notifications
            </Typography>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={onClose}
                color="inherit"
              >
                <CloseIcon className={classes.title} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          aria-label="full width tabs example"
        >
          <Tab label="New" {...a11yProps(0)} />
          <Tab label="Last 30 Days" {...a11yProps(1)} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Notifications
              loading={isNotificationLoading}
              notifications={unreadNotification}
            />
            <Divider />
            {unreadNotification?.items?.length > 0 && (
              <Box className={classes.markAllRead}>
                <Link href="#" onClick={markAllRead} color="inherit">
                  Mark all as read
                </Link>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Notifications
              loading={isNotificationLoading}
              notifications={notifications}
            />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Popover>
  );
};

export default NotificationDrawer;
