import { Box, Drawer, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  helpHeader: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
    marginTop: theme.spacing(2)
  }
}));

const HelpDrawer = ({ open, onClose }) => {
  const classes = useStyles();
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={400}>
        <Typography className={classes.helpHeader}>Help</Typography>
      </Box>
    </Drawer>
  );
};

export default HelpDrawer;
