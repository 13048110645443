import React from 'react';
import { FC } from 'react';
import Helmet from 'react-helmet';

const AMPSettings: FC = () => {
  return (
    <Helmet>
      <script src="//amp.azure.net/libs/amp/2.3.6/azuremediaplayer.min.js" />
      <link
        href="//amp.azure.net/libs/amp/2.3.6/skins/amp-flush/azuremediaplayer.min.css"
        rel="stylesheet"
      />
      <script src="/js/amp_playlist.js" type="text/javascript" />
    </Helmet>
  );
};

export default AMPSettings;
