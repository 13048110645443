import { createSlice } from '@reduxjs/toolkit';
import {
  DealService,
  EnrollmentProgramScheduleService,
  IRegistrationData
} from 'shared';
import { AppThunk } from 'src/store';

export interface RegistrationState {
  registrations: any;
  registrationOptions: IRegistrationData[];
}

const initialState: RegistrationState = {
  registrations: null,
  registrationOptions: null
};

const dealService = DealService.getInstance<DealService>();
const enrollmentProgramScheduleService = EnrollmentProgramScheduleService.getInstance<
  EnrollmentProgramScheduleService
>();

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setRegistrationList(state: RegistrationState, action: any) {
      state.registrations = action.payload;
    },
    setRegistrationOptions(state: RegistrationState, action: any) {
      state.registrationOptions = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getRegistrationList = (): AppThunk => dispatch => {
  dealService.getRegistrationList().then(({ data }) => {
    dispatch(slice.actions.setRegistrationList(data));
  });
};

export const getRegistrationOptions = (): AppThunk => dispatch => {
  enrollmentProgramScheduleService.getRegistrationData().then(({ data }) => {
    dispatch(slice.actions.setRegistrationOptions(data));
  });
};

export default slice;
