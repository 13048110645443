import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { Theme } from 'src/theme';
import { orderBy, sortBy } from 'lodash';

import EmailNotificationsDialog from '../EmailNotificationsDialog';
import LeadCard from '../LeadCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15
  },
  notificationLink: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: theme.spacing(2)
  },
  cardTitle: {
    fontWeight: 700,
    color: '#222',
    width: '100%',
    padding: '16px 12px 0'
  },
  notificationDescription: {
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  clickableItem: {
    width: '100%'
  },
  templateContainer: {
    borderRadius: 4,
    border: '0.5px solid rgba(0, 0, 0, 0.25)',
    margin: 16,
    '&:hover': {
      boxShadow:
        '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)'
    }
  },
  templateContainerNonSelectable: {
    borderRadius: 4,
    border: '0.5px solid rgba(0, 0, 0, 0.25)',
    margin: 16
  },
  cardContentContainer: {
    width: '100%'
  },
  cardContentRoot: { padding: 0 },
  comingSoon: {
    background: '#E8F4FE',
    padding: 8,
    color: '#6C9BF9',
    borderRadius: 33,
    width: 130,
    fontSize: 14
  },
  enabled: {
    background: '#EDF7ED',
    padding: 8,
    color: '#4BA64F',
    borderRadius: 33,
    width: 95,
    fontSize: 14
  },
  disabled: {
    background: '#FFF4E5',
    padding: 8,
    color: '#C67608',
    borderRadius: 33,
    width: 95,
    fontSize: 14
  },
  gridContainer: {
    display: 'flex',
    height: '100%',
    gridGap: '16px'
  },
  gridItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: 'rgba(145, 158, 171, 0.08)'
  },
  dialogSubHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 20,
    marginBottom: '8px'
  }
}));

const LeadEmailTemplates = ({
  groupedTemplates,
  toggleTemplateDialog,
  handleEditTemplate,
  selectedTemplate,
  onSettingClick,
  onCloseSettingModal
}) => {
  const classes = useStyles();
  const [earlyInterest, setEarlyInterest] = useState([]);
  const [tours, setTours] = useState([]);
  const [considering, setConsidering] = useState([]);

  useEffect(() => {
    if (groupedTemplates && Object.keys(groupedTemplates).length > 0) {
      setEarlyInterest(
        sortBy(groupedTemplates['Early Interest'], 'order') || []
      );
      setTours(sortBy(groupedTemplates['Tours'], 'order') || []);
      setConsidering(
        orderBy(groupedTemplates['Considering'], 'name', 'desc') || []
      );
    }
  }, [groupedTemplates]);

  return (
    <Box height="100%">
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="#FFF"
        borderRadius="16px"
        boxShadow="0px 1px 2px 0px rgba(145, 158, 171, 0.16)"
        p="16px"
        height="100%"
      >
        <Typography className={classes.dialogSubHeading}>
          Lead Email Templates
        </Typography>

        <Box className={classes.gridContainer}>
          <Box className={classes.gridItem}>
            <Typography className={classes.cardTitle}>
              Early Interest
            </Typography>
            <Box
              p="12px 16px"
              display="flex"
              flexDirection="column"
              gridGap={8}
              width="100%"
              height="100%"
              overflow="auto"
            >
              {earlyInterest?.map(template => (
                <LeadCard
                  title={template?.name}
                  description={template?.description}
                  handleClick={() => handleEditTemplate(template?.id)}
                  automationStatus={template.automationStatus}
                />
              ))}
            </Box>
          </Box>
          <Box className={classes.gridItem}>
            <Typography className={classes.cardTitle}>
              Tour Scheduled
            </Typography>
            <Box
              p="12px 16px"
              display="flex"
              flexDirection="column"
              gridGap={8}
              width="100%"
              height="100%"
              overflow="auto"
            >
              {tours?.map(template => (
                <LeadCard
                  title={template?.name}
                  description={template?.description}
                  handleClick={() => handleEditTemplate(template?.id)}
                  automationStatus={template.automationStatus}
                />
              ))}
            </Box>
          </Box>
          <Box className={classes.gridItem}>
            <Typography className={classes.cardTitle}>Considering</Typography>
            <Box
              p="12px 16px"
              display="flex"
              flexDirection="column"
              gridGap={8}
              width="100%"
              height="100%"
              overflow="auto"
            >
              {considering?.map(template => (
                <LeadCard
                  title={template?.name}
                  description={template?.description}
                  handleClick={() => handleEditTemplate(template?.id)}
                  automationStatus={template.automationStatus}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* {groupedTemplates &&
        Object.keys(groupedTemplates).map(groupName => {
          return (
            <Card key={groupName} className={classes.root}>
              <CardContent
                classes={{ root: classes.cardContentRoot }}
                className={classes.cardContentContainer}
              >
                <Box
                  pl={2}
                  py={2}
                  borderBottom="0.5px solid rgba(0, 0, 0, 0.25)"
                >
                  <Typography className={classes.cardTitle}>
                    {groupName}
                  </Typography>
                </Box>
                {groupedTemplates[`${groupName}`].map(template => {
                  return (
                    <Box
                      key={template?.id}
                      className={
                        template.status === 'Planned'
                          ? classes.templateContainerNonSelectable
                          : classes.templateContainer
                      }
                    >
                      <ButtonBase
                        disabled={template.status === 'Planned'}
                        className={classes.clickableItem}
                        onClick={() => handleEditTemplate(template.id)}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                          p={2}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                            textAlign="left"
                          >
                            <Typography>{template.name}</Typography>
                            <Typography
                              className={classes.notificationDescription}
                            >{`Customize the UI the parent will see when they go to book a tour`}</Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            {template.status === 'Planned' && (
                              <Typography className={classes.comingSoon}>
                                COMING SOON
                              </Typography>
                            )}
                            {template.status === 'Active' &&
                              template.disabled === false && (
                                <Typography className={classes.enabled}>
                                  ENABLED
                                </Typography>
                              )}
                            {template.status === 'Active' &&
                              template.disabled && (
                                <Typography className={classes.disabled}>
                                  DISABLED
                                </Typography>
                              )}
                            <ArrowForwardIosIcon
                              className={classes.notificationLink}
                            />
                          </Box>
                        </Box>
                      </ButtonBase>
                    </Box>
                  );
                })}
              </CardContent>
            </Card>
          );
        })} */}

      {/* {toggleTemplateDialog && (
        <EmailNotificationsDialog1
          isOpen={toggleTemplateDialog}
          onClose={handleEditTemplate}
          templates={selectedTemplate}
        />
      )} */}
      {toggleTemplateDialog && (
        <EmailNotificationsDialog
          isOpen={toggleTemplateDialog}
          onClose={handleEditTemplate}
          templates={selectedTemplate}
          onSettingClick={onSettingClick}
          onCloseSettingModal={onCloseSettingModal}
        />
      )}
    </Box>
  );
};

export default LeadEmailTemplates;
