import React, { useCallback } from 'react';
import { Box, Paper, makeStyles, Typography, Grid } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import { TextField } from 'formik-material-ui';
import clsx from 'clsx';

import LocationInput from 'src/components/core/form/LocationInput';
import MaskedTextField from 'src/components/core/form/MaskedTextField';
import ImagePreview from 'src/pages/my-site/ConfigureView/ImagePreview';
import { useDispatch, useSelector } from 'src/store';
import { updateCurrentUser } from 'src/slices/current-user';
import { AddressService, ProviderService } from 'shared';
import { useToasts } from 'src/components/core/ToastManager';
import { Theme } from 'src/theme';

const addressService = AddressService.getInstance<AddressService>();
const providerService = ProviderService.getInstance<ProviderService>();

const validationSchema = object().shape({
  facebookUrl: string().nullable(),
  instagramUrl: string().nullable()
});

const defaultValue = {
  name: '',
  logo: '',
  phone: '',
  facebookUrl: '',
  instagramUrl: ''
};

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    borderRadius: '8px',
    border: '1px solid #C4CDD5',
    boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  primaryText: {
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    fontSize: theme.spacing(2),
    letterSpacing: 0.44,
    lineHeight: '24px',
    color: '#000000',
    marginBottom: theme.spacing(2)
  },
  fields: {
    paddingLeft: 20
  },
  fieldContainer: {
    width: '100%',
    marginBottom: 28
  },
  socialContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  socialGutter: {
    marginTop: theme.spacing(2)
  },
  socialText: {
    marginRight: theme.spacing(1),
    marginTop: 10
  }
}));

const MyCenter = ({ profileFormRef, setIsDirty, isDirty }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const provider = useSelector(state => state.currentUser.provider);

  //   const handleDiscardChanges = useCallback(() => {
  //     if (profileFormRef.current) {
  //       profileFormRef.current.resetForm();
  //     }
  //   }, [profileFormRef]);

  const handleSubmitProfile = useCallback(
    async (values: any, action: FormikHelpers<any>) => {
      if (!values.addressLocationId) {
        values.addressLocationId = await addressService
          .create(values.addressLocation)
          .then(resp => resp.data?.id)
          .catch(() => null);
        delete values.address;
      } else {
        await addressService
          .update(values.addressLocationId, {
            ...values.addressLocation,
            fullAddress: null
          })
          .catch(() => {});
      }
      providerService
        .update(provider.id, {
          ...provider,
          ...values,
          addressLocation: {
            ...values.addressLocation,
            fullAddress: null
          }
        })
        .then(resp => {
          dispatch(updateCurrentUser(resp.data));
          addToast('Microsite settings successfully saved');
        })
        .catch(error => {
          addToast(error.error.message, 'error');
        });
    },
    [provider, dispatch, addToast]
  );

  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        flex={1}
        overflow="auto"
      >
        <Formik
          enableReinitialize
          initialValues={Object.assign({}, defaultValue, provider)}
          validationSchema={validationSchema}
          onSubmit={handleSubmitProfile}
          innerRef={profileFormRef}
        >
          {res => {
            if (
              res.initialValues.logo !== res.values.logo ||
              res.initialValues.name !== res.values.name ||
              res.initialValues.addressLocation !==
                res.values.addressLocation ||
              res.initialValues.phone !== res.values.phone ||
              res.initialValues.facebookUrl !== res.values.facebookUrl ||
              res.initialValues.instagramUrl !== res.values.instagramUrl
            ) {
              if (!isDirty) setIsDirty(true);
            }
            return (
              <Form>
                <Paper elevation={0} className={classes.paperContainer}>
                  <Typography variant="h5" className={classes.primaryText}>
                    Enter your center’s contact information
                  </Typography>
                  <Box mt={3}>
                    <Grid container>
                      <Grid item md={2}>
                        <Field name="logo" component={ImagePreview} />
                      </Grid>
                      <Grid item md={10} className={classes.fields}>
                        <Box className={classes.fieldContainer}>
                          <Field
                            fullWidth
                            type="text"
                            label="Center Name"
                            component={TextField}
                            name="name"
                          />
                        </Box>
                        <Box className={classes.fieldContainer}>
                          <Field
                            fullWidth
                            component={LocationInput}
                            label="Center Address"
                            name="addressLocation"
                            placeholder="Search Address"
                          />
                        </Box>
                        <Box className={classes.fieldContainer}>
                          <Field
                            fullWidth
                            type="text"
                            label="Phone Number"
                            component={MaskedTextField}
                            name="phone"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <Paper elevation={0} className={classes.paperContainer}>
                  <Typography variant="h5" className={classes.primaryText}>
                    Enter your social media accounts
                  </Typography>
                  <Grid container direction="column" spacing={3}>
                    <Grid item sm={8}>
                      <Box
                        className={clsx(
                          classes.socialContainer,
                          classes.socialGutter
                        )}
                      >
                        <Typography
                          color="textPrimary"
                          className={classes.socialText}
                        >
                          facebook.com/
                        </Typography>
                        <Field
                          fullWidth
                          size="small"
                          type="text"
                          label="Enter Facebook Profile Name"
                          component={TextField}
                          id="facebookUrl"
                          name="facebookUrl"
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={8}>
                      <Box className={classes.socialContainer}>
                        <Typography
                          color="textPrimary"
                          className={classes.socialText}
                        >
                          instagram.com/
                        </Typography>
                        <Field
                          fullWidth
                          type="text"
                          size="small"
                          label="Enter Instagram Profile Name"
                          component={TextField}
                          name="instagramUrl"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default MyCenter;
