///  <reference types="@types/segment-analytics" />
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import { User, NewProviderService } from 'shared';
import SplashScreen from 'src/components/SplashScreen';
import { instanceApi } from 'shared';
import { AuthService } from 'src/lib/authService';
import { IUserProvider } from 'shared';
// import { 
  // ProviderService, 
//   UserProviderService
// } from 'shared';
import { MediaFileService } from 'shared';
import { 
  // useHistory, 
  useLocation } from 'react-router-dom';
// import userflow from 'userflow.js';
import { useDispatch } from 'src/store';
import {
  // getCurrentUserSubscription,
  // getFeatureFlagsByProvider,
  // getUserById,
  setCurrentProviderData,
  setCurrentUserSubscription,
  setProviderFeatureFlags
} from 'src/slices/current-user';
import { useIntercom } from 'react-use-intercom';
import LogRocket from 'logrocket';
// import { ampli } from './../ampli';
// import loadUserback from 'src/utils/loadUserback';
// import Tracker from '@openreplay/tracker';
// import trackerAssist from '@openreplay/tracker-assist';
import omit from 'lodash/omit';
import { setTourMeetingDetails } from 'src/slices/tour';
import { oidcConfig } from 'src/config';
// import initHelpHero from 'helphero';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  accessToken?: string;
  providerId?: string;
  provider?: IUserProvider;
  userProfile?: User;
}

interface AuthContextValue extends AuthState {
  method: 'OIDC',
  login: () => Promise<void>;
  logout: () => void;
  setLogin: (accessToken: string, user: any) => void;
  register: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    accessToken?: string;
    providerId?: string;
    provider?: IUserProvider;
    userProfile?: User;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    providerId: string;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  accessToken: null,
  provider: null,
  userProfile: null,
};

const setSession = (accessToken: string | null, tenantId: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    instanceApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete instanceApi.defaults.headers.common.Authorization;
  }

  if (tenantId) {
    localStorage.setItem('tenantId', tenantId);
    instanceApi.defaults.headers.common.__tenant = tenantId;
  } else {
    localStorage.removeItem('tenantId');
    delete instanceApi.defaults.headers.common.__tenant;
  }
};

// const setSentryToken = (token: string): void => {
//   if(token) {
//     instanceApi.defaults.headers.common['X-openReplay-SessionToken'] = token;
//   }
// }

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {

      return {
        ...state,
        isInitialised: true,
        ...action.payload
      };
    }
    case 'LOGIN': {
      const { user, providerId } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        providerId
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    // userpilot: any;
    // fcWidget: any;
    // $crisp: any;
    // pendo: any;

    // relicxSDK: any;
    Intercom: any;
    Userback: any;
    chmln: any;
    // heap: any;
  }
}

const usePageViews = () => {
  const location = useLocation();
  React.useEffect(() => {
    const url = new URL(window.location.href);
    var domain = url.hostname.substring(url.host.lastIndexOf(".") + 1);

    window.analytics.page(location.pathname, null, {
      environment: process.env.REACT_APP_ENVIRONMENT,
      user: "Provider",
      domain
    });
    // window.userpilot?.reload();
  }, [location])
}

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'OIDC',
  login: () => Promise.resolve(),
  logout: () => { },
  setLogin: () => { },
  register: () => Promise.resolve()
});

const authService = AuthService.getInstance();
const newProviderService = NewProviderService.getInstance<NewProviderService>();

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {

  usePageViews();

  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const sliceDispatch = useDispatch();
  // const history = useHistory();
  const { boot } = useIntercom();

  /**
   * Redirects to OIDC login page
   */
  const login = async () => {
    const loginFromRegistration = window.localStorage.getItem('loginFromRegistration')
    if (loginFromRegistration !== 'true') {
      // await authService.login();
    }
  };

  const logout = () => {
    setSession(null, null);
    // ampli.userSignout();
    dispatch({ type: 'LOGOUT' });
  };

  const afterLogin = useCallback(
    async (profileUser?: any) => {
      // await newProviderService.loginSucceeded();
      
      const currentProviderInfo = await newProviderService.loginInfo().then(({ data }) => data);
      // const featureFlags = currentProviderInfo.featureFlags;
      
      await sliceDispatch(setProviderFeatureFlags(currentProviderInfo.featureFlags));
      await sliceDispatch(setCurrentUserSubscription(currentProviderInfo.providerSubscription));
      const calendarEvents = currentProviderInfo.calendarEvents || [];
      await sliceDispatch(setTourMeetingDetails(calendarEvents.length > 0 ? calendarEvents[0] : []));
      const provider = await sliceDispatch(setCurrentProviderData(omit(currentProviderInfo, ["providerSubscription", "featureFlags", "calendarEvents"])));

      let user = null;

      if(!profileUser) {

        const token = window.localStorage.getItem('accessToken');
        const tenantId = window.localStorage.getItem('tenantId');
        let userInfoResponse = await fetch(
          `${oidcConfig.stsAuthority}connect/userinfo`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              __tenant: tenantId || null,
              Authorization: `Bearer ${token}`
            }
          }
        );
        const userInfo = await userInfoResponse.json();
        user = {
          access_token: token,
          profile: {
            email: userInfo?.email,
            email_verified: userInfo.email_verified,
            name: userInfo?.name,
            preferred_username: userInfo?.preferred_username,
            family_name: userInfo?.family_name,
            given_name: userInfo?.given_name,
            sub: userInfo?.sub,
            role: userInfo?.role,
            phone_number_verified: userInfo?.phone_number_verified,
            tenantid: tenantId || null
          }
        };
      } else {
        user = profileUser;
      }

      // const providerId = await userProviderService.getCurrentProvider().then(({ data }) => data);
      // const provider = await providerService.get(providerId).then(({ data }) => data);
      // const provider = await sliceDispatch(getUserById(providerId));
      // const userProvider = await userProviderService.getAll().then(({ data }) => {
      //   return data?.items[0];
      // });
      // const user = await authService.getUser();

      // let subscription = ''

      // try {
      //   let response = await subscriptionService.getProviderSubscription();
      //   // subscription = await response.data.subscription.edition.displayName;
      // } catch (ex) {
      //   console.log('Subscription data not loaded');
      // }


      await MediaFileService.init();

      // if (
      //   provider?.providerRegistrySubscriptions?.length === 0
      //   || !userProvider
      //   || !(userProvider?.organizationSize >= 0)
      // ) {
      //   loadUserback(() => {window.Userback.hide()})
      //   // history.push('/app/dashboard?initialSetup=true');
      //   // if(window.Userback) {
      //   //   window.Userback?.hide();
      //   // } 
      //   // else {
      //     // loadUserback(() => {window.Userback.hide()})
      //   // }
      //   history.push('/onboarding');
      // }

      // if (featureFlags && featureFlags['Intercom'] === true) {
        boot({
          // customAttributes: {
          //   location: provider?.addressLocation?.fullAddress,
          //   type: 'Provider',
          //   providerName: provider?.name,
          //   firstName: user?.profile?.given_name,
          //   lastName: user?.profile?.family_name,
          //   subscription,
          //   createdAt: provider?.creationTime,
          // },
          email: user?.profile?.email,
          name: provider?.name,
          userId: user?.profile?.sub,
          hideDefaultLauncher: false
        });
      // }

      

      // if(window.Userback) {
      //   window.Userback.setName(`${user?.profile?.given_name} ${user?.profile?.family_name}`); 
      //   window.Userback.setEmail(user?.profile?.email);
      //   window.Userback.setData({
      //       providerName: provider?.name,
      //       userId: user?.profile?.sub,
      //       email: user?.profile?.email,
      //       userName: `${user?.profile?.given_name} ${user?.profile?.family_name}`
      //   });
      //   window.Userback.show();
      // } else {
          // loadUserback(() => {window.Userback.setName(`${user?.profile?.given_name} ${user?.profile?.family_name}`); 
          // window.Userback.setEmail(user?.profile?.email);
          // window.Userback.setData({
          //     providerName: provider?.name,
          //     userId: user?.profile?.sub,
          //     email: user?.profile?.email,
          //     userName: `${user?.profile?.given_name} ${user?.profile?.family_name}`
          // });
          // window.Userback.show();})
        
      // }

      if(process.env.REACT_APP_ENVIRONMENT === 'Prod')
      { 
        window.chmln.identify(user?.profile?.sub, {   // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")
          id: user?.profile?.sub,
          userId: user?.profile?.sub,   // Required if user is logged in, default creates anonymous ID
          email: user?.profile?.email,
          tenantId: user?.profile?.tenantid,
          providerName: provider?.name,
          environment: 'Prod',
          role: 'Provider',
          providerId: provider?.id,
          subscription: currentProviderInfo?.providerSubscription?.subscription?.edition?.displayName,
          userName: user?.profile?.givenName + user?.profile?.familyName,
          providerState: provider?.addressLocation?.state,
          // provider_zipcode: provider?.addressLocation?.zipCode,
          createDate: provider?.creationTime,      // OPTIONAL, any other unique data that might appear in any page URLs (e.g. 09876 or "12a34b56").
            });
      } else {
        window.chmln.identify(`DIT_${user?.profile?.sub}`, {   // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")
          id: "DIT_" + user?.profile?.sub,
                userId: "DIT_" + user?.profile?.sub,   // Required if user is logged in, default creates anonymous ID
                email: user?.profile?.email,
                providerName: provider?.name,
                role: 'Provider',
                environment: 'DIT',
                providerId: "DIT_" + provider?.id,
                userName: user?.profile?.givenName + user?.profile?.familyName,
                providerState: provider?.addressLocation?.state,
                tenantId: "DIT_" + user?.profile?.tenantid,
                subscription: currentProviderInfo?.providerSubscription?.subscription?.edition?.displayName,
                // provider_zipcode: provider?.addressLocation?.zipCode,
                createDate: provider?.creationTime,     // OPTIONAL, any other unique data that might appear in any page URLs (e.g. 09876 or "12a34b56").
            });
      }

      window.analytics?.track("Signed In", { user_id: user?.profile?.sub });

      // await sliceDispatch(getCurrentUserSubscription());
      
      // try {
      //   await sliceDispatch(getFeatureFlagsByProvider(providerId));
      // } catch (error) {
      // }

      // Commented the onBoarding flow redirection.
      /*
      const onboardingStepStatus = await sliceDispatch(getStepStatus());

      if (!onboardingStepStatus.isSkipped || onboardingStepStatus.isCompleted) {
        const step = lastOnboardingStep(onboardingStepStatus);

        if (step) {
          history.push(step?.section?.routerLink);
          sliceDispatch(setMainStep(step.mainStep));
          sliceDispatch(setActiveStep(step.activeStep));
          if (step.tourStep) {
            sliceDispatch(setTourStep(step.tourStep));
          }
          if (step.profileStep) {
            sliceDispatch(setProfileStep(step.profileStep));
          }
        }
      }
      */

      return provider;
    },
    [sliceDispatch, boot]);

  const setLogin = async (accessToken, user) => {
    setSession(accessToken, user.profile.tenantid);
    const provider = await afterLogin(user);
    let subscription = provider?.subscriptionPlan;

    // try {
    //   let response = await subscriptionService.getProviderSubscription();
    //   subscription = await response.data.subscription.edition.displayName;
    // } catch (ex) {
    //   console.log('Subscription data not loaded');
    // }

    // window.userpilot?.identify(
    //   user.profile.sid,
    //   {
    //     role: 'Provider',
    //     providerId: provider?.id
    //   })


    // window.Intercom("boot", {
    //   app_id: "k07tty3e",
    //   name: user?.profile?.given_name, // Full name
    //   email: user?.profile?.email, // Email address
    //   created_at: provider?.creationTime // Signup date as a Unix timestamp
    // });

    // try {
    //   // window.analytics?.group(user?.profile?.tenantid, {
    //   //   name: `${user?.profile?.given_name} ${user?.profile?.family_name}`,
    //   //   "User ID": user?.profile?.sub,
    //   //   plan: subscription
    //   // });

    //   window.analytics?.identify(user?.profile?.sub, {
    //     "Provider ID": provider?.id,
    //     "Tenant ID": user?.profile?.tenantid,
    //     Email: user?.profile?.email,
    //     State: provider?.addressLocation?.state,
    //     "First Name": user?.profile?.given_name,
    //     "Last Name": user?.profile?.family_name,
    //     "Name": `${user?.profile?.given_name} ${user?.profile?.family_name}`,
    //     // "Provider Name": provider?.name, 
    //     Subscription: subscription,
    //     "User ID": user?.profile?.sub,
    //     Zip: provider?.addressLocation?.zipCode,
    //     "Created At": provider?.creationTime,
    //   });
    // } catch (error) {}

    // try {
    //   window.heap?.identify(user?.profile?.sub);
    //   window.heap?.addUserProperties({"Provider Name": provider?.name, 
    //   "User Name": `${user?.profile?.given_name} ${user?.profile?.family_name}`,
    //   "Email": user?.profile?.email,
    //   "Provider ID": provider?.id,
    //   "Start Date": provider?.creationTime
    // })
    // } catch (error) {}



    // userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
    // userflow.identify(user.profile.sub,
    //   {
    //     role: 'Provider',
    //     providerId: provider?.id,
    //     email: user?.profile?.email,
    //     provider_state: provider?.addressLocation?.state,
    //     name: user?.profile?.given_name,
    //     userId: user?.profile?.sub,
    //     provider_zipcode: provider?.addressLocation?.zipCode,
    //     createdAt: provider?.creationTime,
    //     subscription_plan: subscription
    //   });

      // OpenReplay integration
      // const tracker = new Tracker({
      //   projectKey: process.env.REACT_APP_OPENREPLAY_KEY
      //   // __DISABLE_SECURE_MODE: true
      // });
      // tracker.use(trackerAssist({}));
      // tracker.start({
      //   userID: `${user?.profile?.email} (${user.profile.sub})`,
      //   metadata: {
      //     role: 'Provider',
      //     providerId: provider?.id,
      //     email: user?.profile?.email,
      //     provider_state: provider?.addressLocation?.state,
      //     name: user?.profile?.given_name,
      //     userId: user?.profile?.sub,
      //     provider_zipcode: provider?.addressLocation?.zipCode,
      //     createdAt: provider?.creationTime,
      //     subscription_plan: subscription
      //   }
      // });

      // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
// This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
// Call this function in your authentication promise handler or callback when your visitor and account id values are available
// Please use Strings, Numbers, or Bools for value types.

if(process.env.REACT_APP_ENVIRONMENT === 'Prod')
      {
  //   window.pendo.initialize({
  //     visitor: {
  //       id: user?.profile?.sub,
  //       userId: user?.profile?.sub,   // Required if user is logged in, default creates anonymous ID
  //       email: user?.profile?.email,
  //       providerName: provider?.name,
  //       role: 'Provider',
  //       providerId: provider?.id,
  //       userName: user?.profile?.givenName + user?.profile?.familyName,
  //       providerState: provider?.addressLocation?.state,
  //       // provider_zipcode: provider?.addressLocation?.zipCode,
  //       createDate: provider?.creationTime,
  //         // email:        // Recommended if using Pendo Feedback, or NPS Email
  //         // full_name:    // Recommended if using Pendo Feedback
  //         // role:         // Optional

  //         // You can add any additional visitor level key-values here,
  //         // as long as it's not one of the above reserved names.
  //     },

  //     account: {
  //         // id:           'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
  //         id: user.profile.tenantid,
  //         tenantId: user.profile.tenantid,
  //       providerId: provider?.id,
  //       providerName: provider?.name,
  //       subscription: subscription,
  //       providerState: provider?.addressLocation?.state
  //         // name:         // Optional
  //         // is_paying:    // Recommended if using Pendo Feedback
  //         // monthly_value:// Recommended if using Pendo Feedback
  //         // planLevel:    // Optional
  //         // planPrice:    // Optional
  //         // creationDate: // Optional

  //         // You can add any additional account level key-values here,
  //         // as long as it's not one of the above reserved names.
  //     }
  // });
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
      LogRocket.identify(user?.profile?.sub,
        {
          role: 'Provider',
          environment: 'Production',
          providerId: provider?.id,
          email: user?.profile?.email,
          provider_state: provider?.addressLocation?.state,
          name: `${user?.profile?.given_name} ${user?.profile?.family_name}`,
          userId: user?.profile?.sub,
          provider_zipcode: provider?.addressLocation?.zipCode,
          createdAt: provider?.creationTime,
          subscription_plan: subscription
        });
  // window.chmln.identify(user?.profile?.sub, {   // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")
  //   id: user?.profile?.sub,
  //   userId: user?.profile?.sub,   // Required if user is logged in, default creates anonymous ID
  //   email: user?.profile?.email,
  //   tenantId: user.profile.tenantid,
  //   providerName: provider?.name,
  //   environment: 'Prod',
  //   role: 'Provider',
  //   providerId: provider?.id,
  //   subscription: subscription,
  //   userName: user?.profile?.givenName + user?.profile?.familyName,
  //   providerState: provider?.addressLocation?.state,
  //   // provider_zipcode: provider?.addressLocation?.zipCode,
  //   createDate: provider?.creationTime,      // OPTIONAL, any other unique data that might appear in any page URLs (e.g. 09876 or "12a34b56").
  //     });
      // try {
      //   window.heap?.identify(user?.profile?.sub);
      //   window.heap?.addUserProperties({"Provider Name": provider?.name, 
      //   "User Name": `${user?.profile?.given_name} ${user?.profile?.family_name}`,
      //   "Email": user?.profile?.email,
      //   "Provider Id": provider?.id,
      //   "User Id": user?.profile?.sub,
      //   "Start Date": provider?.creationTime,
      //   "Tenant Id": user.profile.tenantid,
      //   "Environment": 'Prod',
      // })
      // } catch (error) {}

      try {
  
        window.analytics?.identify(user?.profile?.sub, {
          "Provider ID": provider?.id,
          "Tenant ID": user?.profile?.tenantid,
          Email: user?.profile?.email,
          State: provider?.addressLocation?.state,
          "First Name": user?.profile?.given_name,
          "Last Name": user?.profile?.family_name,
          "Name": `${user?.profile?.given_name} ${user?.profile?.family_name}`,
          "Environment": "Prod",
          Subscription: subscription,
          "User ID": user?.profile?.sub,
          Zip: provider?.addressLocation?.zipCode,
          "Created At": provider?.creationTime,
        });
      } catch (error) {}

} else {
//   window.pendo.initialize({
//     visitor: {
//         id: "test-" + user?.profile?.sub,
//         userId: "test-" + user?.profile?.sub,   // Required if user is logged in, default creates anonymous ID
//         email: user?.profile?.email,
//         providerName: provider?.name,
//         role: 'Provider',
//         providerId: "test-" + provider?.id,
//         userName: user?.profile?.givenName + user?.profile?.familyName,
//         providerState: provider?.addressLocation?.state,
//         // provider_zipcode: provider?.addressLocation?.zipCode,
//         createDate: provider?.creationTime,
//         // subscription_plan: subscription
//         // email:        // Recommended if using Pendo Feedback, or NPS Email
//         // full_name:    // Recommended if using Pendo Feedback
//         // role:         // Optional

//         // You can add any additional visitor level key-values here,
//         // as long as it's not one of the above reserved names.
//     },

//     account: {
//         // id:           'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
//         id: "test-" + user.profile.tenantid,
//         tenantId: "test-" + user.profile.tenantid,
//         providerId: "test-" + provider?.id,
//         providerName: provider?.name,
//         subscription: subscription,
//         providerState: provider?.addressLocation?.state
//         // name:         // Optional
//         // is_paying:    // Recommended if using Pendo Feedback
//         // monthly_value:// Recommended if using Pendo Feedback
//         // planLevel:    // Optional
//         // planPrice:    // Optional
//         // creationDate: // Optional

//         // You can add any additional account level key-values here,
//         // as long as it's not one of the above reserved names.
//     }
// });
      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
      LogRocket.identify(`DIT_${user?.profile?.sub}`,
        {
          role: 'Provider',
          environment: 'DIT',
          providerId: `DIT_${provider?.id}`,
          email: `DIT_${user?.profile?.email}`,
          provider_state: provider?.addressLocation?.state,
          name: `DIT_${user?.profile?.given_name} ${user?.profile?.family_name}`,
          userId: `DIT_${user?.profile?.sub}`,
          provider_zipcode: provider?.addressLocation?.zipCode,
          createdAt: provider?.creationTime,
          subscription_plan: subscription
        });
// window.chmln.identify(user?.profile?.sub, {   // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")
//   id: "DIT_" + user?.profile?.sub,
//         userId: "DIT_" + user?.profile?.sub,   // Required if user is logged in, default creates anonymous ID
//         email: user?.profile?.email,
//         providerName: provider?.name,
//         role: 'Provider',
//         environment: 'DIT',
//         providerId: "DIT_" + provider?.id,
//         userName: user?.profile?.givenName + user?.profile?.familyName,
//         providerState: provider?.addressLocation?.state,
//         tenantId: "DIT_" + user.profile.tenantid,
//         subscription: subscription,
//         // provider_zipcode: provider?.addressLocation?.zipCode,
//         createDate: provider?.creationTime,     // OPTIONAL, any other unique data that might appear in any page URLs (e.g. 09876 or "12a34b56").
//     });
    // try {
    //   window.heap?.identify(user?.profile?.sub);
    //   window.heap?.addUserProperties({"Provider Name": provider?.name, 
    //   "User Name": `${user?.profile?.given_name} ${user?.profile?.family_name}`,
    //   "Email": user?.profile?.email,
    //   "Provider Id": "DIT_" + provider?.id,
    //   "User Id": "DIT_" + user?.profile?.sub,
    //   "Start Date": provider?.creationTime,
    //   "Tenant Id": "DIT_" + user.profile.tenantid,
    //   "Environment": 'DIT',
    // })
    // } catch (error) {}

    try {
  
      window.analytics?.identify(user?.profile?.sub, {
        "Provider ID": "DIT_" + provider?.id,
        "Tenant ID": "DIT_" + user?.profile?.tenantid,
        Email: user?.profile?.email,
        State: provider?.addressLocation?.state,
        "First Name": user?.profile?.given_name,
        "Last Name": user?.profile?.family_name,
        "Name": `${user?.profile?.given_name} ${user?.profile?.family_name}`,
        "Environment": 'DIT',
        Subscription: subscription,
        "User ID": "DIT_" + user?.profile?.sub,
        Zip: provider?.addressLocation?.zipCode,
        "Created At": provider?.creationTime,
      });
    } catch (error) {}

}
      
      // if(await tracker.getSessionToken()) {
      //   setSentryToken(await tracker.getSessionToken());
      // }

      // HelpHero integration
      // const hlp = initHelpHero(process.env.REACT_APP_HELPHERO_ID);
      // hlp.identify(user.profile.sub, {
      //   role: 'Provider',
      //   providerId: provider?.id,
      //   email: user?.profile?.email,
      //   provider_state: provider?.addressLocation?.state,
      //   name: user?.profile?.given_name,
      //   userId: user?.profile?.sub,
      //   provider_zipcode: provider?.addressLocation?.zipCode,
      //   createdAt: provider?.creationTime,
      //   subscription_plan: subscription
      // });

      // if(process.env.REACT_APP_ENVIRONMENT === 'Prod')
      // {
        // logrocket integration
      // LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
      // LogRocket.identify(user.profile.sub,
      //   {
      //     role: 'Provider',
      //     providerId: provider?.id,
      //     email: user?.profile?.email,
      //     provider_state: provider?.addressLocation?.state,
      //     name: user?.profile?.given_name,
      //     userId: user?.profile?.sub,
      //     provider_zipcode: provider?.addressLocation?.zipCode,
      //     createdAt: provider?.creationTime,
      //     subscription_plan: subscription
      //   })}
      // window.relicxSDK.identify(user.profile.sub,
      //   {
      //     role: 'Provider',
      //     providerId: provider?.id,
      //     email: user?.profile?.email,
      //     provider_state: provider?.addressLocation?.state,
      //     name: user?.profile?.given_name,
      //     user_name: user?.profile?.given_name,
      //     userId: user?.profile?.sub,
      //     provider_zipcode: provider?.addressLocation?.zipCode,
      //     createdAt: provider?.creationTime,
      //     subscription_plan: subscription
      //   })


    //     if(process.env.REACT_APP_ENVIRONMENT === 'Prod')
    //   {
    //     ampli.load({ environment: 'production' });
    //     ampli.identify(user.profile.sub, {user: {id: user.profile.sub, email: user?.profile?.email }});
    //     ampli.userSignin();
    //   } else {
    //     ampli.load({ environment: 'development' });
    //     ampli.identify(user.profile.sub, {user: {id: user.profile.sub, email: user?.profile?.email }});
    //     ampli.userSignin();
    // }

    



    // window.$crisp.push(["set", "user:email", [user?.profile?.email]]);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        providerId: provider?.id,
      }
    });
    window.localStorage.removeItem('loginFromRegistration');
  };

  /**
   * Redirects to OIDC register page
   */
  const register = async () => { };
  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const tenantId = window.localStorage.getItem('tenantId');

        if (accessToken && tenantId) {
          setSession(accessToken, tenantId);

          // TODO: we can make call to API here (we get token already) to get more details about user, e.g. avatar
          // For now we use what we get from OIDC provider instead (but that not include avatar and probably other things)
          // const response = await instanceApi.get<{ user: User; }>('/api/account/me');
          // const { user } = response.data;

          const user = await authService.getUser();

          const provider = await afterLogin();

          // try {
          //   window.fcWidget?.user?.setProperties({
          //     center: provider?.name,
          //     email: user?.profile?.email,
          //     id: user?.profile?.sub,
          //     name: `${user?.profile?.given_name} ${user?.profile?.family_name}`
          //   });
          // } catch (error) {

          // }

          // try {
          //   window.analytics?.identify(user?.profile?.sub, {
          //     providerId: provider?.id,
          //     tenantId: user?.profile?.tenantid,
          //     email: user?.profile?.email,
          //     provider_state: provider?.addressLocation?.state,
          //     name: user?.profile?.given_name,
          //     userId: user?.profile?.sub,
          //     provider_zipcode: provider?.addressLocation?.zipCode,
          //     createdAt: provider?.creationTime,
          //   });
          // } catch (error) {

          // }
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              accessToken,
              user,
              providerId: provider?.id,
            }
          });

        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              accessToken: null,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            accessToken: null,
            user: null
          }
        });
      }
    };

    initialise();

  }, [afterLogin]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'OIDC',
        login,
        logout,
        register,
        setLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;