import React from 'react';
import { makeStyles, Box, Typography, Switch, Input } from '@material-ui/core';

import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  centerInfoCard: {
    width: '100%',
    padding: theme.spacing(1),
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    color: '#2A3745',
    fontWeight: 500,
    fontSize: 16
  },
  subTitle: {
    color: '#637381',
    fontWeight: 400,
    fontSize: 14
  }
}));

const SettingInfoCard = ({
  title,
  value,
  isChecked,
  showInput = true,
  handleInputChange,
  handleSwitchChange,
  disabled = false
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.centerInfoCard}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.title}>{title}</Typography>
        <Box>
          <Switch
            onChange={handleSwitchChange}
            checked={isChecked}
            color="primary"
            name={title}
            disabled={disabled}
          />
        </Box>
      </Box>
      {showInput && (
        <Input
          className={classes.subTitle}
          onChange={handleInputChange}
          value={value}
          multiline
          fullWidth
        />
      )}
    </Box>
  );
};

export default SettingInfoCard;
