import { Slider, Tooltip, makeStyles } from '@material-ui/core';
import React, { FC, useCallback } from 'react';

export interface AgeRangeSliderProps {
  handleSliderChange: any;
  sliderProps?: any;
  ageList?: any;
  sliderValues?: any;
}

const useStyles = makeStyles(() => ({
  sliderMarkLabel: {
    display: 'none'
  }
}));

const AgeRangeSlider: FC<AgeRangeSliderProps> = ({
  ageList,
  sliderProps,
  sliderValues,
  handleSliderChange
}) => {
  const classes = useStyles();

  const valueLabelComponent = useCallback(
    props => {
      return (
        <>
          {ageList ? (
            <Tooltip
              open={props.open}
              enterTouchDelay={0}
              placement="top"
              title={ageList?.find(el => el.value === props.value)?.label}
            >
              {props.children}
            </Tooltip>
          ) : null}
        </>
      );
    },
    [ageList]
  );

  return (
    <Slider
      value={sliderValues}
      step={null}
      marks={ageList}
      onChange={handleSliderChange}
      aria-labelledby="range-slider"
      valueLabelDisplay="on"
      ValueLabelComponent={valueLabelComponent}
      {...sliderProps}
      classes={{ markLabel: classes.sliderMarkLabel }}
    />
  );
};

export default AgeRangeSlider;
