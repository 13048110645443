import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import {
  IEnrollmentClass,
  IEnrollmentSession,
  IProgram,
  ProgramService,
  media,
  EnrollmentMediaService,
  IEnrollmentAge,
  EnrollmentAgeService,
  session,
  IEnrollmentMedia,
  MediaTypes,
  ProgramStatusEnum,
  IEnrollmentTimeline,
  EnrollmentTimelineService,
  MicrositeTimelineSlideService,
  ISlide,
  EnrollmentMediaGroupService,
} from 'shared';
import { cloneDeep, filter, find, findIndex, isEmpty, omit, orderBy, pick, size, uniqueId } from 'lodash';
import { array, number, object, string } from 'yup';

export interface ProgramsState {
  programs: IProgram[];
  enrollmentAges: IEnrollmentAge[];
  selectedProgram: IProgram;
  selectedSession: IEnrollmentSession;
  selectedTimeline: IEnrollmentTimeline;
  hasValuesChanged: boolean;
  hasCommonColumn: string;
  imageCollection: string;
  template: string;
};

const initialState: ProgramsState = {
  programs: null,
  imageCollection: null,
  enrollmentAges: [],
  selectedProgram: null,
  selectedSession: null,
  selectedTimeline: null,
  hasValuesChanged: false,
  hasCommonColumn: null,
  template: null,
};


const programsValidationSchema = array().of(
  object({
    enrollmentSessions: array().of(
      object({
        enrollmentClasses: array().of(
          object({
            title: string().required('Title is required!'),
            price: number()
              .required('Price is required!')
              .min(0, 'Price should not be negative number'),
            capacity: number()
              .required('Capacity is required!')
              .min(0, 'Capacity should not be negative number'),
            openings: number()
              .required('Openings is required!')
              .min(0, 'Openings should not be negative number'),
            enrolled: number()
              .required('Enrolled is required!')
              .min(0, 'Enrolled should not be negative number')
              .when(
                ['capacity', 'openings'],
                (capacity: number, openings: number, schema) => {
                  return schema.test({
                    test: (enrolled: number) =>
                      capacity === openings + enrolled,
                    message: 'Openings cannot exceed the available Capacity'
                  });
                }
              ),
            startHour: string().required('Start Hour is required!'),
            endHour: string().required('End Hour is required!'),
            daysOfOperation: string()
          }).notRequired()
        )
      }).notRequired()
    )
  }).notRequired()
);


const slice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    setPrograms(state: ProgramsState, action: PayloadAction<{ programs: IProgram[]; }>) {
      const { programs } = action.payload;
      state.programs = orderBy(programs, 'orderNumber');
    },
    setEnrollmentAge(state: ProgramsState, action: PayloadAction<{ enrollmentAges: IEnrollmentAge[]; }>) {
      const { enrollmentAges } = action.payload;
      state.enrollmentAges = enrollmentAges;
    },
    setSelectedProgram(state: ProgramsState, action: PayloadAction<IProgram>) {
      state.selectedProgram = action.payload;
    },
    setSelectedSession(state: ProgramsState, action: PayloadAction<IEnrollmentSession>) {
      state.selectedSession = action.payload;
    },
    setSelectedTimeline(state: ProgramsState, action: PayloadAction<IEnrollmentTimeline>) {
      state.selectedTimeline = action.payload;
    },
    setHasValuesChanged(state: ProgramsState, action: PayloadAction<boolean>) {
      state.hasValuesChanged = action.payload;
    },
    setHasCommonColumn(state: ProgramsState, action: PayloadAction<string>) {
      state.hasCommonColumn = action.payload;
    },
    setImageCollection(state: ProgramsState, action: PayloadAction<string>) {
      state.imageCollection = action.payload;
    },
    setTemplate(state: ProgramsState, action: PayloadAction<string>) {
      state.template = action.payload;
    },
  }
});

export const reducer = slice.reducer;

const programService = ProgramService.getInstance<ProgramService>();
const enrollmentMediaService = EnrollmentMediaService.getInstance<EnrollmentMediaService>();
const enrollmentMediaGroupService = EnrollmentMediaGroupService.getInstance<EnrollmentMediaGroupService>();
const enrollmentAgeService = EnrollmentAgeService.getInstance<EnrollmentAgeService>();
const enrollmentTimelineService = EnrollmentTimelineService.getInstance<EnrollmentTimelineService>();
const micrositeTimelineSlideService = MicrositeTimelineSlideService.getInstance<MicrositeTimelineSlideService>();

export const getEnrollmentAges = (request: any = {}): AppThunk => (dispatch) => {
  return enrollmentAgeService.getAll(request).then((response) => {
    dispatch(slice.actions.setEnrollmentAge({ enrollmentAges: response.data.items }));
    return response;
  })
}

export const createEnrollmentAge = (request: Partial<IEnrollmentAge>): AppThunk => (dispatch, getSate) => {
  const { enrollmentAges } = getSate().programs;
  return enrollmentAgeService.create(request).then((response) => {
    dispatch(slice.actions.setEnrollmentAge({
      enrollmentAges: [...enrollmentAges, response.data]
    }));
    return response.data;
  })
}

export const multipleCreateUpdateEnrollmentAge = (request: Array<Partial<IEnrollmentAge>>): AppThunk => (dispatch, getSate) => {
  return enrollmentAgeService.multipleCreateUpdate(request).then((response) => {
    dispatch(slice.actions.setEnrollmentAge({
      enrollmentAges: response.data
    }));
    return response.data;
  })
}

export const updateEnrollmentAge = (id: string, request: Partial<IEnrollmentAge>): AppThunk => (dispatch, getSate) => {
  const { enrollmentAges } = getSate().programs;
  return enrollmentAgeService.update(id, request).then((response) => {
    const index = findIndex(enrollmentAges, { id });
    const newAgeGroup = [...enrollmentAges];
    enrollmentAges.splice(index, 1, response.data);
    dispatch(slice.actions.setEnrollmentAge({
      enrollmentAges: newAgeGroup
    }));
    return response.data;
  })
}

export const deleteEnrollmentAge = (id: string): AppThunk => (dispatch, getSate) => {
  const { enrollmentAges } = getSate().programs;
  return enrollmentAgeService.delete(id).then((response) => {
    const index = findIndex(enrollmentAges, { id });
    const newAgeGroup = [...enrollmentAges];
    newAgeGroup.splice(index, 1);
    dispatch(slice.actions.setEnrollmentAge({
      enrollmentAges: newAgeGroup
    }));
    return response.data;
  })
}

export const getPrograms = (request: any = {}, forceUpdate = false): AppThunk => async (dispatch, getState) => {
  const { programs } = getState().programs;
  if (forceUpdate || !programs) {
    const data = await programService.getAll(request).then((response) => {
      const programsItems = response.data.items;
      dispatch(slice.actions.setPrograms({ programs: programsItems }));
      return response;
    })
    return data;
  } else {
    return programs;
  }
}

export const updatePrograms = (programs: IProgram[]): AppThunk => (dispatch) => {
  return programService.updateMultiple(programs).then((response) => {
    dispatch(slice.actions.setPrograms({ programs: response.data }));
    return response;
  });
}

export const addProgram = (program, create = true): AppThunk => (dispatch, getState) => {
  const { provider } = getState().currentUser;
  const { programs } = getState().programs;
  const id = uniqueId();
  const index = programs.length;
  const programData = {
    providerId: provider.id,
    enrollmentStart: null,
    enrollmentStatus: 1,
    name: `Program ${index}`,
    id: `${id}`,
    endDate: new Date(),
    startDate: new Date(),
    enrollmentClasses: [],
    enrollmentSessions: [],
    enrollmentMediaAndSessions: [],
    enrollmentMediaGroups: [],
    status: ProgramStatusEnum.PLANNED,
    isPublished: false,
    ...(!create ? { isNew: true } : {}),
    ...program,
  };
  dispatch(slice.actions.setPrograms({ programs: [...programs, programData] }));
  if (create) {
    return programService.create(omit(programData, ['id'])).then(({ data }) => {
      const { programs } = getState().programs;
      const index = findIndex(programs, { id: programData.id });
      const updatedPrograms = cloneDeep(programs)
      updatedPrograms.splice(index, 1, data);
      dispatch(slice.actions.setPrograms({ programs: updatedPrograms }));
    });
  } else {
    return programData
  }
}

export const editProgram = (program: IProgram): AppThunk => (dispatch) => {
  dispatch(slice.actions.setSelectedProgram(program));
}

export const exitEditProgram = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.setSelectedProgram(null));
}

export const setImageCollection = (value: any): AppThunk => (dispatch) => {
  dispatch(slice.actions.setImageCollection(value));
}

export const setProgramTemplate = (value: any): AppThunk => (dispatch) => {
  dispatch(slice.actions.setTemplate(value));
}

export const saveProgramChanges = (): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState().programs;

    await programsValidationSchema.validate(programs);

    let newPrograms: any = programs.filter(program => program.isNew);
    const oldPrograms = programs.filter(program => program.creationTime);

    if (size(newPrograms) > 0) {
      const programCreatePromises = newPrograms.map(async program => {
        const createdProgram = await programService.create(omit(program, 'id', 'isNew'))
          .then(async ({ data }) => {
            return {
              ...data,
              enrollmentMedia: program.enrollmentMedia,
              enrollmentMediaGroups: program.enrollmentMediaGroups,
            };
          });

        return createdProgram;
      })
      await Promise.all(programCreatePromises)
        .then(response => {
          newPrograms = response
          dispatch(updatePrograms(oldPrograms.concat(response)));
        })
    } else {
      await dispatch(updatePrograms(oldPrograms));
    }
    await dispatch(setHasValuesChanged(false));
    return [...oldPrograms, ...newPrograms];
  }
}


export const updateProgram = (id: string, data: IProgram): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      if (id === program.id) {
        return data;
      }
      return program;
    })

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
    return allPrograms;
  }
}

export const deleteProgram = (programId: string): AppThunk => (dispatch, getState) => {
  const { programs } = getState();
  const isNewProgram = findIndex(programs.programs, (program) => program.id === programId && program.isNew);
  const allPrograms = programs.programs.filter((program) => program.id !== programId);
  if (isNewProgram !== -1) {
    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
    return false;
  }
  return programService.delete(programId).then(() => {
    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  })

}

export const setPrograms = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setPrograms({ programs: data }));
}

export const addSession = (programId: string): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedSession({ enrollmentProgramId: programId }));
  };
}

export const editSession = (enrollmentSession: IEnrollmentSession): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedSession(enrollmentSession));
  };
};

export const exitEditSession = (): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedSession(null));
  };
};

export const editTimeline = (micrositeTimeline: IEnrollmentTimeline): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedTimeline(micrositeTimeline));
  };
};

export const exitEditTimeline = (): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedTimeline(null));
  };
};


export const setSelectedProgram = (program: Partial<IProgram>): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedProgram(program));
  };
};

export const reorderPrograms = (oldIndex: number, newIndex: number): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();
    const allPrograms = cloneDeep(programs.programs);
    const [removedProgram] = allPrograms.splice(oldIndex, 1);
    allPrograms.splice(newIndex, 0, removedProgram);
    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}


export const updateSession = (enrollmentSession: IEnrollmentSession): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const programs = cloneDeep(state.programs.programs);

    const allPrograms = programs.map((program) => {
      if (program.id === enrollmentSession.enrollmentProgramId) {
        if (!!program.enrollmentSessions.find(_enrollmentSession => _enrollmentSession.id === enrollmentSession.id)) {
          return {
            ...program,
            enrollmentSessions: program.enrollmentSessions.map(_enrollmentSession => {
              if (_enrollmentSession.id === enrollmentSession.id) {
                return enrollmentSession;
              }
              return _enrollmentSession;
            }),
            enrollmentMediaAndSessions: program.enrollmentMediaAndSessions.map(enrollmentMediaAndSession => {
              if (enrollmentMediaAndSession.type === 'session' && enrollmentMediaAndSession.id === enrollmentSession.id) {
                return {
                  ...enrollmentSession,
                  type: 'session' as session,
                };
              }
              return enrollmentMediaAndSession;
            }),
          };
        } else {
          return {
            ...program,
            enrollmentSessions: [
              ...program.enrollmentSessions,
              enrollmentSession,
            ],
            enrollmentMediaAndSessions: [
              {
                ...enrollmentSession,
                type: 'session' as session,
              },
              ...program.enrollmentMediaAndSessions,
            ],
          };
        }
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const deleteSession = (programId: string, sessionId: string): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          enrollmentSessions: program.enrollmentSessions.filter(enrollmentSession => enrollmentSession.id !== sessionId),
          enrollmentMediaAndSessions: program.enrollmentMediaAndSessions.filter(enrollmentMediaAndSession => enrollmentMediaAndSession.id !== sessionId),
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const addTimeline = (programId: string, timeline: IEnrollmentTimeline): AppThunk => {
  return (dispatch, getState) => {
    const { programs } = getState();
    return enrollmentTimelineService.create(timeline)
      .then((response) => {
        const data = response.data;
        const allPrograms = programs.programs.map((program) => {
          if (program.id === programId) {
            const micrositeTimelines = program.micrositeTimelines || [];
            return {
              ...program,
              micrositeTimelines: [
                ...micrositeTimelines,
                data
              ]
            };
          }
          return program;
        });
        dispatch(slice.actions.setPrograms({ programs: allPrograms }));
        dispatch(slice.actions.setSelectedTimeline(null));
        return response;
      })
      .catch((error) => {
        return error.error
      });
  }
}

export const addTimelineSlideshow = (programId: string, timeline: any, slideshow: ISlide): AppThunk => {
  return (dispatch, getState) => {
    const { programs } = getState();
    return enrollmentTimelineService.create(timeline)
      .then((response) => {
        const data = response.data;
        return micrositeTimelineSlideService.createMultipleSlideshows({
          providerId: timeline.providerId,
          micrositeTimelineId: data.id,
          slideshows: [
            {
              slideshowId: slideshow.id,
              order: 0
            }
          ],
          isDeleteSlideshows: true
        }).then((response) => {
          const timline = {
            ...data,
            micrositeTimelinesSlideshows: [
              ...data.micrositeTimelinesSlideshows,
              response.data
            ]
          }
          const allPrograms = programs.programs.map((program) => {
            if (program.id === programId) {
              const micrositeTimelines = program.micrositeTimelines || [];
              return {
                ...program,
                micrositeTimelines: [
                  ...micrositeTimelines,
                  timline
                ]
              };
            }
            return program;
          });
          dispatch(slice.actions.setPrograms({ programs: allPrograms }));
          dispatch(slice.actions.setSelectedTimeline(null));
        })
      })
      .catch((error) => {
        return error.error
      });
  }
}

export const updateTimelineSlideshow = (programId: string, timeline: IEnrollmentTimeline, slideshow: ISlide): AppThunk => {
  return (dispatch, getState) => {
    const { programs } = getState();
    return enrollmentTimelineService.update(timeline.id, timeline)
      .then(() => {
        const updateTimeline = (data) => {
          const allPrograms = programs.programs.map((program) => {
            if (program.id === programId) {
              return {
                ...program,
                micrositeTimelines: program.micrositeTimelines.map(_timeline => {
                  if (_timeline.id === timeline.id) {
                    return {
                      ...timeline,
                      micrositeTimelinesSlideshows: (data && timeline.micrositeTimelinesSlideshows.concat(data)) || timeline.micrositeTimelinesSlideshows
                    }
                  }
                  return _timeline;
                })
              };
            }
            return program;
          });
          dispatch(slice.actions.setPrograms({ programs: allPrograms }));
          dispatch(slice.actions.setSelectedTimeline(null));
        }
        if (!isEmpty(slideshow)) {
          const timelineSlideshow = (timeline.micrositeTimelinesSlideshows.length > 0 && timeline.micrositeTimelinesSlideshows[timeline.micrositeTimelinesSlideshows.length - 1]) || null;
          if (!isEmpty(timelineSlideshow)) {
            if (timelineSlideshow.slideshowId !== slideshow.id) {
              micrositeTimelineSlideService.create({
                providerId: timeline.providerId,
                micrositeTimelineId: timeline.id,
                slideshowId: slideshow.id,
                order: 0
              }).then((response) => {
                updateTimeline(response.data)
                return response.data;
              })
            } else {
              updateTimeline(null);
            }
          } else {
            micrositeTimelineSlideService.create({
              providerId: timeline.providerId,
              micrositeTimelineId: timeline.id,
              slideshowId: slideshow.id,
              order: 0
            }).then((response) => {
              updateTimeline(response.data);
              return response.data;
            })
          }
        } else {
          updateTimeline(null);
        }
      })
      .catch((error) => {
        return error.error
      });
  }
}

export const addSlideshowTimeline = (timeline: IEnrollmentTimeline, slideshow: ISlide): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();
    return micrositeTimelineSlideService.create({
      providerId: timeline.providerId,
      micrositeTimelineId: timeline.id,
      slideshowId: slideshow.id,
      order: 0
    }).then((response) => {
      if (response.data) {
        const allPrograms = programs.programs.map((program) => {
          if (program.id === timeline.enrollmentProgramId) {
            return {
              ...program,
              micrositeTimelines: program.micrositeTimelines.map(_timeline => {
                if (_timeline.id === timeline.id) {
                  return {
                    ..._timeline,
                    micrositeTimelinesSlideshows: [
                      ..._timeline.micrositeTimelinesSlideshows,
                      response.data
                    ]
                  }
                }
                return _timeline;
              })
            };
          }
          return program;
        });
        dispatch(slice.actions.setPrograms({ programs: allPrograms }));
      };
    })
  }
}

export const copyTimeline = (selectedItem: any[], timelines: IEnrollmentTimeline[]): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      const currentIndex = selectedItem?.indexOf(program.id) >= 0;
      if (currentIndex) {
        return {
          ...program,
          micrositeTimelines: timelines[program.id]
        };
      }
      return program;
    });
    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const deleteTimeline = (programId: string, timelineId: string): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();
    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          micrositeTimelines: program.micrositeTimelines.filter(micrositeTimeline => micrositeTimeline.id !== timelineId)
        };
      }
      return program;
    });
    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
    return enrollmentTimelineService.delete(timelineId);
  }
}

export const reorderSessions = (programId: string, oldIndex: number, newIndex: number): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program, index) => {

      if (program.id === programId) {
        let enrollmentMediaAndSessions = [...program.enrollmentMediaAndSessions];
        const [removedSessionOrMedia] = enrollmentMediaAndSessions.splice(oldIndex, 1);
        enrollmentMediaAndSessions.splice(newIndex, 0, removedSessionOrMedia);

        const enrollmentMedia = filter(enrollmentMediaAndSessions, (media: any) => !!media?.mediaType);

        return {
          ...program,
          enrollmentMedia,
          enrollmentMediaAndSessions,
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const addClass = (programId: string, sessionId: string, enrollmentClass: IEnrollmentClass): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          enrollmentSessions: program.enrollmentSessions.map(enrollmentSession => {
            if (enrollmentSession.id === sessionId) {
              return {
                ...enrollmentSession,
                enrollmentClasses: [
                  ...enrollmentSession.enrollmentClasses,
                  enrollmentClass,
                ],
              };
            }
            return enrollmentSession;
          }),
          enrollmentMediaAndSessions: program.enrollmentMediaAndSessions.map(enrollmentMediaAndSession => {
            if (enrollmentMediaAndSession.type === 'session' && enrollmentMediaAndSession.id === sessionId) {
              return {
                ...enrollmentMediaAndSession,
                enrollmentClasses: [
                  ...enrollmentMediaAndSession.enrollmentClasses,
                  enrollmentClass,
                ],
              };
            }
            return enrollmentMediaAndSession;
          }),
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const updateClass = (programId: string, sessionId: string, updatedClass: IEnrollmentClass): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          enrollmentSessions: program.enrollmentSessions.map(enrollmentSession => {
            if (enrollmentSession.id === sessionId) {
              return {
                ...enrollmentSession,
                enrollmentClasses: enrollmentSession.enrollmentClasses.map(enrollmentClass => {
                  if (enrollmentClass.id === updatedClass.id) {
                    return updatedClass;
                  }
                  return enrollmentClass;
                }),
              };
            }
            return enrollmentSession;
          }),
          enrollmentMediaAndSessions: program.enrollmentMediaAndSessions.map(enrollmentMediaAndSession => {
            if (enrollmentMediaAndSession.type === 'session' && enrollmentMediaAndSession.id === sessionId) {
              return {
                ...enrollmentMediaAndSession,
                enrollmentClasses: enrollmentMediaAndSession.enrollmentClasses.map(enrollmentClass => {
                  if (enrollmentClass.id === updatedClass.id) {
                    return updatedClass;
                  }
                  return enrollmentClass;
                }),
              };
            }
            return enrollmentMediaAndSession;
          }),
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const deleteClass = (programId: string, sessionId: string, classId: string): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          enrollmentSessions: program.enrollmentSessions.map(enrollmentSession => {
            if (enrollmentSession.id === sessionId) {
              return {
                ...enrollmentSession,
                enrollmentClasses: enrollmentSession.enrollmentClasses.filter(enrollmentClass => enrollmentClass.id !== classId),
              };
            }
            return enrollmentSession;
          }),
          enrollmentMediaAndSessions: program.enrollmentMediaAndSessions.map(enrollmentMediaAndSession => {
            if (enrollmentMediaAndSession.type === 'session' && enrollmentMediaAndSession.id === sessionId) {
              return {
                ...enrollmentMediaAndSession,
                enrollmentClasses: enrollmentMediaAndSession.enrollmentClasses.filter(enrollmentClass => enrollmentClass.id !== classId),
              };
            }
            return enrollmentMediaAndSession;
          }),
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const reorderClasses = (programId: string, sessionId: string, oldIndex: number, newIndex: number): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          enrollmentSessions: program.enrollmentSessions.map(enrollmentSession => {
            if (enrollmentSession.id === sessionId) {
              const enrollmentClasses = [...enrollmentSession.enrollmentClasses];
              const [removedClass] = enrollmentClasses.splice(oldIndex, 1);
              enrollmentClasses.splice(newIndex, 0, removedClass);
              return {
                ...enrollmentSession,
                enrollmentClasses,
              };
            }
            return enrollmentSession;
          }),
          enrollmentMediaAndSessions: program.enrollmentMediaAndSessions.map(enrollmentMediaAndSession => {
            if (enrollmentMediaAndSession.type === 'session' && enrollmentMediaAndSession.id === sessionId) {
              const enrollmentClasses = [...enrollmentMediaAndSession.enrollmentClasses];
              const [removedClass] = enrollmentClasses.splice(oldIndex, 1);
              enrollmentClasses.splice(newIndex, 0, removedClass);
              return {
                ...enrollmentMediaAndSession,
                enrollmentClasses,
              };
            }
            return enrollmentMediaAndSession;
          }),
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const addPlaceholderMedia = (mediaType: MediaTypes, programId: string): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        let newMedia = {
          id: `placeholder-${new Date()}`,
          title: '',
          description: '',
          imageUrl: '',
          textBlockLeft: false,
          enrollmentProgramId: programId,
        };
        switch (mediaType) {
          case MediaTypes.IMAGE:
            break;
          case MediaTypes.TEXT:
            break;
          case MediaTypes.TEXT_IMAGE:
            newMedia = {
              ...newMedia,
              textBlockLeft: true,
            }
            break;
        }
        return {
          ...program,
          enrollmentMediaAndSessions: [
            ...(program.enrollmentMediaAndSessions || []),
            {
              ...newMedia,
              mediaType,
              type: 'media' as media,
            },
          ],
          enrollmentMedia: [
            ...(program.enrollmentMedia || []),
            {
              ...newMedia,
              mediaType,
              type: 'media' as media,
            },
          ],
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const saveMediaItem = (enrollmentMedia: IEnrollmentMedia): AppThunk => {
  return async (dispatch, getState) => {
    let response: IEnrollmentMedia;
    if (!enrollmentMedia.creationTime) {
      response = await enrollmentMediaService.create(enrollmentMedia).then(({ data }) => data);
    } else {
      response = await enrollmentMediaService.update(enrollmentMedia.id, enrollmentMedia).then(({ data }) => data);
    }

    response = {
      ...enrollmentMedia,
      ...pick(response, 'id', 'creationTime'),
    }

    const { programs } = getState();
    const program = cloneDeep(find(programs.programs, { id: response.enrollmentProgramId }));

    const enrollmentMediaIndex = findIndex(program?.enrollmentMedia, { id: enrollmentMedia.id });
    if (enrollmentMediaIndex >= 0) {
      program?.enrollmentMedia.splice(enrollmentMediaIndex, 1, response);
    } else {
      program.enrollmentMedia.push(response);
    }

    const enrollmentMediaAndSessionsIndex = findIndex(program?.enrollmentMediaAndSessions, { id: enrollmentMedia.id });
    if (enrollmentMediaAndSessionsIndex >= 0) {
      program?.enrollmentMediaAndSessions.splice(enrollmentMediaAndSessionsIndex, 1, response);
    } else {
      program.enrollmentMediaAndSessions.push(response);
    }


    const allPrograms = programs.programs.map((item) => {
      if (item.id === program.id) {
        return program;
      }
      return item;
    });


    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const toggleMediaView = (programId: string, mediaId: string): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          enrollmentMediaAndSessions: (program.enrollmentMediaAndSessions && program.enrollmentMediaAndSessions.map(enrollmentMediaAndSession => {
            if (enrollmentMediaAndSession.type === 'media' && enrollmentMediaAndSession.id === mediaId) {
              return {
                ...enrollmentMediaAndSession,
                textBlockLeft: !enrollmentMediaAndSession.textBlockLeft,
              };
            }
            return enrollmentMediaAndSession;
          })) || [],
          enrollmentMedia: (program.enrollmentMedia && program.enrollmentMedia.map(enrollmentMedia => {
            if (enrollmentMedia.id === mediaId) {
              return {
                ...enrollmentMedia,
                textBlockLeft: !enrollmentMedia.textBlockLeft,
              };
            }
            return enrollmentMedia;
          })) || []
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}

export const resizeMediaItem = (programId: string, mediaId: string, leftBlockSize: string): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          enrollmentMediaAndSessions: (program.enrollmentMediaAndSessions && program.enrollmentMediaAndSessions.map(enrollmentMediaAndSession => {
            if (enrollmentMediaAndSession.type === 'media' && enrollmentMediaAndSession.id === mediaId) {
              return {
                ...enrollmentMediaAndSession,
                leftBlockSize: leftBlockSize,
              };
            }
            return enrollmentMediaAndSession;
          })) || [],
          enrollmentMedia: (program.enrollmentMedia && program.enrollmentMedia.map(enrollmentMedia => {
            if (enrollmentMedia.id === mediaId) {
              return {
                ...enrollmentMedia,
                leftBlockSize: leftBlockSize,
              };
            }
            return enrollmentMedia;
          })) || []
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}


export const resizeMediaTemplateItem = (enrollmentMedia: IEnrollmentMedia, newSize?: number[] | string[]): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();

    const programId = enrollmentMedia.enrollmentProgramId;
    const mediaId = enrollmentMedia.id;

    const allPrograms = programs.programs.map((program) => {

      if (program.id === programId) {
        return {
          ...program,
          enrollmentMediaAndSessions: (program.enrollmentMediaAndSessions && program.enrollmentMediaAndSessions.map(enrollmentMediaAndSession => {
            if (enrollmentMediaAndSession.type === 'media' && enrollmentMediaAndSession.id === mediaId) {
              return {
                ...enrollmentMediaAndSession,
                leftBlockSize: newSize[0] + '',
              };
            }
            return enrollmentMediaAndSession;
          })) || [],
          enrollmentMedia: (program.enrollmentMedia && program.enrollmentMedia.map(enrollmentMedia => {
            if (enrollmentMedia.id === mediaId) {
              return {
                ...enrollmentMedia,
                leftBlockSize: newSize[0] + '',
              };
            }
            return enrollmentMedia;
          })) || []
        };
      }
      return program;
    });

    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
  }
}


export const deleteMediaItem = (programId: string, mediaId: string): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();
    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          enrollmentMedia: (program.enrollmentMedia && program.enrollmentMedia.filter(enrollmentMedia => enrollmentMedia.id !== mediaId)) || []
        };
      }
      return program;
    });
    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
    return enrollmentMediaService.delete(mediaId);
  }
}

export const deleteMediaItemGroup = (programId: string, groupId: string): AppThunk => {
  return async (dispatch, getState) => {
    const { programs } = getState();
    const allPrograms = programs.programs.map((program) => {
      if (program.id === programId) {
        return {
          ...program,
          enrollmentMediaGroups: (program.enrollmentMediaGroups && program.enrollmentMediaGroups.filter(group => group.id !== groupId)) || []
        };
      }
      return program;
    });
    dispatch(slice.actions.setPrograms({ programs: allPrograms }));
    return enrollmentMediaGroupService.delete(groupId);
  }
}

export const setHasValuesChanged = (value: boolean): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setHasValuesChanged(value));
  }
}

export const setHasCommonColumn = (value: string): AppThunk => {
  return async (dispatch) => {
    dispatch(slice.actions.setHasCommonColumn(value));
  }
}

export default slice;
