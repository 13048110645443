import React from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet';
// import { UserbackToken } from 'src/config';

// const GA_MEASUREMENT_ID: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID;

const GoogleAnalytics: FC = () => {
  return (
    <Helmet>
      {/* <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </script> */}
      {/* {<script type="text/javascript">
      {`window.Userback = window.Userback || {};
      Userback.access_token = '${UserbackToken}';
      (function(d) {
        var s = d.createElement('script');
        s.async = true;
        s.src = 'https://static.userback.io/widget/v1.js';
        (d.head || d.body).appendChild(s);
      })(document);
      `
      }
    </script>} */}
      
    </Helmet>
  );
};

export default GoogleAnalytics;
