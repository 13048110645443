import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  makeStyles,
  Box,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  // IconButton,
  Paper,
  // InputBase,
  ButtonBase,
  IconButton,
  MenuItem,
  Menu
} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// import { KeyboardTimePicker } from '@material-ui/pickers';
import { Theme } from 'src/theme';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import SelectWithLabel from 'src/pages/tours/ToursView/components/SelectWithLabel';
import { useDispatch, useSelector } from 'src/store';
import {
  AutomationService,
  CalendarEventService,
  ProviderSettingsService
} from 'shared';
import useAuth from 'src/hooks/useAuth';
// import SearchIcon from '@material-ui/icons/Search';
// import MicIcon from '@material-ui/icons/Mic';
import CloseIcon from '@material-ui/icons/Close';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BulbIcon from '../icons/BulbIcon';
// import ToggleSwitch from './ToggleSwitch';
import { useToasts } from '../core/ToastManager';
import { getTourSettings } from 'src/slices/tour';
// import DayButton from './DayButton';
import MessageBox from './MessageBox';
import { getInboxSetting } from 'src/slices/chat';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
// import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { useHistory } from 'react-router-dom';
import Registration from './Registration';
// import { ampli } from 'src/ampli';
import EmailNotification from './EmailNotification';
import moment from 'moment';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import { getAutomation } from 'src/slices/settings';
import { pick } from 'lodash';
import MyCenter from './MyCenter/MyCenter';
import MessageUs from './MessageUs';

export interface SettingsDialogProps {
  isOpen: boolean;
  isAutoRedirectToTab?: boolean;
  onClose?: () => void;
  selectedSettings?: string;
  // tourSettings?: any;
  getTourSettingsInfo?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  paperFullScreen: {
    backgroundColor: '#F4F6F8'
  },
  saveContainer: {
    height: 80,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 80px'
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    gridGap: '16px'
  },
  sidebarHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: '#333333'
  },
  dialogHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#212B36',
    display: 'flex',
    alignItems: 'center'
  },
  dialogHeadingSecondary: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    color: '#37879A',
    display: 'flex',
    alignItems: 'center'
  },
  closeModal: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gridGap: 8
  },
  dialogSubHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center'
  },
  noPadding: {
    padding: '0 !important'
  },
  textContent: {
    fontFamily: '"Roboto", sans-serif',
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  navItem: {
    display: 'flex',
    gridGap: 16,
    color: '#37879A',
    fontSize: 14,
    marginBottom: '4px',
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    padding: theme.spacing(1.5, 2),
    borderRadius: 8,
    background: 'rgba(29, 97, 113, 0.08)',
    cursor: 'pointer'
  },
  navItemUnselected: {
    display: 'flex',
    gridGap: 16,
    color: '#333333',
    fontSize: 14,
    marginBottom: '4px',
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    padding: theme.spacing(1.5, 2),
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      color: '#37879A',
      background: 'rgba(29, 97, 113, 0.08)'
    }
  },
  linkButton: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  iconButton: {
    padding: 10
  },
  navHeading: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#454F5B',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  daysInput: {
    width: 60,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  // searchContainer: {
  //   padding: '2px 4px',
  //   display: 'flex',
  //   alignItems: 'center',
  //   marginBottom: '5px',
  //   boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
  // },
  // searchInput: {
  //   marginLeft: theme.spacing(1),
  //   flex: 1
  // }
  paperContainer: {
    borderRadius: '8px',
    border: '1px solid #C4CDD5',
    boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  primaryText: {
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    fontSize: theme.spacing(2),
    letterSpacing: 0.44,
    lineHeight: '24px',
    color: '#000000',
    marginBottom: theme.spacing(2)
  },
  radioGroupRoot: {
    flexDirection: 'row'
  },
  radioLabelRoot: {
    marginRight: 60
  },
  primaryLabel: {
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    fontSize: theme.spacing(2),
    letterSpacing: 0.44,
    lineHeight: '24px',
    color: theme.palette.text.primary
  },
  secondaryLabel: {
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    letterSpacing: 0.44,
    lineHeight: '24px',
    color: theme.palette.text.primary,
    opacity: 0.75
  },
  radioRoot: {
    color: '#838383'
  },
  colorSecondary: {
    '&$checked': {
      color: theme.palette.third.main
    }
  },
  checked: {},
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    background: theme.palette.common.white,
    border: '2px solid #4E9AAC',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1)
  },
  tooltipText: {
    fontWeight: 400,
    fontFamily: '"Roboto", sans-serif',
    fontSize: 16,
    letterSpacing: 0.44,
    lineHeight: '24px',
    color: theme.palette.text.primary
  },
  tooltipButtonContainer: {
    marginLeft: theme.spacing(6)
  },
  tooltipClose: {
    color: '#838383'
  },
  tooltipCloseIcon: {
    fontSize: 14
  },
  tooltipIconContainer: {
    marginRight: theme.spacing(2)
  },
  tooltipIcon: {
    fontSize: theme.spacing(6)
  },
  itemLink: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.secondary.light
  },
  actionButton: {
    marginRight: theme.spacing(3)
  },
  notificationLink: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: theme.spacing(2)
  },
  clickableItem: {
    width: '100%'
  },
  profileContainer: {
    borderRadius: '8px',
    border: '1px solid #C4CDD5',
    boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
    padding: '16px',
    marginBottom: 16
  },
  textContentHighlight: {
    color: '#3380F3',
    paddingRight: 4
  }
}));

const tourLengthOptions = [
  // { value: 15, label: '15 minutes' },
  { value: 30, label: '30 minutes' },
  // { value: 45, label: '45 minutes' },
  { value: 60, label: '1 hour' }
];

const latestTimeOptions = [
  { value: 120, label: '2 hours' },
  { value: 240, label: '4 hours' },
  { value: 360, label: '6 hours' },
  { value: 1440, label: '1 day' },
  { value: 2880, label: '2 days' },
  { value: 4320, label: '3 days' }
];

// const daysOfWeek = [
//   { title: 'Mon', isChecked: true },
//   { title: 'Tue', isChecked: true },
//   { title: 'Wed', isChecked: true },
//   { title: 'Thu', isChecked: true },
//   { title: 'Fri', isChecked: true },
//   { title: 'Sat', isChecked: false },
//   { title: 'Sun', isChecked: false }
// ];

// const weekOptions = [
//   { value: 28, label: '4 weeks' },
//   { value: 42, label: '6 weeks' },
//   { value: 56, label: '8 weeks' },
//   { value: 84, label: '12 weeks' }
// ];

// const tourGapOptions = [
//   { value: 0, label: 'No tour gap' },
//   { value: 15, label: '15 minutes' }
// ];

const providerSettingsService = ProviderSettingsService.getInstance<
  ProviderSettingsService
>();
const calendarEventService = CalendarEventService.getInstance<
  CalendarEventService
>();
const automationService = AutomationService.getInstance<AutomationService>();

const SettingsDialog: FC<SettingsDialogProps> = ({
  isOpen,
  onClose,
  selectedSettings = null,
  isAutoRedirectToTab,
  getTourSettingsInfo
}) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { addToast } = useToasts();
  const { providerId } = useAuth();
  const location = useLocation();
  const contactIdentitiesFormRef = useRef<any>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const tourMeetingDetails = useSelector(
    state => state.tour.tourMeetingDetails
  );
  const provider = useSelector(state => state.currentUser.provider);
  const automations = useSelector(state => state.settings.automations);
  const featureFlags = useSelector(state => state.currentUser.featureFlags);

  const [tourLength, setTourLength] = useState<number | null>(null);
  const [noOfDays, setNoOfDays] = useState<number | null>(null);
  const [bufferAfterMeeting, setBufferAfterMeeting] = useState<string | null>(
    null
  );
  const [latestTimeBeforeTour, setLatestTimeBeforeTour] = useState<
    string | null
  >(null);
  const [activeSettings, setActiveSettings] = useState<any>('tours');
  const [inboxSettings, setInboxSettings] = useState<any>({
    defaultCreationMessageType: 0,
    inboxLayoutMessageCardType: 0
  });
  const [showTooltip, setTooltip] = useState<boolean>(true);
  const [tourSettings, setTourSettings] = useState<any>(null);
  // const [selectedStartTime, handleStartTimeChange] = useState<any>(null);
  // const [selectedCloseTime, handleCloseTimeChange] = useState<any>(null);
  const [hideActionButtons, setHideActionButtons] = useState<boolean>(false);
  const [providerSettings, setProviderSettings] = useState<any>({
    onlineRegistrations: false,
    registrationPayments: false,
    autoRegistrationApproval: false,
    archivedLeadsDays: null
  });
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [messageUsOpen, setMessageUsOpen] = useState(false);

  const [earlyInterestLeadsAnchorEl, setEarlyInterestLeadsAnchorEl] = useState(
    null
  );
  const handleEarlyInterestLeadsClick = event => {
    setEarlyInterestLeadsAnchorEl(event.currentTarget);
  };
  const handleEarlyInterestLeadsClose = () => {
    setEarlyInterestLeadsAnchorEl(null);
  };

  const [consideringLeadsAnchorEl, setConsideringLeadsAnchorEl] = useState(
    null
  );
  const handleConsideringLeadsClick = event => {
    setConsideringLeadsAnchorEl(event.currentTarget);
  };
  const handleConsideringLeadsClose = () => {
    setConsideringLeadsAnchorEl(null);
  };

  const [automationInfo, setAutomationInfo] = useState(null);

  useEffect(() => {
    if (automations) {
      const filteredList = automations.filter(
        automation => automation.postmarkTemplateAlias
      );
      const automationsObj = {};

      filteredList.forEach(item => {
        automationsObj[item.dealStageName] = item;
      });

      setAutomationInfo(automationsObj);
    }
  }, [automations]);

  const getSettingsInfo = useCallback(async () => {
    // const startOfMonth = moment()
    //   .startOf('month')
    //   .format('YYYY-MM-DD');
    // const endOfMonth = moment()
    //   .endOf('month')
    //   .format('YYYY-MM-DD');

    // const tourDetails = await dispatch(getTourSettings(startOfMonth, endOfMonth));
    setTourSettings(tourMeetingDetails);
  }, [tourMeetingDetails]);

  const handleMessageUsDialogClose = useCallback(() => {
    setMessageUsOpen(false);
    setActiveSettings('tours');
  }, []);

  const getProviderSettings = useCallback(() => {
    if (providerId) {
      providerSettingsService.getByProviderId(providerId).then(response => {
        if (response.data) {
          setProviderSettings(response.data);
        } else {
          setProviderSettings({
            onlineRegistrations: false,
            registrationPayments: false,
            autoRegistrationApproval: false,
            archivedLeadsDays: null
          });
        }
      });
    }
  }, [providerId]);

  const getProviderInboxSetting = useCallback(() => {
    providerSettingsService.getProviderInboxSetting().then(response => {
      setInboxSettings(response.data);
      dispatch(getInboxSetting(response.data));
    });
  }, [dispatch, setInboxSettings]);

  const handleCancel = useCallback(() => {
    onClose();
    setIsDirty(false);
    if (activeSettings === 'registration') {
      getProviderSettings();
    } else if (activeSettings === 'inbox') {
      getProviderInboxSetting();
    }
  }, [onClose, activeSettings, getProviderSettings, getProviderInboxSetting]);

  const handleRegistrationSettingsSave = useCallback(() => {
    if (automationInfo) {
      const considering = automationInfo['Considering'];
      const earlyInterest = automationInfo['Early Interest'];

      const neededKeys = [
        'status',
        'executionDays',
        'providerId',
        'baseType',
        'dealStageName',
        'leadType',
        'postmarkTemplateAlias',
        'dealBaseStatus',
        'type'
      ];

      Promise.all([
        automationService.updateSpecific(
          considering.id,
          pick(considering, neededKeys)
        ),
        automationService.updateSpecific(
          earlyInterest.id,
          pick(earlyInterest, neededKeys)
        )
      ]);
    }

    if (providerSettings.id) {
      providerSettingsService
        .update(providerSettings.id, {
          providerId,
          onlineRegistrations: providerSettings.onlineRegistrations,
          registrationPayments: providerSettings.registrationPayments,
          autoRegistrationApproval: providerSettings.autoRegistrationApproval,
          archivedLeadsDays: providerSettings.archivedLeadsDays
        })
        .then(() => {
          addToast('Lead settings updated successfully.');
        })
        .catch(err => {
          let errors = err.error && err.error.validationErrors;

          if (errors && errors[0].message) {
            addToast(errors[0].message, 'error');
          } else {
            addToast(
              'Some thing went wrong when updating registration settings',
              'error'
            );
          }
        })
        .finally(() => {
          getProviderSettings();
        });
    } else {
      providerSettingsService
        .create({
          providerId,
          onlineRegistrations: providerSettings.onlineRegistrations,
          registrationPayments: providerSettings.registrationPayments,
          autoRegistrationApproval: providerSettings.autoRegistrationApproval,
          archivedLeadsDays: providerSettings.archivedLeadsDays
        })
        .then(() => {
          addToast('Registration settings updated successfully.');
        })
        .catch(err => {
          let errors = err.error && err.error.validationErrors;

          if (errors && errors[0].message) {
            addToast(errors[0].message, 'error');
          } else {
            addToast(
              'Some thing went wrong when updating registration settings',
              'error'
            );
          }
        })
        .finally(() => {
          getProviderSettings();
        });
    }
  }, [
    addToast,
    getProviderSettings,
    providerSettings,
    providerId,
    automationInfo
  ]);

  const handleInboxSettingSave = useCallback(() => {
    providerSettingsService
      .updateProviderInboxSetting(inboxSettings)
      .then(response => {
        setInboxSettings(response.data);
        addToast('Inbox settings updated successfully.');
        dispatch(getInboxSetting(response.data));
      })
      .catch(() => {
        addToast('Some thing went wrong when updating inbox settings', 'error');
      });
  }, [inboxSettings, addToast, dispatch]);

  const handleTourSettingsSave = useCallback(
    (duration, noOfDays, bufferAfterMeeting) => {
      const request = {
        daysInTheFuture: 30,
        lastBookingDaysPriorTour: 1,
        duration,
        // bufferBeforeMeeting: tourSettings.bufferBeforeMeeting,
        // bufferAfterMeeting,
        meetingType: 'Tour',
        mondayHours: tourSettings.mondayHours,
        tuesdayHours: tourSettings.tuesdayHours,
        wednesdayHours: tourSettings.wednesdayHours,
        thursdayHours: tourSettings.thursdayHours,
        fridayHours: tourSettings.fridayHours,
        saturdayHours: tourSettings.saturdayHours,
        sundayHours: tourSettings.sundayHours,
        latestTimePriorBeforeBooking: latestTimeBeforeTour
      };

      if (tourSettings.id) {
        calendarEventService
          .updateEventsSchedule(tourSettings.id, request)
          .then(() => {
            addToast('Tour settings updated successfully.');
          })
          .catch(err => {
            let errors = err.error && err.error.validationErrors;

            if (errors && errors[0].message) {
              addToast(errors[0].message, 'error');
            } else {
              addToast(
                'Some thing went wrong when updating tour schedules.',
                'error'
              );
            }
          })
          .finally(() => {
            if (getTourSettingsInfo) {
              getTourSettingsInfo();
            }
          });
      }
    },
    [tourSettings, addToast, getTourSettingsInfo, latestTimeBeforeTour]
  );

  const handleClickContactIdentities = useCallback(async () => {
    if (contactIdentitiesFormRef.current) {
      await contactIdentitiesFormRef.current.handleSubmit();
    }
  }, []);

  const profileFormRef = useRef<any>(null);

  const handleClickProfileSubmit = useCallback(async () => {
    if (profileFormRef.current) {
      await profileFormRef.current.handleSubmit();
    }
  }, []);

  const handleSettingsSave = useCallback(() => {
    switch (activeSettings) {
      case 'tours':
        handleTourSettingsSave(tourLength, noOfDays, bufferAfterMeeting);
        break;
      case 'inbox':
        handleInboxSettingSave();
        break;
      case 'email-notification':
        handleClickContactIdentities();
        break;
      case 'dashboard':
        handleClickProfileSubmit();
        break;
      default:
        handleRegistrationSettingsSave();
        break;
    }

    setIsDirty(false);
  }, [
    activeSettings,
    bufferAfterMeeting,
    handleClickContactIdentities,
    handleInboxSettingSave,
    handleRegistrationSettingsSave,
    handleTourSettingsSave,
    handleClickProfileSubmit,
    noOfDays,
    tourLength
  ]);

  const handleSettingsChange = useCallback(selectedType => {
    setActiveSettings(selectedType);
  }, []);

  const handleInboxSettingChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event?.target;
      if (name) {
        setInboxSettings(prevState => ({
          ...prevState,
          [name]: Number(value)
        }));
        setIsDirty(true);
      }
    },
    [setInboxSettings, setIsDirty]
  );

  const handleTooltipClose = useCallback(() => {
    setTooltip(false);
    localStorage.setItem('showTooltip', 'true');
  }, [setTooltip]);

  useEffect(() => {
    if (isMountedRef && tourSettings && activeSettings === 'tours') {
      setTourLength(tourSettings.duration);
      setLatestTimeBeforeTour(tourSettings.latestTimePriorBeforeBooking);
      setNoOfDays(tourSettings.daysInTheFuture);
      setBufferAfterMeeting(tourSettings.bufferAfterMeeting);
    }
  }, [tourSettings, isMountedRef, activeSettings]);

  useEffect(() => {
    if (activeSettings === 'registration') {
      // Do nothing
    } else {
      setIsDirty(false);
    }
  }, [activeSettings]);

  useEffect(() => {
    if (isMountedRef && providerId) {
      getProviderSettings();
      getProviderInboxSetting();
    }
  }, [isMountedRef, providerId, getProviderSettings, getProviderInboxSetting]);

  useEffect(() => {
    if (isMountedRef) {
      getSettingsInfo();
      getProviderInboxSetting();
    }
  }, [isMountedRef, getSettingsInfo, getProviderInboxSetting]);

  useEffect(() => {
    if (selectedSettings) {
      setActiveSettings(selectedSettings);
    }
  }, [selectedSettings]);

  useEffect(() => {
    if (selectedSettings) {
      const startOfMonth = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
      const endOfMonth = moment()
        .endOf('month')
        .format('YYYY-MM-DD');

      dispatch(getTourSettings(startOfMonth, endOfMonth));
    }
  }, [selectedSettings, dispatch]);

  // useEffect(() => {
  //   ampli.viewSettings();
  // }, []);

  useEffect(() => {
    if (isAutoRedirectToTab && isOpen) {
      const pathname = location.pathname;
      switch (pathname) {
        case '/app/dashboard':
          setActiveSettings('dashboard');
          break;
        case '/app/sites/my-site':
          setActiveSettings('website');
          break;
        case '/app/inbox':
          setActiveSettings('inbox');
          break;
        case '/app/enroll/leads':
          setActiveSettings('leads');
          break;
        case '/app/enroll/tours':
          setActiveSettings('tours');
          break;
        case '/app/families':
          setActiveSettings('families');
          break;
        case '/app/registration/enrollment':
          setActiveSettings('enrollment');
          break;
        case '/app/registration/registration':
          setActiveSettings('registration');
          break;
        default:
          setActiveSettings('dashboard');
          break;
      }
    }
  }, [isAutoRedirectToTab, isOpen, location]);

  useEffect(() => {
    dispatch(getAutomation());
  }, [dispatch]);

  return (
    <Dialog
      fullScreen
      onClose={handleCancel}
      open={isOpen}
      classes={{ paperFullScreen: classes.paperFullScreen }}
    >
      <DialogContent classes={{ root: classes.noPadding }}>
        <Box width="100%" height="100%" display="flex">
          <Box
            borderRight="1px solid #DFE3E8"
            width="260px"
            position="fixed"
            top={0}
            bottom={0}
            p={2}
            bgcolor="#FFFFFF"
            overflow="auto"
          >
            <Box mb={3}>
              <Typography className={classes.sidebarHeading}>
                Settings
              </Typography>
            </Box>
            {/* <Paper component="form" className={classes.searchContainer}>
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.searchInput}
                placeholder="Search settings"
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="voice"
              >
                <MicIcon />
              </IconButton>
            </Paper> */}
            <Typography className={classes.navHeading}>General</Typography>

            <List component="nav">
              <ListItem
                className={
                  activeSettings === 'dashboard'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
                onClick={() => handleSettingsChange('dashboard')}
              >
                <ButtonBase className={classes.linkButton}>
                  <HomeOutlinedIcon />
                  &nbsp;&nbsp;My Center
                </ButtonBase>
              </ListItem>
            </List>
            <Typography className={classes.navHeading}>
              Parent features
            </Typography>
            <List component="nav">
              {provider?.subscriptionPlan?.toLowerCase() !== 'basic' &&
                provider?.subscriptionPlan?.toLowerCase() !== 'free' &&
                featureFlags &&
                featureFlags['Show-Settings-Website'] && (
                  <ListItem
                    className={
                      activeSettings === 'website'
                        ? classes.navItem
                        : classes.navItemUnselected
                    }
                    onClick={() => handleSettingsChange('website')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <path
                        d="M6.9405 19.25C8.16407 18.6631 9.54215 18.3333 10.9997 18.3333C12.4572 18.3333 13.8353 18.6631 15.0588 19.25M6.23301 15.5833H15.7663C17.3065 15.5833 18.0766 15.5833 18.6648 15.2836C19.1823 15.0199 19.603 14.5993 19.8666 14.0818C20.1663 13.4936 20.1663 12.7235 20.1663 11.1833V7.15C20.1663 5.60986 20.1663 4.83978 19.8666 4.25153C19.603 3.73408 19.1823 3.31338 18.6648 3.04973C18.0766 2.75 17.3065 2.75 15.7663 2.75H6.23301C4.69286 2.75 3.92279 2.75 3.33453 3.04973C2.81709 3.31338 2.39639 3.73408 2.13274 4.25153C1.83301 4.83978 1.83301 5.60986 1.83301 7.15V11.1833C1.83301 12.7235 1.83301 13.4936 2.13274 14.0818C2.39639 14.5993 2.81709 15.0199 3.33453 15.2836C3.92279 15.5833 4.69286 15.5833 6.23301 15.5833Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Website
                  </ListItem>
                )}
              <ListItem
                className={
                  activeSettings === 'email-notification'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
                onClick={() => handleSettingsChange('email-notification')}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="mail-04">
                    <path
                      id="Vector"
                      d="M3.66686 9.53328H2.2002V18.3333C2.2002 19.1433 2.85684 19.8 3.66686 19.8H18.3335C19.1435 19.8 19.8002 19.1433 19.8002 18.3333V9.53328H18.3335M3.66686 9.53328L11.0002 13.9333L18.3335 9.53328M3.66686 9.53328V5.13328C3.66686 3.51325 4.98016 2.19995 6.6002 2.19995H15.4002C17.0202 2.19995 18.3335 3.51325 18.3335 5.13328V9.53328"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                Emails
              </ListItem>
              <ListItem
                className={
                  activeSettings === 'message-us'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
                onClick={() => {
                  handleSettingsChange('message-us');
                  setMessageUsOpen(true);
                }}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="mail-04">
                    <path
                      id="Vector"
                      d="M3.66686 9.53328H2.2002V18.3333C2.2002 19.1433 2.85684 19.8 3.66686 19.8H18.3335C19.1435 19.8 19.8002 19.1433 19.8002 18.3333V9.53328H18.3335M3.66686 9.53328L11.0002 13.9333L18.3335 9.53328M3.66686 9.53328V5.13328C3.66686 3.51325 4.98016 2.19995 6.6002 2.19995H15.4002C17.0202 2.19995 18.3335 3.51325 18.3335 5.13328V9.53328"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                Tours & Message Us
              </ListItem>
            </List>
            <Typography className={classes.navHeading}>
              Portal Features
            </Typography>
            <List component="nav">
              {provider?.subscriptionPlan === 'Premium' && (
                <ListItem
                  className={
                    activeSettings === 'inbox'
                      ? classes.navItem
                      : classes.navItemUnselected
                  }
                  onClick={() => handleSettingsChange('inbox')}
                >
                  <EmailOutlinedIcon />
                  &nbsp;&nbsp;Inbox
                </ListItem>
              )}
              <ListItem
                className={
                  activeSettings === 'leads'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
                onClick={() => handleSettingsChange('leads')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M10.3421 3.16556C10.5534 2.73753 10.659 2.52352 10.8024 2.45514C10.9272 2.39565 11.0722 2.39565 11.1969 2.45514C11.3404 2.52352 11.446 2.73753 11.6573 3.16556L13.6617 7.22636C13.7241 7.35273 13.7553 7.41591 13.8009 7.46496C13.8412 7.5084 13.8896 7.54359 13.9434 7.56859C14.0041 7.59683 14.0738 7.60702 14.2133 7.6274L18.6969 8.28275C19.1691 8.35176 19.4051 8.38627 19.5144 8.50159C19.6094 8.60192 19.6542 8.73979 19.6361 8.87681C19.6153 9.0343 19.4443 9.20077 19.1025 9.5337L15.8593 12.6926C15.7582 12.791 15.7077 12.8403 15.6751 12.8989C15.6462 12.9507 15.6277 13.0077 15.6205 13.0667C15.6124 13.1332 15.6244 13.2028 15.6482 13.3419L16.4135 17.8036C16.4942 18.2742 16.5345 18.5095 16.4587 18.6491C16.3927 18.7706 16.2754 18.8558 16.1395 18.881C15.9832 18.91 15.7719 18.7989 15.3494 18.5766L11.341 16.4687C11.2161 16.403 11.1537 16.3702 11.0879 16.3573C11.0296 16.3459 10.9697 16.3459 10.9115 16.3573C10.8457 16.3702 10.7832 16.403 10.6584 16.4687L6.65002 18.5766C6.22743 18.7989 6.01613 18.91 5.8599 18.881C5.72397 18.8558 5.60667 18.7706 5.54068 18.6491C5.46484 18.5095 5.5052 18.2742 5.58591 17.8036L6.35115 13.3419C6.37501 13.2028 6.38694 13.1332 6.37887 13.0667C6.37172 13.0077 6.35319 12.9507 6.32431 12.8989C6.29169 12.8403 6.24114 12.791 6.14004 12.6926L2.89685 9.5337C2.55503 9.20077 2.38412 9.0343 2.36332 8.87681C2.34523 8.73979 2.38993 8.60192 2.48499 8.50159C2.59424 8.38627 2.83031 8.35176 3.30246 8.28275L7.78613 7.6274C7.92556 7.60702 7.99528 7.59683 8.056 7.56859C8.10976 7.54359 8.15816 7.5084 8.19851 7.46496C8.24409 7.41591 8.27528 7.35273 8.33765 7.22636L10.3421 3.16556Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Leads
              </ListItem>
              <ListItem
                className={
                  activeSettings === 'tours'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
                onClick={() => handleSettingsChange('tours')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M3.55176 7.85715H17.9893M5.41783 1.83337V3.40499M15.9268 1.83337V3.40479M19.0205 6.4048L19.0205 17.1667C19.0205 18.8236 17.6774 20.1667 16.0205 20.1667H5.52051C3.86365 20.1667 2.52051 18.8236 2.52051 17.1667V6.40479C2.52051 4.74794 3.86365 3.40479 5.52051 3.40479H16.0205C17.6774 3.40479 19.0205 4.74794 19.0205 6.4048Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Tours
              </ListItem>
              {/* <ListItem
                className={
                  activeSettings === 'families'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('families')}
                  className={classes.linkButton}
                >
                  <GroupOutlinedIcon />
                  &nbsp;&nbsp;Families
                </ButtonBase>
              </ListItem> */}
              {provider?.subscriptionPlan?.toLowerCase() !== 'basic' &&
                provider?.subscriptionPlan?.toLowerCase() !== 'free' &&
                featureFlags &&
                featureFlags['Show-Settings-Registration'] && (
                  <ListItem
                    className={
                      activeSettings === 'registration'
                        ? classes.navItem
                        : classes.navItemUnselected
                    }
                    onClick={() => handleSettingsChange('registration')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <path
                        d="M9.4418 19.8H5.04179C3.82676 19.8 2.84179 18.815 2.8418 17.6L2.84188 4.40006C2.84189 3.18504 3.82686 2.20007 5.04188 2.20007H14.9421C16.1572 2.20007 17.1421 3.18505 17.1421 4.40007V10.4501M12.7421 16.6834L14.7588 18.7001L19.1588 14.2999M6.69214 6.60007H13.2921M6.69214 9.90007H13.2921M6.69214 13.2001H9.99214"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Registration
                  </ListItem>
                )}
            </List>
            {/* <Typography className={classes.navHeading}>Grow</Typography>
            <List component="nav">
              <ListItem
                className={
                  activeSettings === 'enrollment'
                    ? classes.navItem
                    : classes.navItemUnselected
                }
              >
                <ButtonBase
                  onClick={() => handleSettingsChange('enrollment')}
                  className={classes.linkButton}
                >
                  <img src="static/icons/child.svg" alt="error" />
                  &nbsp;&nbsp;Enrollment
                </ButtonBase>
              </ListItem>
            </List>
            <ListItem
              className={
                activeSettings === 'registration'
                  ? classes.navItem
                  : classes.navItemUnselected
              }
            >
              <ButtonBase
                onClick={() => handleSettingsChange('registration')}
                className={classes.linkButton}
              >
                <img src="static/icons/registration.svg" alt="error" />
                &nbsp;&nbsp;Registration
              </ButtonBase>
            </ListItem> */}
          </Box>
          <Box
            padding="16px 32px"
            width="calc(100% - 260px)"
            marginLeft="260px"
            position="relative"
          >
            {activeSettings === 'dashboard' && (
              <>
                <Box display="flex" gridGap={4} flexDirection="column" mb={2}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.dialogHeading}>
                      Center settings
                    </Typography>
                    <Typography
                      className={classes.closeModal}
                      onClick={onClose}
                    >
                      <CloseIcon />
                      Close
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <MyCenter
                    profileFormRef={profileFormRef}
                    setIsDirty={setIsDirty}
                    isDirty={isDirty}
                  />
                </Box>
              </>
            )}

            {activeSettings === 'website' &&
              provider?.subscriptionPlan?.toLowerCase() !== 'basic' &&
              provider?.subscriptionPlan?.toLowerCase() !== 'free' && (
                <>
                  <Box display="flex" gridGap={4} flexDirection="column" mb={2}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography className={classes.dialogHeading}>
                        Website Settings
                      </Typography>
                      <Typography
                        className={classes.closeModal}
                        onClick={onClose}
                      >
                        <CloseIcon />
                        Close
                      </Typography>
                    </Box>
                  </Box>
                  <Paper className={classes.profileContainer}>
                    <ButtonBase
                      className={classes.clickableItem}
                      onClick={() => {
                        handleCancel();
                        history.push('/app/sites/my-site');
                      }}
                      disableRipple
                      disableTouchRipple
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          width="100%"
                          textAlign="left"
                        >
                          <Typography className={classes.dialogHeading}>
                            Website
                          </Typography>
                          <Typography className={classes.dialogSubHeading}>
                            Customize the UI the parent will see when they go to
                            book a tour
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <ArrowForwardIosIcon
                            className={classes.notificationLink}
                          />
                        </Box>
                      </Box>
                    </ButtonBase>
                  </Paper>
                </>
              )}
            {/* {activeSettings === 'website' && showWebsiteDetails && (
              <>
                <Box display="flex" alignItems="baseline">
                  <IconButton
                    className={classes.actionButton}
                    onClick={() => {
                      setShowWebsiteDetails(false);
                    }}
                  >
                    <ArrowBackIosIcon className={classes.itemLink} />
                  </IconButton>
                  <Typography className={classes.dialogHeading}>
                    Back to Website Settings
                  </Typography>
                </Box>

                <Box
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  <MySiteView />
                </Box>
              </>
            )} */}

            {activeSettings === 'inbox' && (
              <>
                <Box display="flex" gridGap={4} flexDirection="column" mb={2}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.dialogHeading}>
                      Inbox settings
                    </Typography>
                    <Typography
                      className={classes.closeModal}
                      onClick={onClose}
                    >
                      <CloseIcon />
                      Close
                    </Typography>
                  </Box>
                </Box>

                <Paper elevation={0} className={classes.paperContainer}>
                  {showTooltip && !localStorage.getItem('showTooltip') && (
                    <Box className={classes.tooltipContainer}>
                      <Box className={classes.tooltipIconContainer}>
                        <BulbIcon className={classes.tooltipIcon} />
                      </Box>
                      <Typography className={classes.tooltipText}>
                        Did you know, you can override these defaults every time
                        you send a message to give you complete control over how
                        you send a message.
                      </Typography>
                      <Box className={classes.tooltipButtonContainer}>
                        <IconButton
                          className={classes.tooltipClose}
                          onClick={handleTooltipClose}
                        >
                          <CloseIcon className={classes.tooltipCloseIcon} />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                  <Typography variant="h4" className={classes.primaryText}>
                    What is the default way you would like to begin a message
                    you create?
                  </Typography>
                  <RadioGroup
                    name="defaultCreationMessageType"
                    value={inboxSettings?.defaultCreationMessageType}
                    onChange={handleInboxSettingChange}
                    className={classes.radioGroupRoot}
                  >
                    <FormControlLabel
                      className={classes.radioLabelRoot}
                      value={0}
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            colorSecondary: classes.colorSecondary,
                            checked: classes.checked
                          }}
                        />
                      }
                      label={
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Typography
                            className={classes.primaryLabel}
                            component="span"
                          >
                            Email
                          </Typography>
                          <Typography
                            className={classes.secondaryLabel}
                            component="span"
                          >
                            You can add a subject line
                          </Typography>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            colorSecondary: classes.colorSecondary,
                            checked: classes.checked
                          }}
                        />
                      }
                      label={
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Typography
                            className={classes.primaryLabel}
                            component="span"
                          >
                            Chat
                          </Typography>
                          <Typography
                            className={classes.secondaryLabel}
                            component="span"
                          >
                            Will send an email to begin a chat session
                          </Typography>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </Paper>
                <Box mb={2}>
                  <Typography className={classes.dialogHeading}>
                    Inbox layout options:
                  </Typography>
                </Box>
                <Paper elevation={0} className={classes.paperContainer}>
                  <RadioGroup
                    name="inboxLayoutMessageCardType"
                    value={inboxSettings?.inboxLayoutMessageCardType}
                    onChange={handleInboxSettingChange}
                    className={classes.radioGroupRoot}
                  >
                    <Box>
                      <FormControlLabel
                        classes={{
                          root: classes.radioLabelRoot,
                          label: classes.primaryLabel
                        }}
                        value={0}
                        control={
                          <Radio
                            classes={{
                              root: classes.radioRoot,
                              colorSecondary: classes.colorSecondary,
                              checked: classes.checked
                            }}
                          />
                        }
                        label="Large message cards"
                      />
                      <MessageBox largeMessage />
                    </Box>
                    <Box>
                      <FormControlLabel
                        classes={{
                          root: classes.radioLabelRoot,
                          label: classes.primaryLabel
                        }}
                        value={1}
                        control={
                          <Radio
                            classes={{
                              root: classes.radioRoot,
                              colorSecondary: classes.colorSecondary,
                              checked: classes.checked
                            }}
                          />
                        }
                        label="Small message cards"
                      />
                      <MessageBox />
                    </Box>
                  </RadioGroup>
                </Paper>
              </>
            )}

            {activeSettings === 'leads' && (
              <>
                <Box display="flex" gridGap={4} flexDirection="column">
                  <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.dialogHeading}>
                      Lead Settings
                    </Typography>
                    <Typography
                      className={classes.closeModal}
                      onClick={onClose}
                    >
                      <CloseIcon />
                      Close
                    </Typography>
                  </Box>
                  {/* <Typography className={classes.dialogSubHeading}>
                    Customize the settings for your leads
                  </Typography> */}
                </Box>

                <Box
                  mt={2}
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="8px"
                  boxShadow="0px 8px 16px 0px rgba(145, 158, 171, 0.16)"
                  border="1px solid #C4CDD5"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    borderBottom="1px solid #C4CDD5"
                    pb={2}
                  >
                    <Typography className={classes.dialogHeading}>
                      Archive Settings
                    </Typography>
                    <Typography className={classes.dialogSubHeading}>
                      After archiving a lead, permanently delete the lead after{' '}
                      <OutlinedInput
                        className={classes.daysInput}
                        id="outlined-days"
                        value={providerSettings.archivedLeadsDays}
                        onChange={e => {
                          const newValue = e.target?.value || null;
                          setProviderSettings(prevSetting => ({
                            ...prevSetting,
                            archivedLeadsDays: newValue
                          }));
                          setIsDirty(true);
                        }}
                      />{' '}
                      days.
                    </Typography>
                  </Box>
                  <Box
                    py="16px"
                    display="flex"
                    flexDirection="column"
                    gridGap={8}
                  >
                    <Typography className={classes.dialogHeading}>
                      Follow Up Automations
                    </Typography>
                    <Box display="flex" flexDirection="column">
                      <Typography className={classes.dialogHeadingSecondary}>
                        For Early Interest Leads
                      </Typography>
                      <Box pt={1.5}>
                        <Button
                          onClick={handleEarlyInterestLeadsClick}
                          endIcon={<KeyboardArrowDownOutlinedIcon />}
                          variant="contained"
                          disableElevation
                        >
                          {automationInfo?.['Early Interest'].status
                            ? 'Enable follow up automations'
                            : 'Disable follow up automations'}
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={earlyInterestLeadsAnchorEl}
                          keepMounted
                          open={Boolean(earlyInterestLeadsAnchorEl)}
                          onClose={handleEarlyInterestLeadsClose}
                        >
                          <MenuItem
                            onClick={() => {
                              setAutomationInfo(prevState => ({
                                ...prevState,
                                'Early Interest': {
                                  ...prevState['Early Interest'],
                                  status: 0
                                }
                              }));
                              setIsDirty(true);
                              handleEarlyInterestLeadsClose();
                            }}
                          >
                            Disable follow up automations
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setAutomationInfo(prevState => ({
                                ...prevState,
                                'Early Interest': {
                                  ...prevState['Early Interest'],
                                  status: 1
                                }
                              }));
                              setIsDirty(true);
                              handleEarlyInterestLeadsClose();
                            }}
                          >
                            Enable follow up automations
                          </MenuItem>
                        </Menu>
                      </Box>
                      {automationInfo?.['Early Interest'].status === 1 && (
                        <Typography className={classes.dialogSubHeading}>
                          Trigger automation if no communication from an early
                          interest lead for{' '}
                          <OutlinedInput
                            className={classes.daysInput}
                            value={
                              automationInfo?.['Early Interest'].executionDays
                            }
                            onChange={e => {
                              const newValue = e.target?.value || null;

                              setAutomationInfo(prevState => ({
                                ...prevState,
                                'Early Interest': {
                                  ...prevState['Early Interest'],
                                  executionDays: newValue
                                }
                              }));
                              setIsDirty(true);
                            }}
                          />{' '}
                          days.
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Typography className={classes.dialogHeadingSecondary}>
                        For Considering Leads
                      </Typography>
                      <Box pt={1.5}>
                        <Button
                          onClick={handleConsideringLeadsClick}
                          endIcon={<KeyboardArrowDownOutlinedIcon />}
                          variant="contained"
                          disableElevation
                        >
                          {automationInfo?.['Considering'].status
                            ? 'Enable follow up automations'
                            : 'Disable follow up automations'}
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={consideringLeadsAnchorEl}
                          keepMounted
                          open={Boolean(consideringLeadsAnchorEl)}
                          onClose={handleConsideringLeadsClose}
                        >
                          <MenuItem
                            onClick={() => {
                              setAutomationInfo(prevState => ({
                                ...prevState,
                                Considering: {
                                  ...prevState['Considering'],
                                  status: 0
                                }
                              }));
                              setIsDirty(true);
                              handleConsideringLeadsClose();
                            }}
                          >
                            Disable follow up automations
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setAutomationInfo(prevState => ({
                                ...prevState,
                                Considering: {
                                  ...prevState['Considering'],
                                  status: 1
                                }
                              }));
                              setIsDirty(true);
                              handleConsideringLeadsClose();
                            }}
                          >
                            Enable follow up automations
                          </MenuItem>
                        </Menu>
                      </Box>
                      {automationInfo?.['Considering'].status === 1 && (
                        <Typography className={classes.dialogSubHeading}>
                          Trigger automation if no communication from an early
                          interest lead for{' '}
                          <OutlinedInput
                            className={classes.daysInput}
                            value={
                              automationInfo?.['Considering'].executionDays
                            }
                            onChange={e => {
                              const newValue = e.target?.value || null;
                              setAutomationInfo(prevState => ({
                                ...prevState,
                                Considering: {
                                  ...prevState['Considering'],
                                  executionDays: newValue
                                }
                              }));
                              setIsDirty(true);
                            }}
                          />{' '}
                          days.
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {activeSettings === 'tours' && (
              <>
                <Box display="flex" gridGap={4} flexDirection="column">
                  <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.dialogHeading}>
                      Tour Settings
                    </Typography>
                    <Typography
                      className={classes.closeModal}
                      onClick={onClose}
                    >
                      <CloseIcon />
                      Close
                    </Typography>
                  </Box>
                </Box>

                <Box
                  mt={2}
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="8px"
                  boxShadow="0px 8px 16px 0px rgba(145, 158, 171, 0.16)"
                  border="1px solid #C4CDD5"
                >
                  <Box mb={3}>
                    <Typography className={classes.dialogSubHeading}>
                      What is the default length of each tour?
                    </Typography>
                    <SelectWithLabel
                      options={tourLengthOptions}
                      label="Default tour length"
                      id="default-tour-length"
                      value={tourLength}
                      setOnChange={setTourLength}
                      setIsDirty={setIsDirty}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography className={classes.dialogSubHeading}>
                      What is the latest time that a parent can schedule a tour
                      prior to the tour date?
                    </Typography>
                    {/* <Typography className={classes.textContent}>
                      <span className={classes.textContentHighlight}>
                        1 Day
                      </span>{' '}
                      prior to the tour
                    </Typography> */}
                    <SelectWithLabel
                      options={latestTimeOptions}
                      label="Latest time prior before scheduling tour"
                      id="latest-time"
                      value={latestTimeBeforeTour}
                      setOnChange={setLatestTimeBeforeTour}
                      setIsDirty={setIsDirty}
                    />
                  </Box>
                  <Box>
                    <Typography className={classes.dialogSubHeading}>
                      How many weeks out into the future should be available for
                      parents to schedule a tour?
                    </Typography>
                    <Typography className={classes.dialogSubHeading}>
                      <span className={classes.textContentHighlight}>
                        4 Weeks
                      </span>{' '}
                      from today
                    </Typography>
                    {/* <SelectWithLabel
                      options={weekOptions}
                      label="Schedule limit"
                      id="schedule-limit"
                      value={noOfDays}
                      setOnChange={setNoOfDays}
                    /> */}
                  </Box>
                </Box>
              </>
            )}

            {activeSettings === 'families' && (
              <>
                <Box display="flex" gridGap={4} flexDirection="column" mb={2}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.dialogHeading}>
                      Families settings
                    </Typography>
                    <Typography
                      className={classes.closeModal}
                      onClick={onClose}
                    >
                      <CloseIcon />
                      Close
                    </Typography>
                  </Box>
                </Box>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  families
                </Box>
              </>
            )}

            {activeSettings === 'enrollment' && (
              <>
                <Box display="flex" gridGap={4} flexDirection="column" mb={2}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.dialogHeading}>
                      Enrollment settings
                    </Typography>
                    <Typography
                      className={classes.closeModal}
                      onClick={onClose}
                    >
                      <CloseIcon />
                      Close
                    </Typography>
                  </Box>
                </Box>

                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  enrollment
                </Box>
              </>
            )}

            {/* {activeSettings === 'registration' && (
              <>
                <Typography className={classes.dialogHeading}>
                  Registration settings
                </Typography>
                <Box
                  bgcolor="#FFFFFF"
                  p={2}
                  borderRadius="12px"
                  boxShadow="0px 2px 4px rgba(88, 88, 88, 0.25)"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={6.5}
                  >
                    <Typography className={classes.textContent}>
                      Allow parents to register online
                    </Typography>

                    <Box pr={10}>
                      <ToggleSwitch
                        checked={providerSettings.onlineRegistrations}
                        onChange={() => {
                          setProviderSettings(prevSetting => ({
                            ...prevSetting,
                            onlineRegistrations: !prevSetting.onlineRegistrations
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={6.5}
                  >
                    <Typography className={classes.textContent}>
                      Enable payments during registration
                    </Typography>
                    <Box pr={10}>
                      <ToggleSwitch
                        checked={providerSettings.registrationPayments}
                        onChange={() => {
                          setProviderSettings(prevSetting => ({
                            ...prevSetting,
                            registrationPayments: !prevSetting.registrationPayments
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className={classes.textContent}>
                      Automatically approve new registrations
                    </Typography>
                    <Box pr={10}>
                      <ToggleSwitch
                        checked={providerSettings.autoRegistrationApproval}
                        onChange={() => {
                          setProviderSettings(prevSetting => ({
                            ...prevSetting,
                            autoRegistrationApproval: !prevSetting.autoRegistrationApproval
                          }));
                          setIsDirty(true);
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )} */}

            {activeSettings === 'registration' &&
              provider?.subscriptionPlan?.toLowerCase() !== 'basic' &&
              provider?.subscriptionPlan?.toLowerCase() !== 'free' && (
                <>
                  <Box display="flex" gridGap={4} flexDirection="column">
                    <Box display="flex" justifyContent="space-between">
                      <Typography className={classes.dialogHeading}>
                        Registration
                      </Typography>
                      <Typography
                        className={classes.closeModal}
                        onClick={onClose}
                      >
                        <CloseIcon />
                        Close
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={classes.dialogSubHeading}>
                        Manage details related to registration
                      </Typography>
                    </Box>
                  </Box>

                  <Registration />
                </>
              )}

            {activeSettings === 'email-notification' && (
              <EmailNotification
                contactIdentitiesFormRef={contactIdentitiesFormRef}
                setHideActionButtons={setHideActionButtons}
                isDirty={isDirty}
                setIsDirty={setIsDirty}
                onClose={onClose}
              />
            )}
            {activeSettings === 'message-us' && (
              <MessageUs
                isOpen={messageUsOpen}
                onClose={handleMessageUsDialogClose}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      {!hideActionButtons && (
        <DialogActions classes={{ root: classes.noPadding }}>
          <div className={classes.saveContainer}>
            <div className={classes.rightSection}>
              <Button
                onClick={handleSettingsSave}
                color="primary"
                variant="contained"
                size="large"
                disabled={!isDirty}
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                color="default"
                variant="outlined"
                size="large"
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SettingsDialog;
