import React, { useState, FC, ReactNode, useCallback, useEffect } from 'react';
import {
  makeStyles,
  BottomNavigation,
  BottomNavigationAction
} from '@material-ui/core';
// import { useSelector } from 'react-redux';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';

import { Theme } from 'src/theme';
// import NavBarNew from './NavBarNew';
import TopBarNew from './TopBarNew';
// import NotificationDrawer from './TopBarNew/NotificationDrawer';
import HelpDrawer from './TopBarNew/HelpDrawer';
import { useHistory } from 'react-router-dom';

interface HomeLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#FBFBFC',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // paddingLeft: 75,
    paddingTop: 60
  },
  mobileMainWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    }
  },
  mainWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingLeft: 96,
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  menuButton: {
    padding: 0,
    marginRight: theme.spacing(2)
  },
  mobileWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 72,
    paddingLeft: 40,
    paddingRight: 40,
    background: '#F3FAFA',
    boxShadow: '0px 4px 15px rgba(219, 219, 219, 0.25)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  logoWrapper: {
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'nowrap'
    }
  },
  logoContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100%',
    marginRight: theme.spacing(1)
  },
  logoImage: {
    width: 32,
    height: 32,
    borderRadius: '100%'
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  brandName: {
    whiteSpace: 'nowrap',
    fontSize: 20,
    marginRight: 10,
    color: theme.palette.text.primary
  },
  iconButton: {
    padding: 0,
    width: 24,
    height: 24,
    background: '#F3FAFA',
    borderRadius: 4,
    color: theme.palette.text.primary
  },
  button: {
    borderRadius: theme.spacing(1),
    width: '100%'
  },
  logoutLink: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: 400,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  userImageWrapper: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  userImageWrapperTop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25
  },
  username: {
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    fontStyle: 'normal',
    textAlign: 'left',
    fontWeight: 400,
    fontSize: 14
  },
  usernamePopover: {
    textTransform: 'capitalize',
    fontWeight: 400,
    color: theme.palette.text.primary,
    textAlign: 'left',
    paddingLeft: theme.spacing(2)
  },
  userEmail: {
    color: theme.palette.text.primary,
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  userDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center'
  },
  userDetailsContainerBorder: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #DCDCDC'
  },
  userDetailsContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%'
  },
  userDetailsContentWrapperTop: {
    display: 'flex',
    flexDirection: 'column'
  },
  headerActionIcon: {
    width: '100%',
    height: '100%'
  },
  profileImage: {
    borderRadius: '50%',
    height: theme.spacing(5),
    width: theme.spacing(5),
    objectFit: 'cover'
  },
  navItemLink: {
    fontWeight: 400,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  mobileMenuIcons: {
    width: 100,
    justifyContent: 'space-around'
  },
  bottomNavigation: {
    margin: '0 auto',
    display: 'table',
    position: 'fixed',
    bottom: 0
  },
  rootBottomNavigation: {
    width: '100vw'
  }
}));

const PortalLayout: FC<HomeLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  // const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [isMobileApp, setMobileApp] = useState<boolean>(false);
  // const { provider } = useSelector(state => ({
  //   provider: state['currentUser'].provider
  // }));

  const [value, setValue] = React.useState('/app/dashboard');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    history.push(newValue);
  };

  useEffect(() => {
    const isMobileApp = window.navigator.userAgent === 'OneVillage-App';
    setMobileApp(isMobileApp);
  }, []);

  // const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  // const matches = useMediaQuery('(min-width:1366px)');

  // const toggleDrawer = useCallback(() => {
  //   setNotificationOpen(!notificationOpen);
  // }, [notificationOpen]);

  const toggleHelpDrawer = useCallback(() => {
    setHelpOpen(!helpOpen);
  }, [helpOpen]);

  return (
    <>
      <div className={classes.root}>
        {/* <NavBarNew
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        /> */}
        <TopBarNew />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
        {/* <NotificationDrawer open={notificationOpen} onClose={toggleDrawer} /> */}
        <HelpDrawer open={helpOpen} onClose={toggleHelpDrawer} />
      </div>

      {isMobileApp && (
        <div className={classes.bottomNavigation}>
          <BottomNavigation
            value={value}
            onChange={handleChange}
            className={classes.rootBottomNavigation}
          >
            <BottomNavigationAction
              label="Home"
              value="/app/dashboard"
              icon={<HomeOutlinedIcon />}
            />
            <BottomNavigationAction
              label="Index"
              value="/app/inbox"
              icon={<EmailOutlinedIcon />}
            />
            <BottomNavigationAction
              label="Leads"
              value="/app/enroll/leads"
              icon={<PersonOutlineOutlinedIcon />}
            />
            <BottomNavigationAction
              label="Tours"
              value="/app/enroll/tours"
              icon={<DateRangeOutlinedIcon />}
            />
            <BottomNavigationAction
              label="Families"
              value="/app/families"
              icon={<GroupOutlinedIcon />}
            />
          </BottomNavigation>
        </div>
      )}
    </>
  );
};

export default PortalLayout;
