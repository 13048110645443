import React, { FC, useState, useCallback, ChangeEvent, useEffect } from 'react';
import { makeStyles, Box, IconButton, Typography, LinearProgress } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ThumbnailImg from 'src/components/ThumbnailImg';
import LogoCollectionDialog from 'src/components/LogoCollectionDialog';
import { useDispatch } from 'src/store';
import { setLogoCollectionDialog } from 'src/slices/dashboard';
import {
    AfterFileUploadEvent,
    useFileUploads
} from 'src/utils/fileUploads';
import type { Theme } from 'src/theme';

interface ImagePreviewProps {
    field?: any;
    form?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 95
    },
    logoInner: {
        display: 'flex',
        alignItems: 'center'
    },
    logoImage: {
        width: 60,
        height: 60,
        background: '#ecebeb',
        borderRadius: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addButton: {},
    editButton: {
        width: 24,
        height: 24,
        position: 'absolute',
        right: 0,
        padding: 0,
        bottom: 0,
        color: "#2A4C6B",
        border: '1px solid #2A4C6B',
        borderRadius: 64,
        background: theme.palette.common.white,
        '&:hover,&:focus,&:active': {
            background: theme.palette.common.white,
        }
    },
    editButtonIcon: {
        fontSize: theme.spacing(2)
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '100%'
    },
    secondaryText: {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '-0.01em',
        color: theme.palette.blue.main,
        marginTop: theme.spacing(1),
        paddingLeft: 4
    },
    linkText: {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '-0.01em',
        textDecoration: 'underline',
        fontWeight: 500,
        cursor: 'pointer',
        color: theme.palette.secondary.main,
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: 'rgba(255,255,255,0.5)',
        position: 'absolute'
    },
    input: {
        display: 'none',
    }
}));

const ImagePreview: FC<ImagePreviewProps> = ({
    field: { name, value },
    form: { setFieldValue },
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleAfterFileUpload = useCallback((event: AfterFileUploadEvent) => {
        setIsLoading(false);
        if (event.path) {
            setFieldValue(name, event.path[0])
        }
    }, [name, setFieldValue, setIsLoading]);

    const uploader = useFileUploads({
        afterFileUpload: handleAfterFileUpload,
        multiple: false
    });

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setImage(URL.createObjectURL(event.target.files[0]));
        uploader.onUpload([event.target.files[0]]);
    }, [uploader, setIsLoading]);

    const handleLogoCollection = useCallback(() => {
        dispatch(setLogoCollectionDialog(true))
    }, [dispatch]);

    const handleStaticImage = useCallback((name, selectedValue) => {
        setImage(selectedValue);
        setFieldValue(name, selectedValue);
    }, [setImage, setFieldValue]);

    useEffect(() => {
        if (!image) {
            setImage(value);
        }
    }, [image, value, setImage])
    
    return (
        <Box className={classes.logoContainer}>
            <Box className={classes.logoInner}>
                <Box className={classes.logoImage}>
                    {(isLoading &&
                        <Box className={classes.loading}>
                            <Box width="100%">
                                <LinearProgress />
                            </Box>
                        </Box>
                    )}
                    {!image && (
                        <>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id={`${name}-button-file`}
                                type="file"
                                onChange={handleChange}
                            />
                            <label htmlFor={`${name}-button-file`}>
                                <IconButton className={classes.addButton} component="span">
                                    <AddCircleOutlineOutlinedIcon />
                                </IconButton>
                            </label>
                        </>
                    )}
                    {(image && !isLoading) && (
                        <>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id={`${name}-button-file`}
                                type="file"
                                onChange={handleChange}
                            />
                            <label htmlFor={`${name}-button-file`}>
                                <IconButton className={classes.editButton} component="span">
                                    <EditOutlinedIcon className={classes.editButtonIcon} />
                                </IconButton>
                            </label>
                        </>
                    )}
                    {image && (
                        <ThumbnailImg
                            className={classes.image}
                            url={image}
                            alt="logo"
                        />
                    )}
                </Box>
            </Box>
            <Typography component="div" className={classes.secondaryText}>
                Or choose from our{" "}
                <Typography
                    component="span"
                    className={classes.linkText}
                    onClick={handleLogoCollection}
                >
                    free library
                </Typography>
                .
            </Typography>
            <LogoCollectionDialog
                fieldName={name}
                setFieldValue={handleStaticImage}
            />
        </Box>
    )
};

export default ImagePreview;