import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { filter, orderBy } from 'lodash';

import { Theme } from 'src/theme';
import SettingInfoCard from '../SettingInfoCard';

const useStyles = makeStyles((theme: Theme) => ({
  accordionContainer: {
    '& .MuiAccordionSummary-root': {
      backgroundColor: '#DFE3E8'
    },
    '& .MuiCollapse-container': {
      backgroundColor: '#F4F6F8'
    }
  }
}));

const CenterInfoTab = ({
  brandingProfileSettings,
  handleChangeBrandProfile
}) => {
  const classes = useStyles();
  const [basicProfileInfo, setBasicProfileInfo] = useState([]);
  const [onlineProfileInfo, setOnlineProfileInfo] = useState([]);

  useEffect(() => {
    if (brandingProfileSettings?.length > 0) {
      setBasicProfileInfo(
        orderBy(filter(brandingProfileSettings, { group: 'basic-profile' }), [
          'order'
        ])
      );
      setOnlineProfileInfo(
        orderBy(filter(brandingProfileSettings, { group: 'online-profiles' }), [
          'order'
        ])
      );
    }
  }, [brandingProfileSettings]);

  return (
    <Box display="flex" flexDirection="column" gridGap="10px">
      <Accordion className={classes.accordionContainer} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="basic-profile"
          id="basic-profile"
        >
          <Typography>Basic Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%" display="flex" flexDirection="column" gridGap={10}>
            {basicProfileInfo.map(profileInfo => (
              <SettingInfoCard
                key={profileInfo.id}
                title={profileInfo.name}
                value={profileInfo.value}
                isChecked={profileInfo.enabled}
                handleInputChange={e => {
                  handleChangeBrandProfile({
                    id: profileInfo.id,
                    value: e.target.value,
                    enabled: profileInfo.enabled
                  });
                }}
                handleSwitchChange={e => {
                  handleChangeBrandProfile({
                    id: profileInfo.id,
                    value: profileInfo.value,
                    enabled: e.target.checked
                  });
                }}
              />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="online-profiles"
          id="online-profiles"
        >
          <Typography>Online Profiles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%" display="flex" flexDirection="column" gridGap={10}>
            {onlineProfileInfo.map(profileInfo => (
              <SettingInfoCard
                key={profileInfo.id}
                title={profileInfo.name}
                value={profileInfo.value}
                isChecked={profileInfo.enabled}
                handleInputChange={e =>
                  handleChangeBrandProfile({
                    id: profileInfo.id,
                    value: e.target.value,
                    enabled: profileInfo.enabled
                  })
                }
                handleSwitchChange={e =>
                  handleChangeBrandProfile({
                    id: profileInfo.id,
                    value: profileInfo.value,
                    enabled: e.target.checked
                  })
                }
              />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CenterInfoTab;
