import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function BulbIcon(props) {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}>
            <path d="M19.7 39.4137C19.4 37.1137 19.1 34.8137 18.5 32.6137C18 30.7137 15.9 28.8137 15.1 26.8137C13.3 21.8137 14.4 17.5137 18.8 14.6137C23.3 11.7137 29 12.9137 32 17.2137C34.5 20.6137 33.7 25.2137 31.9 28.7137C31.5 29.4137 29.3 31.4137 29 32.6137C28.8 33.7137 28.7 39.0137 27.7 39.1137C25 39.4137 22.1 39.1137 19.4 39.1137C18.6 39.1137 18.6 40.4137 19.4 40.4137C22.1 40.4137 26.8 41.4137 29.2 39.9137C29.5 39.7137 29.5 39.3137 29.6 39.0137C30.4 36.0137 30 33.4137 32.1 30.8137C36.4 25.6137 35.6 17.9137 30.4 13.7137C25.6 9.91373 16.2 12.5137 13.5 17.9137C11.9 21.1137 13 23.9137 14 27.2137C14.4 28.7137 15.1 30.3137 16.1 31.5137C18.1 34.0137 18.1 36.8137 18.6 39.8137C18.6 40.5137 19.8 40.2137 19.7 39.4137Z" fill="#4E9AAC" />
            <path d="M18.6 43.2135C21.9 43.2135 25.3 43.2135 28.6 43.1135C29.4 43.1135 29.4 41.8135 28.6 41.8135C25.3 41.8135 21.9 41.8135 18.6 41.9135C17.8 42.0135 17.8 43.2135 18.6 43.2135Z" fill="#4E9AAC" />
            <path d="M18.3998 47.2141C22.1998 47.2141 25.8998 47.2141 29.6998 47.2141C30.4998 47.2141 30.4998 45.9141 29.6998 45.9141C25.8998 45.9141 22.1998 45.9141 18.3998 45.9141C17.5998 46.0141 17.5998 47.2141 18.3998 47.2141Z" fill="#4E9AAC" />
            <path d="M13.1001 31.2133C12.5001 33.5133 10.8001 35.2133 9.00012 36.7133C8.40012 37.2133 9.30012 38.1133 9.90012 37.6133C11.8001 35.9133 13.7001 34.1133 14.3001 31.5133C14.5001 30.8133 13.3001 30.4133 13.1001 31.2133Z" fill="#4E9AAC" />
            <path d="M10.2 21.7134C7 21.5134 3.8 21.6134 0.6 21.9134C-0.2 22.0134 -0.2 23.2134 0.6 23.2134C3.8 22.9134 6.9 22.8134 10.1 23.0134C11 23.0134 11 21.7134 10.2 21.7134Z" fill="#4E9AAC" />
            <path d="M7.19982 7.81369C9.29982 9.81369 11.3998 11.8137 13.4998 13.8137C14.0998 14.4137 14.9998 13.5137 14.3998 12.9137C12.2998 10.9137 10.1998 8.91369 8.09982 6.91369C7.49982 6.31369 6.59982 7.21369 7.19982 7.81369Z" fill="#4E9AAC" />
            <path d="M23.2999 0.813613C23.8999 3.81361 24.5999 6.81361 24.5999 9.91361C24.5999 10.7136 25.7999 10.7136 25.8999 9.91361C25.8999 6.71361 25.1999 3.61361 24.5999 0.513613C24.3999 -0.386387 23.1999 0.0136128 23.2999 0.813613Z" fill="#4E9AAC" />
            <path d="M34.2998 15.0137C34.7998 14.0137 36.1998 13.3137 36.8998 12.4137C37.6998 11.3137 38.0998 9.91369 39.1998 8.91369C39.7998 8.41369 38.8998 7.51369 38.2998 8.01369C37.2998 8.81369 36.8998 10.0137 36.2998 11.1137C35.4998 12.4137 33.8998 13.0137 33.1998 14.3137C32.8998 15.1137 33.9998 15.8137 34.2998 15.0137Z" fill="#4E9AAC" />
            <path d="M36.8002 25.0135C40.0002 24.4135 43.3002 24.1135 46.5002 24.1135C47.3002 24.1135 47.3002 22.8135 46.5002 22.8135C43.1002 22.8135 39.8002 23.1135 36.4002 23.7135C35.7002 23.9135 36.0002 25.1135 36.8002 25.0135Z" fill="#4E9AAC" />
            <path d="M32.4998 34.0133C34.4998 35.5133 36.8998 36.1133 38.9998 37.4133C39.6998 37.8133 40.2998 36.7133 39.5998 36.3133C37.5998 35.1133 35.1998 34.5133 33.3998 33.1133C32.6998 32.6133 31.7998 33.5133 32.4998 34.0133Z" fill="#4E9AAC" />
            <path d="M26.6002 17.4133C28.7002 19.5133 29.7002 22.3133 29.3002 25.3133C29.2002 26.1133 30.4002 26.4133 30.5002 25.6133C31.0002 22.2133 29.9002 18.9133 27.5002 16.5133C26.9002 15.9133 26.0002 16.8133 26.6002 17.4133Z" fill="#4E9AAC" />
        </SvgIcon>
    );
}