import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { OnboardingStatusService, IOnboardingStatus } from 'shared';

export interface OnBoardingState {
  currentStep: number;
  mainStep: number;
  activeStep: number;
  profileStep: number;
  tourStep: number;
  allSteps: any[];
  stepStatus: IOnboardingStatus;
};

const initialState: OnBoardingState = {
  currentStep: 0,
  mainStep: 0,
  profileStep: 0,
  tourStep: 0,
  activeStep: -1,
  allSteps: [],
  stepStatus: {
    chooseSubscription: false,
    basicInformation: false,
    contact: false,
    programs: false,
    tourSettings: false,
    tourSchedule: false,
    newPrograms: false,
    sections: false,
    classes: false,
    micrositeSettings: false,
    schoolOverview: false,
    isSkipped: false,
    isCompleted: false,
    isCenterFound: false,
    isAggrementsAccepted: false,
    micrositeName: false,
    micrositeLogo: false,
    micrositePhotos: false,
    micrositeAgeRange: false,
    micrositeHours: false,
    micrositeCalendar: false,
    micrositeSchedules: false,
    micrositeDescription: false,
    micrositeSocialMedia: false,
    micrositeSlug: false,
    micrositeChat: false
  }
};

const slice = createSlice({
  name: 'on-boarding',
  initialState,
  reducers: {
    setCurrentStep(state: OnBoardingState, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setMainStep(state: OnBoardingState, action: PayloadAction<number>) {
      state.mainStep = action.payload;
    },
    setActiveStep(state: OnBoardingState, action: PayloadAction<number>) {
      state.activeStep = action.payload;
    },
    setProfileStep(state: OnBoardingState, action: PayloadAction<number>) {
      state.profileStep = action.payload;
    },
    setTourStep(state: OnBoardingState, action: PayloadAction<number>) {
      state.tourStep = action.payload;
    },
    setAllSteps(state: OnBoardingState, action: PayloadAction<any[]>) {
      state.allSteps = action.payload;
    },
    setStepStatus(state: OnBoardingState, action: PayloadAction<IOnboardingStatus>) {
      state.stepStatus = action.payload;
    },
  }
});

export const reducer = slice.reducer;

const onboardingStatusService = OnboardingStatusService.getInstance<OnboardingStatusService>();

export const setCurrentStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setCurrentStep(data));
}


export const setMainStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setMainStep(data));
}


export const setActiveStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setActiveStep(data));
}

export const setProfileStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setProfileStep(data));
}

export const setTourStep = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setTourStep(data));
}


export const setAllSteps = (data): AppThunk => (dispatch) => {
  dispatch(slice.actions.setAllSteps(data));
}

export const getStepStatus = (): AppThunk => (dispatch) => {
  return onboardingStatusService.getStatusByProvider().then(({ data }) => {
    dispatch(slice.actions.setStepStatus(data));
    return data;
  })
}

export const updateStepStatus = (data: Partial<IOnboardingStatus>): AppThunk => (dispatch, getState) => {
  const { onBoarding } = getState()
  return onboardingStatusService.update(onBoarding.stepStatus.id, {
    ...onBoarding.stepStatus,
    ...data,
  }).then(({ data }) => {
    dispatch(slice.actions.setStepStatus(data));
    return data;
  })
}

export default slice;
