import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { SessionService } from 'shared';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'src/store';
import { updateSessionTimeout } from 'src/slices/dashboard';
import { Theme } from 'src/theme';
import CloseIcon from '@material-ui/icons/Close';

const sessionService = SessionService.getInstance<SessionService>();

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    color: theme.palette.blue.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogTitleText: {
    fontSize: 24,
    fontWeight: 700,
    color: '#212B36'
  },
  backdrop: {
    background: 'rgba(0, 0, 0, 0.5)'
  },
  dialogPaper: {
    borderRadius: '12px'
  },
  buttonStyle: { borderRadius: 8 },
  description: { fontSize: 20, color: '#637381' },
  timer: { fontSize: 36, fontWeight: 700 },
  actionRoot: {
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24
  }
}));

const SessionDialog = ({ open, handleClose, handleLogout }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [seconds, setSeconds] = React.useState(300);

  useEffect(() => {
    if (open) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        setSeconds(0);
        dispatch(updateSessionTimeout(null));
        history.go(0);
      }
    } else {
      setSeconds(300);
    }
  }, [seconds, handleLogout, open, history, dispatch]);

  const timeFormatted = s => {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  };

  const handleCloseDialog = useCallback(() => {
    setSeconds(300);
    handleClose();
  }, [handleClose]);

  const handleExtendSession = useCallback(() => {
    sessionService.extendSession().then(resp => {
      dispatch(updateSessionTimeout(null));
    });
    handleCloseDialog();
  }, [handleCloseDialog, dispatch]);

  const handleLogoutNow = useCallback(() => {
    dispatch(updateSessionTimeout(null));
    handleLogout();
    handleCloseDialog();
  }, [dispatch, handleCloseDialog, handleLogout]);

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backdrop
        }
      }}
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={handleExtendSession}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography className={classes.dialogTitleText}>
          You will be logged out soon
        </Typography>
        <IconButton onClick={handleExtendSession}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography className={classes.description}>
            For your security, we log you out automatically when you have not
            been active for a certain period of time.
          </Typography>
        </DialogContentText>
        <span className={classes.timer}>{timeFormatted(seconds)}</span>
      </DialogContent>
      <DialogActions classes={{ root: classes.actionRoot }}>
        <Button
          className={classes.buttonStyle}
          onClick={handleLogoutNow}
          variant="outlined"
        >
          Log out now
        </Button>
        <Button
          className={classes.buttonStyle}
          onClick={handleExtendSession}
          variant="contained"
          color="primary"
          autoFocus
        >
          Continue session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionDialog;
