import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { Theme } from 'src/theme';

const useSelectStyles = makeStyles((theme: Theme) => ({
  root: {},
  formControl: {
    minWidth: 300,
    margin: theme.spacing(1)
  }
}));

export interface SelectWithLabelProps {
  options?: any;
  label?: string;
  id?: string;
  value?: string | number;
  setOnChange?: any;
  setIsDirty?: any;
}

const SelectWithLabel: FC<SelectWithLabelProps> = ({
  options,
  label,
  id,
  value,
  setOnChange,
  setIsDirty
}) => {
  const classes = useSelectStyles();

  const handleChange = useCallback(
    e => {
      setOnChange(e.target.value);
      if (setIsDirty) {
        setIsDirty(true);
      }
    },
    [setOnChange, setIsDirty]
  );

  return (
    <FormControl className={classes.formControl} variant="outlined">
      <InputLabel shrink id={id}>
        {label}
      </InputLabel>
      <Select autoWidth label={label} onChange={handleChange} value={value}>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectWithLabel;
