import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import {
  IMediaFile,
  IAlbum,
  MediaFileService,
  AlbumService,
  LibraryViewEnum,
  ILibraryFilter,
  FileSortByEnum,
  IGetMediaFilesById,
} from 'shared';
import {
  clone,
  cloneDeep,
  findIndex,
} from 'lodash';

export interface MediaState {
  mediaFiles?: IMediaFile[];
  albums?: IAlbum[];
  libraryView: LibraryViewEnum;
  filters?: ILibraryFilter;
  totalFileCount: number;
};

const initialState: MediaState = {
  mediaFiles: null,
  albums: null,
  libraryView: LibraryViewEnum.GRID,
  totalFileCount: 0,
  filters: {
    filesType: 'all',
    orderBy: FileSortByEnum.CREATED,
    order: 'DESC',
  },
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setFilters(state: MediaState, action: PayloadAction<ILibraryFilter>) {
      state.filters = {
        ...state.filters,
        ...action.payload
      };
    },
    setLibraryView(state: MediaState, action: PayloadAction<LibraryViewEnum>) {
      state.libraryView = action.payload;
    },
    setTotalFileCount(state: MediaState, action: PayloadAction<number>) {
      state.totalFileCount = action.payload;
    },
    setMediaFiles(state: MediaState, action: PayloadAction<IMediaFile[]>) {
      state.mediaFiles = action.payload;
    },
    setAlbums(state: MediaState, action: PayloadAction<IAlbum[]>) {
      state.albums = action.payload;
    },
  }
});


const mediaFileService = MediaFileService.getInstance<MediaFileService>();
const albumService = AlbumService.getInstance<AlbumService>();

export const applyLibraryFilters = (filter: ILibraryFilter): AppThunk => (dispatch) => {
  dispatch(slice.actions.setFilters(filter));
}

export const setLibraryView = (view: LibraryViewEnum): AppThunk => (dispatch) => {
  dispatch(slice.actions.setLibraryView(view));
}

export const setFilesInList = (files: IMediaFile[]): AppThunk => (dispatch) => {
  dispatch(slice.actions.setMediaFiles(files));
}

export const getMediaFiles = (request: IGetMediaFilesById = {}, concat = false): AppThunk => (dispatch, getState) => {
  return mediaFileService.getAllByAlbum(request).then(({ data }) => {
    const { mediaFiles } = getState().media;
    let items = [];
    if (concat) {
      items = [...(mediaFiles || []), ...data.items];
    } else {
      items = data.items;
    }
    dispatch(slice.actions.setMediaFiles(items));
    dispatch(slice.actions.setTotalFileCount(data.totalCount));
    return data;
  })
}

export const addMediaFile = (request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().media;
  return mediaFileService.create(request).then(({ data }) => {
    const list = updateMediaFileInList(state, {...data, thumbnail: request.file.objectUrl});
    dispatch(slice.actions.setMediaFiles(list));
    return data;
  })
}

export const updateMediaFile = (id: string, request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().media;
  return mediaFileService.update(id, request).then(({ data }) => {
    const list = updateMediaFileInList(state, data);
    dispatch(slice.actions.setMediaFiles(list));
    return data;
  })
}

export const updateMediaFilesAlbum = (albumId: string, request: IMediaFile): AppThunk => (dispatch, getState) => {
  const state = getState().media;
  return mediaFileService.updateMediaFilesAlbum(albumId, [request.id]).then(({ data }) => {
    const list = updateMediaFileInList(state, { ...request, albumId });
    dispatch(slice.actions.setMediaFiles(list));
    return data;
  })
}


export const deleteMediaFile = (id: string): AppThunk => (dispatch, getState) => {
  const { mediaFiles } = getState().media;
  return mediaFileService.delete(id).then(({ data }) => {
    const index = findIndex(mediaFiles, { id });
    const newMediaFiles = clone(mediaFiles);
    newMediaFiles.splice(index, 1);
    dispatch(slice.actions.setMediaFiles(newMediaFiles));
    return data;
  })
}


export const setAlbumsInList = (albums: IAlbum[]): AppThunk => (dispatch) => {
  dispatch(slice.actions.setAlbums(albums));
}

export const getAlbums = (request: any = {}): AppThunk => (dispatch) => {
  return albumService.getAll(request).then(({ data }) => {
    dispatch(slice.actions.setAlbums(data.items));
    return data;
  })
}

export const addAlbum = (request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().media;
  return albumService.create(request).then(({ data }) => {
    const list = updateAlbumInList(state, data);
    dispatch(slice.actions.setAlbums(list));
    return data;
  })
}

export const updateAlbum = (id: string, request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().media;
  return albumService.update(id, request).then(({ data }) => {
    const list = updateAlbumInList(state, data);
    dispatch(slice.actions.setAlbums(list));
    return data;
  })
}

export const deleteAlbum = (id: string): AppThunk => (dispatch, getState) => {
  const { albums } = getState().media;
  return albumService.delete(id).then(({ data }) => {
    const index = findIndex(albums, { id });
    const newAlbums = clone(albums);
    newAlbums.splice(index, 1);
    dispatch(slice.actions.setAlbums(newAlbums));
    return data;
  })
}

function updateMediaFileInList(state: MediaState, file: IMediaFile) {
  const index = findIndex(state.mediaFiles, { id: file.id });
  let mediaFiles = cloneDeep(state.mediaFiles);
  if (index >= 0) {
    mediaFiles.splice(index, 1, file);
  } else {
    mediaFiles = [file, ...(mediaFiles || [])];
  }
  return mediaFiles;
}

function updateAlbumInList(state: MediaState, file: IMediaFile) {
  const index = findIndex(state.albums, { id: file.id });
  let albums = cloneDeep(state.albums);
  if (index >= 0) {
    albums.splice(index, 1, file);
  } else {
    albums = [file, ...(albums || [])];
  }
  return albums;
}

export const reducer = slice.reducer;

export default slice;
