import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { IFamily, FamilyService, IParent, IChild, ParentService, ChildService } from 'shared';
import { chain, cloneDeep, find, findIndex } from 'lodash';

export interface FamilyState {
  families: IFamily[];
};

const initialState: FamilyState = {
  families: null,
};

const slice = createSlice({
  name: 'families',
  initialState,
  reducers: {
    setFamilies(state: FamilyState, action: PayloadAction<IFamily[]>) {
      state.families = action.payload;
    },
  }
});


const familyService = FamilyService.getInstance<FamilyService>();
const parentService = ParentService.getInstance<ParentService>();
const childService = ChildService.getInstance<ChildService>();

export const setFamilyInList = (family: IFamily): AppThunk => (dispatch, getState) => {
  const { families } = getState().families;
  const newFamilies = families ? [...families.filter(familyItem => familyItem.id !== family.id), family] : [family];
  dispatch(slice.actions.setFamilies(newFamilies));
}

export const getFamilies = (request: any = {}): AppThunk => (dispatch) => {
  return familyService.getAll(request).then(({ data }) => {
    dispatch(slice.actions.setFamilies(data.items));
    return data;
  })
}

export const addFamily = (request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().families;
  return familyService.create(request).then(({ data }) => {
    const list = updateFamilyInList(state, data);
    dispatch(slice.actions.setFamilies(list));
    return data;
  })
}

export const updateFamily = (id: string, request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().families;
  return familyService.update(id, request).then(({ data }) => {
    const list = updateFamilyInList(state, data);
    dispatch(slice.actions.setFamilies(list));
    return data;
  })
}

export const deleteFamily = (id: string, request: any = {}): AppThunk => (dispatch, getState) => {
  const { families } = getState().families;
  return familyService.update(id, request).then(({ data }) => {

    const index = findIndex(families, { id });
    families.splice(index, 1);
    dispatch(slice.actions.setFamilies(families));
    return data;
  })
}


export const addParent = (request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().families;
  console.log('addParent', { request });
  return parentService.create(request).then(({ data }) => {
    const list = addOrUpdateParentInList(state, data);
    dispatch(slice.actions.setFamilies(list));

    console.log('addParent', { data });
    return data;
  })
}

export const updateParent = (id: string, request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().families;
  return parentService.update(id, request).then(({ data }) => {
    const list = addOrUpdateParentInList(state, data);
    dispatch(slice.actions.setFamilies(list));
    return data;
  })
}

export const deleteParent = (id: string): AppThunk => (dispatch, getState) => {
  const state = getState().families;
  return parentService.delete(id).then(({ data }) => {

    const family = chain(state.families).cloneDeep().filter({ parents: [{ id }] }).first().value();
    const index = findIndex(family.parents, { id });
    if (index >= 0) {
      family.parents.splice(index, 1);
    }

    const list = updateFamilyInList(state, family);
    dispatch(slice.actions.setFamilies(list));

    return data;
  })
}


export const addChildren = (request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().families;
  return childService.create(request).then(({ data }) => {
    // dispatch(slice.actions.addOrUpdateChildren(data));
    const list = addOrUpdateChildrenInList(state, data);
    dispatch(slice.actions.setFamilies(list));
    return data;
  })
}

export const updateChildren = (id: string, request: any = {}): AppThunk => (dispatch, getState) => {
  const state = getState().families;
  return childService.update(id, request).then(({ data }) => {
    // dispatch(slice.actions.addOrUpdateChildren(data));
    const list = addOrUpdateChildrenInList(state, data);
    dispatch(slice.actions.setFamilies(list));
    return data;
  })
}

export const deleteChildren = (id: string): AppThunk => (dispatch, getState) => {
  const state = getState().families;
  return childService.delete(id).then(({ data }) => {

    const family = chain(state.families).cloneDeep().filter({ children: [{ id }] }).first().value();
    const index = findIndex(family.children, { id });
    if (index >= 0) {
      family.children.splice(index, 1);
    }
    const list = updateFamilyInList(state, family);
    dispatch(slice.actions.setFamilies(list));
    return data;
  })
}


function updateFamilyInList(state: FamilyState, family: IFamily) {
  const index = findIndex(state.families, { id: family.id });
  const families = cloneDeep(state.families) || [];
  if (index >= 0) {
    families.splice(index, 1, family);
  } else {
    families.push(family);
  }
  return families;
}

function addOrUpdateParentInList(state: FamilyState, parent: IParent) {
  const family = cloneDeep(find(state.families, { id: parent.familyId }));
  const index = findIndex(family.parents, { id: parent.id });

  if (index >= 0) {
    family.parents.splice(index, 1, parent);
  } else {
    family.parents.push(parent);
  }

  if(parent.isPrimaryContact) {
    family.primaryParent = parent;
  }

  return updateFamilyInList(state, family);
}

function addOrUpdateChildrenInList(state: FamilyState, child: IChild) {
  const family = cloneDeep(find(state.families, { id: child.familyId }));
  const index = findIndex(family.children, { id: child.id });
  if (index >= 0) {
    family.children.splice(index, 1, child);
  } else {
    family.children.push(child);
  }
  return updateFamilyInList(state, family);
}

export const reducer = slice.reducer;

export default slice;
