import React, { FC } from 'react';
import { TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { fieldToTextField } from 'formik-material-ui';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      showMask
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
    />
  );
}

const MaskedTextField: FC<any> = props => (
  <TextField
    {...fieldToTextField(props)}
    InputProps={{
      inputComponent: TextMaskCustom
    }}
  />
);

export default MaskedTextField;
