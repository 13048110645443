import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Breadcrumbs,
  Dialog,
  DialogContent,
  Link,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { Theme } from 'src/theme';
import LeadEmailTemplates from '../LeadEmailTemplates';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: '16px 80px !important',
    overflow: 'hidden',
    background: '#F4F6F8',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '16px'
  },
  closeModal: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gridGap: 8
  }
}));

const EmailTemplate = ({
  groupedTemplates,
  toggleTemplateDialog,
  handleEditTemplate,
  selectedTemplate,
  onClose,
  onSettingClick,
  isOpen
}) => {
  const classes = useStyles();

  return (
    <Dialog disableEnforceFocus fullScreen onClose={onClose} open={isOpen}>
      <DialogContent className={classes.dialogContent}>
        <Box display="flex" gridGap={4} flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link
                component="button"
                underline="always"
                color="primary"
                onClick={onSettingClick}
              >
                Settings
              </Link>
              <Link
                component="button"
                underline="always"
                color="primary"
                onClick={onSettingClick}
              >
                Emails
              </Link>
            </Breadcrumbs>
            <Typography className={classes.closeModal} onClick={onClose}>
              <CloseIcon />
              Close
            </Typography>
          </Box>
        </Box>

        <LeadEmailTemplates
          groupedTemplates={groupedTemplates}
          toggleTemplateDialog={toggleTemplateDialog}
          handleEditTemplate={handleEditTemplate}
          selectedTemplate={selectedTemplate}
          onSettingClick={onSettingClick}
          onCloseSettingModal={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EmailTemplate;
