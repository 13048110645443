
import React, { useState, useCallback, FC } from 'react';
import SubscriptionLimitsExceededDialog, { SubscriptionLimitsExceededContext } from "./SubscriptionLimitsExceededDialog";

const SubscriptionLimitsExceededProvider: FC = ({
    children
}) => {
    const [subTitleDialog, setSubTitleDialog] = useState('');
    const [canUpgradeDialog, setCanUpgradeDialog] = useState(null);
    const [resolveReject, setResolveReject] = useState([]);

    const subscriptionLimitsExceededValue = useCallback((options = {}) => {
        return new Promise((resolve, reject) => {
            setSubTitleDialog(options['subTitle']);
            setCanUpgradeDialog(options['canUpgrade']);
            setResolveReject([resolve, reject]);
        });
    }, [setSubTitleDialog, setCanUpgradeDialog, setResolveReject]);


    const handleClose = useCallback(() => {
        setResolveReject([]);
    }, [setResolveReject]);

    return (
        <>
            <SubscriptionLimitsExceededContext.Provider value={subscriptionLimitsExceededValue}>
                {children}
            </SubscriptionLimitsExceededContext.Provider>
            <SubscriptionLimitsExceededDialog
                open={resolveReject.length === 2}
                subTitle={subTitleDialog}
                canUpgrade={canUpgradeDialog}
                resolveReject={resolveReject}
                onClose={handleClose} />
        </>
    );
};

export default SubscriptionLimitsExceededProvider;