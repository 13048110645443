import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  makeStyles,
  Dialog,
  DialogContent,
  Typography,
  Box,
  LinearProgress,
  Backdrop,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import clsx from 'clsx';
import {
  getProviderTemplateLayouts,
  getProviderTemplateLayout,
  setProviderTemplateLayout,
  getProviderBrandingProfileInfo,
  updateProviderBrandingProfileInfo
} from 'src/slices/settings';
import { useDispatch, useSelector } from 'src/store';
import { Theme } from 'src/theme';
import LayoutTab from './LayoutTab';
import CenterInfoTab from './CenterInfoTab';
import SignatureTab from './SignatureTab';
import {
  getSectionTemplates,
  getTemplateContentByType,
  getTemplateSectionByAlias,
  updateProviderTemplateContent
} from 'src/slices/email-template';
import { ProviderTemplateService } from 'shared';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: '16px 80px !important',
    overflow: 'hidden',
    background: '#F4F6F8',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '16px',
    height: '100vh'
  },
  layoutContainer: {
    border: '1px solid #C4CDD5',
    boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
    display: 'flex',
    height: '95%',
    background: '#FFFFFF',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  layoutLeftSection: {
    width: 340,
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  layoutRightSection: {
    flex: 1,
    height: '100%',
    padding: '16px',
    backgroundColor: '#F4F4F4'
  },
  title: {
    color: '#10182',
    fontWeight: 500,
    fontSize: 20,
    padding: theme.spacing(2)
  },
  tabBtn: {
    color: '#667085',
    fontWeight: 500,
    fontSize: 14,
    borderRadius: 6
  },
  selectedTabBtn: {
    backgroundColor: '#F9F5FF',
    color: '#6941C6'
  },
  tabBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gridGap: theme.spacing(1),
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #EAECF0'
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderTop: '1px solid #EAECF0'
  },
  footerBtn: {
    color: '#2A3745',
    fontWeight: 500,
    fontSize: 14,
    borderRadius: theme.spacing(1),
    backgroundColor: 'rgba(145, 158, 171, 0.08)',
    border: '1px solid rgba(145, 158, 171, 0.32)'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  dialogSubHeading: {
    color: '#212B36',
    padding: '8px 16px',
    borderBottom: '1px solid #EAECF0',
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 20
  },
  closeModal: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gridGap: 8
  },
  saveActionButton: {
    display: 'flex',
    alignItems: 'center',
    background: '#37879A',
    color: '#fff',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#52a3b7'
    },
    '&:disabled': {
      background: 'rgba(145, 158, 171, 0.32)',
      color: '#454F5B'
    }
  }
}));

interface SignatureDetail {
  closing: string;
  closingDisabled: boolean;
  closingActive: boolean;
  name: string;
  nameDisabled: boolean;
  nameActive: boolean;
}

interface ProfileDetail {
  full_name: string;
  fullNameDisabled: boolean;
  fullNameActive: boolean;
  role: string;
  roleDisabled: boolean;
  roleActive: boolean;
  phone: string;
  phoneDisabled: boolean;
  phoneActive: boolean;
}

const Loader = () => (
  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
    <Box width="250px">
      <LinearProgress />
    </Box>
  </Box>
);

const providerTemplateService = ProviderTemplateService.getInstance<
  ProviderTemplateService
>();

const LayoutSettings = ({ isOpen, onClose, onEmail }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    layouts,
    selectedLayout,
    isLayoutLoading,
    isUpdatingLayoutLoading,
    brandingProfileInfo
  } = useSelector(state => state.settings);

  const [selectedLayoutHtmlBody, setSelectedLayoutHtmlBody] = useState('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [layoutSetting, setLayoutSettings] = useState({
    selectedLayoutType: '',
    selectedColor: {
      primary_darkest: '#212B36',
      primary_darker: '#454F5B',
      primary_main: '#637381',
      primary_lighter: '#C4CDD5',
      primary_lightest: '#F4F6F8'
    }
  });
  const [brandingProfileSettings, setBrandingProfileSettings] = useState([]);
  const [isDirty, setIsDirty] = useState(false);

  // Signature Tab state
  const [
    closingSignature,
    setClosingSignature
  ] = useState<SignatureDetail | null>({
    closing: '',
    closingActive: false,
    closingDisabled: true,
    name: '',
    nameActive: false,
    nameDisabled: true
  });
  const [contactProfile, setContactProfile] = useState<ProfileDetail | null>({
    full_name: '',
    fullNameActive: false,
    fullNameDisabled: true,
    role: '',
    roleActive: false,
    roleDisabled: true,
    phone: '',
    phoneActive: false,
    phoneDisabled: true
  });
  const [signatureSection, setSignatureSection] = useState(null);
  const [profileSection, setProfileSection] = useState(null);
  const [currentSignatureSection, setCurrentSignatureSection] = useState(null);
  const [currentProfileSection, setCurrentProfileSection] = useState(null);
  // const [emailInfo, setEmailInfo] = useState([]);

  const handleChangeSelectedLayoutType = selectedLayoutType => {
    setLayoutSettings(prevState => ({
      ...prevState,
      selectedLayoutType
    }));
    setIsDirty(true);
  };

  const handleChangeBrandProfile = ({ id, value, enabled }) => {
    const brandDetails = brandingProfileSettings.map(brandDetail => {
      if (brandDetail.id === id) {
        return { ...brandDetail, value, enabled };
      }
      return brandDetail;
    });
    setBrandingProfileSettings(brandDetails);
    setIsDirty(true);
  };

  const handleChangeSelectedLayoutColor = selectedColor => {
    setLayoutSettings(prevState => ({
      ...prevState,
      selectedColor
    }));

    const brandingProfileSettingsDetails = brandingProfileSettings.map(
      brandingProfileSetting => {
        if (brandingProfileSetting.templateVariable === 'primary_main') {
          return {
            ...brandingProfileSetting,
            value: selectedColor['primary_main']
          };
        }
        if (brandingProfileSetting.templateVariable === 'primary_lightest') {
          return {
            ...brandingProfileSetting,
            value: selectedColor['primary_lightest']
          };
        }
        if (brandingProfileSetting.templateVariable === 'primary_lighter') {
          return {
            ...brandingProfileSetting,
            value: selectedColor['primary_lighter']
          };
        }
        if (brandingProfileSetting.templateVariable === 'primary_darker') {
          return {
            ...brandingProfileSetting,
            value: selectedColor['primary_darker']
          };
        }
        if (brandingProfileSetting.templateVariable === 'primary_darkest') {
          return {
            ...brandingProfileSetting,
            value: selectedColor['primary_darkest']
          };
        }
        return brandingProfileSetting;
      }
    );
    setBrandingProfileSettings(brandingProfileSettingsDetails);
    setIsDirty(true);
  };

  const handleSelectTabIndex = activeIndex => {
    setSelectedTabIndex(activeIndex);
  };

  const renderInitValueInsideTemplateVariables = useCallback(() => {
    brandingProfileSettings.forEach(profileInfo => {
      if (profileInfo.group) {
        const profileElements = document.querySelectorAll<HTMLElement>(
          `*[id^=${profileInfo.templateVariable}]`
        );
        if (profileElements.length > 0) {
          profileElements.forEach(profileElement => {
            if (profileInfo.enabled) {
              profileElement.style.display = 'block';
              if (profileInfo.group !== 'online-profiles') {
                profileElement.innerHTML = profileInfo.value;
              }
            } else {
              profileElement.style.display = 'none';
            }
          });
        }
      }
    });
  }, [brandingProfileSettings]);

  const handleSubmitProviderTemplateLayout = useCallback(async () => {
    if (selectedTabIndex === 2) {
      await dispatch(
        getTemplateSectionByAlias({
          htmlBody: signatureSection,
          settingsJson: JSON.stringify({
            closing_signature: {
              closing: closingSignature.closing,
              display_closing:
                closingSignature.closingActive ||
                closingSignature.closingDisabled
                  ? 'block'
                  : 'none',
              name: closingSignature.name,
              display_name:
                closingSignature.nameActive || closingSignature.nameDisabled
                  ? 'block'
                  : 'none'
            }
          }),
          postmarkAlias: null
        })
      ).then(resp => {
        dispatch(
          updateProviderTemplateContent({
            content: resp,
            templateVariable: 'closing_signature',
            settingsJson: JSON.stringify({
              closing_signature: {
                closing: closingSignature.closing,
                display_closing:
                  closingSignature.closingActive ||
                  closingSignature.closingDisabled
                    ? 'block'
                    : 'none',
                name: closingSignature.name,
                display_name:
                  closingSignature.nameActive || closingSignature.nameDisabled
                    ? 'block'
                    : 'none'
              }
            }),
            usageType: 1,
            providerTemplateId: null
          })
        );
      });

      await dispatch(
        getTemplateSectionByAlias({
          htmlBody: profileSection,
          settingsJson: JSON.stringify({
            contact_profile: {
              full_name: contactProfile.full_name,
              display_full_name:
                contactProfile.fullNameActive || contactProfile.fullNameDisabled
                  ? 'block'
                  : 'none',
              role: contactProfile.role,
              display_role:
                contactProfile.roleActive || contactProfile.roleDisabled
                  ? 'block'
                  : 'none',
              phone: contactProfile.phone,
              display_phone:
                contactProfile.phoneActive || contactProfile.phoneDisabled
                  ? 'block'
                  : 'none'
            }
          }),
          postmarkAlias: null
        })
      ).then(resp => {
        dispatch(
          updateProviderTemplateContent({
            content: resp,
            templateVariable: 'contact_profile',
            settingsJson: JSON.stringify({
              contact_profile: {
                full_name: contactProfile.full_name,
                display_full_name:
                  contactProfile.fullNameActive ||
                  contactProfile.fullNameDisabled
                    ? 'block'
                    : 'none',
                role: contactProfile.role,
                display_role:
                  contactProfile.roleActive || contactProfile.roleDisabled
                    ? 'block'
                    : 'none',
                phone: contactProfile.phone,
                display_phone:
                  contactProfile.phoneActive || contactProfile.phoneDisabled
                    ? 'block'
                    : 'none'
              }
            }),
            usageType: 1,
            providerTemplateId: null
          })
        );
      });

      await dispatch(getTemplateContentByType()).then(data => {
        if (data && data.length > 0) {
          data.forEach(item => {
            const settingsJson = JSON.parse(item.settingsJson);
            if (item.templateVariable === 'closing_signature') {
              setClosingSignature({
                ...settingsJson?.closing_signature,
                nameActive:
                  settingsJson?.closing_signature?.display_name === 'block',
                nameDisabled: false,
                closingActive:
                  settingsJson?.closing_signature?.display_closing === 'block',
                closingDisabled: false
              });
              setCurrentSignatureSection(item.content);
            } else if (item.templateVariable === 'contact_profile') {
              setContactProfile({
                ...settingsJson?.contact_profile,
                fullNameActive:
                  settingsJson?.contact_profile?.display_full_name === 'block',
                roleActive:
                  settingsJson?.contact_profile?.display_role === 'block',
                phoneActive:
                  settingsJson?.contact_profile?.display_phone === 'block',
                fullNameDisabled: false,
                roleDisabled: false,
                phoneDisabled: false
              });
              setCurrentProfileSection(item.content);
            }
          });
        } else {
          setClosingSignature({
            closing: '',
            closingActive: false,
            closingDisabled: true,
            name: '',
            nameActive: false,
            nameDisabled: true
          });
          setContactProfile({
            full_name: '',
            fullNameActive: false,
            fullNameDisabled: true,
            role: '',
            roleActive: false,
            roleDisabled: true,
            phone: '',
            phoneActive: false,
            phoneDisabled: true
          });
        }
      });
    }
    if (selectedTabIndex === 0) {
      if (layoutSetting.selectedLayoutType) {
        await dispatch(
          setProviderTemplateLayout(layoutSetting.selectedLayoutType)
        );
      }
    }
    const request = {
      providerId: brandingProfileInfo?.providerId,
      type: 0,
      settings: brandingProfileSettings.map(brandingProfileSetting => ({
        id: brandingProfileSetting.id,
        name: brandingProfileSetting.name,
        value: brandingProfileSetting.value,
        templateVariable: brandingProfileSetting.templateVariable,
        enabled: brandingProfileSetting.enabled,
        order: brandingProfileSetting.order,
        group: brandingProfileSetting.group
      }))
    };
    await dispatch(
      updateProviderBrandingProfileInfo(brandingProfileInfo?.id, request)
    );

    await providerTemplateService.updateTemplateCompleteStatus({
      layoutUpdate: true
    });
    setIsDirty(false);
  }, [
    dispatch,
    selectedTabIndex,
    brandingProfileSettings,
    layoutSetting.selectedLayoutType,
    brandingProfileInfo,
    closingSignature,
    contactProfile,
    profileSection,
    signatureSection
  ]);

  useEffect(() => {
    if (selectedLayout?.htmlBody) {
      let htmlBody = selectedLayout.htmlBody;

      let colors = {
        primary_darkest: '#212B36',
        primary_darker: '#454F5B',
        primary_main: '#637381',
        primary_lighter: '#C4CDD5',
        primary_lightest: '#F4F6F8'
      };

      brandingProfileSettings.forEach(brandingProfileSetting => {
        if (brandingProfileSetting.templateVariable === 'primary_main') {
          htmlBody = htmlBody.replaceAll(
            '{{primary_main}}',
            brandingProfileSetting.value
          );
          colors = { ...colors, primary_main: brandingProfileSetting.value };
        }
        if (brandingProfileSetting.templateVariable === 'primary_lightest') {
          htmlBody = htmlBody.replaceAll(
            '{{primary_lightest}}',
            brandingProfileSetting.value
          );
          colors = {
            ...colors,
            primary_lightest: brandingProfileSetting.value
          };
        }
        if (brandingProfileSetting.templateVariable === 'primary_lighter') {
          htmlBody = htmlBody.replaceAll(
            '{{primary_lighter}}',
            brandingProfileSetting.value
          );
          colors = { ...colors, primary_lighter: brandingProfileSetting.value };
        }
        if (brandingProfileSetting.templateVariable === 'primary_darker') {
          htmlBody = htmlBody.replaceAll(
            '{{primary_darker}}',
            brandingProfileSetting.value
          );
          colors = { ...colors, primary_darker: brandingProfileSetting.value };
        }
        if (brandingProfileSetting.templateVariable === 'primary_darkest') {
          htmlBody = htmlBody.replaceAll(
            '{{primary_darkest}}',
            brandingProfileSetting.value
          );
          colors = { ...colors, primary_darkest: brandingProfileSetting.value };
        }
      });

      htmlBody = htmlBody.replace('{{#secondary_cta}}', '');
      htmlBody = htmlBody.replace('{{/secondary_cta}}', '');

      setLayoutSettings(prevState => ({ ...prevState, selectedColor: colors }));
      setSelectedLayoutHtmlBody(htmlBody);
    }
  }, [selectedLayout, brandingProfileSettings]);

  useEffect(() => {
    if (!isLayoutLoading && selectedLayoutHtmlBody) {
      const postmarkTemplateContent = document.getElementById(
        'postmark_template_content'
      );
      if (postmarkTemplateContent) {
        postmarkTemplateContent.innerHTML = `<div>
            <p>Thanks for your interest in our school.  Your tour is all set, your confirmation details are below. </p>
            <br />
            <p>{{content}}</p>
            <br />
            <p>I look forward to seeing you then.</p>
            <br />
          </div>`;

        renderInitValueInsideTemplateVariables();
      }

      const signatureContent = document.getElementById('closing_signature');
      signatureContent.innerHTML = currentSignatureSection;

      const profileContent = document.getElementById('contact_profile');
      profileContent.innerHTML = currentProfileSection;
    }
  }, [
    selectedLayoutHtmlBody,
    isLayoutLoading,
    renderInitValueInsideTemplateVariables,
    currentProfileSection,
    currentSignatureSection
  ]);

  useEffect(() => {
    if (layoutSetting.selectedLayoutType) {
      dispatch(getProviderTemplateLayout(layoutSetting.selectedLayoutType));
    }
  }, [dispatch, layoutSetting.selectedLayoutType]);

  useEffect(() => {
    if (layouts?.length > 0) {
      const selectedLayout = layouts.find(layout => layout.selected);
      setLayoutSettings(prevState => ({
        ...prevState,
        selectedLayoutType: selectedLayout.alias
      }));
    }
  }, [layouts]);

  useEffect(() => {
    if (brandingProfileInfo?.settings?.length > 0) {
      setBrandingProfileSettings(brandingProfileInfo.settings);
    }
  }, [brandingProfileInfo]);

  useEffect(() => {
    dispatch(getProviderBrandingProfileInfo(0));
    dispatch(getProviderTemplateLayouts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTemplateContentByType()).then(data => {
      if (data && data.length > 0) {
        data.forEach(item => {
          const settingsJson = JSON.parse(item.settingsJson);
          if (item.templateVariable === 'closing_signature') {
            setClosingSignature({
              ...settingsJson?.closing_signature,
              nameActive:
                settingsJson?.closing_signature?.display_name === 'block',
              nameDisabled: false,
              closingActive:
                settingsJson?.closing_signature?.display_closing === 'block',
              closingDisabled: false
            });
            setCurrentSignatureSection(item.content);
          } else if (item.templateVariable === 'contact_profile') {
            setContactProfile({
              ...settingsJson?.contact_profile,
              fullNameActive:
                settingsJson?.contact_profile?.display_full_name === 'block',
              roleActive:
                settingsJson?.contact_profile?.display_role === 'block',
              phoneActive:
                settingsJson?.contact_profile?.display_phone === 'block',
              fullNameDisabled: false,
              roleDisabled: false,
              phoneDisabled: false
            });
            setCurrentProfileSection(item.content);
          }
        });
      } else {
        setClosingSignature({
          closing: '',
          closingActive: false,
          closingDisabled: true,
          name: '',
          nameActive: false,
          nameDisabled: true
        });
        setContactProfile({
          full_name: '',
          fullNameActive: false,
          fullNameDisabled: true,
          role: '',
          roleActive: false,
          roleDisabled: true,
          phone: '',
          phoneActive: false,
          phoneDisabled: true
        });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSectionTemplates()).then(data => {
      if (data) {
        setProfileSection(
          data?.find(item => item.name === 'Contact Profile').contentHTML
        );
        setSignatureSection(
          data?.find(item => item.name === 'Closing Signature').contentHTML
        );
      }
    });
  }, [dispatch]);

  return (
    <Dialog disableEnforceFocus fullScreen onClose={onClose} open={isOpen}>
      <DialogContent className={classes.dialogContent}>
        <Box display="flex" gridGap={4} flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link
                component="button"
                underline="always"
                color="primary"
                onClick={onEmail}
              >
                Settings
              </Link>
              <Link
                component="button"
                underline="always"
                color="primary"
                onClick={onEmail}
              >
                Emails
              </Link>
            </Breadcrumbs>
            <Typography className={classes.closeModal} onClick={onClose}>
              <CloseIcon />
              Close
            </Typography>
          </Box>
        </Box>

        <Box className={classes.layoutContainer}>
          <Box className={classes.layoutLeftSection}>
            <Box display="flex" flexDirection="column" height="100%" flex={1}>
              <Typography className={classes.dialogSubHeading}>
                Layout Templates
              </Typography>
              <Box className={classes.tabBtnContainer}>
                <Button
                  className={clsx(classes.tabBtn, {
                    [classes.selectedTabBtn]: selectedTabIndex === 0
                  })}
                  onClick={() => handleSelectTabIndex(0)}
                >
                  Layout
                </Button>
                <Button
                  className={clsx(classes.tabBtn, {
                    [classes.selectedTabBtn]: selectedTabIndex === 1
                  })}
                  onClick={() => handleSelectTabIndex(1)}
                >
                  Center
                </Button>
                <Button
                  className={clsx(classes.tabBtn, {
                    [classes.selectedTabBtn]: selectedTabIndex === 2
                  })}
                  onClick={() => handleSelectTabIndex(2)}
                >
                  Signature
                </Button>
              </Box>
              <Box
                p={2}
                display="flex"
                flexDirection="column"
                overflow="auto"
                height="calc(100vh - 180px)"
              >
                {selectedTabIndex === 0 && (
                  <LayoutTab
                    layoutSetting={layoutSetting}
                    handleChangeSelectedLayoutType={
                      handleChangeSelectedLayoutType
                    }
                    handleChangeSelectedLayoutColor={
                      handleChangeSelectedLayoutColor
                    }
                  />
                )}
                {selectedTabIndex === 1 && (
                  <CenterInfoTab
                    brandingProfileSettings={brandingProfileSettings}
                    handleChangeBrandProfile={handleChangeBrandProfile}
                  />
                )}
                {selectedTabIndex === 2 && (
                  <SignatureTab
                    closingSignature={closingSignature}
                    setClosingSignature={setClosingSignature}
                    contactProfile={contactProfile}
                    setContactProfile={setContactProfile}
                    setIsDirty={setIsDirty}
                  />
                )}
              </Box>
              <Box className={classes.footerSection}>
                <Button
                  className={classes.footerBtn}
                  onClick={onClose}
                  disabled={isUpdatingLayoutLoading}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.saveActionButton}
                  onClick={handleSubmitProviderTemplateLayout}
                  disabled={!isDirty}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className={classes.layoutRightSection}>
            {isLayoutLoading || !selectedLayoutHtmlBody ? (
              <Loader />
            ) : (
              <Box
                width="100%"
                height="100%"
                overflow="auto"
                dangerouslySetInnerHTML={{
                  __html: selectedLayoutHtmlBody
                }}
              />
            )}
          </Box>
        </Box>
        <Backdrop className={classes.backdrop} open={isUpdatingLayoutLoading}>
          <Loader />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
};

export default LayoutSettings;
