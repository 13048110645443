import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function MailIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 16" fill="none" {...props}>
            <path
                d="M2 0H18C18.5304 0 19.0391 0.210714 19.4142 0.585786C19.7893 0.960859 20 1.46957 20 2V14C20 14.5304 19.7893 15.0391 19.4142 15.4142C19.0391 15.7893 18.5304 16 18 16H2C0.89 16 0 15.1 0 14V2C0 0.89 0.89 0 2 0ZM10 7L18 2H2L10 7ZM2 14H18V4.37L10 9.36L2 4.37V14Z"
            />
        </SvgIcon>
    );
}