// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';

const storageAccountName = process.env.storageresourcename || 'ovmedia'; // Fill string with your Storage resource name

// return list of blobs in container to display
// const getBlobsInContainer = async (containerClient: ContainerClient, containerName: string, userId: string) => {
//   const returnedBlobUrls: string[] = [];

//   // get list of blobs in container
//   // eslint-disable-next-line
//   for await (const blob of containerClient.listBlobsFlat({prefix:userId})) {
//     // if image is public, just construct URL
//     returnedBlobUrls.push(
//       `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
//     );
//   }

//   return returnedBlobUrls;
// }

const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: File,
  userId: string,
  onProgress,
  fileName: string
) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(
    `${userId}/${fileName}`
  );

  // set mimetype as determined from browser with file upload control
  const options = {
    blobHTTPHeaders: { blobContentType: file.type },
    onProgress: onProgress
  };

  // upload file
  await blobClient.uploadBrowserData(file, options);
};

const uploadFileToBlob = async (
  file: File | null,
  containerName: string,
  userId: string,
  sasToken: string,
  onProgress
): Promise<string> => {
  if (!file) return;

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  // get Container - full public read access
  const containerClient: ContainerClient = blobService.getContainerClient(
    containerName
  );

  var timestamp = new Date().getTime();
  const fileName = `${timestamp}-${file.name}`;

  // upload file
  await createBlobInContainer(
    containerClient,
    file,
    userId,
    onProgress,
    fileName
  );

  var fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${userId}/${fileName}`;

  // if (containerName === 'image') {
  //   fileUrl = `https://cdn.onevillage.co/thumbnails/default/${userId}/${fileName}`;
  // }

  return fileUrl;
};

// Test convert to listed data
export const getBlobsInContainerByUserId = async (
  containerName: string,
  userId: string,
  sasToken: string
) => {
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  const containerClient: ContainerClient = blobService.getContainerClient(
    containerName
  );

  const returnedBlobUrls = [];

  for await (const blob of containerClient.listBlobsFlat({ prefix: userId })) {
    // console.error(blob);
    // TODO get data from DB
    const name = blob.name;
    const id = blob.versionId;
    const thumbnailUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`;
    const src = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`;
    const type = containerName;
    let duration = null;
    const title = name.substring(name.indexOf('/') + 1, name.lastIndexOf('.'));

    if (containerName === 'video' || containerName === 'audio') {
      duration = 0; // TODO get real duration
    }

    returnedBlobUrls.push({ id, thumbnailUrl, src, duration, type, title });
  }

  return returnedBlobUrls;
};

export default uploadFileToBlob;
