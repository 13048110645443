import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {

    Box,
    LinearProgress,
    makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';

export interface StripeFormDialogProps {
    className?: string;
    url?: string;
    alt?: string;
    previewStyle?: any;
    skeleton?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
    },
    hidden: {
        display: 'none',
    }
}));

const ThumbnailImg: FC<StripeFormDialogProps> = ({ className, url, alt, previewStyle, skeleton }) => {
    const classes = useStyles();
    const [imgUrl, setImgUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    const reloadImage = useCallback(url => {
        setLoading(true);
        setImgUrl(null);

        setTimeout(() => {
            setImgUrl(url);
        }, 2000);
    }, [setImgUrl, setLoading]);

    useEffect(() => {
        setImgUrl(url);
    }, [setImgUrl, setLoading, url]);

    return (
        <>
            {loading && (
                <>
                    {skeleton ? (
                        <Skeleton
                            className={clsx(className)}
                            variant="rect"
                            width="100%"
                        />
                    ) : (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width={100}
                            maxWidth="100%"
                        >
                            <LinearProgress />
                        </Box>
                    )}
                </>
            )}

            <img
                alt={alt}
                className={clsx((loading && classes.hidden), className)}
                src={imgUrl}
                style={previewStyle}
                onError={(e) => {
                    reloadImage(url);
                }}
                onLoad={() => {
                    setLoading(false);
                }}
            />
        </>
    );
};

export default ThumbnailImg;
