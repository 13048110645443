import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import {
  IEnrollmentClass,
  IEnrollmentSession,
  IEnrollmentClassOption,
  IEnrollmentPrice,
  IEnrollmentRoom,
  IEnrollmentProgramSchedule,
  EnrollmentClassService,
  EnrollmentClassOptionService,
  RoomService,
  PriceService,
  EnrollmentProgramScheduleService,
  EnrollmentSessionService,
} from 'shared';
import { findIndex } from 'lodash';

export interface ProgramsState {
  enrollmentSessions: IEnrollmentSession[];
  enrollmentSchedules: IEnrollmentProgramSchedule[];
  enrollmentClasses: IEnrollmentClass[];
  enrollmentRooms: IEnrollmentRoom[];
  enrollmentPrices: IEnrollmentPrice[];
  enrollmentClassOptions: IEnrollmentClassOption[];
};

const initialState: ProgramsState = {
  enrollmentSessions: null,
  enrollmentSchedules: null,
  enrollmentClasses: null,
  enrollmentRooms: null,
  enrollmentPrices: null,
  enrollmentClassOptions: null,
};


const slice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    setEnrollmentSessions(state: ProgramsState, action: PayloadAction<IEnrollmentSession[]>) {
      state.enrollmentSessions = action.payload;
    },
    setEnrollmentSchedules(state: ProgramsState, action: PayloadAction<IEnrollmentProgramSchedule[]>) {
      state.enrollmentSchedules = action.payload;
    },
    setEnrollmentClasses(state: ProgramsState, action: PayloadAction<IEnrollmentClass[]>) {
      state.enrollmentClasses = action.payload;
    },
    setEnrollmentRooms(state: ProgramsState, action: PayloadAction<IEnrollmentRoom[]>) {
      state.enrollmentRooms = action.payload;
    },
    setEnrollmentPrices(state: ProgramsState, action: PayloadAction<IEnrollmentPrice[]>) {
      state.enrollmentPrices = action.payload;
    },
    setEnrollmentClassOptions(state: ProgramsState, action: PayloadAction<IEnrollmentClassOption[]>) {
      state.enrollmentClassOptions = action.payload;
    },
  }
});

export const reducer = slice.reducer;

const enrollmentSessionService = EnrollmentSessionService.getInstance<EnrollmentSessionService>();
const enrollmentClassService = EnrollmentClassService.getInstance<EnrollmentClassService>();
const enrollmentProgramScheduleService = EnrollmentProgramScheduleService.getInstance<EnrollmentProgramScheduleService>();
const enrollmentClassOptionService = EnrollmentClassOptionService.getInstance<EnrollmentClassOptionService>();
const roomService = RoomService.getInstance<RoomService>();
const priceService = PriceService.getInstance<PriceService>();


export const getEnrollmentSessions = (request: any = {}, forceUpdate = false): AppThunk => async (dispatch, getState) => {
  const { enrollmentSessions } = getState().enrollment;
  if (forceUpdate || !enrollmentSessions) {
    const data = await enrollmentSessionService.getAll(request).then((response) => {
      dispatch(slice.actions.setEnrollmentSessions(response.data.items));
      return response;
    })
    return data;
  } else {
    return enrollmentSessions;
  }
}

export const createEnrollmentSession = (request: Partial<IEnrollmentSession>): AppThunk => (dispatch, getSate) => {
  const { enrollmentSessions } = getSate().enrollment;
  return enrollmentSessionService.create(request).then((response) => {
    dispatch(slice.actions.setEnrollmentSessions([...enrollmentSessions, response.data]));
    return response.data;
  })
}

export const updateEnrollmentSession = (id: string, request: Partial<IEnrollmentSession>): AppThunk => (dispatch, getSate) => {
  const { enrollmentSessions } = getSate().enrollment;
  return enrollmentSessionService.update(id, request).then((response) => {
    const index = findIndex(enrollmentSessions, { id });
    const newEnrollmentSessions = [...enrollmentSessions];
    newEnrollmentSessions.splice(index, 1, response.data);
    dispatch(slice.actions.setEnrollmentSessions(newEnrollmentSessions));
    return response.data;
  })
}

export const deleteEnrollmentSession = (id: string): AppThunk => (dispatch, getSate) => {
  const { enrollmentSessions } = getSate().enrollment;
  return enrollmentSessionService.delete(id).then((response) => {
    const index = findIndex(enrollmentSessions, { id });
    const newEnrollmentSessions = [...enrollmentSessions];
    newEnrollmentSessions.splice(index, 1);
    dispatch(slice.actions.setEnrollmentSessions(newEnrollmentSessions));
    return response.data;
  })
}


/* =======================  Classes ======================== */

export const getEnrollmentClasses = (request: any = {}, forceUpdate = false): AppThunk => async (dispatch, getState) => {
  const { enrollmentClasses } = getState().enrollment;
  if (forceUpdate || !enrollmentClasses) {
    const data = await enrollmentClassService.getAll(request).then((response) => {
      dispatch(slice.actions.setEnrollmentClasses(response.data.items));
      return response;
    })
    return data;
  } else {
    return enrollmentClasses;
  }
}

export const createEnrollmentClass = (request: Partial<IEnrollmentClass>): AppThunk => (dispatch, getSate) => {
  const { enrollmentClasses } = getSate().enrollment;
  return enrollmentClassService.create(request).then((response) => {
    dispatch(slice.actions.setEnrollmentClasses([...enrollmentClasses, response.data]));
    return response.data;
  })
}

export const updateEnrollmentClass = (id: string, request: Partial<IEnrollmentClass>): AppThunk => (dispatch, getSate) => {
  const { enrollmentClasses } = getSate().enrollment;
  return enrollmentClassService.update(id, request).then((response) => {
    const index = findIndex(enrollmentClasses, { id });
    const newEnrollmentClasses = [...enrollmentClasses];
    newEnrollmentClasses.splice(index, 1, response.data);
    dispatch(slice.actions.setEnrollmentClasses(newEnrollmentClasses));
    return response.data;
  })
}

export const deleteEnrollmentClass = (id: string): AppThunk => (dispatch, getSate) => {
  const { enrollmentClasses } = getSate().enrollment;
  return enrollmentClassService.delete(id).then((response) => {
    const index = findIndex(enrollmentClasses, { id });
    const newEnrollmentClasses = [...enrollmentClasses];
    newEnrollmentClasses.splice(index, 1);
    dispatch(slice.actions.setEnrollmentClasses(newEnrollmentClasses));
    return response.data;
  })
}

/* =======================  ClassOptions ======================== */

export const getEnrollmentClassOptions = (request: any = {}, forceUpdate = false): AppThunk => async (dispatch, getState) => {
  const { enrollmentClassOptions } = getState().enrollment;
  if (forceUpdate || !enrollmentClassOptions) {
    const data = await enrollmentClassOptionService.getAll(request).then((response) => {
      dispatch(slice.actions.setEnrollmentClassOptions(response.data.items));
      return response;
    })
    return data;
  } else {
    return enrollmentClassOptions;
  }
}

export const createEnrollmentClassOption = (request: Partial<IEnrollmentClassOption>): AppThunk => (dispatch, getSate) => {
  const { enrollmentClassOptions } = getSate().enrollment;
  return enrollmentClassOptionService.create(request).then((response) => {
    dispatch(slice.actions.setEnrollmentClassOptions([...enrollmentClassOptions, response.data]));
    return response.data;
  })
}

export const updateEnrollmentClassOption = (id: string, request: Partial<IEnrollmentClassOption>): AppThunk => (dispatch, getSate) => {
  const { enrollmentClassOptions } = getSate().enrollment;
  return enrollmentClassOptionService.update(id, request).then((response) => {
    const index = findIndex(enrollmentClassOptions, { id });
    const newEnrollmentClassOptions = [...enrollmentClassOptions];
    newEnrollmentClassOptions.splice(index, 1, response.data);
    dispatch(slice.actions.setEnrollmentClassOptions(newEnrollmentClassOptions));
    return response.data;
  })
}

export const deleteEnrollmentClassOption = (id: string): AppThunk => (dispatch, getSate) => {
  const { enrollmentClassOptions } = getSate().enrollment;
  return enrollmentClassOptionService.delete(id).then((response) => {
    const index = findIndex(enrollmentClassOptions, { id });
    const newEnrollmentClassOptions = [...enrollmentClassOptions];
    newEnrollmentClassOptions.splice(index, 1);
    dispatch(slice.actions.setEnrollmentClassOptions(newEnrollmentClassOptions));
    return response.data;
  })
}



/* =======================  Schedules ======================== */

export const getEnrollmentSchedules = (request: any = {}, forceUpdate = false): AppThunk => async (dispatch, getState) => {
  const { enrollmentSchedules } = getState().enrollment;
  if (forceUpdate || !enrollmentSchedules) {
    const data = await enrollmentProgramScheduleService.getAll(request).then((response) => {
      dispatch(slice.actions.setEnrollmentSchedules(response.data.items));
      return response;
    })
    return data;
  } else {
    return enrollmentSchedules;
  }
}

export const createEnrollmentSchedule = (request: Partial<IEnrollmentProgramSchedule>): AppThunk => (dispatch, getSate) => {
  const { enrollmentSchedules } = getSate().enrollment;
  return enrollmentProgramScheduleService.create(request).then((response) => {
    dispatch(slice.actions.setEnrollmentSchedules([...enrollmentSchedules, response.data]));
    return response.data;
  })
}

export const updateEnrollmentSchedule = (id: string, request: Partial<IEnrollmentProgramSchedule>): AppThunk => (dispatch, getSate) => {
  const { enrollmentSchedules } = getSate().enrollment;
  return enrollmentProgramScheduleService.update(id, request).then((response) => {
    const index = findIndex(enrollmentSchedules, { id });
    const newEnrollmentSchedules = [...enrollmentSchedules];
    newEnrollmentSchedules.splice(index, 1, response.data);
    dispatch(slice.actions.setEnrollmentSchedules(newEnrollmentSchedules));
    return response.data;
  })
}

export const deleteEnrollmentSchedule = (id: string): AppThunk => (dispatch, getSate) => {
  const { enrollmentSchedules } = getSate().enrollment;
  return enrollmentProgramScheduleService.delete(id).then((response) => {
    const index = findIndex(enrollmentSchedules, { id });
    const newEnrollmentSchedules = [...enrollmentSchedules];
    newEnrollmentSchedules.splice(index, 1);
    dispatch(slice.actions.setEnrollmentSchedules(newEnrollmentSchedules));
    return response.data;
  })
}


/* =======================  Rooms ======================== */

export const getEnrollmentRooms = (request: any = {}, forceUpdate = false): AppThunk => async (dispatch, getState) => {
  const { enrollmentRooms } = getState().enrollment;
  if (forceUpdate || !enrollmentRooms) {
    const data = await roomService.getAll(request).then((response) => {
      dispatch(slice.actions.setEnrollmentRooms(response.data.items));
      return response;
    })
    return data;
  } else {
    return enrollmentRooms;
  }
}

export const createEnrollmentRoom = (request: Partial<IEnrollmentRoom>): AppThunk => (dispatch, getSate) => {
  const { enrollmentRooms } = getSate().enrollment;
  return roomService.create(request).then((response) => {
    dispatch(slice.actions.setEnrollmentRooms([...enrollmentRooms, response.data]));
    return response.data;
  })
}

export const updateEnrollmentRoom = (id: string, request: Partial<IEnrollmentRoom>): AppThunk => (dispatch, getSate) => {
  const { enrollmentRooms } = getSate().enrollment;
  return roomService.update(id, request).then((response) => {
    const index = findIndex(enrollmentRooms, { id });
    const newEnrollmentRooms = [...enrollmentRooms];
    newEnrollmentRooms.splice(index, 1, response.data);
    dispatch(slice.actions.setEnrollmentRooms(newEnrollmentRooms));
    return response.data;
  })
}

export const deleteEnrollmentRoom = (id: string): AppThunk => (dispatch, getSate) => {
  const { enrollmentRooms } = getSate().enrollment;
  return roomService.delete(id).then((response) => {
    const index = findIndex(enrollmentRooms, { id });
    const newEnrollmentRooms = [...enrollmentRooms];
    newEnrollmentRooms.splice(index, 1);
    dispatch(slice.actions.setEnrollmentRooms(newEnrollmentRooms));
    return response.data;
  })
}

/* =======================  Prices ======================== */

export const getEnrollmentPrices = (request: any = {}, forceUpdate = false): AppThunk => async (dispatch, getState) => {
  const { enrollmentPrices } = getState().enrollment;
  if (forceUpdate || !enrollmentPrices) {
    const data = await priceService.getAll(request).then((response) => {
      dispatch(slice.actions.setEnrollmentPrices(response.data.items));
      return response;
    })
    return data;
  } else {
    return enrollmentPrices;
  }
}

export const createEnrollmentPrice = (request: Partial<IEnrollmentPrice>): AppThunk => (dispatch, getSate) => {
  const { enrollmentPrices } = getSate().enrollment;
  return priceService.create(request).then((response) => {
    dispatch(slice.actions.setEnrollmentPrices([...enrollmentPrices, response.data]));
    return response.data;
  })
}

export const updateEnrollmentPrice = (id: string, request: Partial<IEnrollmentPrice>): AppThunk => (dispatch, getSate) => {
  const { enrollmentPrices } = getSate().enrollment;
  return priceService.update(id, request).then((response) => {
    const index = findIndex(enrollmentPrices, { id });
    const newEnrollmentPrices = [...enrollmentPrices];
    newEnrollmentPrices.splice(index, 1, response.data);
    dispatch(slice.actions.setEnrollmentPrices(newEnrollmentPrices));
    return response.data;
  })
}

export const deleteEnrollmentPrice = (id: string): AppThunk => (dispatch, getSate) => {
  const { enrollmentPrices } = getSate().enrollment;
  return priceService.delete(id).then((response) => {
    const index = findIndex(enrollmentPrices, { id });
    const newEnrollmentPrices = [...enrollmentPrices];
    newEnrollmentPrices.splice(index, 1);
    dispatch(slice.actions.setEnrollmentPrices(newEnrollmentPrices));
    return response.data;
  })
}


export default slice;
