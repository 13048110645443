import React, {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'src/store';
import { apiConfig } from 'src/config';
import { HubConnectionBuilder } from '@microsoft/signalr';
import {
  pushNotification,
  updateProviderSummary,
  updateSessionTimeout
} from 'src/slices/dashboard';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { pushMessageOnThread } from 'src/slices/chat';
import useAuth from 'src/hooks/useAuth';
import { updateUserSubscription } from 'src/slices/current-user';
// import { UserProviderService } from 'shared';

interface SignalRProviderProps {
  children?: ReactNode;
}

// const userProviderService = UserProviderService.getInstance<
//   UserProviderService
// >();

const SignalRContext = createContext(null);

export const SignalRProvider: FC<SignalRProviderProps> = ({ children }) => {
  const isMountedRef = useIsMountedRef();
  const [connection, setConnection] = useState<any>(null);
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    async function initConnection() {
      if (isMountedRef && user?.profile?.sub) {
        const apiUrl = apiConfig.apiUrl.endsWith('/')
          ? apiConfig.apiUrl
          : `${apiConfig.apiUrl}/`;
        const newConnection = new HubConnectionBuilder()
          .withUrl(`${apiUrl}hubs/notification?userId=${user?.profile?.sub}`)
          .withAutomaticReconnect()
          .build();
        setConnection(newConnection);
      }
    }
    initConnection();
  }, [isMountedRef, user]);

  useEffect(() => {
    if (connection) {
      // userProviderService.getCurrentProvider().then(({ data }) => {
      connection
        .start()
        .then(() => {
          console.log('Notification Connected!');
          connection.on('ReceiveNotification', message => {
            console.log('ReceiveNotification', message);
            dispatch(pushNotification(message));
          });

          connection.on('ReceiveMessage', message => {
            console.log('ReceiveMessage', message);
            dispatch(pushMessageOnThread(message));
          });

          connection.on('ReceiveTourSummary', message => {
            dispatch(updateProviderSummary(message));
            console.log('ReceiveTourSummary', message);
          });

          connection.on('ReceiveCompleteSubscription', message => {
            dispatch(updateUserSubscription(message));
            console.log('ReceiveCompleteSubscription', message);
          });

          connection.on('ReceiveSessionTimeout', message => {
            console.log('ReceiveSessionTimeout', message);
            dispatch(updateSessionTimeout(message));
          });
        })
        .catch(e => console.log('Connection failed: ', e));
      // });
    }
  }, [connection, dispatch]);

  return (
    <SignalRContext.Provider value={{}}>{children}</SignalRContext.Provider>
  );
};

export const SignalRContextConsumer = SignalRContext.Consumer;

export default SignalRProvider;
