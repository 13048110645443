import React, { useCallback } from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import { Box, IconButton, makeStyles, MobileStepper } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { Theme } from 'src/theme';
import SWSwipeableViews from 'react-swipeable-views';

export interface SwipeableViewsProps {
  className?: any;
  hasNav?: boolean;
  hasStepper?: boolean;
  totalPanels?: number;
  action?: () => void,
  animateHeight?: boolean,
  animateTransitions?: boolean,
  axis?: 'x' | 'x-reverse' | 'y' | 'y-reverse',
  children?: any,
  containerStyle?: object,
  disabled?: boolean,
  disableLazyLoading?: boolean,
  enableMouseEvents?: boolean,
  hysteresis?: number,
  ignoreNativeScroll?: boolean,
  index?: number,
  onChangeIndex?: (index: number, indexLatest?: number, meta?: any) => void,
  onMouseDown?: () => void,
  onMouseLeave?: () => void,
  onMouseMove?: () => void,
  onMouseUp?: () => void,
  onScroll?: () => void,
  onSwitching?: () => void,
  onTouchEnd?: () => void,
  onTouchMove?: () => void,
  onTouchStart?: () => void,
  onTransitionEnd?: () => void,
  resistance?: boolean,
  slideClassName?: string,
  slideStyle?: any,
  springConfig?: {
    delay?: any,
    duration?: any,
    easeFunction?: any,
  },
  style?: any,
  threshold?: number,
}

export interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  className?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  scrollButtonContainer: {
    position: 'absolute',
    top: '50%',
    border: `2px solid`,
    color: theme.palette.third[300],
  },
  leftScrollButtonContainer: {
    left: 0,
    transform: 'translate(-100%, -50%)',
    marginLeft: theme.spacing(-1),
  },
  rightScrollButtonContainer: {
    right: 0,
    transform: 'translate(100%, -50%)',
    marginRight: theme.spacing(-1),
  },
  mobileStepper: {
    justifyContent: 'center',
    background: 'transparent'
  },
  mobileStepperDot: {
    margin: '0 5px',
    width: '20px',
    height: '20px',
    border: `2px solid ${theme.palette.grey[300]}`,
    background: theme.palette.grey[300],
  },
  mobileStepperDotActive:{
    background: theme.palette.background.paper,
  }
}));


export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      p={2}
      hidden={value !== index}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}


const SwipeableViews: FC<SwipeableViewsProps> = ({
  className,
  children,
  totalPanels,
  hasNav,
  hasStepper,
  index,
  onChangeIndex,
  ...props
}) => {
  const classes = useStyles();

  const handleScroll = useCallback(
    (dir: 'right' | 'left') => () => {
      if (dir === 'left') {
        onChangeIndex && onChangeIndex(index, Math.max(index - 1, 0));
      } else {
        let nextValue = index + 1;
        if (totalPanels) {
          nextValue = Math.min(nextValue, totalPanels);
        }
        onChangeIndex && onChangeIndex(index, nextValue);
      }
    },
    [onChangeIndex, index, totalPanels],
  )

  return (
    <Box
      className={className}
    >
      <Box
        position="relative"
      >
        <SWSwipeableViews
          index={index}
          onChangeIndex={onChangeIndex}
          {...props}
        >
          {children}
        </SWSwipeableViews>

        {hasNav && (
          <>
            <IconButton
              size="small"
              disabled={index === 0}
              onClick={handleScroll('left')}
              className={clsx(classes.scrollButtonContainer, classes.leftScrollButtonContainer)}
            >
              <ArrowBack />
            </IconButton>


            <IconButton
              size="small"
              disabled={index === totalPanels - 1}
              onClick={handleScroll('right')}
              className={clsx(classes.scrollButtonContainer, classes.rightScrollButtonContainer)}
            >
              <ArrowForward />
            </IconButton>
          </>
        )}
      </Box>

      {hasStepper && (
        <Box mt={2}>
          <MobileStepper
            classes={{
              root: classes.mobileStepper,
              dot: classes.mobileStepperDot,
              dotActive: classes.mobileStepperDotActive,
            }}
            variant="dots"
            steps={totalPanels}
            position="static"
            activeStep={index}
            nextButton={null}
            backButton={null}
          />
        </Box>
      )}

    </Box>
  );
}

export default SwipeableViews;
