import React, { FC, useCallback, createContext } from 'react';
import {
  makeStyles,
  Button,
  ButtonProps,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import { Theme } from 'src/theme';
import DefaultDialog, { DefaultDialogProps } from './Dialog';

export const ConfirmContext = createContext(null);

export interface ConfirmDialogProps extends DefaultDialogProps {
  description?: string;
  yesText?: string;
  noText?: string;
  yesButtonProps?: ButtonProps;
  noButtonProps?: ButtonProps;
  resolveReject?: any[];
}

export const defaultProps = {
  title: 'Confirm',
  description: '',
  yesText: 'Yes',
  noText: 'No',
  dialogProps: {},
  yesButtonProps: {},
  noButtonProps: {}
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialogContentText: {
    fontSize: 18,
    lineHeight: '28px',
    maxWidth: 573,
    color: theme.palette.text.primary
  },
  actionButton: {
    width: '100%',
    maxWidth: 176,
    minHeight: 48,
    padding: 0,
    fontSize: theme.spacing(2),
    letterSpacing: '0.02em',
    fontWeight: 'bold',
    boxShadow: '0px 8px 16px rgba(221, 132, 89, 0.1)'
  }
}));

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  noButtonProps,
  noText,
  yesButtonProps,
  yesText,
  description,
  resolveReject,
  onClose,
  ...dialogProps
}) => {
  const classes = useStyles();
  const [resolve, reject] = resolveReject;

  const handleCancel = useCallback(
    e => {
      reject();
      onClose && onClose(e, 'backdropClick');
    },
    [reject, onClose]
  );

  const handleConfirm = useCallback(
    e => {
      resolve();
      onClose && onClose(e, 'backdropClick');
    },
    [resolve, onClose]
  );

  return (
    <DefaultDialog
      onClose={onClose}
      actions={
        <>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.actionButton}
            {...noButtonProps}
            onClick={handleCancel}
          >
            {noText}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.actionButton}
            {...yesButtonProps}
            onClick={handleConfirm}
          >
            {yesText}
          </Button>
        </>
      }
      {...dialogProps}
    >
      {description && (
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            {description}
          </DialogContentText>
        </DialogContent>
      )}
    </DefaultDialog>
  );
};

export default ConfirmDialog;
