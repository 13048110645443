import React, { useEffect, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { ITemplatesByProviderType, ProviderTemplateService } from 'shared';
import CloseIcon from '@material-ui/icons/Close';

import ParentEmails from './ParentEmails/ParentEmails';
import EmailTemplate from './EmailTemplate';

const useStyles = makeStyles(() => ({
  dialogHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#212B36',
    display: 'flex',
    alignItems: 'center'
  },
  dialogSubHeading: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center'
  },
  closeModal: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#637381',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gridGap: 8
  }
}));

const providerTemplateService = ProviderTemplateService.getInstance<
  ProviderTemplateService
>();

const EmailNotification = ({
  contactIdentitiesFormRef,
  setHideActionButtons,
  setIsDirty,
  isDirty,
  onClose
}) => {
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState('');
  const classes = useStyles();

  const handleIsLeadEmailTemplate = templateType => {
    if (!templateType) {
      setHideActionButtons(false);
    } else {
      setHideActionButtons(true);
    }
    setSelectedEmailTemplate(templateType);
  };

  const [templates, setTemplates] = useState<any[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [toggleTemplateDialog, setToggleTemplateDialog] = useState<boolean>(
    false
  );

  const [groupedTemplates, setGroupedTemplates] = useState<any>(null);

  const handleEditTemplate = (templateId: string = '') => {
    if (templateId) {
      const providerTemplate = templates.filter(
        template => template.id === templateId
      )[0];
      setSelectedTemplate(providerTemplate);
    }
    setToggleTemplateDialog(!toggleTemplateDialog);
  };

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item]
      }),
      {}
    );

  useEffect(() => {
    providerTemplateService
      .getTemplatesByProvider(ITemplatesByProviderType.EMAIL_TEMPLATE)
      .then(({ data }) => {
        setTemplates(data);
        setGroupedTemplates(groupBy(data, 'groupType'));
      });
  }, []);

  return (
    <>
      <Box display="flex" gridGap={4} flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.dialogHeading}>
            Email Settings
          </Typography>
          <Typography className={classes.closeModal} onClick={onClose}>
            <CloseIcon />
            Close
          </Typography>
        </Box>
        {/* <Typography className={classes.dialogSubHeading}>
          Customize the content for your email settings
        </Typography> */}
      </Box>
      <Box>
        <ParentEmails
          handleIsLeadEmailTemplate={handleIsLeadEmailTemplate}
          contactIdentitiesFormRef={contactIdentitiesFormRef}
          setIsDirty={setIsDirty}
          isDirty={isDirty}
          onClose={onClose}
        />
      </Box>

      {selectedEmailTemplate === 'Lead Emails' && (
        <EmailTemplate
          groupedTemplates={groupedTemplates}
          toggleTemplateDialog={toggleTemplateDialog}
          handleEditTemplate={handleEditTemplate}
          selectedTemplate={selectedTemplate}
          onClose={() => {
            setSelectedEmailTemplate('');
            onClose();
          }}
          onSettingClick={() => setSelectedEmailTemplate('')}
          isOpen={true}
        />
      )}
    </>
  );
};

export default EmailNotification;
