import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
// import { AuthService } from 'src/lib/authService';
import { NewProviderService } from 'shared';

interface AuthGuardProps {
  children?: ReactNode;
}

// const authService = AuthService.getInstance();

const newProviderService = NewProviderService.getInstance<NewProviderService>();

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, user, logout } = useAuth();

  const accessToken = user ? user.access_token : null;
  var tokenExpired = false;

  if (accessToken) {
    try {
      const date = new Date(0);
      date.setUTCSeconds(user.expires_at);

      if (date < new Date()) {
        tokenExpired = true;

        // authService.logout().then(() => {
        // });

        newProviderService.logoutSucceeded().then(() => {});

        logout();
      }
    } catch (err) {
      tokenExpired = true;
      
      // authService.logout().then(() => {
      // });

      newProviderService.logoutSucceeded().then(() => {});

      logout();
    }
  }

  if (!tokenExpired) {
    if (!isAuthenticated) {
      // window.$crisp.push(['do', 'chat:hide']);
      return <Redirect to="/portal-login" />;
    } else {
      // window.$crisp.push(['do', 'chat:show']);
    }

    return (
      <>
        {children}
      </>
    );
  }
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
